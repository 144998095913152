import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert,
  Button, Image, Modal,
} from 'antd';
import {
  CloudDownloadOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { IExportDTO } from '../../../../shared/dtos/ExportDTO';

import loadingGif from '../../assets/uploading.gif';
import api from '../../services/api';

interface ExportModalProps {
  visible?: boolean;
  onCancel(): void;
  fileName: string;
  title?: string;
  prepareCall(): Promise<IExportDTO>;
}

const ExportModal: React.FC<ExportModalProps> = ({
  visible,
  onCancel,
  title = 'Export Data',
  prepareCall,
}) => {
  const [report, setReport] = useState<IExportDTO>();

  const checkReport = useCallback(async (id: number) => {
    const result = await api.get<IExportDTO>(`/api/export/${id}`);
    setReport(result);
    if (result.status !== 'FINISHED') {
      setTimeout(() => {
        checkReport(id);
      }, 3000);
    }
  }, []);

  const prepareExport = useCallback(async () => {
    const result = await prepareCall();
    setReport(result);
    checkReport(result.exportId);
  }, [checkReport, prepareCall]);

  const handlePrepareExport = useCallback(() => {
    prepareExport();
  }, [prepareExport]);

  const createAlert = useCallback((message: string | React.ReactNode, description: string | React.ReactNode) => (
        <Alert
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
          message={message}
          description={description}
          type="info"
        />
  ), []);

  const alertPanel = useMemo(() => {
    switch (report?.status || '') {
      case 'IN_PROGRESS':
        return {
          icon: <Image preview={false} width={80} height={80} src={loadingGif} />,
          content: createAlert('Exporting data', 'Please wait till export finished...'),
        };
      case 'FINISHED':
        return {
          icon: <CheckCircleOutlined style={{ fontSize: 37 }} />,
          content: createAlert('File ready for download', (
            <Button type="default" href={report?.url} download={report?.url?.split('/').reverse()[0]} icon={<DownloadOutlined />}>
              Click here to download
            </Button>
          )),
        };
      case 'IDLE':
        return {
          icon: <Image preview={false} width={80} height={80} src={loadingGif} />,
          content: createAlert('Preparing file', 'Please Wait, server is preparing file for download...'),
        };
      default:
        return {
          icon: <CloudDownloadOutlined style={{ fontSize: 37 }} />,
          content: createAlert((<Button onClick={handlePrepareExport}>Start the export process</Button>), 'Please click above to proceed with the export process'),
        };
    }
  }, [createAlert, handlePrepareExport, report?.status, report?.url]);

  useEffect(() => {
    if (visible) {
      setReport(undefined);
    }
  }, [visible]);

  return (
    <Modal
      onCancel={onCancel}
      cancelText="Close"
      okButtonProps={{
        hidden: true,
      }}
      destroyOnClose
      title={title}
      visible={visible}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
        <div style={{
          width: 90,
          height: 90,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {alertPanel.icon}
        </div>
        <div style={{
          flex: 1,
        }}>{alertPanel.content}</div>
      </div>
    </Modal>
  );
};

export default ExportModal;
