import React, {
  useCallback, useMemo,
} from 'react';
import {
  Card, Button, Typography,
} from 'antd';

import TrackingFilter from './tracking_filter';
import RangeFilter from './date_filter';
import TagsFilter from './tags_filter';
import SitesFilter from './sites_filter';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import ArticleStatusFilter from './article_status_filter';

const { Text } = Typography;

export interface IKeywordFilter {
  type: 'tracking' | 'tags' | 'date' | 'sites' | 'articleStatus'
  description: string
}

interface IBaseFilter {
  title: string
  onRemove(): void
}

export interface IChangeFilterProps {
  onChange(attr : Record<string, unknown>): void
  availableSites?: ISiteDTO[]
}

export const allFilters: IKeywordFilter[] = [
  {
    type: 'articleStatus',
    description: 'By article status',
  },
  {
    type: 'tracking',
    description: 'By tracking',
  },
  {
    type: 'sites',
    description: 'By sites',
  },
  {
    type: 'tags',
    description: 'By tags',
  },
  {
    type: 'date',
    description: 'By date',
  },
];

const gridStyle = {
  width: '100%',
  paddingTop: 10,
  paddingBottom: 15,
  paddingLeft: 15,
  paddingRight: 15,
};

const BaseFilter: React.FC<IBaseFilter> = ({ title, children, onRemove }) => {
  const handleRemove = useCallback(() => {
    onRemove();
  }, [onRemove]);
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Text type="secondary">{title}:</Text>
        {children}
      </div>
      <Button onClick={handleRemove} type="link" danger >Remove filter</Button>
    </div>
  );
};

interface IKeywordsFilterProps {
  filters: IKeywordFilter[]
  onRemoveFilter(key: string, filterNames: string[]): void
  onChange(filter: Record<string, string | number | undefined>): void
  availableSites: ISiteDTO[]
}

const KeywordsFilter: React.FC<IKeywordsFilterProps> = ({
  filters,
  onRemoveFilter,
  onChange,
  availableSites,
}) => {
  const mapFilters = useMemo(() => ({
    tracking: (
      <BaseFilter title="Tracking status" onRemove={() => onRemoveFilter('tracking', ['tracked'])}>
        <TrackingFilter onChange={onChange} />
      </BaseFilter>
    ),
    date: (
      <BaseFilter title="Creation period" onRemove={() => onRemoveFilter('date', ['createdFrom', 'createdTo'])}>
        <RangeFilter onChange={onChange} />
      </BaseFilter>
    ),
    tags: (
      <BaseFilter title="Tags" onRemove={() => onRemoveFilter('tags', ['tags'])}>
        <TagsFilter onChange={onChange} />
      </BaseFilter>
    ),
    sites: (
      <BaseFilter title="Sites" onRemove={() => onRemoveFilter('sites', ['sites'])}>
        <SitesFilter onChange={onChange} availableSites={availableSites} />
      </BaseFilter>
    ),
    articleStatus: (
      <BaseFilter title="Article Status" onRemove={() => onRemoveFilter('articleStatus', ['articleStatus'])}>
        <ArticleStatusFilter onChange={onChange} />
      </BaseFilter>
    ),
  }), [onChange, onRemoveFilter, availableSites]);

  return (
    <>
      {filters.length > 0 && (
        <Card style={{
          marginBottom: 15,
          width: 500,
        }}>
          {filters.map((filter) => (
            <Card.Grid key={filter.type} hoverable={false} style={gridStyle} >
              {mapFilters[filter.type]}
            </Card.Grid>
          ))}

        </Card>
      )}
    </>
  );
};
export default KeywordsFilter;
