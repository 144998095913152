/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';

import {
  Card,
  Divider,
  Form,
  Mentions,
  Modal,
  Typography,
} from 'antd';

import sanitizeHtml from 'sanitize-html';

import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { Option } from 'antd/lib/mentions';
import {
  ICommentUserHydratedDTO,
  ICommentDTO,
} from '../../../shared/dtos/CommentDTO';

import api from '../services/api';
import { useAuth } from '../hooks/auth';
import { IPaginatedQuery } from '../../../shared/dtos/IPaginatedQuery';
import User, { IShortUser } from '../../../shared/dtos/UserDTO';

const { Text } = Typography;
const MAX_COMMENT_LENGTH = 200;
const REGEX_MENTION = /(?<=@{)(.[0-9]*)(?=})/gi;
const REGEX_MENTION_MAIL = /(?<=@)(.[a-zA-Z0-9._-]*)(?= )/gi;

interface InputProps {
  keywordId: number
  fullscreen?: boolean
}

const timeDifference = (previous: Date) => {
  const current = new Date();
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current.getTime() - previous.getTime();

  if (elapsed < msPerMinute) {
    return `${Math.round(elapsed / 1000)} seconds ago`;
  }
  if (elapsed < msPerHour) {
    return `${Math.round(elapsed / msPerMinute)} minutes ago`;
  } if (elapsed < msPerDay) {
    return `${Math.round(elapsed / msPerHour)} hours ago`;
  } if (elapsed < msPerMonth) {
    return `approximately ${Math.round(elapsed / msPerDay)} days ago`;
  } if (elapsed < msPerYear) {
    return `approximately ${Math.round(elapsed / msPerMonth)} months ago`;
  }
  return `approximately ${Math.round(elapsed / msPerYear)} years ago`;
};

const Comments: React.FC<InputProps> = ({ keywordId, fullscreen }) => {
  const { user, handleError } = useAuth();
  const [comments, setComments] = useState<ICommentUserHydratedDTO[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [loadingMention, setLoadingMention] = useState(false);
  const [search, setSearch] = useState <string>();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IShortUser[]>([]);
  const [editCommentId, setEditCommentId] = useState();
  const [newComment, setNewComment] = useState('');
  const [updatedComment, setUpdatedComment] = useState('');
  const [form] = Form.useForm();

  const loadComments = useCallback(async (): Promise<void> => {
    try {
      const commentResults = await api.get<ICommentUserHydratedDTO[]>(`/api/keywords/${keywordId}/comments`);
      setComments(commentResults);
      const sUsers = commentResults.reduce((iUsers: IShortUser[], comment) => {
        const { mentions = [] } = comment;
        const filtered = mentions.filter((m) => !iUsers.includes(m));
        return [...filtered, ...filtered];
      }, []);
      setSelectedUsers(sUsers);
      setLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the keywords comments.', true);
    }
  }, [handleError, keywordId]);

  const handleInputChange = useCallback((value) => {
    setNewComment(value);
  }, []);

  const handleUpdateInputChange = useCallback((value) => {
    setUpdatedComment(value);
  }, []);

  const editComment = useCallback((comment) => {
    setEditCommentId(comment.comment_id);
    setUpdatedComment(comment.comment);
  }, []);

  const loadUsers = useCallback(async (): Promise<void> => {
    if (!search) {
      setUsers([]);
      return;
    }

    setLoadingMention(true);
    const result = await api.get<IPaginatedQuery<User>>('/api/users', {
      params: {
        query: search,
      },
    });
    const { nodes } = result;
    setLoadingMention(false);
    setUsers(nodes);
  }, [search]);

  const onSearch = useCallback((iSearch: string) => {
    setSearch(iSearch);
    loadUsers();
  }, [loadUsers]);

  const parseNameToTag = useCallback((content: string) => {
    const mentionNames = content.match(REGEX_MENTION_MAIL) as string[];
    if (!mentionNames) {
      return content;
    }
    const parsedContent = mentionNames.reduce((parse, mentionName) => {
      const selectedUser = selectedUsers.find((us) => `${us.first_name}${us.last_name}` === mentionName);
      if (!selectedUser) {
        return parse;
      }

      const replaced = parse.replaceAll(`@${mentionName}`, `@{${selectedUser.id}}`);
      return replaced;
    }, content);
    return parsedContent;
  }, [selectedUsers]);

  const parseTagToName = useCallback((comment: ICommentUserHydratedDTO) => {
    const mentionIds = comment.comment.match(REGEX_MENTION) as string[];
    if (!mentionIds) {
      return comment.comment;
    }
    const parsedContent = mentionIds.reduce((parse, mentionId) => {
      const selectedUser = comment.mentions.find((us) => us.id === Number(mentionId));
      if (!selectedUser) {
        return parse;
      }
      const replaced = parse.replaceAll(`@{${mentionId}}`, `@${selectedUser.first_name}${selectedUser.last_name}`);
      return replaced;
    }, comment.comment);
    return parsedContent;
  }, []);

  const createMentionedUser = (shortUser: IShortUser) => {
    const userName = `${shortUser.first_name} ${shortUser.last_name}`;
    return `<span style='font-weight: bold;'>${userName}</span>`;
  };

  const parseTagToContent = useCallback((comment: ICommentUserHydratedDTO) => {
    const { mentions = [] } = comment;
    const commentContent = mentions.reduce((tmpContent, shortUser) => {
      const tmp = tmpContent.replaceAll(`@{${shortUser.id}}`, `${createMentionedUser(shortUser)}`);
      const clean = sanitizeHtml(tmp, {
        allowedTags: ['span'],
        allowedAttributes: {
          'span': ['style'],
        },
      });
      return clean;
    }, comment.comment);
    return commentContent;
  }, []);

  const deleteComment = useCallback((comment) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this comment?\nThis action can't be undone.",
      async onOk() {
        try {
          await api.delete<ICommentDTO>(
            `/api/keywords/${keywordId}/comments/${comment.comment_id}`,
          );
          loadComments();
        } catch (err) {
          handleError(err, 'There was a problem deleting the keyword comment.', true);
        }
      },
    });
  }, [handleError, keywordId, loadComments]);

  const handleCommentSubmit = useCallback(async (): Promise<void> => {
    const body = {
      keyword_id: keywordId,
      user_id: user.id,
      comment: parseNameToTag(newComment),
    };
    try {
      await api.post<ICommentDTO>(
        `/api/keywords/${keywordId}/comments`,
        { body },
      );
      form.resetFields();
      setNewComment('');
      loadComments();
    } catch (err) {
      handleError(err, 'There was a problem creating the keyword comment.', true);
    }
  }, [form, handleError, keywordId, loadComments, newComment, parseNameToTag, user.id]);

  const handleCommentUpdate = useCallback(async (): Promise<void> => {
    const body = {
      comment: parseNameToTag(updatedComment),
    };
    try {
      await api.patch<ICommentDTO>(
        `/api/keywords/${keywordId}/comments/${editCommentId}`,
        { body },
      );
      setEditCommentId(undefined);
      loadComments();
    } catch (err) {
      handleError(err, 'There was a problem updating the keyword comment.', true);
    }
  }, [parseNameToTag, updatedComment, keywordId, editCommentId, loadComments, handleError]);

  const addSelectedUserById = useCallback((userId: number) => {
    const newUser = users.find((us) => us.id === userId);
    if (newUser) {
      setSelectedUsers((previous) => [...previous, newUser]);
    }
  }, [users]);

  const createMentionInput = useCallback((handleInput, handleEnter, defaultValue) => (
      <Mentions
        maxLength={MAX_COMMENT_LENGTH}
        filterOption={false}
        onChange={handleInput}
        onPressEnter={handleEnter}
        defaultValue={defaultValue}
        placeholder="Comments here. Use @mentions to get someone's attention"
        rows={2}
        onSelect={(newUser) => addSelectedUserById(Number(newUser.key))}
        loading={loadingMention}
        onSearch={onSearch}>
        {users.map(({
          first_name: firstName, last_name: lastName, username, id,
        }) => (
          <Option key={`${id}`} value={`${firstName}${lastName}`} className="antd-demo-dynamic-option">
            <span>{`${firstName} ${lastName}`}</span>
            <div style={{
              fontStyle: 'italic',
            }}>
              {username}
            </div>
          </Option>
        ))}
      </Mentions>
  ), [addSelectedUserById, loadingMention, onSearch, users]);

  const renderComentItem = useCallback((comment: ICommentUserHydratedDTO) => {
    if (comment.comment_id === editCommentId) {
      const initialContent = parseTagToName(comment);
      return (
        <div key={comment.comment_id}>
          <Text strong>{comment.first_name}{' '}{comment.last_name}</Text>{'  -  '}
          <Text type={'secondary'}>{timeDifference(new Date(comment.created_at))}</Text>
          <DeleteOutlined style={{ float: 'right', color: '#999', cursor: 'pointer' }} onMouseDown={() => deleteComment(comment)} />
          {createMentionInput(handleUpdateInputChange, handleCommentUpdate, initialContent)}
          <Divider />
        </div>
      );
    }

    const commentContent = parseTagToContent(comment);
    return (
        <div key={comment.comment_id}>
          <Text strong>{comment.first_name}{' '}{comment.last_name}</Text>{'  -  '}
          <Text type={'secondary'}>{timeDifference(new Date(comment.created_at))}</Text>
          {
            user.id === comment.user_id
            && <EditOutlined
              style={{ float: 'right', color: '#999', cursor: 'pointer' }}
              onClick={() => editComment(comment)}
            />
          }
          <div style={{
            color: '##5d5d5d',
          }} dangerouslySetInnerHTML={{ __html: commentContent }} />
          <Divider />
        </div>
    );
  }, [createMentionInput, deleteComment, editComment, editCommentId, handleCommentUpdate, handleUpdateInputChange, parseTagToContent, parseTagToName, user.id]);

  const renderComments = useMemo(() => {
    const commentsItems = comments.map((comment) => renderComentItem(comment));
    return (
      <>
        {
          commentsItems
        }
      </>
    );
  }, [comments, renderComentItem]);

  useEffect(() => {
    loadComments();
  }, [loadComments]);

  return (
    <Card style={{
      paddingBottom: 10,
      overflowY: 'auto',
      height: fullscreen ? '100vh' : 360,
    }} >
      {
        isLoading ? (
          <LoadingOutlined spin />
        ) : renderComments
      }
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name='comment'
          label='Add New Comment'
        >
          {createMentionInput(handleInputChange, handleCommentSubmit, '')}
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Comments;
