import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Table,
  Tag,
  Typography,
} from 'antd';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface IProduct {
  ASIN: string
  AVAILABILITY: string
  AWARD: string
  IMAGE_URL: string
  NAME: string
  REVENUE: number
}

interface IContent {
  ARTICLE_URL: string
  EARNINGS: number
  FLAGS: string[]
  MODIFIED_DATE: string
  PERMALINK: string
  POST_DATE: string
  POST_ID: number
  POST_STATUS: string
  POST_TITLE: string
  PRODUCT_LIST: string[]
  SITE: string
}

interface IContentProductTable {
  record: IContent
  showFlags: boolean
}

const { Link } = Typography;

const ContentProductTable: React.FC<IContentProductTable> = ({
  record,
  showFlags,
}) => {
  const { handleError } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState<IProduct[]>();

  const columns = [
    {
      title: 'Image',
      dataIndex: 'IMAGE_URL',
      key: 'IMAGE_URL',
      render: (imageUrl: string) => (
        <img src={imageUrl} width={100} />
      ),
    },
    {
      title: 'Award',
      dataIndex: 'AWARD',
      key: 'AWARD',
    },
    {
      title: 'ASIN',
      dataIndex: 'ASIN',
      key: 'ASIN',
      render: (asin: string) => (
        <Link
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          href={`https://www.amazon.com/dp/${asin}`}
          target={'_blank'}
        >
          {asin}
        </Link>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'NAME',
      key: 'NAME',
    },
    {
      title: 'Earnings',
      dataIndex: 'REVENUE',
      key: 'REVENUE',
      render: (revenue: number) => (revenue ? `$${revenue}` : '$0.00'),
    },
    {
      title: 'Availability',
      dataIndex: 'AVAILABILITY',
      key: 'AVAILABILITY',
      render: (stockStatus: string) => (
        <Tag color={stockStatus === 'Now' ? 'green' : 'volcano'}>{stockStatus || 'Unknown'}</Tag>
      ),
    },
  ];

  const fetchProductData = useCallback(async () => {
    try {
      const products = await api.get<IProduct[]>(
        `/api/snowflake/products?site=${record.SITE}&postId=${record.POST_ID}&asins[]=${record?.PRODUCT_LIST?.join('&asins[]=')}`,
      );
      setProductData(products);
      setIsLoading(false);
    } catch (e) {
      handleError(e);
    }
  }, [handleError, record.POST_ID, record?.PRODUCT_LIST, record.SITE]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]);

  return (
    <>
      <div style={{ boxShadow: 'rgb(178 212 255) 0px 0px 15px 10px' }}>
        <Table
          loading={isLoading}
          rowKey={(product) => product.ASIN}
          columns={columns}
          dataSource={
            showFlags
              ? productData?.filter((product: IProduct) => product.AVAILABILITY !== 'Now')
              : productData
          }
          pagination={false}
        />
      </div>
    </>
  );
};

export default ContentProductTable;
