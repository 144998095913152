import React, { useCallback } from 'react';

import { Checkbox } from 'antd';

import { IChangeFilterProps } from '.';

const SitesFilter: React.FC<IChangeFilterProps> = ({ onChange, availableSites }) => {
  const handleChangeSites = useCallback((siteIds) => {
    onChange({
      sites: siteIds,
    });
  }, [onChange]);

  const siteMapper = availableSites?.map((site) => ({
    label: (
        <div key={site.site_id} style={{ display: 'inline-block', width: '150px', padding: 2 }}>
          <img src={site.logo_url} width={18} />
          {' '}{site.display_name}
        </div>
    ),
    value: site.site_id,
  }));

  return (
    <div>
      <Checkbox.Group
        options={siteMapper}
        onChange={handleChangeSites}
      />
    </div>
  );
};

export default SitesFilter;
