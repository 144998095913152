export const DEFAULT_USER = 'dt';

enum ROLES {
  'adm',
  'opm',
  'qa',
  'eng',
  'wrt',
}

export enum PERMISSIONS {
  ViewKeywords = 'ViewKeywords',
  ViewTools = 'ViewTools',
  KeywordsOpportunities = 'KeywordsOpportunities',
  KeywordsUpload = 'KeywordsUpload',
  ManageUsers = 'ManageUsers',
  SearchUsers = 'SearchUsers',
  ManageSites = 'ManageSites',
  ManageWebhooks = 'ManageWebhooks',
  ManageFeatureFlags = 'ManageFeatureFlags',
  SearchSites = 'SearchSites',
  CreateUser = 'CreateUser',
  UpdateUser = 'UpdateUser',
  Profile = 'Profile',
  ManageScan = 'ManageScan',
  EditProfile = 'EditProfile',
  ChangePassword = 'ChangePassword',
  ManageArticles = 'ManageArticles',
  ManageProducts = 'ManageProducts',
  PendingArticles = 'PendingArticles',
  ValidateArticles = 'ValidateArticles',
  CreateArticle = 'CreateArticle',
  SearchArticles = 'SearchArticles',
  KeywordResearch = 'KeywordResearch',
  ManageExcludedWords = 'ManageExcludedWords',
  Bot = 'Bot',
  CommissionCategories = 'CommissionCategories',
  ManageCommissionCategories = 'ManageCommissionCategories',
  UserAccess = 'UserAccess',
}

export type RoleType = keyof typeof ROLES;

export type PermissionType = keyof typeof PERMISSIONS;

type PermissionAttr = {
  [permission in PermissionType]: boolean | PermissionAttr;
};

type PermissionMap = {
  [role in RoleType]: PermissionAttr;
};

const PERMISSIONS_MAP = {
  adm: {
    Profile: {
      EditProfile: true,
      ChangePassword: true,
    },
    ViewKeywords: {
      KeywordsOpportunities: true,
      KeywordsUpload: true,
    },
    ManageSites: {
      SearchSites: true,
      ManageWebhooks: false,
    },
    ManageUsers: {
      CreateUser: true,
      UpdateUser: true,
      SearchUsers: true,
    },
    ManageArticles: {
      ValidateArticles: true,
      PendingArticles: true,
      SearchArticles: true,
      CreateArticle: true,
    },
    ViewTools: {
      KeywordResearch: true,
    },
    ManageFeatureFlags: false,
    ManageProducts: true,
    ManageExcludedWords: true,
    ManageScan: true,
    Bot: true,
    CommissionCategories: true,
    ManageCommissionCategories: true,
    UserAccess: true,
  },

  opm: {
    Profile: {
      EditProfile: true,
      ChangePassword: true,
    },
    ViewKeywords: {
      KeywordsOpportunities: true,
      KeywordsUpload: true,
    },
    ManageArticles: {
      PendingArticles: true,
      SearchArticles: true,
      CreateArticle: true,
    },
    ViewTools: {
      KeywordResearch: true,
    },
    ManageProducts: true,
    CommissionCategories: true,
    ManageCommissionCategories: true,
  },

  qa: {
    Profile: {
      EditProfile: true,
      ChangePassword: true,
    },
    ViewKeywords: {
      KeywordsOpportunities: true,
      KeywordsUpload: true,
    },
    ManageArticles: {
      ValidateArticles: true,
      PendingArticles: true,
      SearchArticles: true,
      CreateArticle: true,
    },
    ViewTools: {
      KeywordResearch: true,
    },
    ManageProducts: true,
    CommissionCategories: true,
    ManageCommissionCategories: true,
  },

  eng: {
    Profile: {
      EditProfile: true,
      ChangePassword: true,
    },
    ViewKeywords: {
      KeywordsOpportunities: true,
      KeywordsUpload: true,
    },
    ManageSites: {
      SearchSites: true,
      ManageWebhooks: true,
    },
    ManageArticles: {
      ValidateArticles: true,
      PendingArticles: true,
      SearchArticles: true,
      CreateArticle: true,
    },
    ManageUsers: {
      SearchUsers: true,
    },
    ManageScan: true,
    ViewTools: {
      KeywordResearch: true,
    },
    ManageFeatureFlags: true,
    ManageProducts: true,
    ManageExcludedWords: true,
    Bot: true,
    CommissionCategories: true,
    ManageCommissionCategories: true,
  },

  wrt: {
    ViewTools: {
      KeywordResearch: true,
    },
  },
} as PermissionMap;

const findDeepKeys = (o: PermissionAttr): PermissionType[] => {
  let result: PermissionType[] = [];
  Object.keys(o).forEach((k) => {
    const p = k as unknown as PermissionType;
    if (o[p] === true) {
      result.push(p);
    }
    if (o[p] !== false) {
      result.push(p);
      const oAsPermissionAttr = o[p] as PermissionAttr;
      const subresults = findDeepKeys(oAsPermissionAttr);
      result = [...result, ...subresults];
    }
  });
  return result;
};

export default class Auth {
  public static isPermitted(role: RoleType, ...permissionsToCheck: PermissionType[]): boolean {
    if (!role || !permissionsToCheck) {
      return true;
    }
    const map = PERMISSIONS_MAP[role];
    const keys = findDeepKeys(map);
    const indexPermission = permissionsToCheck.findIndex((check) => keys.includes(check));
    return indexPermission > -1;
  }
}
