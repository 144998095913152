import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Checkbox,
  Form,
  Input,
  Modal,
  Switch,
} from 'antd';

import api from '../services/api';
import Keyword, { Tag } from '../../../shared/dtos/KeywordDTO';
import { ISiteDTO } from '../../../shared/dtos/SiteDTO';
import InputTags, { ISelectedTag } from './InputTags';
import { useAuth } from '../hooks/auth';

interface INewKeywordModelProps {
  editKeyword?: Keyword
  availableSites: ISiteDTO[]
  visible?: boolean
  onClose?(): void
  onSuccess?(newKeyword: Keyword): void
}

interface IKeywordFormData {
  keyword: string
  tracked: boolean
  tags: Tag[]
  sites: ISiteDTO[]
}

const defaultFormState = {
  keyword: '',
  tracked: false,
  tags: [],
  sites: [],
};

const NewKeywordModal: React.FC<INewKeywordModelProps> = ({
  editKeyword, availableSites, visible, onClose, onSuccess,
}: INewKeywordModelProps) => {
  const { handleError } = useAuth();
  const [tags, setTags] = useState<string[]>([]);
  const [sites, setSites] = useState<number[]>([]);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editKeyword) {
      form.setFieldsValue({
        keyword: editKeyword.keyword,
        tracked: editKeyword.is_tracked,
        tags: editKeyword.tags,
        sites: editKeyword?.sites?.map((site) => site.site_id) || [],
      });
      setTags(editKeyword.tags.map((tag) => tag.tag));
    } else {
      form.setFieldsValue(defaultFormState);
    }
  }, [editKeyword, form, visible]);

  const handleCloseModal = useCallback(() => {
    form.setFieldsValue(defaultFormState);
    setTags([]);
    if (onClose) {
      onClose();
    }
  }, [form, onClose]);

  const handleChangeTags = useCallback((newTags: ISelectedTag[]) => {
    setTags(newTags.map((nt) => nt.tag));
  }, []);

  const handleChangeSites = useCallback((newSites) => {
    setSites(newSites);
  }, []);

  const handleKeywordSubmit = useCallback(async (keywordFormValues: IKeywordFormData) => {
    setSaving(true);
    const body = {
      ...keywordFormValues,
      tags: tags.map((tag) => ({ tag })),
      sites,
      tracked: !!keywordFormValues.tracked,
    };

    try {
      let result;
      if (editKeyword) {
        result = await api.patch<Keyword>(`/api/keywords/${editKeyword.keyword_id}`, {
          body,
        });
      } else {
        result = await api.post<Keyword>('/api/keywords', {
          body,
        });
      }

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (err) {
      handleError(err, 'Error saving keyword.');
    } finally {
      setSaving(false);
    }
  }, [tags, sites, editKeyword, onSuccess, handleError]);

  const buttonOkLabel = useMemo(() => (saving ? 'Saving' : 'Submit'), [saving]);

  const siteMapper = availableSites.map((site) => ({
    label: (
        <div key={site.site_id} style={{ display: 'inline-block', width: '150px', padding: 2 }}>
          <img src={site.logo_url} width={18} />
          {' '}{site.display_name}
        </div>
    ),
    value: site.site_id,
  }));

  const tagsToEdit = useMemo(() => {
    if (!editKeyword) {
      return [];
    }
    return editKeyword.tags;
  }, [editKeyword]);

  return (
    <Modal
      title={ editKeyword
        ? 'Edit Keyword'
        : 'New Keyword'
      }
      visible={visible}
      onOk={() => form.submit()}
      confirmLoading={saving}
      okText={buttonOkLabel}
      onCancel={handleCloseModal}
    >

      <Form
        form={form}
        onFinish={handleKeywordSubmit}
        name='keywordForm'
        layout='horizontal'
        labelCol={{ span: 5 }}
        style={{ margin: '20px' }}
        className='App-form'
      >
        <Form.Item
          label='Keyword'
          name='keyword'
          rules={[{ required: true, message: 'Please input a keyword!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Tags'
          name='tags'
        >
          <InputTags editTags={tagsToEdit} onChangeTags={handleChangeTags} />
        </Form.Item>

        <Form.Item label='Track' name='tracked' valuePropName={'checked'}>
          <Switch />
        </Form.Item>
        <Form.Item label='Sites' name='sites'>
          <Checkbox.Group options={siteMapper} onChange={handleChangeSites} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewKeywordModal;
