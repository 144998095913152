/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useMemo } from 'react';

import {
  Card,
  Divider,
  Rate,
} from 'antd';

import Text from 'antd/lib/typography/Text';
import Link from 'antd/lib/typography/Link';
import { IAmazonArticleProductDTO } from '../../../../shared/dtos/ArticleDTO';
import ComparisonImage from '../Articles/ArticleComparison/comparison_image';
import { formatCurrency } from '../../utils';

interface AmazonProductViewProps {
  product: IAmazonArticleProductDTO
  styles?: React.CSSProperties
}

const centerRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
} as React.CSSProperties;

const badgeStyles = {
  color: 'white',
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '2px 12px',
} as React.CSSProperties;

const AmazonProductView: React.FC<AmazonProductViewProps> = ({
  product, styles,
}) => {
  const priceTxt = useMemo(() => {
    const { price, priceLimit } = product;
    if (price) {
      const priceT = formatCurrency(price);
      if (price && priceLimit) {
        const priceLimitT = formatCurrency(priceLimit);
        return `${priceT} - ${priceLimitT}`;
      }
      return priceT;
    }
    return undefined;
  }, [product]);

  return (
  <Card
    bordered={false}
    bodyStyle={{
      borderColor: product.oos ? '#C70039' : '#232F3E',
      borderWidth: product.oos ? 3 : 1,
      borderStyle: 'solid',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '25px 14px 14px',
      ...styles,
    }}>
    <div>
      <span style={{
        ...badgeStyles,
        backgroundColor: product.oos ? '#C70039' : '#232F3E',
      }}>
        {product.award}
      </span>
      <div style={{
        marginRight: 16,
        minWidth: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <ComparisonImage size={100} src={product.image} />
      </div>
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Link
        style={{
          fontSize: 12,
        }}
        className="App-Link"
        target="_blank"
        href={`https://www.amazon.com/dp/${product.asin}`}
        >
        {product.name}
      </Link>
      <Text type="secondary">by {product.offerBy}</Text>
      <div style={{
        ...centerRowStyle,
        justifyContent: 'space-between',
      }}>
        <span style={centerRowStyle}>
          <Rate
            className="App-Rate-Small"
            disabled
            allowHalf
            defaultValue={product.rate} />
          <Text
            style={{
              fontSize: 12,
              marginLeft: 4,
              marginTop: 7,
            }}
            type="secondary">
            {product.totalRatings}
          </Text>
        </span>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
          {product.oldPrice && (
            <Text
              type="secondary"
              style={{
                fontSize: 12,
                marginRight: 4,
              }}
              delete >
              {formatCurrency(product.oldPrice)}
            </Text>
          )}
          {product.price && (
            <Text
              strong
              style={{
                fontSize: 16,
                marginRight: 4,
              }}>
                {priceTxt}
            </Text>
          )}
          {product.oos && (
            <Text
              style={{
                fontSize: 12,
                marginRight: 4,
                fontWeight: 'bold',
              }}
              type="danger">
              Out Of Stock
            </Text>
          )}
        </div>
      </div>
      <Divider style={{
        margin: '8px 0',
      }} />
      {product.offerAward && (
        <Text>{product.offerAward}</Text>
      )}
      {product.highlight && (
        <Text type="secondary">{product.highlight}</Text>
      )}
    </div>
  </Card>
  );
};

export default AmazonProductView;
