import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Typography,
  Image,
  Tooltip,
  Divider,
  Row,
  Col,
  Space,
  Popover,
} from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  format,
  formatDistance,
  parseISO,
} from 'date-fns';
import confirm from 'antd/lib/modal/confirm';
import User from '../../../../shared/dtos/UserDTO';
import { IArticle } from '../../../../shared/dtos/ArticleDTO';
import { IProduct } from '../../../../shared/dtos/ProductDTO';
import api from '../../services/api';
import { IMAGE_NOT_FOUND_AVG } from '../../utils';
import { useAuth } from '../../hooks/auth';
import { IArticleReturn } from '../../../../shared/dtos/ArticleReturnDTO';
import UserLink from '../Users/user_link';
import { handleCancelArticle } from './article_actions';
import ArticleStatusText from './article_status_text';

const { Text, Link } = Typography;

interface IArticlePreviewPopoverProps {
  article: IArticle
  onDelete?(): void
  onError?(error: string): void
  forceUpdate?(article: IArticle): void
  onEdit?(article: IArticle, resubmit?: boolean): void
}

const ArticlePreviewPopover: React.FC<IArticlePreviewPopoverProps> = ({
  article, onError, forceUpdate, onEdit, onDelete, children,
}) => {
  const { handleError } = useAuth();
  const [visible, setVisible] = useState(false);
  const [resync, setResync] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [returns, setReturns] = useState<IArticleReturn[]>([]);
  const [articleLoaded, setArticleLoaded] = useState(false);
  const [articleData, setArticleData] = useState<IArticle>(article);

  const handleOnError = useCallback((err) => {
    if (onError) {
      onError(err.message);
    }
  }, [onError]);

  const handleOnDelete = useCallback(() => {
    if (onDelete) {
      onDelete();
    }
  }, [onDelete]);

  const handleOnEdit = useCallback((iArticle: IArticle, resubmit?: boolean) => {
    if (onEdit) {
      onEdit(iArticle, resubmit);
    }
  }, [onEdit]);

  const handleResyncCard = useCallback(async () => {
    if (articleData.gid) {
      setResync(true);
      try {
        await api.post<IArticle>(`/api/articles/${articleData.article_id}/sync`, {});
      } catch (err) {
        handleOnError(err);
      } finally {
        setResync(false);
      }
    }
  }, [articleData.article_id, articleData.gid, handleOnError]);

  const [createdAt, dtAgo] = useMemo(() => {
    if (!articleData.created_at) {
      return ['', ''];
    }
    const dtString = parseISO(articleData.created_at.toString());
    const dtAgoFormatted = formatDistance(dtString, new Date(), { addSuffix: true });
    return [format(dtString, 'yyyy-MM-dd HH:mm'), dtAgoFormatted];
  }, [articleData.created_at]);

  const handleDeleteArticle = useCallback(() => {
    if (returns.length > 0) {
      handleCancelArticle({
        article,
        callback: () => {
          handleOnDelete();
        },
      });
      return;
    }
    confirm({
      title: 'Are you sure you want to delete this article?',
      icon: <ExclamationCircleOutlined />,
      content: 'This operation cannot be undone',
      okText: 'Yes',
      zIndex: 9000,
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await api.delete(`/api/articles/${articleData.article_id}`);
          handleOnDelete();
        } catch (err) {
          handleOnError(err);
        }
      },
    });
  }, [article, articleData.article_id, handleOnDelete, handleOnError, returns.length]);

  const handleEditArticle = useCallback(() => {
    setVisible(false);
    const articleToEdit = { ...articleData, returns, products };
    handleOnEdit(articleToEdit);
  }, [articleData, returns, products, handleOnEdit]);

  const footerContent = useMemo(() => {
    if (!onDelete && !onEdit && !forceUpdate) {
      return undefined;
    }
    return (
      <>
        <Divider style={{
          marginTop: 5,
          marginBottom: 10,
        }} />
        <Row>
          <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleDeleteArticle}
              type="primary"
              loading={!articleLoaded}
              danger
              size="small"
              style={{ fontSize: 12, width: 80 }}
              icon={<DeleteOutlined />}>Delete</Button>
          </Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={handleEditArticle}
              type="primary"
              size="small"
              style={{ fontSize: 12, width: 80 }}
              icon={<EditOutlined />}>Edit</Button>
          </Col>
        </Row>
      </>
    );
  }, [onDelete, onEdit, forceUpdate, handleDeleteArticle, articleLoaded, handleEditArticle]);

  const cardLabel = useMemo(() => {
    if (articleData.status) {
      const humanized = `${articleData.status.charAt(0).toUpperCase()}${articleData.status.slice(1)}`;
      return humanized;
    }
    return '';
  }, [articleData.status]);

  const summaryContent = useMemo(() => (
      <p style={{ fontSize: 10 }}>
        <strong>ID </strong> {articleData.article_id}<br />
        <strong style={{ marginTop: 12 }}>Site </strong> {articleData.site?.display_name}<br />
        <strong>Status </strong> {cardLabel}<br />
        <strong>Requested by </strong> <UserLink user={articleData.user as User} /><br />
      </p>
  ), [articleData.article_id, articleData.site?.display_name, articleData.user, cardLabel]);

  const wordPressContent = useMemo(() => (
        <>
          {articleData.wordpress_id && (
            <>
              <strong>Wordpress: </strong> {articleData.wordpress_id}<br />
              <Divider style={{
                marginTop: 3,
                marginBottom: 2,
              }} />
            </>
          )}
        </>
  ), [articleData.wordpress_id]);

  const asanaContent = useMemo(() => {
    if (articleData.gid) {
      return (
        <>
          <strong>Asana: </strong> <Link target="_blank" href={articleData.asana_url}>{articleData.gid}</Link>
          {forceUpdate && (
            <Link onClick={() => handleResyncCard()} style={{
              marginLeft: 10,
            }}>
              <SyncOutlined spin={resync} />
            </Link>
          )}
          <br />
          <strong>Section: </strong> {articleData.asana_section?.name}<br />
          {wordPressContent}
          <Divider style={{
            marginTop: 3,
            marginBottom: 2,
          }} />
        </>
      );
    }
    return (<></>);
  }, [articleData.gid, articleData.asana_url, articleData.asana_section?.name, forceUpdate, resync, wordPressContent, handleResyncCard]);

  const infoContent = useMemo(() => {
    const imageContent = articleData.image_url || IMAGE_NOT_FOUND_AVG;

    return (
      <div style={{
        minWidth: 280,
      }}>
        <Text style={{ maxWidth: 230 }} ellipsis={{ tooltip: true }}>
          {articleData.title}
        </Text>
        <Tooltip title={createdAt}>
          <Text type="secondary" style={{
            marginLeft: 5,
            fontSize: 7,
            float: 'right',
          }}>
            {dtAgo}
          </Text>
        </Tooltip>
        <br />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 6,
        }}>
          <Image
              fallback={IMAGE_NOT_FOUND_AVG}
              preview={false}
              style={{ height: 60, width: 'auto' }}
              src={imageContent}
            />
          <div style={{ marginLeft: 10 }}>
            {summaryContent}
          </div>
        </div>
        <div style={{ fontSize: 10 }}>
          {asanaContent}
          <strong>Products:<br /></strong>
          {products.length === 0 ? (
            <Text type="secondary">None</Text>
          ) : (
            <Space direction="vertical">
              {products.map((product) => (
                <span key={product.product_id}>
                  {product.display_name || product.name} ({product.asin})<br />
                  {product.award}
                </span>
              ))}
            </Space>
          )}
        </div>
        {footerContent}
      </div>
    );
  }, [articleData.image_url, articleData.title, createdAt, dtAgo, summaryContent, asanaContent, products, footerContent]);

  const handleFindDependencies = useCallback(async (iVisible: boolean): Promise<void> => {
    setVisible(iVisible);
    if (iVisible) {
      try {
        const detailedArticle = await api.get<IArticle>(`/api/articles/${article.article_id}`);
        setArticleData(detailedArticle);
        setProducts(detailedArticle.products || []);
        setReturns(detailedArticle.returns || []);
        setArticleLoaded(true);
      } catch (err) {
        handleError(err, 'Could not find the article');
      }
    }
  }, [article.article_id, handleError]);

  const contentChildren = useMemo(() => {
    if (children) {
      return (
        <a>
          {children}
        </a>
      );
    }
    return (
      <Button type="link" style={{ padding: 0 }}>
          <ArticleStatusText style={{
            width: 170,
          }} status={articleData.status} />
        </Button>
    );
  }, [articleData.status, children]);

  return (
    <Popover
      content={infoContent}
      trigger="click"
      visible={visible}
      onVisibleChange={handleFindDependencies}
    >
      {contentChildren}
    </Popover>
  );
};

export default ArticlePreviewPopover;
