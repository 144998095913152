import React, {
  useCallback, useEffect, useState,
} from 'react';

import {
  Modal,
  Form,
  Input,
  Button,
} from 'antd';
import api from '../services/api';
import User from '../../../shared/dtos/UserDTO';
import { useAuth } from '../hooks/auth';

interface IChangePasswordModalProps {
  visible?: boolean;
  onClose(): void;
  onSuccess(changedUser: User): void;
}

interface IChangePasswordFormData {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const ChangePasswordModal: React.FC<IChangePasswordModalProps> = ({
  visible, onClose, onSuccess,
}: IChangePasswordModalProps) => {
  const { handleError } = useAuth();
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const validateNewPasswordConfirmation = useCallback(async (rule, value): Promise<void> => {
    const newPasswordValue = form.getFieldValue('newPassword');
    if (newPasswordValue === value) {
      await Promise.resolve();
    } else {
      await Promise.reject(new Error());
    }
  }, [form]);

  const handleSubmit = useCallback(async ({ password, newPassword }: IChangePasswordFormData) => {
    setSaving(true);

    try {
      const result = await api.patch<User>('/api/profile/password', {
        body: {
          password, newPassword,
        },
      });
      onSuccess(result);
      return;
    } catch (err) {
      handleError(err, 'Error changing password.');
    } finally {
      setSaving(false);
    }
  }, [handleError, onSuccess]);

  return (
    <Modal
      title="Change Password"
      visible={visible}
      onOk={() => form.submit()}
      confirmLoading={saving}
      okText="Submit"
      onCancel={handleCloseModal}
      footer={[
        <Button key="cancel" onClick={handleCloseModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          loading={saving}
          onClick={() => form.submit()}
        >
          Submit
          </Button>,
      ]}>

      <Form
        form={form}
        onFinish={handleSubmit}
        name='keywordForm'
        layout='horizontal'
        labelCol={{ span: 7 }}
        style={{ margin: '10px' }}
        className='App-form'
      >
        <Form.Item
          label='Current'
          name='password'
          rules={[{ required: true, message: 'Please input the current password!' }]}
        >
          <Input.Password placeholder="Current password" />
        </Form.Item>

        <Form.Item
          label='New Password'
          name='newPassword'
          rules={[{ required: true, message: 'Please input the new password!' }]}
        >
          <Input.Password placeholder="New password" />
        </Form.Item>

        <Form.Item
          label='Confirmation'
          name='newPasswordConfirmation'
          rules={[
            { required: true, message: 'Please confirm the new password!' },
            { validator: validateNewPasswordConfirmation, message: 'Must be equals new password' },
          ]}
        >
          <Input.Password placeholder="New password confirmation" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
