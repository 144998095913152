import React, {
  useCallback, useEffect,
} from 'react';

import { Radio } from 'antd';
import { IChangeFilterProps } from '.';

const TrackingFilter: React.FC<IChangeFilterProps> = ({ onChange }) => {
  const handleChangeTrack = useCallback((value) => {
    onChange({
      tracked: value.target.value,
    });
  }, [onChange]);

  useEffect(() => {
    onChange({
      tracked: 'true',
    });
  }, [onChange]);

  return (
  <Radio.Group name='tracked' defaultValue="true" onChange={handleChangeTrack}>
    <Radio.Button value="true" >Tracked</Radio.Button>
    <Radio.Button value="false">Not tracked</Radio.Button>
  </Radio.Group>
  );
};

export default TrackingFilter;
