import React from 'react';

import { useParams } from 'react-router-dom';
import HeaderPanel from '../../../components/header_panel';
import UserDetails from '../../../components/Users/user_details';

interface ShowUserParams {
  id: number
}

const ShowUser: React.FC = () => {
  const { id } = useParams() as unknown as ShowUserParams;

  return (
    <>
      <HeaderPanel
        title="User Details"
        actions={[
          {
            label: 'Users',
            route: '/users',
          },
        ]}
        />
        <UserDetails
          id={id} />
    </>
  );
};

export default ShowUser;
