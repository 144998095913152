/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './styles.scss';
import { formatDistance } from 'date-fns';
import {
  Button,
  Select,
  Form,
  Input,
  Spin,
  Tooltip,
  notification,
} from 'antd';

import {
  SendOutlined, WarningFilled, PicCenterOutlined,
} from '@ant-design/icons';
import { parseISO } from 'date-fns/esm';
import asanaBotAvatar from '../../assets/bot_avatar.png';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import {
  IBotContext,
  IBotMessage, IBotOption,
} from '../../../../shared/dtos/BotDTO';

export interface IChatMessage {
  message: string
  userId?: number
  date: Date
  type?: string
  error?: boolean
  options?: IBotOption[]
}

export interface ICreatetMessage {
  message: string
  context?: IBotContext
}

const ChatBot: React.FC = () => {
  const { signOut } = useAuth();
  const inputRef = useRef<Input>(null);
  const selectRef = useRef<HTMLInputElement>(null);
  const [started, setStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [search, setSearch] = useState('');
  const { handleError, user } = useAuth();
  const [form] = Form.useForm();
  const [messages, setMessages] = useState<IBotMessage[]>([]);

  const scrollToBottom = () => {
    const chat = document.getElementById('chatList');
    if (chat) {
      chat.scrollTop = chat.scrollHeight;
    }
  };

  const loadMessages = useCallback(async () => {
    const iMessages = await api.get<IBotMessage[]>('/api/bot');
    if (iMessages.length > 0) {
      setLoading(false);
      setSending(false);
    }
    setMessages((previous) => {
      if (iMessages.length > previous.length) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
      return iMessages;
    });
  }, []);

  useEffect(() => {
    if (started) {
      const pingCode = setInterval(() => {
        loadMessages().catch((err) => {
          clearInterval(pingCode);
          notification.error({
            key: 'error-handler',
            message: err.message,
            description: 'Please reload the page to start a new chat',
          });
          if (err.code === 401) {
            signOut();
          }
        });
      }, 2500);
    }
  }, [loadMessages, signOut, started]);

  const startChat = useCallback(async () => {
    try {
      setLoading(true);
      await api.post('/api/bot', {});
      setStarted(true);
    } catch (err: any) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    startChat();
  }, [startChat]);

  const handleSendMessage = useCallback((newMessage: ICreatetMessage) => {
    if (newMessage.message.trim().length === 0) {
      return;
    }
    try {
      api.put('/api/bot', {
        body: {
          message: newMessage.message,
          context: newMessage.context,
        },
      });
      form.setFieldsValue({
        message: '',
      });
      setSending(true);
    } catch (err: any) {
      handleError(err);
    }
  }, [form, handleError]);

  const handleButton = useCallback((value: string) => {
    handleSendMessage({
      message: value,
      context: {
        awaiting: true,
      },
    });
  }, [handleSendMessage]);

  const handleShortcut = useCallback(() => {
    setShowShortcuts(true);
  }, []);

  useEffect(() => {
    if (search === '/') {
      setShowShortcuts(true);
      form.setFieldsValue({
        message: '',
      });
      setSearch('');
    }
  }, [form, search]);

  useEffect(() => {
    if (showShortcuts) {
      selectRef.current?.focus();
    }
  }, [showShortcuts]);

  const handleBackspace = useCallback((e) => {
    if (search.length === 0 && e.key === 'Backspace') {
      setShowShortcuts(false);
      inputRef.current?.focus();
    }
  }, [search.length]);

  const handleSelectShortcut = useCallback((value) => {
    setShowShortcuts(false);
    const tokens = value.split('?');
    const message = tokens[0];
    let context;
    if (tokens.length > 1) {
      const params = tokens[1].split('&');
      context = params.reduce((obj: any, p: string) => {
        const keyValue = p.split('=');
        const key = keyValue[0];
        const iValue = keyValue[1];
        obj[key] = iValue;
        return obj;
      }, {});
      context.awaiting = true;
    }

    handleSendMessage({
      message,
      context,
    });
  }, [handleSendMessage]);

  return (
    <div className="chatWindow">
        <ul className="chat" id="chatList">
          {loading && (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 100,
            }}>
              <Spin />
            </div>
          )}

          {!loading && messages.map((data) => (
            <div key={data.botId}>
              <li className={data.author ? 'self' : 'other'}>
                  <div className="msg">
                    <p style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                      <span style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                      }}>
                      {!data.author && (
                        <img src={asanaBotAvatar} style={{
                          width: 16,
                          height: 16,
                        }} />
                      )}
                      {data.author ? user.first_name : 'AOP Bot'}
                      </span>
                      <span className="time">{formatDistance(parseISO(data.createdAt), new Date())}</span>
                    </p>
                    <div className="message"> {Number(data.error) === 1 && <WarningFilled style={{
                      color: 'red',
                      marginRight: 4,
                    }} />}{data.message}</div>
                    {data.options && data.options.length > 0 && (
                      <div className="actions">
                        {data.options.map((option) => (
                          <span key={option.value}>
                            {option.type === 'link' && (
                              <Button href={option.value} target="_blank" >{option.label}</Button>
                            )}
                            {option.type === 'download' && (
                                <Button type="link" href={option.value} download={option.label.split('/').reverse()[0]}>{option.label}</Button>
                            )}
                            {!option.type && (
                              <Button onClick={() => handleButton(option.value)} >{option.label}</Button>
                            )}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </li>
            </div>
          ))}
        </ul>
        <div className="chatInputWrapper">
          <Form form={form} onFinish={handleSendMessage} >
            <Form.Item name="message" style={{
              height: 12,
              marginLeft: 6,
              marginRight: 6,
            }}>

              <Input
                ref={inputRef}
                className="textarea input"
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                addonBefore={(

                    <Tooltip title="Shortcuts - [ / ]">
                      <Button onClick={handleShortcut} type="ghost" style={{
                        height: 30,
                      }}>
                        <PicCenterOutlined />
                      </Button>
                    </Tooltip>
                )}
                addonAfter={(
                  <Tooltip title="Send - [Enter]">
                    <Button loading={sending} onClick={() => form.submit()} type="ghost" style={{
                      height: 30,
                    }}>
                        <SendOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  placeholder="Enter your message..."
                  autoComplete="off"
                />
            </Form.Item>
            {showShortcuts && (
              <Select
              showSearch
              ref={selectRef}
              open={showShortcuts}
              style={{
                position: 'absolute',
                width: 490,
                marginTop: -36,
                marginLeft: 54,
                height: 28,
                maxHeight: 28,
                zIndex: 99,
              }}
              showArrow={false}
              value={search}
              placeholder="Select the shortcut"
              optionFilterProp="children"
              onBlur={() => setShowShortcuts(false)}
              onChange={handleSelectShortcut}
              onKeyUp={handleBackspace}
              onSearch={(val) => setSearch(val)}
              >
                <Select.Option value="agent.export.asana.download?boardId=1200751064404060">Download AOP Quality Refresh Board</Select.Option>
                <Select.Option value="agent.export.asana.sync?boardId=1200751064404060">Sync AOP Quality Refresh Board</Select.Option>
                <Select.Option value="agent.export.sov.report">Generate Share of Voice Report</Select.Option>
              </Select>
            )}
          </Form>
        </div>
      </div>
  );
};

export default ChatBot;
