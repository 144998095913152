import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import {
  EnvType, IHealth,
} from '../../../shared/dtos/HealthDTO';

import api from '../services/api';
import { IActionSubmenu } from '../components/header_panel';

interface HeaderState {
  badgeCount: number
  envName: EnvType
  publicHost?: string
  subHeader: IActionSubmenu[]
  keywordSearch: boolean
}

interface HeaderContextData {
  badgeCount: number;
  envName: EnvType
  publicHost?: string
  subHeader: IActionSubmenu[]
  keywordSearch: boolean
  initSubHeader(action: IActionSubmenu[]): void
  setKeywordSearch(showKeyword: boolean): void
  updatePublicHost(newHost: string): void;
}

const HeaderContext = createContext<HeaderContextData>({} as HeaderContextData);

export const HeaderProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<HeaderState>(() => ({
    badgeCount: 0,
    keywordSearch: false,
    subHeader: [],
    envName: 'development',
  } as HeaderState));

  const initSubHeader = useCallback((actions: IActionSubmenu[]) => {
    setData((previous) => ({ ...previous, subHeader: previous.subHeader !== actions ? actions : previous.subHeader }));
  }, []);

  const setKeywordSearch = useCallback((keywordSearch: boolean) => {
    setData((previous) => ({ ...previous, keywordSearch }));
  }, []);

  const updatePublicHost = useCallback((newHost: string) => {
    setData((previous) => ({
      ...previous,
      publicHost: newHost,
    }));
  }, []);

  const loadEnv = useCallback(async () => {
    try {
      const health = await api.get<IHealth>('/api/health');
      setData((previous) => ({
        ...previous,
        envName: health.env,
        publicHost: health.publicHost,
      }));
    // eslint-disable-next-line no-empty
    } catch (err) {}
  }, []);

  useEffect(() => {
    loadEnv();
  }, [loadEnv]);

  return (
    <HeaderContext.Provider
      value={{
        badgeCount: data.badgeCount,
        envName: data.envName,
        publicHost: data.publicHost,
        setKeywordSearch,
        initSubHeader,
        keywordSearch: data.keywordSearch,
        subHeader: data.subHeader,
        updatePublicHost,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export function useHeader(): HeaderContextData {
  const context = useContext(HeaderContext);
  return context;
}
