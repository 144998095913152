import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Form,
  Input,
  Button,
  Divider,
  Typography,
} from 'antd';

import { useAuth } from '../../hooks/auth';
import { DEFAULT_USER } from '../../services/auth';
import { AOP_HELP_EMAIL } from '../../utils';

const { Text, Link } = Typography;

interface ISignInParams {
  username: string;
  password: string;
}

interface IAuthFrom {
  from: Location;
}

const SignIn: React.FC = () => {
  const { signIn, handleError } = useAuth();
  const [mail, setMail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation<IAuthFrom>();
  let redirectLocation: string | undefined;

  useEffect(() => {
    const from = location.state && location.state.from;
    if (from) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      redirectLocation = from as unknown as string;
      window.history.replaceState({}, document.title);
    }
  }, []);

  const handleSignIn = useCallback(async ({ username, password }: ISignInParams): Promise<void> => {
    try {
      setLoading(true);
      await signIn({ username, password });
      if (redirectLocation) {
        history.push(
          redirectLocation,
        );
      }
    } catch (err) {
      handleError(err, 'Sign-in error');
    } finally {
      setLoading(false);
    }
  }, [history, redirectLocation, signIn, handleError]);

  const handleForgotPassword = useCallback(() => {
    history.push('forgot-password');
  }, [history]);

  const usernameType = useMemo(() => {
    if (mail === DEFAULT_USER) {
      return 'string';
    }
    return 'email';
  }, [mail]);

  return (
    <>
      <Text type="secondary">Please enter your credentials to authenticate:</Text>
      <Form
      style={{ marginTop: 15 }}
        labelCol={{ span: 7 }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSignIn}
      >
        <Form.Item
          label="Email"
          name="username"
          rules={[{ required: true, type: usernameType, message: 'Please input a valid email!' }]}
        >
          <Input placeholder="Email address" onChange={(val) => setMail(val.target.value)} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Your password" />
        </Form.Item>

        <Form.Item wrapperCol={{
          offset: 7,
        }}>
          <Button loading={loading} type="primary" htmlType="submit" style={{
            width: '100%',
          }}>
            Sign In
          </Button>

        </Form.Item>

      </Form>
      <Divider />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Link
          style={{ marginLeft: 4 }}
          onClick={handleForgotPassword}>
            Forgot your password?
        </Link>
        <Link
          style={{ marginLeft: 4 }}
          href={`mailto: ${AOP_HELP_EMAIL}`}>
            Need help?
        </Link>
      </div>
    </>
  );
};

export default SignIn;
