/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useCallback } from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';

import {
  Form,
  FormInstance,
  Input,

} from 'antd';

import { FormListFieldData } from 'antd/lib/form/FormList';

interface IFAQFormProps {
  form: FormInstance
  formListFieldData: FormListFieldData
  index: number
  onRemove(name: number): void
}

const FAQForm: React.FC<IFAQFormProps> = ({
  formListFieldData, onRemove,
}) => {
  const {
    key, name, fieldKey, ...restField
  } = formListFieldData;

  const handleRemove = useCallback(() => {
    onRemove(name);
  }, [name, onRemove]);

  return (
    <>
      <Form.Item
        {...restField}
        style={{ width: '663px', display: 'inline-block', paddingRight: '8px', verticalAlign: 'baseline' }}
        name={[name, 'question']}
        fieldKey={[fieldKey, 'question']}
        rules={[{ required: true, message: 'Missing question' }]}
      >
        <Input placeholder="Question" />
      </Form.Item>
      <MinusCircleOutlined onClick={handleRemove} />
    </>
  );
};

export default FAQForm;
