import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Modal,
  Form,
  Radio,
  Switch,
  Input,
  Button,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import User, { Role } from '../../../../shared/dtos/UserDTO';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface INewUserModelProps {
  editUser?: User;
  visible?: boolean;
  onClose(): void;
  onSuccess(newUser: User): void;
}

interface IUserFormData {
  keyword: string;
  track: boolean;
  active: boolean
}

const defaultFormState = {
  role_id: 'opm',
  first_name: '',
  last_name: '',
  username: '',
  active: true,
};

const NewUserModal: React.FC<INewUserModelProps> = ({
  visible, onClose, onSuccess, editUser,
}: INewUserModelProps) => {
  const { handleError } = useAuth();
  const [roles, setRoles] = useState<Role[]>([]);
  const [saving, setSaving] = useState(false);
  const [defaultValues, setDefaultValues] = useState(defaultFormState);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editUser) {
      const activeUnkown = editUser.active as unknown;
      const active = activeUnkown === 1 || activeUnkown === true;
      setDefaultValues({
        role_id: editUser.role?.id || 'opm',
        first_name: editUser.first_name,
        last_name: editUser.last_name,
        username: editUser.username,
        active,
      });
      return;
    }
    setDefaultValues(defaultFormState);
  }, [editUser]);

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const handleUserSubmit = useCallback(async (userFormValues: IUserFormData) => {
    setSaving(true);
    const body = { ...userFormValues, active: defaultValues.active };

    try {
      if (editUser) {
        const result = await api.put<User>(`/api/users/${editUser.id}`, {
          body,
        });
        onSuccess(result);
        return;
      }
      const result = await api.post<User>('/api/users', {
        body,
      });
      onSuccess(result);
      return;
    } catch (err) {
      handleError(err, 'Error saving user.');
    } finally {
      setSaving(false);
    }
  }, [defaultValues.active, editUser, onSuccess, handleError]);

  const buttonOkLabel = useMemo(() => (saving ? 'Saving' : 'Submit'), [saving]);

  const loadRoles = useCallback(async (): Promise<void> => {
    try {
      const receveivedRoles = await api.get<Role[]>('/api/auth/roles');
      setRoles(receveivedRoles);
    } catch (err) {
      handleError(err, 'Roles could not be searched.', true);
    }
  }, [handleError]);

  const handleRole = useCallback((event) => {
    const { value } = event.target;
    setDefaultValues((previous) => ({ ...previous, role_id: value }));
    if (value === 'adm') {
      Modal.info({
        title: 'Double check before confirming administrator role',
        icon: <ExclamationCircleOutlined />,
        content: 'With great power comes great responsibility',
        onCancel() {
          setDefaultValues((previous) => ({ ...previous, role_id: 'opm' }));
        },
      });
    }
  }, []);

  const handleDisable = useCallback((event) => {
    if (event) {
      setDefaultValues((previous) => ({ ...previous, active: event }));
      return;
    }
    Modal.confirm({
      title: 'Do you want to lock this user?',
      icon: <ExclamationCircleOutlined />,
      content: 'The user will no longer be able to access the AOP Platform.',
      onOk() {
        setDefaultValues((previous) => ({ ...previous, active: event }));
      },
    });
  }, []);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  return (
    <Modal
      title={`${editUser ? 'Edit' : 'New'} User`}
      visible={visible}
      onOk={() => form.submit()}
      confirmLoading={saving}
      okText={buttonOkLabel}
      onCancel={handleCloseModal}
      footer={[
        editUser ? (
          <Switch style={{
            position: 'absolute',
            width: 100,
            left: 35,
            marginTop: 3,
            alignSelf: 'flex-start',
          }}
          onChange={handleDisable}
          checkedChildren="Active"
          unCheckedChildren="Locked"
          checked={defaultValues.active} />
        ) : (
          <div />
        ),
        <Button key="cancel" onClick={handleCloseModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          loading={saving}
          onClick={() => form.submit()}
        >
          Submit
          </Button>,
      ]}>

      <Form
        form={form}
        onFinish={handleUserSubmit}
        initialValues={defaultValues}
        name='userForm'
        layout='horizontal'
        labelCol={{ span: 5 }}
        style={{ margin: '20px' }}
        className='App-form'
      >

        <Form.Item
          label='Email'
          name='username'
          rules={[{ required: true, type: 'email', message: 'Please a valid email!' }]}
        >
          <Input placeholder='name@domain.com' />
        </Form.Item>

        <Form.Item
          label='First Name'
          name='first_name'
          rules={[{ required: true, message: 'Please input the first name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Last Name'
          name='last_name'
          rules={[{ required: true, message: 'Please input the last name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Role'
          name='role_id'
        >
          <Radio.Group buttonStyle="solid" onChange={handleRole} >
            {roles.map((role) => (
              <Radio.Button
                style={{
                  width: 170,
                }}
                key={role.description}
                value={role.id}>
                  {role.description}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewUserModal;
