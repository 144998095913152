/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-throw-literal */
import { AuthError } from '../hooks/auth';
import { toQueryString } from '../utils';

interface IApiPostProps {
  body?: Record<string, any> | FormData;
  blob?: boolean;
}

interface IApiGetProps {
  params?: Record<string, any>;
  blob?: boolean;
}

interface IDefaults {
  headers: Record<string, string>;
}

class Api {
  defaults: IDefaults = { headers: {} };

  public async post<P>(url: string, { body, blob }: IApiPostProps): Promise<P> {
    let bodyContent: string | FormData | undefined;
    let headers: Record<string, any> = {
      'Content-Type': 'application/json',
      ...this.defaults.headers,
    };
    if (body instanceof FormData) {
      headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'x-www-form-urlencoded, Origin, X-Requested-With, Content-Type, Accept, Authorization, *',
        ...this.defaults.headers,
      };
      bodyContent = body;
    } else if (body) {
      bodyContent = body ? JSON.stringify(body) : undefined;
    }

    const result = await fetch(url, {
      method: 'POST',
      headers,
      body: bodyContent,
    });
    if (result.ok && blob) {
      const content = await result.blob();
      return content as unknown as P;
    }
    return this.handleResult(result);
  }

  public async patch<P>(url: string, { body, blob }: IApiPostProps): Promise<P> {
    const bodyContent = body ? JSON.stringify(body) : undefined;
    const result = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...this.defaults.headers,
      },
      body: bodyContent,
    });
    if (result.ok && blob) {
      const content = await result.blob();
      return content as unknown as P;
    }
    return this.handleResult(result);
  }

  public async put<P>(url: string, apiPost?: IApiPostProps): Promise<P> {
    const bodyContent = apiPost?.body;
    const body = bodyContent ? JSON.stringify(bodyContent) : undefined;
    const result = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...this.defaults.headers,
      },
      body,
    });
    return this.handleResult(result);
  }

  public async delete<P>(url: string): Promise<P> {
    const result = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...this.defaults.headers,
      },
    });
    return this.handleResult(result);
  }

  public async get<P>(url: string, apiGetProps?: IApiGetProps): Promise<P> {
    const blob = apiGetProps && apiGetProps.blob;
    const params = apiGetProps && apiGetProps.params;
    const query = toQueryString(params);
    const composedUrl = query ? `${url}?${query}` : url;
    const result = await fetch(composedUrl, {
      headers: this.defaults.headers,
    });

    if (result.ok && blob) {
      const content = await result.blob();
      return content as unknown as P;
    }
    return this.handleResult(result);
  }

  async handleResult(result: Response): Promise<any> {
    let json: any = {};
    try {
      json = await result.json();
    } catch (err) {
      if (result.ok) {
        return {};
      }
      throw new AuthError('Service Unavailable', 503);
    }
    if (!result.ok) {
      if (result.status === 428) {
        console.log(json.message);
        throw Error(result.statusText);
      }
      if (result.status === 401) {
        throw new AuthError(json.message);
      }

      if (json.message === 'celebrate request validation failed') {
        if (json.validation.body) {
          throw Error(json.validation.body.message);
        }
        if (json.validation.query) {
          throw Error(json.validation.query.message);
        }
        if (json.validation.params) {
          throw Error(json.validation.params.message);
        }
      }
      throw Error(json.message);
    }
    return json;
  }
}

export default new Api();
