import React,
{
  useCallback,
  useMemo,
} from 'react';

import {
  Divider,
  Popover,
  Row,
  Col,
  Button,
} from 'antd';

import { InteractionOutlined } from '@ant-design/icons';
import { IStatDTO } from '../../../../shared/dtos/StatDTO';

type StatPopoverProps = {
  stat: IStatDTO
  onClose(): void
  onCompare(): void
};

interface StatRowContentProps {
  label: string
  paddingLeft?: 0 | 22
}

const StatRowContent: React.FC<StatRowContentProps> = ({ label, children, paddingLeft = 0 }) => (
  <Row>
      <Col
        span={5}
        style={{
          paddingLeft,
          marginRight: 4,
        }}>
        <strong>{label}</strong></Col>
      <Col span={18}><span>{children}</span></Col>
    </Row>
);

const StatPopover: React.FC<StatPopoverProps> = ({
  stat, onClose, onCompare,
}) => {
  const popoverContent = useMemo(() => (
      <div style={{
        fontSize: 10,
        width: '410px',
        overflowY: 'auto',
        maxHeight: 290,
      }}>
        <div style={{
          marginTop: 10,
          marginBottom: 10,
        }}>
        <StatRowContent label="Title">
          <a
            href={`https://www.amazon.com/${stat.parsed_data.fullArticleUrl}`}
            target={'_blank'}
          >
            {stat.parsed_data.title}
          </a>
        </StatRowContent>

        <StatRowContent label="Published At">
          {stat.parsed_data.publishedDate}
        </StatRowContent>

        {
          stat.parsed_data.items && (
            <>
              <StatRowContent label="Items" />
              {stat.parsed_data.items.map((item, index) => (
                <div
                  style={{
                    marginTop: 6,
                  }}
                  key={item.asin}>
                  <StatRowContent label="ASIN" paddingLeft={22}>
                    <a
                      href={`https://www.amazon.com/dp/${item.asin}`}
                      target={'_blank'}>
                      {item.asin}
                    </a>
                  </StatRowContent>
                  <StatRowContent label="Name" paddingLeft={22}>
                    {item.name}
                  </StatRowContent>
                  <StatRowContent label="Award" paddingLeft={22}>
                    {item.award}
                  </StatRowContent>
                  <StatRowContent label="Blurb" paddingLeft={22}>
                    {item.blurb}
                  </StatRowContent>
                  {
                    stat.parsed_data.items.length !== index + 1
                      ? <Divider/>
                      : null
                  }
                </div>
              ))}
            </>
          )}
          </div>
      </div>
  ), [stat]);

  const handleVisibleChange = useCallback(() => {
    onClose();
  }, [onClose]);

  const titleContent = useMemo(() => {
    const compareButton = !stat.is_winning && (
      <Button
        onClick={onCompare}
        icon={<InteractionOutlined />}
        type="primary" >
        Compare
      </Button>
    );
    return (
      <span style={{
        height: 36,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <strong>{stat.publisher}</strong>
        {compareButton}
      </span>
    );
  }, [onCompare, stat.is_winning, stat.publisher]);

  return (
    <Popover
      title={titleContent}
      trigger="click"
      placement="bottom"
      visible={true}
      overlayStyle={{
        zIndex: 8,
      }}
      overlayClassName="App-stat-popover"
      onVisibleChange={handleVisibleChange}
      content={popoverContent} >
      <span>{stat.publisher}</span>
    </Popover>
  );
};

export default StatPopover;
