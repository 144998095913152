/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Badge,
  Rate,
  Typography,
  Tooltip,
} from 'antd';

import {
  DownOutlined,
  FlagOutlined,
} from '@ant-design/icons';

import api from '../../../services/api';

const { Link } = Typography;

interface IAmazonCard {
  asin: string
  award: string
  summary: string
}

const AmazonCard: React.FC<IAmazonCard> = ({
  asin,
  award,
  summary,
}) => {
  const [productData, setProductData] = useState<any>();

  const getProductData = useCallback(async () => {
    const productResponse = await api.get<any>(`/api/preview/product/${asin}`);
    setProductData(productResponse.pop());
  }, [asin]);

  const renderPrice = useCallback(() => {
    const priceString: string = (
      productData && productData?.Offers?.Listings?.[0]?.Price?.DisplayAmount
    ) || '$0.00';
    const priceNumber: number = (
      productData && productData?.Offers?.Listings?.[0]?.Price?.Amount
    ) || 0;
    const savingsNumber: number = (
      productData && productData?.Offers?.Listings?.[0]?.Price?.Savings?.Amount
    ) || 0;
    const isPrimeEligible = (
      productData && productData?.Offers?.Listings?.[0]?.DeliveryInfo?.IsPrimeEligible
    ) || false;

    const priceArray = priceString.split('.');
    return (
      <div style={{ paddingBottom: '20px' }}>
        <span
          style={{
            fontSize: '12px',
            top: '-.5em',
            position: 'relative',
          }}
        >
          $
        </span>
        <span className={'App-Price'}>{priceArray?.[0]?.replace('$', '')}</span>
        <span
          style={{
            fontSize: '12px',
            top: '-.5em',
            position: 'relative',
          }}
        >
          {priceArray?.[1]}
        </span>
        {
          savingsNumber !== 0 && (
            <span
              style={{
                fontSize: '13px',
                color: '#565959',
                textDecoration: 'line-through',
                verticalAlign: 'text-bottom',
                paddingLeft: '4px',
              }}
            >
              ${(priceNumber + savingsNumber).toFixed(2)}
            </span>
          )
        }
        {
          isPrimeEligible && (
            <div style={{ paddingTop: '6px' }}>
              <img src={'/amazon_prime_logo.png'} width={53} />
            </div>
          )
        }
      </div>
    );
  }, [productData]);

  useEffect(() => {
    getProductData();
  }, [getProductData]);

  const itemOutOfStock = useMemo(() => (
    productData && productData?.inStock !== 'IN_STOCK'
  ), [productData]);

  return (
    <>
      <Badge
        count={
          itemOutOfStock ? (
            <Tooltip title={'Item out of stock'}>
              <FlagOutlined
                style={{
                  fontSize: '20px',
                  color: 'white',
                  padding: '8px',
                  backgroundColor: 'red',
                  borderRadius: '18px',
                }}
              />
            </Tooltip>
          ) : null}
      >
        <div style={{ maxWidth: 240, border: itemOutOfStock ? '3px red solid' : '' }}>
          <div style={{ fontSize: '16px', fontWeight: 'bolder' }}>
            {award}
          </div>
          <img src={productData && productData?.Images?.Primary?.Large?.URL} width={'100%'} />
          <div style={{
            textOverflow: 'ellipsis',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            maxHeight: '60px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            paddingTop: '10px',
            paddingBottom: '10px',
          }}>
            {productData && productData?.ItemInfo?.Title?.DisplayValue}
          </div>
          {
            productData && (
              <div>
                <Rate
                  className={'App-Rate-Medium'}
                  disabled
                  allowHalf
                  style={{ color: '#f69b20' }}
                  defaultValue={
                    productData && productData?.Offers?.Listings?.[0]?.MerchantInfo?.FeedbackRating
                  }
                />
                <DownOutlined style={{ padding: '6px', fontSize: '10px' }} />
                <Link style={{ color: '#007185' }}>
                  {productData && productData?.Offers?.Listings?.[0]?.MerchantInfo?.FeedbackCount}
                </Link>
              </div>
            )
          }
          <div style={{ paddingTop: '6px' }}>
            {renderPrice()}
          </div>
          <div
            style={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 4,
              lineHeight: '20px',
            }}
          >
            {summary}
          </div>
        </div>
      </Badge>
    </>
  );
};

export default AmazonCard;
