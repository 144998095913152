import { PlusOutlined } from '@ant-design/icons';
import {
  Input,
  Tag as AntTag,
  Typography,
  AutoComplete,
} from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Tag } from '../../../shared/dtos/KeywordDTO';
import { useAuth } from '../hooks/auth';
import api from '../services/api';

const { Text } = Typography;

export interface ISelectedTag {
  tag_id?: number;
  tag: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  editTags?: Tag[],
  emptyText?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerStyle?: any;
  onChangeTags?(tags: ISelectedTag[]): void
}

const InputTags: React.FC<InputProps> = ({
  editTags = [],
  emptyText = 'No selected tags',
  containerStyle = {},
  onChangeTags,
}) => {
  const { handleError } = useAuth();
  const [savedTags, setSavedTags] = useState<Tag[]>([]);
  const [tags, setTags] = useState<ISelectedTag[]>(editTags);
  const [inputTagVisible, setInputTagVisible] = useState(false);
  const [inputTagValue, setInputTagValue] = useState('');

  const inputTagRef = useRef<Input>(null);

  const loadTags = useCallback(async (): Promise<void> => {
    try {
      const receveivedTags = await api.get<Tag[]>('/api/keywords/tags');
      setSavedTags(receveivedTags);
    } catch (err) {
      handleError(err, 'Tags could not be searched.', true);
    }
  }, [handleError]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  useEffect(() => {
    setTags(editTags || []);
  }, [editTags]);

  const handleClose = useCallback((removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  }, [tags]);

  const showInput = useCallback(() => {
    setInputTagVisible(true);
  }, []);

  useEffect(() => {
    if (inputTagVisible) {
      inputTagRef?.current?.focus();
    }
  }, [inputTagVisible]);

  useEffect(() => {
    if (onChangeTags) {
      onChangeTags(tags);
    }
  }, [onChangeTags, tags]);

  const handleInputChange = useCallback((value) => {
    setInputTagValue(value);
  }, []);

  const handleInputConfirm = useCallback((value?: string) => {
    const newValue = value || inputTagValue;
    if (!newValue) {
      setInputTagVisible(false);
      setInputTagValue('');
      return;
    }
    const tagExists = tags.find((fTag) => fTag.tag === newValue);
    if (tagExists) {
      setInputTagVisible(false);
      setInputTagValue('');
      return;
    }
    const savedTag = savedTags.find((sTag) => sTag.tag === newValue);

    const newTag = savedTag || { tag: newValue };
    const newTags = [...tags, newTag];
    setTags(newTags);
    setInputTagVisible(false);
    setInputTagValue('');
  }, [inputTagValue, savedTags, tags]);

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      handleInputConfirm();
    }
  }, [handleInputConfirm]);

  const tagChield = useMemo(() => tags.map((iTag) => {
    const tagElem = (
      <AntTag
        key={iTag.tag}
        color="blue"
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(iTag);
        }}
      >
        {iTag.tag}
      </AntTag>
    );
    return (
      <span key={iTag.tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  }), [handleClose, tags]);

  const savedOptionsAvailable = useMemo(() => {
    const availableTags = savedTags.filter((sTag) => !tags.includes(sTag));
    const options = availableTags.map((aTag) => ({
      value: aTag.tag,
    }));
    return options;
  }, [savedTags, tags]);

  return (
    <div
      style={containerStyle}
    >
            {tags.length === 0 && (
              <div style={{
                marginTop: 5,
              }}>
                <Text type="danger" >{emptyText}</Text>
              </div>
            )}
            <div style={{ marginBottom: 16 }}>
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: 'from',
                  duration: 100,
                }}
                leave={{
                  opacity: 0, width: 0, scale: 0, duration: 200,
                }}
                appear={false}
              >
                {tagChield}
              </TweenOneGroup>
            </div>
            {inputTagVisible && (
              <AutoComplete
                ref={inputTagRef}
                style={{ width: 120 }}
                options={savedOptionsAvailable}
                onChange={handleInputChange}
                onBlur={() => handleInputConfirm()}
                onInputKeyDown={handleKeyDown}
                onSelect={(val) => handleInputConfirm(val)}
                filterOption={(inputValue, option) => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              />
            )}
            {!inputTagVisible && (
              <AntTag onClick={showInput} className="site-tag-plus">
                <PlusOutlined /> New Tag
              </AntTag>
            )}

    </div>
  );
};

export default InputTags;
