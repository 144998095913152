import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  CloseCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Popconfirm,
  Tag,
  Tooltip,
} from 'antd';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import { IArticle } from '../../../../shared/dtos/ArticleDTO';
import ArticlePreviewPopover from './article_preview_popover';

export interface ITempArticleCell extends IArticle {
  tmpId: string
  draft: boolean
}

interface IArticleTableCardProps {
  onRemove(): void
  onEdit(article: IArticle, resubmit?: boolean): void
  repeatedConfirmation?: boolean
  article: IArticle
}

const ArticleTableCard: React.FC<IArticleTableCardProps> = ({
  article: iArticle,
  onRemove,
  onEdit,
  repeatedConfirmation,
}) => {
  const [repeated, setRepeated] = useState(true);
  const [article, setArticle] = useState<IArticle>(iArticle);
  const [error, setError] = useState<string>();
  const site = article.site || {} as unknown as ISiteDTO;

  const handleAddDuplicated = useCallback(() => {
    setRepeated(false);
  }, []);

  useEffect(() => {
    setRepeated(!!repeatedConfirmation);
  }, [repeatedConfirmation]);

  const handleRemoveTempCard = useCallback(() => {
    setError(undefined);
    if (onRemove) {
      onRemove();
    }
  }, [onRemove]);

  const tempCard = useMemo(() => {
    if (error) {
      return (
        <Tooltip title={error} >
          <Button type="link" disabled={!error} onClick={handleRemoveTempCard} style={{ padding: 0 }} >
            <Tag
              icon={!error ? <SyncOutlined spin /> : <CloseCircleOutlined />}
              color={!error ? 'volcano' : 'red'}
              style={{ paddingBottom: 3, width: 170 }}>
              {' '}({!error ? 'Saving...' : 'Error'})
            </Tag>
          </Button>
        </Tooltip>
      );
    }
    if (repeated) {
      return (
        <Popconfirm
          title={(
            <p>There is another pending article to <strong>{site.display_name}</strong><br />
            Do you want to add a new one?</p>
          )}
          onConfirm={() => handleAddDuplicated()}
          onCancel={() => onRemove && onRemove()}
          okText="Yes"
          cancelText="No">
          <Button type="link" style={{ padding: 0 }} >
            <Tag
              icon={<WarningOutlined />}
              color="volcano"
              style={{ paddingBottom: 3, width: 170 }}>
              {' '} Repeated article
            </Tag>
          </Button>
        </Popconfirm>
      );
    }
    return undefined;
  }, [error, handleAddDuplicated, handleRemoveTempCard, onRemove, repeated, site.display_name]);

  return (
    <>
      {tempCard || (
        <ArticlePreviewPopover
          article={article}
          onDelete={() => onRemove()}
          onEdit={onEdit}
          forceUpdate={(myArticle) => setArticle(myArticle)}
          onError={(msg) => setError(msg)} />
      )}
    </>
  );
};

export default ArticleTableCard;
