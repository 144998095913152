/* eslint-disable @typescript-eslint/await-thenable */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Card,
  notification,
  BackTop,
  Form,
  Select,
} from 'antd';

import {
  CloseCircleOutlined, PlayCircleOutlined,
} from '@ant-design/icons';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import HeaderPanel from '../../../components/header_panel';
import {
  IFunctionScan,
  IIndexScanParamsDTO,
  IScan,
  IScanSearch,
} from '../../../../../shared/dtos/ScanDTO';
import ListScan from '../../../components/Scan/ListScan';

const Scan: React.FC = () => {
  const { handleError } = useAuth();
  const [scans, setScans] = useState<IScan[]>([]);
  const [sites, setSites] = useState<IScan[]>([]);
  const [functions, setFunctions] = useState<string[]>([]);
  const [totalFunctionsToRun, setTotalFunctionsToRun] = useState(0);
  const [scaning, setScaning] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [functionProcessing, setFunctionProcessing] = useState<number>();

  const [filters, setFilters] = useState<IIndexScanParamsDTO>({} as IIndexScanParamsDTO);

  const setFunctionResult = useCallback((functionId: number, changedFunction: IFunctionScan) => {
    setScans((previous) => previous.map((mon) => ({
      ...mon,
      features: mon.features.map((ft) => ({
        ...ft,
        functions: ft.functions.map((ff) => (ff.id === functionId ? changedFunction : ff)),
      })),
    })));
  }, []);

  const setFunctionError = useCallback((functionId: number, message: string) => {
    setScans((previous) => previous.map((mon) => ({
      ...mon,
      features: mon.features.map((ft) => ({
        ...ft,
        functions: ft.functions.map((ff) => (ff.id === functionId ? {
          ...ff,
          status: 'error',
          items: [
            {
              label: 'HTTP Error',
              value: message,
              status: 'error',
              tip: 'Try again',
            },
          ],
        } : ff)),
      })),
    })));
  }, []);

  const findFunctionByIndex = useCallback((index: number) => {
    const functionByOrder = scans.reduce((scanReduced, sc) => {
      const fff = sc.features.reduce((featureReduced, ff) => [...featureReduced, ...ff.functions], [] as IFunctionScan[]);
      return [...scanReduced, ...fff];
    }, [] as IFunctionScan[]);
    return functionByOrder[index];
  }, [scans]);

  const processFunction = useCallback(async (functionId: number): Promise<void> => {
    try {
      const foundFunction = await api.get<IFunctionScan>(`/api/scan/${functionId}`);
      setFunctionResult(functionId, foundFunction);
    } catch (err) {
      setFunctionError(functionId, err.message);
    }
  }, [setFunctionError, setFunctionResult]);

  const handleAutoFix = useCallback(async (functionId: number, fix: string): Promise<void> => {
    try {
      const foundFunction = await api.put<IFunctionScan>(`/api/scan/${functionId}`, {
        body: {
          fix,
        },
      });
      setFunctionResult(functionId, foundFunction);
    } catch (err) {
      setFunctionError(functionId, err.message);
    }
  }, [setFunctionError, setFunctionResult]);

  const processSequencialFunction = useCallback(async (functionId: number): Promise<void> => {
    if (totalFunctionsToRun > 0 && functionId >= totalFunctionsToRun) {
      setScaning(false);
      setFunctionProcessing(undefined);
      return;
    }
    const iFunction = findFunctionByIndex(functionId);
    setScanned(true);
    setFunctionProcessing(iFunction.id);
    await processFunction(iFunction.id);
    processSequencialFunction(functionId + 1);
  }, [totalFunctionsToRun, findFunctionByIndex, processFunction]);

  const findChecklist = useCallback(async () => {
    try {
      const { data, total } = await api.get<IScanSearch>('/api/scan', {
        params: filters,
      });
      setScans(data);
      setTotalFunctionsToRun(total);
      if (functions.length === 0) {
        const tmpSites: IScan[] = [];
        const functionsReduced = data.reduce((scanReaded, scan) => {
          tmpSites.push(scan);
          const newScanReaded = scan.features.reduce((featureReaded, feature) => {
            const newFeatureReaded = feature.functions.reduce((functionReaded, fc) => {
              if (!scanReaded.includes(fc.name)) {
                functionReaded.push(fc.name);
              }
              return functionReaded;
            }, [] as string[]);
            return [...featureReaded, ...newFeatureReaded];
          }, [] as string[]);
          return [...scanReaded, ...newScanReaded];
        }, [] as string[]);
        setSites(tmpSites);
        setFunctions(functionsReduced);
      }
    } catch (err) {
      handleError(err, 'Sites could not be searched.');
    }
  }, [filters, functions.length, handleError]);

  useEffect(() => {
    findChecklist();
  }, [findChecklist]);

  const handleStartScan = useCallback(() => {
    if (scans.length > 0) {
      setScaning(true);
      processSequencialFunction(0);
    } else {
      notification.info({
        message: 'No functions to be scanned',
      });
    }
  }, [processSequencialFunction, scans.length]);

  const handleResetScan = useCallback(() => {
    setScanned(false);
    setScans([]);
    setTotalFunctionsToRun(0);
    findChecklist();
  }, [findChecklist]);

  const handleChangeSite = useCallback((site: string) => {
    setFilters((previous) => ({ ...previous, site }));
    handleResetScan();
  }, [handleResetScan]);

  const handleChangeFunction = useCallback((iFunction: string) => {
    setFilters((previous) => ({ ...previous, function: iFunction }));
    handleResetScan();
  }, [handleResetScan]);

  const siteIcon = useCallback((iconUrl: string) => {
    if (iconUrl === 'aop') {
      return '/dark_logo.png';
    }
    return iconUrl;
  }, []);

  const topButton = useMemo(() => {
    if (scanned && !scaning) {
      return (
        <Button
          type="default"
          shape="round"
          icon={<CloseCircleOutlined />}
          onClick={handleResetScan}>
          Reset Scan
        </Button>
      );
    }
    return (
      <Button
        loading={scaning}
        type="primary"
        shape="round"
        icon={<PlayCircleOutlined />}
        onClick={handleStartScan}>
        Start Scan
      </Button>
    );
  }, [handleResetScan, handleStartScan, scaning, scanned]);

  const handleDisableAutoScroll = useCallback((e) => {
    e.preventDefault();
    setAutoScroll((previous) => !previous);
  }, []);

  const disableScrollButton = useMemo(() => {
    const backgroundColor = autoScroll ? '#1088e9' : '#565656';
    const text = autoScroll ? 'Disable Auto Scroll' : 'Enable Auto Scroll';
    return (
      <BackTop
        onClick={handleDisableAutoScroll}
        style={{
          marginRight: 30,
        }}>
        <div style={{
          height: 40,
          width: 140,
          lineHeight: '40px',
          right: 10,
          borderRadius: 4,
          backgroundColor,
          color: '#fff',
          textAlign: 'center',
          fontSize: 14,
        }}>{text}</div>
      </BackTop>
    );
  }, [autoScroll, handleDisableAutoScroll]);

  return (
    <>
      <HeaderPanel
        title="AOP Connectivity Scan"
        extra={topButton} />
      <Card style={{
        marginTop: 4,
        marginBottom: 4,
      }}>
        <Form
          wrapperCol={{ span: 5 }}
          layout="inline"
          onFinish={handleStartScan}
        >
          <Form.Item name="site" label="Site">
            <Select onChange={handleChangeSite} style={{
              width: 300,
            }}>
              <Select.Option key={'site_all'} value={''}>
                All
              </Select.Option>
              {sites.map((scan) => (
                <Select.Option key={`site_${scan.name}`} value={scan.name}>
                  <img src={siteIcon(scan.icon)} width={18} />{' '}{scan.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="function" label="Function">
            <Select onChange={handleChangeFunction} style={{
              width: 300,
            }}>
              <Select.Option key={'function_all'} value={''}>
                All
              </Select.Option>
              {functions.map((iFunction) => (
                <Select.Option key={`function_${iFunction}`} value={iFunction}>
                  {iFunction}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      <Card>
        <ListScan
          autoScroll={autoScroll}
          scans={scans}
          autoFix={(functionId, fix) => handleAutoFix(functionId, fix)}
          runItem={(functionId) => processFunction(functionId)}
          currentCheckItem={functionProcessing} />
      </Card>
      {scaning && (
        disableScrollButton
      )}
    </>
  );
};

export default Scan;
