import {
  useMemo,
  useState,
} from 'react';

import {
  Drawer,
  Layout,
  Typography,
  Button,
  Badge,
} from 'antd';

import {
  MenuOutlined,
  RightOutlined,
  GithubOutlined,
  BellOutlined,
} from '@ant-design/icons';

import { Link as LinkRouter } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useHeader } from '../../hooks/header';
import TopSubmenu from '../../components/top_submenu';
import SideMenu from '../../components/Layouts/side_menu';

const { Header, Footer, Content } = Layout;
const { Title, Link, Text } = Typography;
const year = new Date().getFullYear();

const GITHUB_COMMIT_URL = `https://github.com/DigitalTrends/sleestak/commit/${process.env.BUILD_COMMIT_HASH as string}`;

const SignedLayout: React.FC = ({ children }) => {
  const { subHeader, keywordSearch } = useHeader();
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useAuth();
  const { badgeCount, envName } = useHeader();

  const topDrawer = useMemo(() => (
    <div>
      <Text type="secondary" style={{
        fontSize: 14,
      }}>Hello,</Text>
      <br />
      <Text style={{
        fontSize: 24,
      }}>{user.first_name}</Text>
      <br />
      <Text type="secondary" style={{
        fontSize: 10,
      }}>
        <RightOutlined />
        {user.role?.description}
      </Text>

    </div>
  ), [user.first_name, user.role?.description]);

  return (
    <Layout style={{
      minHeight: '100vh',
    }}>
      <Header style={{
        zIndex: 10,
      }}>
        <div style={{
          paddingTop: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Link href="/" style={{ marginTop: 7 }}>
            <Title style={{ color: '#fff', fontSize: 28 }} >
              DTMG Toolbox
            </Title>
          </Link>
          <div>
            {badgeCount > 0 && (
              <LinkRouter
                style={{
                  marginRight: 10,
                }}
                to="/articles/pending">
                <Badge count={badgeCount} offset={[1, 2]}>
                  <BellOutlined style={{
                    fontSize: 26,
                    color: 'white',
                  }} />
                </Badge>
              </LinkRouter>
            )}

            <Button onClick={() => setShowMenu(true)}
              style={{
                borderWidth: 0,
                color: 'white',
                backgroundColor: 'transparent',
              }}>
              <MenuOutlined style={{
                fontSize: 26,
                color: 'white',
              }} />
            </Button>
          </div>
        </div>
      </Header>
      <TopSubmenu
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
        actions={subHeader}
        onClickRightMenu={() => setShowMenu(true)}
        showKeywordSearch={keywordSearch} />
      <Content style={{
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
      }}>
        {children}
      </Content>
      <Footer>
        <div style={{ float: 'left' }}>
          DigitalTrends ©{year}
        </div>
        <div style={{ float: 'right' }}>
          {envName !== 'production' && (
              <small style={{ marginRight: 6 }}>
                <Text type="secondary">{envName}</Text>
              </small>
          )}
          <a href={GITHUB_COMMIT_URL}>
            <GithubOutlined /> {process.env.BUILD_COMMIT_HASH}
          </a>
        </div>
      </Footer>

      <Drawer
        title={topDrawer}
        placement="right"
        closable={true}
        onClose={() => setShowMenu(false)}
        visible={showMenu}
        bodyStyle={{
          padding: 0,
        }}
      >
        <SideMenu onCloseMenu={() => setShowMenu(false)} />
      </Drawer>
    </Layout>
  );
};

export default SignedLayout;
