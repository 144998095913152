/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Form,
  Input,
  Modal,
} from 'antd';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { ICommissionCategoryDTO } from '../../../../shared/dtos/CommissionCategoryDTO';
import { commissionCategoriesBaseApiCall } from '../../pages/commission_categories/commision_categories';

interface INewCommissionCategoryModalProps {
  editCommissionCategory?: ICommissionCategoryDTO
  visible?: boolean
  onClose?(): void
  onSuccess?(newCommissionCategory: ICommissionCategoryDTO): void
}

interface ICommissionCategoryFormData {
  name: string
  commission: number
}

const defaultFormState = {
  name: '',
  commission: 0.0,
};

const NewCommissionCategory: React.FC<INewCommissionCategoryModalProps> = ({
  editCommissionCategory, visible, onClose, onSuccess,
}: INewCommissionCategoryModalProps) => {
  const { handleError } = useAuth();
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editCommissionCategory) {
      form.setFieldsValue({
        name: editCommissionCategory.name,
        commission: editCommissionCategory.commission,
      });
    } else {
      form.setFieldsValue(defaultFormState);
    }
  }, [editCommissionCategory, form, visible]);

  const handleCloseModal = useCallback(() => {
    form.setFieldsValue(defaultFormState);
    if (onClose) {
      onClose();
    }
  }, [form, onClose]);

  const handleCommissionCategorySubmit = useCallback(async (commissionCategoryFormValues: ICommissionCategoryFormData) => {
    setSaving(true);
    const body = {
      commission_category_id: editCommissionCategory ? editCommissionCategory.commission_category_id : undefined,
      ...commissionCategoryFormValues,
    };

    try {
      let result;
      if (editCommissionCategory) {
        result = await api.put<ICommissionCategoryDTO>(`${commissionCategoriesBaseApiCall}${editCommissionCategory.commission_category_id}`, {
          body,
        });
      } else {
        result = await api.post<ICommissionCategoryDTO>(commissionCategoriesBaseApiCall, {
          body,
        });
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (err) {
      handleError(err, 'Error saving commission category.');
    } finally {
      setSaving(false);
    }
  }, [editCommissionCategory, onSuccess, handleError]);

  const buttonOkLabel = useMemo(() => (saving ? 'Saving' : 'Submit'), [saving]);

  return (
    <Modal
      title={ editCommissionCategory
        ? 'Edit Commission Category'
        : 'New Commission Category'
      }
      visible={visible}
      onOk={() => form.submit()}
      confirmLoading={saving}
      okText={buttonOkLabel}
      onCancel={handleCloseModal}
    >

      <Form
        form={form}
        onFinish={handleCommissionCategorySubmit}
        name='CommissionCategoryForm'
        layout='horizontal'
        labelCol={{ span: 5 }}
        style={{ margin: '20px' }}
        className='App-form'
      >
        <Form.Item
          label='Name'
          name='name'
          rules={[{ required: true, max: 150 }]}
        >
          <Input autoComplete='off' placeholder='Name'/>
        </Form.Item>
        <Form.Item
          label='%'
          name='commission'
          rules={[{ required: true, message: 'Please input a commission percentage!' }]}
        >
          <Input autoComplete='off' type='number' placeholder='Commission' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewCommissionCategory;
