import React from 'react';

import { format } from 'date-fns';

import { Divider } from 'antd';
import { ISiteDTO } from '../../../../../shared/dtos/SiteDTO';
import AmazonTile from './amazon_tile';
import { IAOPWordpressPost } from '../../../../../shared/dtos/WordpressDTO';

interface IArticleFullPreview {
  site: ISiteDTO,
  article: IAOPWordpressPost
}

const ArticleFullPreview: React.FC<IArticleFullPreview> = ({
  site,
  article,
}) => (
  <>
    <div style={{
      borderTop: '2px solid #ddd',
      paddingTop: '25px',
      borderBottom: '2px solid #ddd',
      backgroundColor: 'white',
      fontFamily: "'Lato', sans-serif",
      marginBottom: '30px',
      whiteSpace: 'pre-line',
    }}>
      <div
        style={{
          maxWidth: 620,
          margin: '0 auto',
        }}
      >
        <img
          src={site?.logo_url}
          width={30}
          style={{ borderRadius: '17px' }}
        />
        <span style={{ paddingLeft: '12px', verticalAlign: 'text-top' }}>
          {site?.display_name}
        </span>
      </div>
      <div
        style={{
          fontFamily: "'Literata', serif",
          fontSize: '28px',
          padding: '15px 0 25px 12px',
          fontWeight: 'bold',
          maxWidth: 640,
          margin: '0 auto',
        }}
      >
        {article.title}
      </div>
      <div
        style={{
          maxWidth: 735,
          margin: '0 auto',
          paddingBottom: '25px',
        }}
      >
        <img
          src={article.heroImageUrl}
          width={735}
        />
      </div>
      <div
        style={{
          maxWidth: 640,
          margin: '0 auto',
          fontSize: '15px',
          lineHeight: '21px',
        }}
      >
        <div style={{ paddingBottom: '25px' }}>
          <span
            style={{
              color: '#555',
              fontWeight: 'bolder',
              textTransform: 'uppercase',
            }}
          >
            WRITTEN BY:{' '}{article.authorName || 'COMMERCE CONTRIBUTOR'}
          </span>
          <span
            style={{
              color: '#767676',
              paddingLeft: '10px',
            }}
          >
            Updated{' '}
            {format(new Date(article.datePublished), 'MMMM d, yyyy')}
          </span>
        </div>
        <div style={{ paddingBottom: '25px' }}>
          {article.introText}
        </div>
        <div style={{ paddingBottom: '25px' }}>
          {article.introCopy}
        </div>
        {
          article.products.map((listItem, index) => (
            <div key={index}>
              <div
                style={{
                  fontSize: '28px',
                  fontWeight: 'bold',
                  fontFamily: "'Literata', serif",
                  lineHeight: '35px',
                }}
              >
                {listItem.name}
              </div>
              <div
                style={{
                  fontSize: '18px',
                  fontFamily: "'Literata', serif",
                  fontWeight: 600,
                  lineHeight: '40px',
                }}
              >
                {listItem.award}
              </div>
              <div>
                {listItem.description}
              </div>
              <AmazonTile
                asin={listItem.asin}
                award={listItem.award}
                summary={listItem.summary}
                headline={listItem.headline}
              />
            </div>
          ))
        }
        <div>
          {article.outroCopy}
        </div>
        {
          article.questions.map((question, index) => (
            <div key={index} style={{ paddingBottom: '20px' }}>
              <div
                style={{
                  fontSize: '28px',
                  fontWeight: 'bold',
                  fontFamily: "'Literata', serif",
                  lineHeight: '35px',
                }}
              >
                {question.headline}
              </div>
              <div>{question.acceptedAnswer}</div>
            </div>
          ))
        }
        {
          article.authorName && (
            <div style={{ paddingBottom: '20px' }}>
              <div
                style={{
                  fontSize: '18px',
                  fontFamily: "'Literata', serif",
                  fontWeight: 600,
                  lineHeight: '40px',
                }}
              >
                About the Author
              </div>
              <div>{article.authorDescription}</div>
            </div>
          )
        }
        {
          article.trustStatement && (
            <div style={{ paddingBottom: '20px' }}>
              <div
                style={{
                  fontSize: '18px',
                  fontFamily: "'Literata', serif",
                  fontWeight: 600,
                  lineHeight: '40px',
                }}
              >
                Why You Should Trust Us?
              </div>
              <div>{article.trustStatement}</div>
            </div>
          )
        }
        <Divider style={{ margin: '16px 0 16px 0' }} />
        <div
          style={{
            fontStyle: 'italic',
            paddingBottom: '25px',
          }}
        >
          The publisher earns affiliate commissions from Amazon for qualifying purchases. The opinions expressed about the independently selected products mentioned in this content are those of the publisher, not Amazon.
        </div>
        <Divider style={{ margin: '16px 0 24px 0' }} />
      </div>
    </div>
  </>
);

export default ArticleFullPreview;
