/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
} from 'react';

import { notification } from 'antd';

import User from '../../../shared/dtos/UserDTO';
import SignedLayout from '../pages/layouts/signed_layout';
import UnsignedLayout from '../pages/layouts/unsigned_layout';
import Auth, {
  PermissionType, RoleType,
} from '../services/auth';

import api from '../services/api';

export class AuthError {
  public readonly message: string;
  public readonly code: number;

  constructor(message: string, code = 401) {
    this.message = message;
    this.code = code;
  }
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  username: string;
  password: string;
}

interface AuthContextData {
  user: User;
  token: string;
  updateUser(user: User): void;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  isPermitted(...permissions: PermissionType[]): boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any, title?: string | boolean, description?: string | boolean): void;
  useNoLayout(noLayout: boolean): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [noLayout, setNoLayout] = useState(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@AOP:token');
    const user = localStorage.getItem('@AOP:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const useNoLayout = useCallback((iNoLayout: boolean) => {
    setNoLayout(iNoLayout);
  }, []);

  const signIn = useCallback(async ({ username, password }) => {
    const response = await api.post<AuthState>('/api/auth', {
      body: {
        username,
        password,
      },
    });
    const { token, user } = response;
    localStorage.setItem('@AOP:token', token);
    localStorage.setItem('@AOP:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@AOP:token');
    localStorage.removeItem('@AOP:user');

    delete api.defaults.headers.authorization;

    setData({} as AuthState);
  }, []);

  const handleError = useCallback((err: Error, title?: string | boolean, description?: string | boolean) => {
    const subtitle = description === true ? 'Please try again later' : (description || err.message);

    if (err instanceof AuthError) {
      if (err.code === 401) {
        signOut();
      }
    }
    if (title !== false) {
      const message = title || 'Error';
      notification.error({
        key: 'error-handler',
        message,
        description: subtitle,
      });
    }
  }, [signOut]);

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem('@AOP:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      });
    },
    [data.token],
  );

  const isPermitted = useCallback((...permissions: PermissionType[]): boolean => {
    if (data.user && permissions.length > 0) {
      const role = data.user.role_id as RoleType;
      const permitted = permissions.find((permission) => Auth.isPermitted(role, permission));
      return !!permitted;
    }
    return true;
  }, [data.user]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user, token: data.token, signIn, signOut, updateUser, handleError, isPermitted, useNoLayout,
      }}
    >
      {noLayout ? children : (
        <>
          {data.user ? (
            <SignedLayout>{children}</SignedLayout>
          ) : (
            <UnsignedLayout>{children}</UnsignedLayout>
          )}
        </>
      )}

    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
