import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Skeleton,
  Slider,
  Spin,
  Tooltip,
  Typography,
} from 'antd';

import {
  ArrowRightOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import api from '../../services/api';
import KeywordOpportunitiesTable, { getScoreLabel } from '../../components/keyword_opportunities_table';

import {
  IOpportunityKeywordDTO,
  IResultFindOpportunitiesDTO,
} from '../../../../shared/dtos/OpportunityDTO';

import ScheduleOpportunitiesModal from '../../components/schedule_opportunities_modal';
import { useAuth } from '../../hooks/auth';
import HeaderPanel from '../../components/header_panel';
import { keywordSubmenuActions } from './keywords_summary';
import { REGION_OPTIONS } from '../../utils';

const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

interface IFormKeywordOpportunities {
  avgPriceMax?: number;
  avgPriceMin?: number;
  avgUnitsSoldMax?: number;
  avgUnitsSoldMin?: number;
  category: string[];
  excludeText: string;
  includeText: string;
  monthlySearchVolumeMin?: number;
  monthlySearchVolumeMax?: number;
  monthlyTrendMin?: number;
  monthlyTrendMax?: number;
  monthlyVlmMin?: number;
  monthlyVlmMax?: number;
  opportunityScoreMin?: number
  opportunityScoreMax?: number
  competitionMin?: number
  competitionMax?: number
  seasonalityMin?: number
  seasonalityMax?: number
  excludeTopBrands: boolean
  excludeExistingKeywords: boolean
  page: number
  sortBy?: string
  sortOrder?: string
  perPage?: number
}

const initialState = {
  category: [],
  competitionMax: 100,
  competitionMin: 1,
  seasonalityMax: 5,
  seasonalityMin: 1,
  excludeTopBrands: true,
  excludeExistingKeywords: false,
  avgPriceMax: undefined,
  avgPriceMin: undefined,
  avgUnitsSoldMax: undefined,
  avgUnitsSoldMin: undefined,
  monthlySearchVolumeMin: undefined,
  monthlySearchVolumeMax: undefined,
  monthlyTrendMin: undefined,
  monthlyTrendMax: undefined,
  monthlyVlmMin: undefined,
  monthlyVlmMax: undefined,
  opportunityScoreMin: undefined,
  opportunityScoreMax: undefined,
  excludeText: '',
  includeText: '',
  page: 1,
  sortBy: undefined,
  sortOrder: undefined,
  perPage: undefined,
};

const { Meta } = Card;
const CheckboxGroup = Checkbox.Group;

export const KEYWORDS_PER_PAGE = 50;

const KeywordOpportunities: React.FC = () => {
  const { handleError } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();

  const [preload, setPreload] = useState(false);
  const [filters, setFilters] = useState<IFormKeywordOpportunities>(() => initialState as IFormKeywordOpportunities);
  const [filterUsed, setFilterUsed] = useState<IFormKeywordOpportunities>();
  const [checkAll, setCheckAll] = useState(false);
  const [region, setRegion] = useState('us');
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [searched, setSearched] = useState(false);
  const [openFilter, setOpenFilter] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<IOpportunityKeywordDTO[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const parseArray = useCallback((paramName) => params.getAll(paramName), [params]);

  const parseBoolean = useCallback((paramName: string, defaultValue = false) => {
    const paramValue = params.get(paramName);
    if (paramValue === null) {
      return defaultValue;
    }
    return paramValue === 'true' || paramValue === '1';
  }, [params]);

  const parseNumber = useCallback((paramName: string, defaultValue = undefined) => {
    const paramValue = params.get(paramName);
    if (paramValue === null) {
      return defaultValue;
    }
    return Number(paramValue);
  }, [params]);

  const parse = useCallback((paramName: string) => params.get(paramName), [params]);

  const arrowRight = (
    <ArrowRightOutlined style={{
      color: '#d3d3d3',
      fontSize: 12,
      margin: 5,
    }} />
  );

  const handleClickSchedule = useCallback((event) => {
    setShowScheduleModal(true);
    event.stopPropagation();
  }, []);

  useEffect(() => {
    if (!preload) {
      const iRegion = parse('region');
      if (iRegion && iRegion !== region) {
        setRegion(iRegion);
      }
      const initialFilters = {
        competitionMin: parseNumber('competitionMin', initialState.competitionMin),
        competitionMax: parseNumber('competitionMax', initialState.competitionMax),
        seasonalityMin: parseNumber('seasonalityMin', initialState.seasonalityMin),
        seasonalityMax: parseNumber('seasonalityMax', initialState.seasonalityMax),
        includeText: parse('includeText') || initialState.includeText,
        excludeText: parse('excludeText') || initialState.excludeText,
        page: parseNumber('page', initialState.page),
        excludeTopBrands: parseBoolean('excludeTopBrands', initialState.excludeTopBrands),
        excludeExistingKeywords: parseBoolean(
          'excludeExistingKeywords',
          initialState.excludeExistingKeywords,
        ),
        category: parseArray('category[]'),
        avgPriceMin: parseNumber('avgPriceMin'),
        avgPriceMax: parseNumber('avgPriceMax'),
        avgUnitsSoldMin: parseNumber('avgUnitsSoldMin'),
        avgUnitsSoldMax: parseNumber('avgUnitsSoldMax'),
        monthlySearchVolumeMin: parseNumber('monthlySearchVolumeMin'),
        monthlySearchVolumeMax: parseNumber('monthlySearchVolumeMax'),
        monthlyTrendMin: parseNumber('monthlyTrendMin'),
        monthlyTrendMax: parseNumber('monthlyTrendMax'),
        monthlyVlmMin: parseNumber('monthlyVlmMin'),
        monthlyVlmMax: parseNumber('monthlyVlmMax'),
        opportunityScoreMin: parseNumber('opportunityScoreMin'),
        opportunityScoreMax: parseNumber('opportunityScoreMax'),
      };
      setFilters(initialFilters);
      setPreload(true);
      form.setFieldsValue(initialFilters);
    }
  }, [preload, form, parse, parseArray, parseBoolean, parseNumber, region]);

  const scheduleButton = useMemo(() => (
    <Tooltip
      title="Schedule automatic keywords updates"
      placement="left">
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          height: 40,
        }}
        type="ghost" onClick={(event) => handleClickSchedule(event)}
      >
        <div style={{
          fontSize: 12,
          marginRight: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}>
          <Text style={{ color: '#007bff' }}>Last Sync: <Text strong style={{ color: '#007bff' }}>2021-03-12</Text></Text>
          <Text style={{ color: '#007bff' }}>Repeat: <Text strong style={{ color: '#007bff' }}>Monthly</Text></Text>
        </div>

      </Button>
    </Tooltip>
  ), [handleClickSchedule]);

  const loadCategories = useCallback(async (): Promise<void> => {
    try {
      setLoadingCategories(true);
      const result = await api.get<string[]>(
        '/api/keyword-opportunities/categories',
        { params: { country: region } },
      );
      setCategories(result);
    } catch (err) {
      handleError(err, 'Categories could not be searched.');
    } finally {
      setLoadingCategories(false);
    }
  }, [region, handleError]);

  const onChangeCategories = useCallback((category) => {
    const queryString = new URLSearchParams();
    category.forEach((element: string) => {
      queryString.append('category[]', element);
    });
    setFilters((previous) => ({ ...previous, category }));
    setCheckAll(category.length === categories.length);
  }, [categories.length]);

  const onChangeRegion = useCallback((value) => {
    setFilters((previous) => ({ ...previous, category: [] }));
    setCheckAll(false);
    setRegion(value);
  }, []);

  const onCheckAllChange = useCallback((e) => {
    const category = e.target.checked ? categories : [];
    setFilters((previous) => ({ ...previous, category }));
    setCheckAll(e.target.checked);
  }, [categories]);

  const onCheckExcludeTopBrands = useCallback((e) => {
    setFilters((previous) => ({ ...previous, excludeTopBrands: e.target.checked }));
  }, []);

  const onCheckExcludeExistingKeywords = useCallback((e) => {
    setFilters((previous) => ({ ...previous, excludeExistingKeywords: e.target.checked }));
  }, []);

  const handleChangeCompetition = useCallback((values) => {
    const competitionMin = values[0];
    const competitionMax = values[1];
    setFilters((previous) => ({ ...previous, competitionMin, competitionMax }));
  }, []);

  const handleSaveSyncConfig = useCallback(() => {
    notification.warning({
      message: 'Save sync settings',
      description:
        'This function is under construction.',
    });
  }, []);

  const handleRunSyncConfig = useCallback(() => {
    notification.warning({
      message: 'Save sync settings and Run',
      description:
        'This function is under construction.',
    });
  }, []);

  const handleChangeSeasonality = useCallback((values) => {
    const seasonalityMin = values[0];
    const seasonalityMax = values[1];
    setFilters((previous) => ({ ...previous, seasonalityMin, seasonalityMax }));
  }, []);

  const handleResetForm = useCallback(() => {
    form.setFieldsValue(initialState);
    setFilters(initialState);
    setCheckAll(false);
    history.push({ search: '' });
  }, [form, history]);

  useEffect(() => {
    loadCategories();
    return () => {
      setKeywords([]);
      setTotalCount(0);
    };
  }, [loadCategories]);

  const labelsCompetition = useMemo(() => {
    const labelMin = getScoreLabel(filters.competitionMin, initialState.competitionMax || 100);
    const labelMax = getScoreLabel(filters.competitionMax, initialState.competitionMax || 100);
    return [labelMin, labelMax];
  }, [filters.competitionMax, filters.competitionMin]);

  const labelsSeasonality = useMemo(() => {
    const labelMin = getScoreLabel(filters.seasonalityMin, initialState.seasonalityMax || 5);
    const labelMax = getScoreLabel(filters.seasonalityMax, initialState.seasonalityMax || 5);
    return [labelMin, labelMax];
  }, [filters.seasonalityMax, filters.seasonalityMin]);

  const searchKeywords = useCallback(async (filterParams): Promise<void> => {
    try {
      setLoading(true);
      const resultKeywords = await api.get<IResultFindOpportunitiesDTO>('/api/keyword-opportunities', {
        params: {
          country: region,
          ...filterParams,
        },
      });
      const { keywords: gKeywords, total_count: gTotalCount } = resultKeywords;

      setKeywords(
        filters.excludeExistingKeywords
          ? gKeywords.filter((keyword) => !keyword.keyword_id)
          : gKeywords,
      );
      setTotalCount(gTotalCount);
      setSearched(true);
      setOpenFilter(false);
    } catch (err) {
      handleError(err, 'Keywords could not be searched.', true);
    } finally {
      setLoading(false);
    }
  }, [region, filters.excludeExistingKeywords, handleError]);

  const handleFormSubmit = useCallback((formValues: IFormKeywordOpportunities): void => {
    const {
      excludeTopBrands, competitionMin, competitionMax, seasonalityMin, seasonalityMax,
      page, sortBy, sortOrder,
    } = filters;
    const filterParams = {
      ...formValues,
      excludeTopBrands,
      competitionMin,
      competitionMax,
      seasonalityMin,
      seasonalityMax,
      page,
      sortBy,
      sortOrder,
      perPage: KEYWORDS_PER_PAGE,
    };
    setFilterUsed(filterParams);
    const objUrl = {
      ...filterParams,
      region,
    };
    const cleanedParams = JSON.parse(JSON.stringify(objUrl));
    const queryString = new URLSearchParams(cleanedParams);
    filters.category.forEach((cat) => {
      queryString.append('category[]', cat);
    });
    history.push({ search: queryString.toString() });
  }, [filters, history, region]);

  const performSearchByPage = useCallback((page: number, sortBy?: string, toOrder?: string) => {
    const sortOrder = toOrder ? toOrder.slice(0, -3) : undefined;
    setFilters((previous) => ({
      ...previous, page, sortBy, sortOrder,
    }));
    setFilterUsed((previous) => (previous ? {
      ...previous, page, sortBy, sortOrder,
    } : undefined));
  }, []);

  useEffect(() => {
    if (filterUsed) {
      searchKeywords(filterUsed);
    }
    return () => {
      setKeywords([]);
      setTotalCount(0);
    };
  }, [filterUsed, filters, searchKeywords]);

  return (
    <>
      <HeaderPanel
        hideHome
        actions={keywordSubmenuActions}
        showKeywordSearch={true}
      />

      <Collapse activeKey={[openFilter ? '1' : '']} onChange={() => setOpenFilter((previous) => !previous)}>
        <Panel header="Search filter" key="1" extra={scheduleButton}>
          <Form
            initialValues={filters}
            form={form}
            layout="vertical"
            onFinish={handleFormSubmit}
          >
            <Row>
              <Col span={12}>
                <Card>
                  <Select
                    value={region}
                    onChange={onChangeRegion}
                    style={{ marginBottom: 30, width: 200 }}
                  >
                    {
                      REGION_OPTIONS.map((mRegion) => (
                        <Option key={mRegion.value} value={mRegion.value}>
                            <img width={20} src={mRegion.icon} />
                            <span style={{ marginLeft: 10 }}>{mRegion.name}</span>
                        </Option>
                      ))
                    }
                  </Select>

                  <Meta
                    title="Select one or multiple categories"
                    description={
                      <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                        Select all
                      </Checkbox>
                    }
                    style={{
                      marginBottom: 15,
                    }}
                  />
                  <Skeleton loading={loadingCategories} title={false} paragraph active>
                      <CheckboxGroup value={filters.category} style={{ width: '100%' }} onChange={onChangeCategories} >
                        <Row>
                          {categories.map((category, idx) => (
                            <Col span={12} key={`${category}_${idx}`}>
                              <Checkbox value={category} >{category}</Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </CheckboxGroup>
                  </Skeleton>
                  <Divider />
                  <Row>
                    <Col span={12}>
                      <Form.Item name="includeText" label="Include Keywords" style={{
                        marginRight: 30,
                      }}>
                        <Input placeholder="Enter words separated by comma" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="excludeText" label="Exclude Keyword" style={{
                        marginRight: 30,
                      }}>
                        <Input placeholder="Enter words separated by comma" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={12} >
                <Card style={{
                  height: 560,
                }}>
                  <Row>
                    <Col span={12}>
                      <Form.Item label="Average Monthly Units Sold" tooltip="The average monthly units sold for listings in this niche.">
                        <Form.Item name="avgUnitsSoldMin" noStyle>
                          <InputNumber placeholder="Min" min={0} />
                        </Form.Item>
                        {arrowRight}
                        <Form.Item name="avgUnitsSoldMax" noStyle>
                          <InputNumber placeholder="Max" min={0} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Average Monthly Price" tooltip="The average sales price for listings in this niche.">
                        <Form.Item name="avgPriceMin" noStyle>
                          <InputNumber placeholder="Min" min={0} />
                        </Form.Item>
                        {arrowRight}
                        <Form.Item name="avgPriceMax" noStyle>
                          <InputNumber placeholder="Max" min={0} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Monthly Search Volume" tooltip="The number of times the keyword has been searched in the last 30 days.">
                        <Form.Item name="monthlySearchVolumeMin" noStyle>
                          <InputNumber placeholder="Min" min={0} />
                        </Form.Item>
                        {arrowRight}
                        <Form.Item name="monthlySearchVolumeMax" noStyle>
                          <InputNumber placeholder="Max" min={0} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Monthly Volume Trend" tooltip="Keyword search volume over the last 30 days vs the prior 30 days. High growth maybe refers to a new trend and you could find fresh product ideas from this niche.">
                        <Form.Item name="monthlyTrendMin" noStyle>
                          <InputNumber placeholder="Min" min={0} />
                        </Form.Item>
                        {arrowRight}
                        <Form.Item name="monthlyTrendMax" noStyle>
                          <InputNumber placeholder="Max" min={0} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Niche Score" tooltip="The Niche Score is graded on a scale of 1-10 where 10 is the highest opportunity and 1 is the lowest. The score is calculated based on the demand, competition, and the quality of the listings in this niche.">
                        <Form.Item name="opportunityScoreMin" noStyle>
                          <InputNumber placeholder="Min" min={0} />
                        </Form.Item>
                        {arrowRight}
                        <Form.Item name="opportunityScoreMax" noStyle>
                          <InputNumber placeholder="Max" min={0} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="competition"
                        label="Competition"
                        tooltip="We measure competitiveness based on keywords and number of reviews. Aim for competition in the low to medium range."
                        style={{
                          marginRight: 30,
                        }}>
                        <Slider
                          range
                          tipFormatter={null}
                          max={100}
                          min={1}
                          step={10}
                          value={[filters.competitionMin || initialState.competitionMin, filters.competitionMax || initialState.competitionMax]}
                          onChange={handleChangeCompetition}
                        />
                        <p style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: 12,
                          marginTop: -5,
                          color: 'black',
                        }}>
                          {labelsCompetition[0]} - {labelsCompetition[1]}
                        </p>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="seasonality"
                        label="Seasonality"
                        tooltip="It is based on how different the demand for a product is and if that spike in demand is cyclical (happens the same time every year, etc...) Low seasonality means a shorter peak during that season. Very high seasonality means a keyword’s peak demand is much higher and lasts only a few weeks. Not Classified means we're still collecting data."
                        style={{
                          marginRight: 30,
                        }}>
                        <Slider
                          range
                          tipFormatter={null}
                          value={[filters.seasonalityMin || initialState.seasonalityMin, filters.seasonalityMax || initialState.seasonalityMax]}
                          max={5}
                          min={1}
                          step={1}
                          onChange={handleChangeSeasonality}
                        />
                        <p style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: 12,
                          marginTop: -5,
                          color: 'black',
                        }}>
                          {labelsSeasonality[0]} - {labelsSeasonality[1]}
                        </p>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col span={12}>
                      <Form.Item name="excludeTopBrands" >
                        <Checkbox checked={filters.excludeTopBrands} onChange={onCheckExcludeTopBrands}>
                          Exclude Top Brands
                        </Checkbox>
                        <Tooltip title='Remove popular brands (e.g "Nike", "Apple", etc)'>
                          <QuestionCircleOutlined style={{
                            color: 'rgba(0, 0, 0, 0.45)',
                          }} />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="excludeExistingKeywords" >
                        <Checkbox checked={filters.excludeExistingKeywords} onChange={onCheckExcludeExistingKeywords}>
                          Exclude Existing Keywords
                        </Checkbox>
                        <Tooltip title='Removes keywords that are alread in our system'>
                          <QuestionCircleOutlined style={{
                            color: 'rgba(0, 0, 0, 0.45)',
                          }} />
                        </Tooltip>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <div style={{
              margin: 10,
              marginRight: 20,
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              <Button onClick={handleResetForm}>
                Reset Filters
            </Button>
              <Spin spinning={loading}>
                <Button type="primary"
                  htmlType="submit"
                  style={{
                    marginLeft: 10,
                  }}>
                  Search
              </Button>
              </Spin>
            </div>
          </Form>
        </Panel>
      </Collapse>
      {searched && (
        <Spin spinning={loading}>
          <KeywordOpportunitiesTable
            keywords={keywords}
            page={filters.page}
            totalCount={totalCount}
            onChange={(iPage, iSort, iSortOrder) => performSearchByPage(iPage, iSort, iSortOrder)}
          />
        </Spin>
      )}
      <ScheduleOpportunitiesModal
        onClose={() => setShowScheduleModal(false)}
        onSave={() => handleSaveSyncConfig()}
        onSaveAndRun={() => handleRunSyncConfig()}
        visible={showScheduleModal} />
    </>
  );
};

export default KeywordOpportunities;
