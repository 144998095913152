import React, {
  useMemo,
  useEffect,
} from 'react';

import {
  Redirect,
  Route as ReactDOMRoute,
  RouteProps as ReactRouteProps,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import {
  PermissionType,
  RoleType,
} from '../services/auth';
import api from '../services/api';

interface RouteProps extends ReactRouteProps {
  permit?: PermissionType;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  permit,
  component: Component,
  ...rest
}) => {
  const { user, isPermitted } = useAuth();

  const initialRoute = useMemo(() => {
    if (window.location.href === '/game-solutions') {
      return '/game-solutions';
    }

    if (!user) {
      return '/';
    }
    const userRole = user.role_id as RoleType;
    return userRole === 'wrt' ? '/tools' : '/keywords';
  }, [user]);

  useEffect(() => {
    const userId = user?.id;
    const restObject = rest.location;
    const url = window.location.href;

    const postUserActivity = async () => {
      await api.post('/api/user-activity', {
        body: {
          userId,
          url,
          restObject,
        },
      });
    };
    postUserActivity();
  }, [rest.location, user]);

  return (
    <ReactDOMRoute
      {...rest}
        render={({ location }) => (
          (!!permit === !!user && (!permit || isPermitted(permit)))
            ? (
              <Component />
            )
            : (
              <Redirect
                to={{
                  pathname: initialRoute,
                  state: { from: location },
                }}
              />
            ))}
    />
  );
};

export default Route;
