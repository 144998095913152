import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Spin, Empty,
} from 'antd';
import { Line } from 'react-chartjs-2';
import api from '../../services/api';
import { IFilterReport } from '../../pages/reports';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import { useAuth } from '../../hooks/auth';

type ContentSurfacesChartProps = {
  filters: IFilterReport
  sites: ISiteDTO[]
};

type ContentSurfaceRecord = {
  publisher: string
  createdAt: string
  total: number
};

type LineChartDataObject = {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    backgroundColor: string
    borderColor: string
    hidden?: boolean
  }[]
};

const COLOR_PALETTE = [
  '80, 80, 80',
  '255, 140, 0',
  '232, 17, 35',
  '236, 0, 140',
  '104, 33, 122',
  '0, 24, 143',
  '0, 188, 242',
  '0, 178, 148',
  '0, 158, 73',
  '186, 216, 10',
];

const ContentSurfacesChart: React.FC<ContentSurfacesChartProps> = ({
  filters, sites,
}) => {
  const { handleError } = useAuth();
  const { dateRange, tags, site } = filters;
  const [isLoading, setIsLoading] = useState(false);

  const [contentSurface, setContentSurface] = useState<ContentSurfaceRecord[][]>([]);

  const getSiteAliasName = useCallback((siteName?: string) => {
    if (siteName) {
      return siteName.replace(/\s/g, '').toLowerCase();
    }
    return undefined;
  }, []);

  const selectedSiteName = useMemo(() => {
    if (site) {
      const selectedSite = sites.find((st) => st.site_id === site);
      if (selectedSite) {
        return getSiteAliasName(selectedSite.display_name);
      }
    }
    return undefined;
  }, [site, getSiteAliasName, sites]);

  const performQuery = useCallback(async () => {
    try {
      setIsLoading(true);
      const contentSurfacesData = await api.get<ContentSurfaceRecord[][]>(
        '/api/stats/content-surfaces',
        {
          params: {
            dateRange,
            tags,
          },
        },
      );

      setContentSurface(contentSurfacesData);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }, [dateRange, tags, handleError]);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  const chartContent = useMemo(() => {
    if (contentSurface.length === 0) {
      return undefined;
    }

    const overAllDataSet = [{
      label: 'Over All',
      data: new Array(dateRange).fill(0),
      backgroundColor: 'rgb(0, 0, 0)',
      borderColor: 'rgb(0, 0, 0, 0.4)',
      hidden: false,
    }];

    const contentSurfaceFiltered = contentSurface.filter((cs) => cs.length > 0);

    const data = {
      labels: contentSurface[0].map((contentSurfacesRecord) => contentSurfacesRecord.createdAt),
      datasets: contentSurfaceFiltered.map((contentSurfacesRecords, index) => ({
        label: contentSurfacesRecords[0].publisher,
        data: contentSurfacesRecords.map((contentSurfacesRecord, dataIndex) => {
          overAllDataSet[0].data[dataIndex] += contentSurfacesRecord.total;
          return contentSurfacesRecord.total;
        }),
        hidden: selectedSiteName !== getSiteAliasName(contentSurfacesRecords[0].publisher),
        backgroundColor: `rgb(${COLOR_PALETTE[index % COLOR_PALETTE.length]})`,
        borderColor: `rgb(${COLOR_PALETTE[index % COLOR_PALETTE.length]}, 0.4)`,
      })),
    } as LineChartDataObject;
    data.datasets = [
      ...data.datasets,
      ...overAllDataSet,
    ];
    return data;
  }, [contentSurface, getSiteAliasName, dateRange, selectedSiteName]);

  return (
    <>
      {
        isLoading ? (
          <Spin size={'large'}>
            <div style={{ height: '700px', width: '100%' }}></div>
          </Spin>
        ) : (
          <>
            {
              chartContent ? (
                <Line
                  style={{ maxHeight: '700px' }}
                  type={'vertical'}
                  data={chartContent}
                />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
          </>
        )
      }
    </>
  );
};

export default ContentSurfacesChart;
