import React, {
  useCallback, useState,
} from 'react';

import {
  Modal, Form, Typography, Button, Radio,
} from 'antd';

const { Text } = Typography;

interface INewKeywordModelProps {
  visible?: boolean;
  onClose?(): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave(config: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSaveAndRun(config: any): void;
}

const ScheduleOpportunitiesModal: React.FC<INewKeywordModelProps> = ({
  visible, onClose, onSave, onSaveAndRun,
}: INewKeywordModelProps) => {
  const [repeating, setRepeating] = useState<string>();
  const [form] = Form.useForm();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSave = useCallback(() => {
    if (onClose) {
      onClose();
      onSave({ repeating });
    }
  }, [onClose, onSave, repeating]);

  const handleSaveAndRun = useCallback(() => {
    if (onClose) {
      onClose();
      onSaveAndRun({ repeating });
    }
  }, [onClose, onSaveAndRun, repeating]);

  const handleChangeRepeating = useCallback(({ target }) => {
    setRepeating(target.value);
  }, []);

  return (
    <Modal
      title="Schedule keywords updates"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave}>
          Save
        </Button>,
        <Button key="submit" type="primary" onClick={handleSaveAndRun}>
          Save and Run
        </Button>,
      ]}
    >

      <Text>Last sync: <Text strong>2021-02-12 08:00am</Text></Text>

      <Form
        form={form}
        name='keywordForm'
        layout="vertical"
        labelCol={{ span: 6 }}
        className='App-form'
      >
        <Form.Item label="Repeat?" name="requiredMark">
        <Radio.Group onChange={handleChangeRepeating}>
          <Radio.Button value='n'>Never</Radio.Button>
          <Radio.Button value='d'>Daily</Radio.Button>
          <Radio.Button value='w'>Weekly</Radio.Button>
          <Radio.Button value='m'>Monthly</Radio.Button>
        </Radio.Group>
      </Form.Item>

      </Form>
    </Modal>
  );
};

export default ScheduleOpportunitiesModal;
