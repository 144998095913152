import React from 'react';

import {
  STYLES,
  COLORS,
} from './constants';

interface ComparisonRowProps {
  index?: number
  styles?: React.CSSProperties
}

const { ROW_STYLES } = STYLES;
const { ROW, ROW_DARKER } = COLORS;

const ComparisonRow: React.FC<ComparisonRowProps> = ({
  index, styles, children,
}) => {
  const isHeader = index === undefined;
  const borderBottomWidth = isHeader ? 2 : 0;
  const rowColor = ((index || 0) % 2) === 0 ? ROW_DARKER : ROW;
  const backgroundColor = isHeader ? undefined : rowColor;
  const marginTop = !!index && index > 0 ? 1 : 0;
  const alignItems = isHeader ? 'flex-end' : 'center';

  return (
    <div
      style={{
        ...ROW_STYLES,
        alignItems,
        borderBottomWidth,
        marginTop,
        backgroundColor,
        ...styles,
      }}>
      {children}
    </div>
  );
};

export default ComparisonRow;
