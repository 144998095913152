import React, {
  useCallback,
  useMemo,
} from 'react';

import { InfoCircleFilled } from '@ant-design/icons';

import { Tooltip } from 'antd';

import {
  COLORS, STYLES,
} from './constants';

export interface IMetaInfo {
  title: string
  onClick(): void
}

interface ComparisonCenterBlockProps {
  text?: string
  index?: number
  icon?: React.ReactNode
  info?: IMetaInfo
  styles?: React.CSSProperties
  bodyStyles?: React.CSSProperties
}

const {
  CENTER,
  CENTER_DARKER,
} = COLORS;

const { CENTER_BLOCK_STYLES, CENTER_BLOCK_CONTENT_STYLES } = STYLES;

const ComparisonCenterBlock: React.FC<ComparisonCenterBlockProps> = ({
  text, icon, info, styles, bodyStyles, index,
}) => {
  const background = useMemo(() => {
    if (index === undefined) {
      return undefined;
    }
    return index % 2 === 0 ? CENTER_DARKER : CENTER;
  }, [index]);

  const handleClickInfo = useCallback((e) => {
    info?.onClick();
    e.stopPropagation();
  }, [info]);

  const infoPanel = useMemo(() => {
    if (!info) {
      return undefined;
    }
    return (
      <span
        onClick={handleClickInfo}
        style={{
          position: 'absolute',
          marginLeft: 120,
          marginTop: -5,
          cursor: 'pointer',
        }}>
        <Tooltip title={info.title}>
          <InfoCircleFilled style={{
            color: COLORS.ICON_CENTER,
          }} />
        </Tooltip>
      </span>
    );
  }, [handleClickInfo, info]);

  return (
    <div style={{
      ...CENTER_BLOCK_STYLES,
      ...bodyStyles,
      background,
    }}>
      {infoPanel}
      {icon && (icon)}
      <span style={{
        ...CENTER_BLOCK_CONTENT_STYLES,
        ...styles,
      }}>
        {text}
      </span>
    </div>
  );
};

export default ComparisonCenterBlock;
