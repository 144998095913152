import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Menu,
  Divider,
  Table,
  Tooltip,
  Rate,
} from 'antd';
import { format } from 'date-fns';
import {
  WarningOutlined, LoadingOutlined,
} from '@ant-design/icons';
import {
  Bar, Line,
} from 'react-chartjs-2';
import api from '../services/api';
import {
  IOpportunityGraphData,
  IOpportunityProductData,
  IOpportunityProductDataResponse,
} from '../../../shared/dtos/OpportunityDTO';
import { useAuth } from '../hooks/auth';

interface IKeywordOpportunitiesChartProps {
  name: string
  asins: string[]
}

type MenuKey = 'averageUnitsSold' |
'averagePrice' |
'searchVolume' |
'seasonality' |
'topProducts';

const formatter = new Intl.NumberFormat(
  'en-US', {
    style: 'currency',
    currency: 'USD',
  },
);

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const MENU_KEY_TO_CHART_CONFIG = {
  averageUnitsSold: {
    label: 'Average Units Sold History',
    type: 'bar',
    yKey: 'avgUnitsSold',
    xKey: 'date',
  },
  averagePrice: {
    label: 'Average Price History',
    type: 'line',
    yKey: 'avgPrice',
    xKey: 'date',
  },
  searchVolume: {
    label: 'Historical Search Volume',
    type: 'line',
    yKey: 'estimatedExactSearchVolume',
    xKey: 'estimateStartDate',
  },
  seasonality: {
    label: 'Seasonality History',
    type: 'line',
    yKey: 'value',
    xKey: 'week',
  },
  topProducts: {
    label: 'Top Products',
    type: 'table',
    yKey: '',
    xKey: '',
  },
};

const KeywordOpportunitiesChart: React.FC<IKeywordOpportunitiesChartProps> = ({
  name, asins,
}: IKeywordOpportunitiesChartProps) => {
  const { handleError } = useAuth();
  const [currentChartView, setCurrentChartView] = useState<MenuKey>('averageUnitsSold');
  const [graphData, setGraphData] = useState<IOpportunityGraphData>();
  const [graphLabel, setGraphLabel] = useState<string>(MENU_KEY_TO_CHART_CONFIG.averageUnitsSold.label);
  const [productData, setProductData] = useState<IOpportunityProductData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const productColumns = [
    {
      title: 'Product Information',
      width: 300,
      key: 'productInfo',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => a.name.localeCompare(b.name),
      render: (product: IOpportunityProductData) => (
        <>
          <div style={{ display: 'flex', width: '300px' }}>
            <div>
              <img src={product.imageUrl} height={60} width={60} style ={{ padding: '10px' }} />
            </div>
            <div>
              <div style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: 200,
              }}>
                <b>
                  {product.name}
                </b>
              </div>
              <div style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: 200,
              }}>
                <a href={product.product_url} target={'_blank'}>
                  {product.asin}
                </a>
                <Divider type="vertical" />
                {product.brand}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => a.category.localeCompare(b.category),
    },
    {
      title: 'Mo. Sales',
      dataIndex: 'estimatedSales',
      key: 'sales',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => a.estimatedSales - b.estimatedSales,
    },
    {
      title: 'Revenue',
      dataIndex: 'estRevenue',
      key: 'revenue',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => a.estRevenue - b.estRevenue,
      render: (revenue: number) => formatter.format(revenue),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => a.price - b.price,
      render: (price: number) => formatter.format(price),
    },
    {
      title: 'Review Numbers',
      dataIndex: 'nReviews',
      key: 'reviews',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => a.nReviews - b.nReviews,
    },
    {
      title: 'Date First Available',
      key: 'firstAvailableDate',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => {
        let aListedAt = a.listedAt;
        let bListedAt = b.listedAt;
        let aEstimatedListedAt = a.estimatedListedAt;
        let bEstimatedListedAt = b.estimatedListedAt;
        if (typeof aListedAt === 'string') {
          aListedAt = parseInt(aListedAt, 10);
        }
        if (typeof bListedAt === 'string') {
          bListedAt = parseInt(bListedAt, 10);
        }
        if (typeof aEstimatedListedAt === 'string') {
          aEstimatedListedAt = parseInt(aEstimatedListedAt, 10);
        }
        if (typeof bEstimatedListedAt === 'string') {
          bEstimatedListedAt = parseInt(bEstimatedListedAt, 10);
        }
        return (aListedAt || aEstimatedListedAt) - (bListedAt || bEstimatedListedAt);
      },
      render: (product: IOpportunityProductData) => {
        let productListedAt;
        if (product.listedAt) {
          productListedAt = product.listedAt;
          if (typeof product.listedAt === 'string') {
            productListedAt = parseInt(product.listedAt, 10);
          }
          return (<span>{format(new Date(productListedAt), 'yyyy-MM-dd')}</span>);
        }

        if (product.estimatedListedAt) {
          productListedAt = product.estimatedListedAt;
          if (typeof product.estimatedListedAt === 'string') {
            productListedAt = parseInt(product.estimatedListedAt, 10);
          }
          return (
            <span>
              {format(new Date(productListedAt), 'yyyy-MM-dd')}{' '}
              <Tooltip title={'Since Amazon does not provide this data, we will estimate by using the earliest review date or earliest Q&A date'}>
                <WarningOutlined />
              </Tooltip>
            </span>
          );
        }

        return null;
      },
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      sorter: (a: IOpportunityProductData, b: IOpportunityProductData) => a.rating - b.rating,
      render: (rating: number) => (
        rating && <Rate key={`${rating}+${Math.random()}`} disabled allowHalf defaultValue={rating} />
      ),
    },
  ];

  const loadAverageUnitsSold = useCallback(async (): Promise<void> => {
    try {
      const averageUnitsSoldResults = await api.get<IOpportunityGraphData>(
        `/api/keyword-opportunities/average-units-sold?name=${encodeURIComponent(name)}`,
      );
      setGraphData(averageUnitsSoldResults);
      setIsLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the graph data.', true);
    }
  }, [name, handleError]);

  const loadAveragePrice = useCallback(async (): Promise<void> => {
    try {
      const averageUnitsSoldResults = await api.get<IOpportunityGraphData>(
        `/api/keyword-opportunities/average-price?name=${encodeURIComponent(name)}`,
      );
      setGraphData(averageUnitsSoldResults);
      setIsLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the graph data.', true);
    }
  }, [handleError, name]);

  const loadSearchVolume = useCallback(async (): Promise<void> => {
    try {
      const averageUnitsSoldResults = await api.get<IOpportunityGraphData>(
        `/api/keyword-opportunities/search-volume?name=${encodeURIComponent(name)}`,
      );
      setGraphData(averageUnitsSoldResults);
      setIsLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the graph data.', true);
    }
  }, [handleError, name]);

  const loadSeasonality = useCallback(async (): Promise<void> => {
    try {
      const averageUnitsSoldResults = await api.get<IOpportunityGraphData>(
        `/api/keyword-opportunities/seasonality?name=${encodeURIComponent(name)}`,
      );
      setGraphData(averageUnitsSoldResults);
      setIsLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the graph data.', true);
    }
  }, [handleError, name]);

  const loadTopProducts = useCallback(async (): Promise<void> => {
    try {
      const topProductsResults = await api.get<IOpportunityProductDataResponse>(
        `/api/keyword-opportunities/top-products?asins[]=${asins.join('&asins[]=')}`,
      );
      setProductData(topProductsResults.data.data.products);
      setIsLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the product data.', true);
    }
  }, [asins, handleError]);

  const menuKeyToLoadMethodMapper = {
    averageUnitsSold: loadAverageUnitsSold,
    averagePrice: loadAveragePrice,
    searchVolume: loadSearchVolume,
    seasonality: loadSeasonality,
    topProducts: loadTopProducts,
  };

  const handleMenuClick = (menuKey: MenuKey) => {
    setIsLoading(true);
    setCurrentChartView(menuKey);
    setGraphLabel(MENU_KEY_TO_CHART_CONFIG[menuKey].label);
    menuKeyToLoadMethodMapper[menuKey]();
  };

  useEffect(() => {
    loadAverageUnitsSold();
  }, [loadAverageUnitsSold]);

  return (
    <div style={{ boxShadow: 'rgb(178 212 255) 0px 0px 15px 10px' }}>
      <Menu onClick={(e) => handleMenuClick(e.key as MenuKey)} selectedKeys={[currentChartView]} mode='horizontal'>
        <Menu.Item key={'averageUnitsSold'}>Average Units Sold</Menu.Item>
        <Menu.Item key={'averagePrice'}>Average Price</Menu.Item>
        <Menu.Item key={'searchVolume'}>Search Volume</Menu.Item>
        <Menu.Item key={'seasonality'}>Seasonality</Menu.Item>
        <Divider type="vertical" />
        <Menu.Item key={'topProducts'}>Top Products</Menu.Item>
      </Menu>
      {
        isLoading && (
          <div style={{
            fontSize: 54,
            color: 'rgba(65,145,247,1)',
            padding: 50,
            textAlign: 'center',
          }}>
            <LoadingOutlined />
          </div>
        )
      }
      {
        !isLoading && MENU_KEY_TO_CHART_CONFIG[currentChartView].type === 'bar' && (
          <Bar
            type={'vertical'}
            style={{ maxHeight: '500px' }}
            data={{
              labels: graphData && graphData.data.map((data) => data[MENU_KEY_TO_CHART_CONFIG[currentChartView].xKey]),
              datasets: [
                {
                  label: graphLabel,
                  data: graphData && graphData.data.map((data) => data[MENU_KEY_TO_CHART_CONFIG[currentChartView].yKey]),
                  backgroundColor: 'rgba(65,145,247,1)',
                },
              ],
            }}
            options={options}
          />
        )
      }
      {
        !isLoading && MENU_KEY_TO_CHART_CONFIG[currentChartView].type === 'line' && (
          <Line
            type={'vertical'}
            style={{ maxHeight: '500px' }}
            data={{
              labels: graphData && graphData.data.map((data) => data[MENU_KEY_TO_CHART_CONFIG[currentChartView].xKey]),
              datasets: [
                {
                  label: graphLabel,
                  data: graphData && graphData.data.map((data) => data[MENU_KEY_TO_CHART_CONFIG[currentChartView].yKey]),
                  backgroundColor: 'rgba(65,145,247,1)',
                  borderColor: 'rgba(65,145,247,1)',
                },
              ],
            }}
          />
        )
      }
      {
        !isLoading && MENU_KEY_TO_CHART_CONFIG[currentChartView].type === 'table' && (
          <Table
            columns={productColumns}
            dataSource={productData}
            pagination={false}
            scroll={{ y: 500 }}
          />
        )
      }
    </div>
  );
};

export default KeywordOpportunitiesChart;
