import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Form,
  Table,
  Typography,
  Button,
  Row,
  Col,
  Tooltip,
  Pagination,
} from 'antd';

import {
  formatDistance,
  format,
  parseISO,
} from 'date-fns';

import { ColumnProps } from 'antd/lib/table';
import api from '../../services/api';
import { IPaginatedQuery } from '../../../../shared/dtos/IPaginatedQuery';
import User from '../../../../shared/dtos/UserDTO';
import { useAuth } from '../../hooks/auth';
import {
  IArticle,
  IQueryArticleDTO,
} from '../../../../shared/dtos/ArticleDTO';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import DatePicker from '../../components/DatePicker';
import SearchUsers from '../../components/search_users';
import ArticleStatusText from '../../components/Articles/article_status_text';
import ArticleDetailsModal from '../../components/Articles/article_details_modal';
import HeaderPanel from '../../components/header_panel';
import UserLink from '../../components/Users/user_link';
import { PERMISSIONS } from '../../services/auth';

const { RangePicker } = DatePicker;

const { Text } = Typography;

interface IArticleStatus {
  label: string
  value: string
}

interface IArticlesFormFilter {
  periodDt: Date[]
  status: string[]
}

const defaultFilters = {
  page: 1,
  sort: 'articles.article_id',
  sortOrder: 'desc',
} as IQueryArticleDTO;

const ArticlesPage: React.FC = () => {
  const { handleError, isPermitted } = useAuth();
  const [query, setQuery] = useState<IQueryArticleDTO>({ ...defaultFilters });
  const [allSites, setAllSites] = useState<ISiteDTO[]>([]);
  const [allStatus, setAllStatus] = useState<IArticleStatus[]>([]);
  const [selectedArticle, setSelectedArticle] = useState<number | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const articlesSubmenuActions = useMemo(() => {
    if (isPermitted(PERMISSIONS.PendingArticles)) {
      return [
        {
          label: 'Articles',
          route: '/articles',
        },
      ];
    }
    return [];
  }, [isPermitted]);

  const handleShowDetails = useCallback((rowId: number) => {
    setSelectedArticle((previous) => (rowId === previous ? undefined : rowId));
  }, []);

  const columns = useMemo(() => {
    const permittedColumns: ColumnProps<IArticle>[] = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'articles.title',
        sorter: true,
        render: (title: string) => (
          <strong>{title}</strong>
        ),
      },
      {
        title: 'Site',
        dataIndex: 'site',
        key: 'sites',
        sorter: true,
        filters: allSites.map((iSite) => ({ text: iSite.display_name, value: iSite.site_id })),
        render: (site: ISiteDTO) => (
            <div key={site.site_id}>
              <img style={{ width: '18px' }}src={site.logo_url} />
              {' '}{site.display_name}
            </div>
        ),
      },
      {
        title: 'Requested by',
        dataIndex: 'user',
        key: 'users.first_name',
        sorter: true,
        render: (user: User) => (
          <UserLink user={user} />
        ),
      },
      {
        title: 'Requested at',
        dataIndex: 'created_at',
        key: 'articles.created_at',
        sorter: true,
        render: (value: string) => {
          const dt = parseISO(value);
          const dtAgoFormatted = formatDistance(dt, new Date(), { addSuffix: true });
          const dtFormatted = format(dt, 'yyyy-MM-dd HH:mm');
          return (
            <Tooltip title={dtFormatted}>
              <Text>{dtAgoFormatted}</Text>
            </Tooltip>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 80,
        render: (value: string) => (
          <ArticleStatusText status={value} />
        ),
        sorter: true,
        filters: allStatus.map((iStatus) => ({
          text: iStatus.label,
          value: iStatus.value,
        })),
      },
      {
        title: '',
        dataIndex: 'article_id',
        key: 'article_id',
        width: 80,
        render: (articleId: number) => (
          <Button type="link" onClick={() => handleShowDetails(articleId)}>
            See more
          </Button>
        ),
      },
    ];
    return permittedColumns;
  }, [allSites, allStatus, handleShowDetails]);

  const loadAllStatus = useCallback(async () => {
    try {
      const found = await api.get<IArticleStatus[]>('/api/articles/status');
      setAllStatus(found);
    } catch (err) {
      handleError(err, 'Could not search list of status');
    }
  }, [handleError]);

  const loadAllSites = useCallback(async () => {
    try {
      const found = await api.get<ISiteDTO[]>('/api/sites');
      setAllSites(found);
    } catch (err) {
      handleError(err, 'Could not search sites');
    }
  }, [handleError]);

  useEffect(() => {
    loadAllSites();
    loadAllStatus();
  }, [loadAllSites, loadAllStatus]);

  const searchArticles = useCallback(async () => {
    try {
      const { nodes, totalCount: currentTotalCount, page } = await api.get<IPaginatedQuery<IArticle>>('/api/articles', {
        params: {
          ...query,
        },
      });
      setTotalCount(currentTotalCount);
      setArticles(nodes);
      setCurrentPage(page);
    } catch (err) {
      handleError(err, 'Articles could not be searched.');
    }
  }, [handleError, query]);

  useEffect(() => {
    searchArticles();
  }, [searchArticles]);

  const handleChangeQuery = useCallback((pagination, filter, sorter) => {
    const sortOrder = sorter.order ? sorter.order.slice(0, -3) : defaultFilters.sortOrder;
    const sort = sorter.columnKey;

    setQuery((previous) => ({
      ...previous,
      ...filter,
      sort,
      sortOrder,
    }));
  }, []);

  const handleChangeFormFilters = useCallback((changed: IArticlesFormFilter) => {
    const { periodDt, ...rest } = changed;

    setQuery((previous) => {
      previous.createdAtBegin = periodDt ? periodDt[0].toISOString() : undefined;
      previous.createdAtEnd = periodDt ? periodDt[1].toISOString() : undefined;
      return {
        ...previous,
        ...rest,
      };
    });
  }, []);

  const handleArticleChanged = useCallback((saved?: IArticle) => {
    const cloneArticles = [...articles];
    if (saved) {
      const indexChangede = cloneArticles.findIndex((iArticle) => iArticle.article_id === saved.article_id);
      cloneArticles[indexChangede] = saved;
      setArticles(cloneArticles);
    } else {
      searchArticles();
    }
  }, [searchArticles, articles]);

  const handlePaginationChange = useCallback((page) => {
    setQuery((previous) => ({
      ...previous,
      page,
    }));
  }, []);

  return (
    <>
      <HeaderPanel
        actions={articlesSubmenuActions}
        title="Articles History" />

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Form
          name='keywordFormSearch'
          className='App-form'
          onValuesChange={handleChangeFormFilters}
        >
          <Row>
            <Col span={10}>
              <Form.Item
                label="Requested At"
                name={'periodDt'}>
                <RangePicker
                  style={{
                    width: 260,
                  }}
                  format={'yyyy-MM-dd'}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Users"
                name={'users'}>
                <SearchUsers
                  style={{
                    width: 300,
                  }}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </div>

      <Table
        size="small"
        columns={columns}
        dataSource={articles}
        onChange={handleChangeQuery}
        pagination={false}
        rowKey={(item) => item.article_id}
      />

      <div style={{ paddingTop: '20px' }}>
        <Pagination
          onChange={handlePaginationChange}
          showSizeChanger={false}
          pageSize={20}
          current={currentPage}
          total={totalCount}
        />
      </div>

      <ArticleDetailsModal
        onChange={(savedArticle) => handleArticleChanged(savedArticle)}
        onClose={() => setSelectedArticle(undefined)}
        articleId={selectedArticle} />

    </>
  );
};

export default ArticlesPage;
