import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Form,
  Input,
  Button,
  Divider,
  Typography,
} from 'antd';
import {
  useHistory, useLocation,
} from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import User from '../../../../shared/dtos/UserDTO';

const { Text, Link } = Typography;

interface IConfirmationParams {
  password: string;
  confirmation: string;
}

const Confirmation: React.FC = () => {
  const { signIn, handleError } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const token = location.pathname.split('/').pop();
  const [user, setUser] = useState <User>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const loadUserToken = useCallback(async (): Promise<void> => {
    try {
      const result = await api.get('/api/auth/confirmation', {
        params: {
          token,
        },
      });
      setUser(result as User);
    } catch (err) {
      history.push('/');
      handleError(err, 'Invalid confirmation');
    }
  }, [history, token, handleError]);

  useEffect(() => {
    loadUserToken();
  }, [loadUserToken]);

  const validateNewPasswordConfirmation = useCallback(async (rule, value): Promise<void> => {
    const newPasswordValue = form.getFieldValue('password');
    if (newPasswordValue === value) {
      await Promise.resolve();
    } else {
      await Promise.reject(new Error());
    }
  }, [form]);

  const handleContinue = useCallback(async ({ password }: IConfirmationParams): Promise<void> => {
    try {
      setLoading(true);
      const result = await api.post('/api/auth/confirmation', {
        body: {
          token,
          password,
        },
      });
      const confirmedUser = result as User;
      if (confirmedUser) {
        await signIn({ username: confirmedUser.username, password });
        history.push('/keywords');
      }
    } catch (err) {
      handleError(err, 'Sign-in error');
      history.push('/');
    } finally {
      setLoading(false);
    }
  }, [history, signIn, token, handleError]);

  const handleBackLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <>
      <Text type="secondary">Welcome, <strong>{user?.first_name}</strong>!</Text>
      <br />
      <Text type="secondary">Please create a new password:</Text>
      <Form
        form={form}
        style={{ marginTop: 15 }}
        labelCol={{ span: 7 }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleContinue}
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password placeholder="Your password" />
        </Form.Item>

        <Form.Item
          label="Confirmation"
          name="passwordConfirmation"
          rules={[
            { required: true, message: 'Please confirm your password!' },
            { validator: validateNewPasswordConfirmation, message: 'Must be equals password' },
          ]}
        >
          <Input.Password placeholder="Repeat your password" />
        </Form.Item>

        <Form.Item wrapperCol={{
          offset: 7,
        }}>
          <Button loading={loading} type="primary" htmlType="submit" style={{
            width: '100%',
          }}>
            Continue
          </Button>

        </Form.Item>

      </Form>
      <Divider />
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}>
        <Link style={{ marginLeft: 4 }} onClick={handleBackLogin}>Back to Login</Link>
      </div>
    </>
  );
};

export default Confirmation;
