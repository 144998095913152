import React, {
  ReactText,
  useCallback,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import {
  Modal,
  Tag,
  Input,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import api from '../services/api';
import Keyword, { Tag as TagType } from '../../../shared/dtos/KeywordDTO';
import { useAuth } from '../hooks/auth';

interface ITagsModelProps {
  visible?: boolean;
  tags?: TagType[];
  keywordIds: ReactText[];
  onClose?(): void;
  onSuccess?(newKeyword: Keyword): void;
}

const TagManagementModal: React.FC<ITagsModelProps> = ({
  visible,
  tags,
  keywordIds,
  onClose,
  onSuccess,
}: ITagsModelProps) => {
  let inputRef: Input;
  const { handleError } = useAuth();
  const [saving, setSaving] = useState(false);
  const [tagsToAdd, setTagsToAdd] = useState<TagType[]>([]);
  const [tagsToRemove, setTagsToRemove] = useState<TagType[]>([]);
  const [newTagValue, setNewTagValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);

  const handleClose = useCallback(() => {
    setTagsToAdd([]);
    setTagsToRemove([]);
    setNewTagValue('');
    setInputVisible(false);
    setSaving(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleNewTagRemoval = (removedTag: TagType) => {
    setTagsToAdd(_.uniqBy(_.xorBy(tagsToAdd, [removedTag], 'tag'), 'tag'));
  };

  const handleCurrentTagRemoval = (removedTag: TagType) => {
    if (removedTag?.tag_id) {
      setTagsToRemove(_.uniqBy([...tagsToRemove, removedTag], 'tag'));
    } else {
      handleNewTagRemoval(removedTag);
    }
  };

  const handleDeletedTagRemoval = (removedTag: TagType) => {
    setTagsToRemove(_.uniqBy(_.xorBy(tagsToRemove, [removedTag], 'tag'), 'tag'));
  };

  const handleTagSubmit = useCallback(async () => {
    setSaving(true);
    const body = {
      tagsToAdd,
      tagsToRemove,
      keywordIds,
    };

    try {
      const result = await api.post<Keyword>('/api/keywords/tags', {
        body,
      });
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (err) {
      handleError(err, 'Error saving keyword.');
    } finally {
      setTagsToAdd([]);
      setTagsToRemove([]);
      setNewTagValue('');
      setInputVisible(false);
      setSaving(false);
    }
  }, [tagsToAdd, tagsToRemove, keywordIds, onSuccess, handleError]);

  const showInput = () => {
    setInputVisible(true);
    inputRef?.focus();
  };

  const saveInputRef = (input: Input) => {
    inputRef = input;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (newTagValue.trim() !== '') {
      setTagsToAdd([...tagsToAdd, { tag: newTagValue.trim().toLowerCase() } as TagType]);
    }

    setNewTagValue('');
    setInputVisible(false);
  };

  const buttonOkLabel = useMemo(() => (saving ? 'Saving' : 'Submit'), [saving]);
  const tagsToShow = useMemo(() => (
    _.xorBy(_.unionBy(tags, tagsToAdd, 'tag'), tagsToRemove, 'tag')
  ), [tags, tagsToAdd, tagsToRemove]);

  const tagElement = (tag: TagType, color: string, handleTagRemoval: ((tag: TagType) => void)) => {
    const tagElem = (
      <Tag
        key={`${tag.tag_id}-${tag.tag}`}
        color={color}
        closable
        onClose={(e) => {
          e.preventDefault();
          handleTagRemoval(tag);
        }}
      >
        {tag.tag}
      </Tag>
    );
    return (
      <span key={`${tag.tag_id}-${tag.tag}`} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const currentTagMap = (tag: TagType) => tagElement(tag, 'blue', handleCurrentTagRemoval);
  const deletedTagMap = (tag: TagType) => tagElement(tag, 'red', handleDeletedTagRemoval);
  const addedTagMap = (tag: TagType) => tagElement(tag, 'green', handleNewTagRemoval);

  return (
    <Modal
      title='Tag Managment'
      visible={visible}
      onOk={handleTagSubmit}
      confirmLoading={saving}
      okText={buttonOkLabel}
      onCancel={handleClose}
    >
      <div style={{ marginBottom: 16 }}>
          <div style={{ fontWeight: 'bold' }}>Current Tags:</div>
          { tagsToShow?.map(currentTagMap) }
          {inputVisible && (
            <Input
              ref={saveInputRef}
              type="text"
              size="small"
              style={{ width: 78 }}
              value={newTagValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )}
          {!inputVisible && (
            <Tag onClick={showInput} className="site-tag-plus">
              <PlusOutlined /> New Tag
            </Tag>
          )}
          {
            tagsToAdd.length
              ? (<div style={{ fontWeight: 'bold', marginTop: '8px' }}>Tags To Add:</div>)
              : null
          }
          { tagsToAdd?.map(addedTagMap) }
          {
            tagsToRemove.length
              ? (<div style={{ fontWeight: 'bold', marginTop: '8px' }}>Tags to Remove:</div>)
              : null
          }
          { tagsToRemove?.map(deletedTagMap) }
      </div>
    </Modal>
  );
};

export default TagManagementModal;
