/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Typography,
  Row,
  Col,
  Modal,
  Image,
  Space,
  Card,
  Comment,
  message,
  Tooltip,
} from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  StopOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  format,
  formatDistance,
  parseISO,
} from 'date-fns';
import Button from 'antd/es/button';
import {
  ISaveArticleDTO,
  IArticle,
} from '../../../../shared/dtos/ArticleDTO';
import { useAuth } from '../../hooks/auth';
import { IMAGE_NOT_FOUND_AVG } from '../../utils';
import api from '../../services/api';
import ArticleStatusText from './article_status_text';
import User from '../../../../shared/dtos/UserDTO';

import {
  createExtraField,
  IArticleToResubmit,
} from '../../pages/articles/pending_articles';
import ArticleReasonModal, { IArticleReasonCommand } from './article_reason_modal';
import NewArticleModal from './new_article_modal';
import UserLink from '../Users/user_link';
import {
  handleCancelArticle, resubmitArticle, saveArticle,
} from './article_actions';
import { PERMISSIONS } from '../../services/auth';

const {
  Text,
  Link,
} = Typography;
const MAX_ACTION_BUTTON_WIDTH = 150;

interface IArticleDetailsModalProps {
  articleId?: number
  onClose(): void
  onChange(savedArticle?: IArticle): void
}

const ArticleDetailsModal: React.FC<IArticleDetailsModalProps> = ({
  articleId, onClose, onChange,
}) => {
  const { user: loggedUser, handleError, isPermitted } = useAuth();
  const [article, setArticle] = useState<IArticle>();
  const [reasonModal, setReasonModal] = useState<IArticleReasonCommand>();
  const [articleToEdit, setArticleToEdit] = useState<IArticleToResubmit>();

  const loadArticle = useCallback(async () => {
    if (articleId) {
      try {
        const resultArticle = await api.get<IArticle>(`/api/articles/${articleId}`);
        setArticle(resultArticle);
      } catch (err) {
        handleError(err, 'Articles could not be searched.');
      }
    }
  }, [handleError, articleId]);

  const formattedDate = useMemo(() => {
    if (article) {
      const dt = parseISO(article.created_at.toString());
      const dtAgoFormatted = formatDistance(dt, new Date(), { addSuffix: true });
      const dtFormatted = format(dt, 'yyyy-MM-dd HH:mm');
      return (
          <Tooltip title={dtFormatted}>
            <Text type="secondary" style={{
              fontSize: 12,
            }}>{dtAgoFormatted}</Text>
          </Tooltip>
      );
    }
    return <Text></Text>;
  }, [article]);

  const linkAsana = useMemo(() => {
    if (article && article.asana_url) {
      return <Link target="_blank" href={article.asana_url}>{article.gid}</Link>;
    }
    return <Text type="secondary">Pending</Text>;
  }, [article]);

  useEffect(() => {
    loadArticle();
  }, [loadArticle]);

  const formatDate = useCallback((date: Date) => {
    const dt = parseISO(date.toString());
    return format(dt, 'yyyy-MM-dd HH:mm');
  }, []);

  const articleUsername = useCallback((user?: User) => {
    if (!user) {
      return '';
    }
    if (user.id === loggedUser.id) {
      return 'You';
    }
    return `${user.first_name} ${user.last_name}`;
  }, [loggedUser.id]);

  const generateItemDetail = useCallback((title: string, content: React.ReactElement | string | undefined) => (
      <Row>
        <Col span={8}>
          <Text strong>{title}</Text>
        </Col>
        <Col span={16}>
          <Text>{content}</Text>
        </Col>
      </Row>
  ), []);

  const generateBigItemDetail = useCallback((title: string, content: React.ReactElement | string | undefined) => (
    <Card title={title} >
      {content}
    </Card>
  ), []);

  const productsContent = useMemo(() => {
    if (article) {
      if (!article.products || article.products.length === 0) {
        return <Text type="secondary">None</Text>;
      }
      return (
        <>
          {article.products.map((product) => (
            <Space direction="vertical" style={{
              width: '100%',
            }}>
            <Row>
              <Col xs={8}>
                {product.asin}
              </Col>
              <Col xs={8}>
              {product.display_name || product.name}
              </Col>
              <Col xs={8}>
              {product.award}
              </Col>
            </Row>
          </Space>
          ))}
        </>
      );
    }
    return <Text type="secondary">--</Text>;
  }, [article]);

  const returnsContent = useMemo(() => {
    if (article) {
      if (!article.returns || article.returns.length === 0) {
        return <Text type="secondary">None</Text>;
      }
      return (
        <>
          {article.returns.map((articleReturn) => (
            <Space direction="vertical" key={articleReturn.article_return_id} style={{
              width: '100%',
            }}>
              <Comment
                key={articleReturn.article_return_id}
                author={articleUsername(articleReturn.user)}
                content={
                  <p>
                    {articleReturn.reason}
                  </p>
                }
                datetime={
                  <span>{formatDate(articleReturn.created_at)}</span>
                }
              >
              {articleReturn.answered_at && (
                <Comment
                  style={{
                    marginTop: -25,
                  }}
                  author={articleUsername(article.user)}
                  content={
                    <p style={
                      articleReturn.complement ? {} : {
                        color: '#808080',
                        fontStyle: 'italic',
                      }}>
                      {articleReturn.complement || '[no comments]'}
                    </p>
                  }
                  datetime={
                    <span>{formatDate(articleReturn.answered_at)}</span>
                  }
                    />
              )}
              </Comment>
          </Space>
          ))}
        </>
      );
    }
    return <Text type="secondary">--</Text>;
  }, [formatDate, article, articleUsername]);

  const handleApprove = useCallback(async (iArticle: ISaveArticleDTO, reason: string) => {
    message.loading('Approving...', 0);
    try {
      onClose();
      await saveArticle(iArticle);
      const savedArticle = await api.patch<IArticle>(`/api/articles/validate/${iArticle.articleId}`, {
        body: {
          command: 'approve',
          reason,
        },
      });
      onChange(savedArticle);
    } catch (err) {
      handleError(err);
    } finally {
      message.destroy();
    }
  }, [handleError, onChange, onClose]);

  const handleValidate = useCallback((command: string) => {
    if (!article) {
      return;
    }
    switch (command) {
      case 'approve':
        setArticleToEdit({
          article,
          command: (savedArticle: ISaveArticleDTO, extraField: string) => {
            handleApprove(savedArticle, extraField);
          },
          extraField: {
            title: 'QA Comments',
            placeholder: 'Type aditional comments here',
          },
        });
        break;
      case 'reject':
        setReasonModal({
          article,
          command: 'reject',
        });
        break;
      default:
        setReasonModal({
          article,
          command: 'return',
        });
        break;
    }
  }, [handleApprove, article]);

  const handleRequester = useCallback((command: string) => {
    if (!article) {
      return;
    }
    const lastReturn = article.returns ? article.returns[0] : undefined;
    switch (command) {
      case 'cancel':
        handleCancelArticle({
          article,
          callback: (canceledArticle?: IArticle) => {
            onClose();
            onChange(canceledArticle);
          },
        });
        break;
      default:
        onClose();
        setArticleToEdit({
          article,
          command: async (savedArticle, comments) => {
            message.loading('Saving changes..');
            try {
              const savedArticle2 = await saveArticle(savedArticle);
              if (lastReturn) {
                const dblSavedArticle = await resubmitArticle(savedArticle, lastReturn, comments);
                onChange(dblSavedArticle);
              } else {
                onChange(savedArticle2);
              }
              onClose();
            } catch (err) {
              handleError(err, 'Could not save the article');
            } finally {
              message.destroy();
            }
          },
          extraField: createExtraField(lastReturn),
        });
        break;
    }
  }, [handleError, onChange, onClose, article]);

  const actions = useMemo(() => {
    const canValidate = isPermitted(PERMISSIONS.ValidateArticles) && article?.status === 'requested';
    const canResubmit = loggedUser.id === article?.user_id && article?.status === 'returned';
    const canEdit = loggedUser.id === article?.user_id && article?.status === 'draft';
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Button
          style={{
            float: 'left',
            width: MAX_ACTION_BUTTON_WIDTH,
          }}
          onClick={() => onClose()} >
          Close
        </Button>
        <span>
          {canEdit && (
            <>
              <Button
                danger
                style={{
                  width: MAX_ACTION_BUTTON_WIDTH,
                }}
                type="primary"
                icon={<StopOutlined />}
                onClick={() => handleRequester('cancel')} >
                Delete
              </Button>
              <Button
                type="primary"
                style={{
                  width: MAX_ACTION_BUTTON_WIDTH,
                }}
                icon={<EditOutlined />}
                onClick={() => handleRequester('submit')} >
                Edit
              </Button>
            </>
          )}
          {
            canResubmit && (
              <>
                <Button
                  danger
                  style={{
                    width: MAX_ACTION_BUTTON_WIDTH,
                  }}
                  type="primary"
                  icon={<StopOutlined />}
                  onClick={() => handleRequester('cancel')} >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{
                    width: MAX_ACTION_BUTTON_WIDTH,
                  }}
                  icon={<CheckCircleOutlined />}
                  onClick={() => handleRequester('submit')} >
                  Resubmit
                </Button>
              </>
            )
          }
          {
            canValidate && (
              <>
                <Button
                    danger
                    style={{
                      width: MAX_ACTION_BUTTON_WIDTH,
                    }}
                    type="primary"
                    icon={<StopOutlined />}
                    onClick={() => handleValidate('reject')} >
                  Reject
                </Button>
                <Button
                  ghost
                  style={{
                    width: MAX_ACTION_BUTTON_WIDTH,
                  }}
                  type="primary"
                  icon={<QuestionCircleOutlined />}
                  onClick={() => handleValidate('return')} >
                  Return
                </Button>
                <Button
                  type="primary"
                  style={{
                    width: MAX_ACTION_BUTTON_WIDTH,
                  }}
                  icon={<CheckCircleOutlined />}
                  onClick={() => handleValidate('approve')} >
                  Approve
                </Button>
              </>
            )
          }
        </span>
      </div>
    );
  }, [isPermitted, article?.status, article?.user_id, loggedUser.id, onClose, handleRequester, handleValidate]);

  const keyword = useMemo(() => {
    if (article && article.keywords && article.keywords.length > 0) {
      return article.keywords[0].keyword;
    }
    return '';
  }, [article]);

  return (
    <>
      <Modal
        title='Article Details'
        visible={!!articleId}
        footer={actions}
        onCancel={() => onClose()}
        width={750}>

        {article ? (
          <>
          <Card bodyStyle={{
            padding: 10,
          }}>
            <Row style={{
              marginTop: 4,
              marginBottom: 14,
            }}>
              <Col style={{
                width: '100%',
              }}>
                <Text
                  strong
                  style={{
                    float: 'left',
                  }}>
                  {article.title}
                </Text>
                <Text
                  type="secondary"
                  style={{
                    float: 'right',
                  }}>
                    {formattedDate}
                  </Text>
              </Col>
            </Row>
            <Row>
              <Col xs={7}>
                {article.image_url ? (
                    <Image
                    fallback={IMAGE_NOT_FOUND_AVG}
                    width={170}
                    src={article.image_url}
                  />
                ) : (
                  <Image
                    preview={false}
                    width={170}
                    src={IMAGE_NOT_FOUND_AVG}
                  />
                )}
              </Col>
              <Col xs={17}>
                <Space direction="vertical" style={{
                  width: '100%',
                }}>
                  {generateItemDetail('Keyword:', keyword)}
                  {generateItemDetail('Site:', (
                    <>
                      <img style={{ width: '18px' }}src={article.site?.logo_url} />
                      {' '}
                      {article.site?.display_name}
                    </>
                  ))}
                  {article.user && generateItemDetail('Requested By:', <UserLink user={article.user} />)}
                  {generateItemDetail('Status:', (
                    <ArticleStatusText status={article.status} />
                  ))}
                  {generateItemDetail('Validated by:', (
                    <Text type="secondary">{article.qa_user ? articleUsername(article.qa_user) : 'Pending'}</Text>
                  ))}
                  {generateItemDetail('Asana:', linkAsana)}
                  {article.wordpress_id
                    && generateItemDetail('Wordpress:', `${article.wordpress_id}`)
                  }
                </Space>
              </Col>
            </Row>
            </Card>
            {generateBigItemDetail('Products:', productsContent)}
            {generateBigItemDetail('Rejection Notes:', returnsContent)}
          </>
        ) : (
          <div>
            <LoadingOutlined style={{
              alignSelf: 'center',
              justifySelf: 'center',
              width: '100%',
              fontSize: 36,
            }} />
          </div>
        )}

      </Modal>
      <ArticleReasonModal
        articleCommand={reasonModal}
        onFinish={(savedArticle: IArticle) => {
          setReasonModal(undefined);
          onClose();
          onChange(savedArticle);
        }}
        onClose={() => setReasonModal(undefined)} />

      <NewArticleModal
        onSubmit={() => {}}
        onClose={() => setArticleToEdit(undefined)}
        onSave={(iArticle: ISaveArticleDTO, extraField: string) => articleToEdit?.command(iArticle, extraField)}
        selectedArticle={articleToEdit?.article}
        visible={!!articleToEdit?.article} />
    </>
  );
};

export default ArticleDetailsModal;
