import React from 'react';

import {
  Card, Layout, Typography,
} from 'antd';
import {
  Content,
  Footer,
} from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import { useHeader } from '../../hooks/header';

const { Text } = Typography;

const year = new Date().getFullYear();

const UnsignedLayout: React.FC = ({ children }) => {
  const { envName } = useHeader();
  return (
    <Layout style={{
      minHeight: '100vh',
    }}>
      <Content style={{
        alignSelf: 'center',
      }}>
        <Card
          bordered
          className="App-unsigned-card"
          headStyle={{
            background: '#232F3E',
            color: 'white',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            display: 'flex',
            justifyContent: 'center',
          }}
          title={
            <Title className="App-unsigned-title">
                DTMG Tool
            </Title>
          }>
          {children}
        </Card>
      </Content>
      <Footer style={{
        alignSelf: 'center',
      }}>
          DigitalTrends ©{year}
          {envName !== 'production' && (
            <small style={{ marginLeft: 6 }}>
              <Text type="secondary">/{envName}</Text>
            </small>
          )}
      </Footer>
    </Layout>
  );
};

export default UnsignedLayout;
