/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Form,
  Input,
  Button,
  Select,
  Popconfirm,
  Switch,
  Table,
  Typography,
} from 'antd';

import {
  CheckOutlined, CloseOutlined,
} from '@ant-design/icons';
import {
  IFeatureFlagDTO, IOptionFeatureFlagDTO,
} from '../../../../shared/dtos/FeatureFlagDTO';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import ff from '../../feature_flag';
import HeaderPanel from '../../components/header_panel';

const { Title } = Typography;
const { Option } = Select;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: string
  inputType: 'bool' | 'string' | 'select'
  record: IFeatureFlagDTO
  index: number
  children: React.ReactNode
  option?: IOptionFeatureFlagDTO
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  option,
  ...restProps
}) => {
  let inputNode;

  if (option?.type === 'boolean') {
    inputNode = (
      <Switch defaultChecked={record.value === 'true' || record.value === '1'} />
    );
  }
  if (option?.type === 'enum') {
    inputNode = (
      <Select>
        {option.values?.map((value: string, idx: number) => (
          <Option key={`sel_option_${idx}`} value={value}>{value}</Option>
        ))}
      </Select>
    );
  }
  if (option?.type === 'string') {
    inputNode = (<Input value="Feature flag value" />);
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const formatFeatureFlagName = (featureFlagName: string): string => featureFlagName.toUpperCase()
  .replace(/\s+/g, '_')
  .replace(/[^A-Z0-9_]/g, '');

const FeatureFlags: React.FC = () => {
  const { SHOW_FEATURE_FLAG_HEADER, FEATURE_FLAG_HEADER_TEXT } = ff;
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const { handleError } = useAuth();
  const [featureFlags, setFeatureFlags] = useState<IFeatureFlagDTO[]>([]);
  const [options, setOptions] = useState<IOptionFeatureFlagDTO[]>([]);
  const [editingKey, setEditingKey] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<IOptionFeatureFlagDTO>();
  const isEditing = (record: IFeatureFlagDTO) => record.feature_flag_id === editingKey;

  const loadFeatureFlags = useCallback(async () => {
    try {
      const featureFlagRecords = await api.get<IFeatureFlagDTO[]>('api/feature-flags');
      setFeatureFlags(featureFlagRecords);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  const loadFeatureFlagsOptions = useCallback(async () => {
    try {
      const mOptions = await api.get<IOptionFeatureFlagDTO[]>('api/feature-flags/options');
      setOptions(mOptions);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    loadFeatureFlagsOptions();
    loadFeatureFlags();
  }, [loadFeatureFlags, loadFeatureFlagsOptions]);

  const handleFeatureFlagEdit = (record: IFeatureFlagDTO) => {
    editForm.setFieldsValue({ ...record });
    setEditingKey(record.feature_flag_id);
  };

  const handleFeatureFlagEditCancel = () => {
    setEditingKey(0);
  };

  const handleFeatureFlagEditSave = async (featureFlag: IFeatureFlagDTO) => {
    const iOption = options.find((option) => option.name.toUpperCase() === featureFlag.feature_flag.toUpperCase());
    if (!iOption) {
      return;
    }
    try {
      const row = (await editForm.validateFields()) as IFeatureFlagDTO;
      row.type = iOption.type === 'boolean' ? 'bool' : 'string';
      row.feature_flag = iOption.name;

      await api.patch<IFeatureFlagDTO[]>(`api/feature-flags/${featureFlag.feature_flag_id}`, {
        body: row,
      });
      setEditingKey(0);
      loadFeatureFlags();
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAddFeatureFlag = async () => {
    if (!selectedOption) {
      return;
    }
    try {
      const featureFlagData = (await addForm.validateFields()) as IFeatureFlagDTO;
      featureFlagData.type = selectedOption.type === 'boolean' ? 'bool' : 'string';
      featureFlagData.feature_flag = formatFeatureFlagName(featureFlagData.feature_flag);

      await api.post<IFeatureFlagDTO[]>('api/feature-flags', {
        body: featureFlagData,
      });
      addForm.resetFields();
      setEditingKey(0);
      loadFeatureFlags();
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleFeatureFlagDelete = async (record: IFeatureFlagDTO) => {
    try {
      await api.delete<IFeatureFlagDTO[]>(`api/feature-flags/${record.feature_flag_id}`);
      loadFeatureFlags();
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Delete Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Feature Flag Name',
      dataIndex: 'feature_flag',
      width: '30%',
      editable: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '15%',
      editable: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '30%',
      editable: true,
      render: (value: string, record: IFeatureFlagDTO) => {
        if (record.type === 'bool') {
          if (value === 'true' || value === '1') {
            return (<CheckOutlined style={{
              color: 'green',
            }} />);
          }
          return (<CloseOutlined style={{
            color: 'red',
          }} />);
        }
        return value;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render: (_value: unknown, record: IFeatureFlagDTO) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a onClick={() => handleFeatureFlagEditSave(record)} style={{ marginRight: 8 }}>
              Save
            </a>
            <Popconfirm title="Are you sure?" onConfirm={handleFeatureFlagEditCancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              style={{ marginRight: 8 }}
              disabled={editingKey !== 0}
              onClick={() => handleFeatureFlagEdit(record)
            }>
              Edit
            </Typography.Link>
            <Popconfirm title="Are you sure?" onConfirm={() => handleFeatureFlagDelete(record)}>
              <a>Delete</a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IFeatureFlagDTO) => {
        const editing = col.dataIndex === 'value' && isEditing(record);
        return {
          record,
          inputType: record.type,
          dataIndex: col.dataIndex,
          title: col.title,
          editing,
          option: options.find((option) => option.name.toUpperCase() === record.feature_flag.toUpperCase()),
        };
      },
    };
  });

  const handleSelectOption = useCallback((value) => {
    const iOption = options.find((option) => option.name === value);
    setSelectedOption(iOption);
    let formValue;
    if (!iOption) {
      formValue = undefined;
    }
    if (iOption?.type === 'boolean') {
      formValue = false;
    }
    addForm.setFieldsValue({
      value: formValue,
    });
  }, [addForm, options]);

  const inputField = useMemo(() => {
    if (!selectedOption) {
      return (
        <Input value="Select a type" disabled />
      );
    }
    if (selectedOption.type === 'boolean') {
      return (
        <Switch />
      );
    }
    if (selectedOption.type === 'enum' && selectedOption.values) {
      return (
        <Select>
          {selectedOption.values.map((value: string, idx: number) => (
            <Option key={`sel_option_${idx}`} value={value}>{value}</Option>
          ))}
        </Select>
      );
    }
    return <Input value="Feature flag value" />;
  }, [selectedOption]);

  const availableOptions = useMemo(() => {
    const registeredNames = featureFlags.map((feat) => feat.feature_flag.toUpperCase());
    const filtered = options.filter((option) => !registeredNames.includes(option.name.toUpperCase()));
    return filtered;
  }, [featureFlags, options]);

  return (
    <>
      <HeaderPanel
        title="Feature Flags" />
      {
        SHOW_FEATURE_FLAG_HEADER && <Title level={3}>{FEATURE_FLAG_HEADER_TEXT}</Title>
      }
      <Form form={addForm} component={false}>
        <div style={{ display: 'flex' }}>
          <Form.Item
            name='feature_flag'
            style={{ margin: 10, width: '30%' }}
            rules={[
              {
                required: true,
                message: 'Please Input Feature Flag Name!',
              },
            ]}
          >
            <Select onChange={handleSelectOption}>
              {
                availableOptions.map((option) => (
                  <Option key={option.name} value={option.name}>{option.name}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            style={{ margin: 10, width: '15%' }}
          >
            <Input value={selectedOption?.type} readOnly />
          </Form.Item>
          <Form.Item
            name='value'
            style={{ margin: 10, width: '30%' }}
            rules={[
              {
                required: true,
                message: 'Please Input Value!',
              },
            ]}
          >
            {inputField}
          </Form.Item>
          <Button
            type='primary'
            shape='round'
            style={{ margin: 10 }}
            onClick={() => handleAddFeatureFlag()}
          >
            Add
          </Button>
        </div>
      </Form>
      <Form form={editForm} component={false} onFinish={handleAddFeatureFlag}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={featureFlags}
          columns={mergedColumns}
          rowClassName="editable-row"
        />
    </Form>
    </>
  );
};

export default FeatureFlags;
