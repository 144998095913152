import React from 'react';

import Text from 'antd/lib/typography/Text';

import { STYLES } from './constants';

const { CELL_STYLES } = STYLES;

interface ComparisonCellProps {
  title?: string
  subtitle?: string
  styles?: React.CSSProperties
}

const ComparisonCell: React.FC<ComparisonCellProps> = ({
  title, subtitle, styles, children,
}) => (
  <div style={{ ...CELL_STYLES, ...styles }}>
    {title ? (
      <>
        <strong>
          {title}
        </strong>
        {subtitle && (
          <Text
            type="secondary"
            ellipsis={true}
            style={{
              width: '100%',
              paddingLeft: 22,
              paddingRight: 22,
            }}>
            {subtitle}
          </Text>
        )}
      </>
    ) : children}
  </div>
);

export default ComparisonCell;
