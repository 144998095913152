import React from 'react';

import { AuthProvider } from './auth';
import { HeaderProvider } from './header';

const AppProvider: React.FC = ({ children }) => (
  <HeaderProvider>
    <AuthProvider>
      {children}
    </AuthProvider>
  </HeaderProvider>
);

export default AppProvider;
