import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';
import {
  Empty,
  Spin,
  Switch,
} from 'antd';

import { IWordpressPreview } from '../../../../shared/dtos/WordpressDTO';

import api from '../../services/api';
import ArticleFullPreview from './components/full_page_preview';
import ArticleCollapsedPreview from './components/collapsed_preview';
import { useAuth } from '../../hooks/auth';
import HeaderPanel from '../../components/header_panel';

type ISitePrefix = 'bm' | 'hs' | 'pt' | 'nf' | 'ok' | 'ta' | 'tj' | 'tm' | 'dt';

interface ISitePrefixMap {
  bm: string
  dt: string
  hs: string
  pt: string
  nf: string
  ok: string
  ta: string
  tj: string
  tm: string
}

const sitePrefixMapping: ISitePrefixMap = {
  bm: 'blissmark',
  hs: 'happysprout',
  pt: 'pawtracks',
  nf: 'newfolks',
  ok: '21oak',
  ta: 'theangle',
  tj: 'toughjobs',
  tm: 'themanual',
  dt: 'digitaltrends',
};

const ArticlePreview: React.FC = () => {
  const { handleError } = useAuth();
  const { site: sitePrefix, id: postId } = useParams<{ site: ISitePrefix, id: string }>();
  const [loading, setLoading] = useState(false);
  const [wordpressPreview, setWordpressPreview] = useState<IWordpressPreview>();
  const [viewFullArticle, setViewFullArticle] = useState<boolean>(false);
  const siteName = sitePrefixMapping[sitePrefix];

  const handleApiCall = useCallback(async () => {
    if (!siteName) {
      return;
    }
    try {
      setLoading(true);
      const result = await api.get<IWordpressPreview>('/api/preview', {
        params: {
          site: siteName,
          id: postId,
        },
      });
      setWordpressPreview(result);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [handleError, postId, siteName]);

  const article = useMemo(() => {
    if (!wordpressPreview) {
      return undefined;
    }
    const { post } = wordpressPreview;
    return post;
  }, [wordpressPreview]);

  useEffect(() => {
    handleApiCall();
  }, [handleApiCall]);

  return (
    <div>
      <HeaderPanel
        hideHome
        actions={[]}
        showKeywordSearch={false}
      />
      <div style={{ float: 'left', fontSize: '20px', margin: '12px' }}>
        Article Preview
      </div>
      <div style={{ float: 'right', fontSize: '20px', margin: '12px' }}>
        <span style={{ paddingRight: '8px' }}>Collapsed</span>
        <Switch checked={viewFullArticle} onChange={() => setViewFullArticle(!viewFullArticle)} />
        <span style={{ paddingLeft: '8px' }}>Full</span>
      </div>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Literata:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
      <div
        style={{
          boxShadow: 'rgb(178 212 255) 0px 0px 15px 10px',
          backgroundColor: 'white',
        }}
      >
        <img src={'/amazon_top_nav.png'} style={{ width: '100%' }}/>
        {
          loading && (
            <div style={{
              width: '100%',
              height: 500,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Spin />
            </div>
          )
        }
        {
          !article && !loading && (
            <Empty style={{ padding: 80 }} description={`Post '${postId}' not found in WordPress`} />
          )
        }
        {
          article && (
            <>
              {
                viewFullArticle
                  ? wordpressPreview?.post && article && (
                    <ArticleFullPreview
                      site={wordpressPreview?.site}
                      article={article}
                    />
                  )
                  : wordpressPreview?.site && article && (
                    <ArticleCollapsedPreview
                      site={wordpressPreview?.site}
                      article={article}
                      handleViewFullArticleClick={() => setViewFullArticle(true)}
                    />
                  )
              }
            </>
          )
        }
      </div>
    </div>
  );
};

export default ArticlePreview;
