/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';

import {
  Form,
  FormInstance,
  Input,
  Space,
} from 'antd';

import { FormListFieldData } from 'antd/lib/form/FormList';
import {
  ICreateProductDTO,
  IProduct,
} from '../../../../shared/dtos/ProductDTO';

interface IProductFormProps {
  form: FormInstance
  formListFieldData: FormListFieldData
  index: number
  onRemove(name: number): void
}

const ASIN_SIZE = 10;
const AWARD_MAX_CHAR_COUNT = 30;
const SUMMARY_MAX_CHAR_COUNT = 200;

const ProductForm: React.FC<IProductFormProps> = ({
  form, formListFieldData, onRemove, index,
}) => {
  const {
    key, name, fieldKey, ...restField
  } = formListFieldData;
  const [awardCharCount, setAwardCharCount] = useState(form.getFieldValue('products')[index]?.award?.length || 0);

  const validateUniqueASIN = useCallback(async (_rule, value: string): Promise<void> => {
    if (!value) {
      return Promise.resolve();
    }
    const products = form.getFieldValue('products') as IProduct[];
    const asinProducts = products.filter((product) => product && product.asin.toUpperCase() === value.toUpperCase());
    if (asinProducts.length === 1) {
      return Promise.resolve();
    }
    return Promise.reject('ASIN must be unique');
  }, [form]);

  const handleRemove = useCallback(() => {
    onRemove(name);
  }, [name, onRemove]);

  const handleChangeAsin = useCallback((e) => {
    const value = e.target.value.toUpperCase();
    const products = form.getFieldValue('products') as ICreateProductDTO[];
    products[index].asin = value;
    form.setFieldsValue({ products });
  }, [form, index]);

  const handleAwardOnChange = useCallback((event) => {
    const { value } = event.target;
    const products = form.getFieldValue('products') as ICreateProductDTO[];
    products[index].award = value;
    form.setFieldsValue({ products });
    setAwardCharCount(value.length);
  }, [form, index]);

  const asinField = useMemo(() => (
      <Form.Item
        name={[name, 'asin']}
        style={{
          width: 180,
        }}
        fieldKey={[fieldKey, 'asin']}
        rules={[
          { required: true, message: 'Missing ASIN' },
          { validator: validateUniqueASIN },
        ]}
      >
        <Input
          placeholder="ASIN"
          maxLength={ASIN_SIZE}
          minLength={ASIN_SIZE}
          onChange={handleChangeAsin}
        />
      </Form.Item>
  ), [fieldKey, handleChangeAsin, name, validateUniqueASIN]);

  const awardField = useMemo(() => (
    <div style={{
      width: 220,
    }}>
      <Form.Item
        name={[name, 'award']}
        style={{
          width: 220,
        }}
        fieldKey={[fieldKey, 'award']}
        rules={[{ required: true, message: 'Missing award' }]}
      >
        <Input
          placeholder="Award"
          maxLength={AWARD_MAX_CHAR_COUNT}
          onChange={handleAwardOnChange}
        />
      </Form.Item>
      <div style={{
        float: 'right',
        color: 'rgba(0, 0, 0, 0.45)',
        fontSize: '14px',
        position: 'relative',
        top: '-22px',
      }}>
        {awardCharCount}{' '}/{' '}{AWARD_MAX_CHAR_COUNT}
      </div>
    </div>
  ), [awardCharCount, fieldKey, handleAwardOnChange, name]);

  return (
    <>
      <Space style={{ display: 'flex', marginBottom: '-18px' }} align="baseline">
        {asinField}
        <Form.Item
          {...restField}
          name={[name, 'display_name']}
          style={{
            width: 240,
          }}
          fieldKey={[fieldKey, 'display_name']}
          rules={[{ required: true, message: 'Missing name' }]}
        >
          <Input placeholder="Product Name" />
        </Form.Item>
        {awardField}
        <MinusCircleOutlined onClick={handleRemove} />
      </Space>
      <Form.Item
        {...restField}
        name={[name, 'summary']}
        style={{
          width: '680px',
          display: 'inline-block',
          paddingRight: '22px',
          paddingBottom: '28px',
          borderBottom: '1px solid #ccc',
        }}
        fieldKey={[fieldKey, 'summary']}
      >
        <Input.TextArea placeholder="Summary" maxLength={SUMMARY_MAX_CHAR_COUNT} showCount />
      </Form.Item>
    </>
  );
};

export default ProductForm;
