/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  Col,
  Collapse,
  Dropdown,
  Menu,
  Modal,
  Row,
} from 'antd';

import {
  AimOutlined,
  BoldOutlined,
  CalendarOutlined,
  DiffOutlined,
  FontColorsOutlined,
  PictureOutlined,
  ShoppingOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import Tag from 'antd/es/tag';

import Text from 'antd/lib/typography/Text';
import { IStatDTO } from '../../../../../shared/dtos/StatDTO';
import api from '../../../services/api';
import {
  IAmazonArticleDTO,
  IArticleContent,
} from '../../../../../shared/dtos/ArticleDTO';
import AmazonProductView from '../../Products/amazon_product_panel';

import ComparisonCenterBlock, { IMetaInfo } from './comparison_center_block';
import ComparisonCell from './comparison_cell';
import ComparisonRowValue from './comparison_row_value';
import ComparisonHeader from './comparison_header';
import ComparisonImage from './comparison_image';
import ComparisonRow from './comparison_row';
import WordsExcludeModal from './words_exclude_modal';
import { formatCurrency } from '../../../utils';
import { Keyword } from '../../../../../shared/dtos';

import {
  STYLES,
  WORD_FREQUENCY,
} from './constants';

enum ArticleMetaCategory {
  'UPDATED',
  'HERO TEXT',
  'PRODUCTS',
  'KEYWORDS',
  'WORDS',
  'PARAGRAPHS',
  'FIGURES',
  'TITLES / SUBTITLE',
  'BOLD CONTENT',
}

type ArticleMetaCategoryType = keyof typeof ArticleMetaCategory;

interface ArticleComparisonModalProps {
  stat?: IStatDTO
  onClose(): void
}

interface IArticleMetaInfo {
  title?: string
  subtitle?: string
  content?: React.ReactNode
  category: ArticleMetaCategoryType
}

export interface IArticleError {
  message: string
  url?: string
}

export interface IWordFrequency {
  name: string
  count: number
}

const { Panel } = Collapse;

const {
  MODAL_STYLES,
  CENTER_BLOCK_HEADER_STYLES,
  ICON_STYLES,
} = STYLES;

const {
  MIN,
} = WORD_FREQUENCY;

const TITLE_TAGS = ['h1', 'h2', 'h3'];
const BOLD_TAGS = ['b'];

const fixArticleURL = (url: string) => {
  if (url.startsWith('/ospublishing/')) {
    const codePart = url.split('&').find((p) => p.endsWith('.en_US'));
    if (!codePart) {
      throw Error('Invalid URL');
    }
    const code = codePart.split('.').reduce((larger: string | undefined, part) => {
      if (!larger || part.length > larger.length) {
        return part;
      }
      return larger;
    }, undefined);
    if (!code) {
      throw Error('Invalid URL');
    }
    return `https://www.amazon.com//ospublishing/story/${code}`;
  }
  return url;
};

const countWords = (content: string) => {
  if (!content) {
    return undefined;
  }
  return content.trim().split(/\s+/).length;
};

const countWordFrequency = (iArticle: IAmazonArticleDTO, ignoreWords: string[]): IWordFrequency[] => {
  const valueContent = iArticle.content.map((c) => c.value);
  const content = valueContent.join(' ');
  const allWords = content.trim().split(/\s+/);
  const countWordss = allWords.reduce((result: IWordFrequency[], word) => {
    if (ignoreWords.includes(word.trim().toLowerCase())) {
      return result;
    }
    const resultIndex = result.findIndex((r) => r.name.toLowerCase() === word.toLowerCase());
    if (resultIndex > -1) {
      const objCount = result[resultIndex].count;
      result[resultIndex] = {
        name: word,
        count: objCount + 1,
      };
    } else {
      result.push({
        name: word,
        count: 1,
      });
    }
    return result;
  }, []);
  const orderedFiltered = countWordss.filter((a) => a.count >= MIN);
  const orderedCounter = orderedFiltered.sort((a, b) => (a.count > b.count ? -1 : 1));
  return orderedCounter;
};

const ArticleComparisonModal: React.FC<ArticleComparisonModalProps> = ({
  stat, onClose,
}) => {
  const [article, setArticle] = useState<IAmazonArticleDTO>();
  const [articleLoading, setArticleLoading] = useState(false);
  const [articleError, setArticleError] = useState<IArticleError>();
  const [myArticle, setMyArticle] = useState<IAmazonArticleDTO>();
  const [myArticleLoading, setMyArticleLoading] = useState(false);
  const [myArticleError, setMyArticleError] = useState<IArticleError>();
  const [excludedWords, setExcludedWords] = useState<string[]>([]);
  const [showExcludedWordsModal, setShowExcludedWordsModal] = useState(false);

  const getMetaIcon = useCallback((category: ArticleMetaCategoryType) => {
    switch (category) {
      case 'UPDATED':
        return <CalendarOutlined style={ICON_STYLES} />;
      case 'HERO TEXT':
        return <AimOutlined style={ICON_STYLES} />;
      case 'PRODUCTS':
        return <ShoppingOutlined style={ICON_STYLES} />;
      case 'KEYWORDS':
        return <StarOutlined style={ICON_STYLES} />;
      case 'WORDS':
        return <DiffOutlined style={ICON_STYLES} />;
      case 'FIGURES':
        return <PictureOutlined style={ICON_STYLES} />;
      case 'TITLES / SUBTITLE':
        return <FontColorsOutlined style={ICON_STYLES} />;
      default:
        return <BoldOutlined style={ICON_STYLES} />;
    }
  }, []);

  const handleOpenExclusionList = useCallback(() => {
    setShowExcludedWordsModal(true);
  }, []);

  const getMetaInfo = useCallback((category: ArticleMetaCategoryType): IMetaInfo | undefined => {
    switch (category) {
      case 'WORDS':
        return {
          title: 'Exclusion List',
          onClick: () => handleOpenExclusionList(),
        };
      default:
        return undefined;
    }
  }, [handleOpenExclusionList]);

  const loadUpdated = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => ({
    category: 'UPDATED',
    title: format(new Date(iArticle.updatedAt), 'MMMM d, yyyy'),
  }), []);

  const loadHeroText = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => ({
    category: 'HERO TEXT',
    title: iArticle.heroText ? `${countWords(iArticle.heroText)} words` : undefined,
    subtitle: iArticle.heroText,
    content: iArticle.heroText,
  }), []);

  const renderProductContent = useCallback((iArticle: IAmazonArticleDTO) => {
    if (!iArticle || iArticle.products.length === 0) {
      return undefined;
    }
    return (
      <ul style={{
        listStyleType: 'none',
        overflow: 'hidden',
        display: 'contents',
      }}>
        {iArticle.products.map((product) => (
          <li key={product.asin} >
            <AmazonProductView
              styles={{
                marginBottom: 6,
              }}
              product={product} />
          </li>
        ))}
      </ul>
    );
  }, []);

  const loadProducts = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => {
    const count = iArticle.products.filter((p) => !!p.price).length;

    const total = iArticle.products.reduce((tt: number, p) => {
      if (p.price && p.priceLimit) {
        return tt + ((p.price + p.priceLimit) / 2);
      }
      if (p.price) {
        return p.price + tt;
      }
      return tt;
    }, 0);
    const avg = formatCurrency(total / count);
    const subtitle = iArticle.products.length === 0 ? undefined : `Average price: ${avg}`;

    return {
      category: 'PRODUCTS',
      title: `${iArticle.products.length} products`,
      subtitle,
      content: renderProductContent(iArticle),
    };
  }, [renderProductContent]);

  const getKeywordMenu = useCallback((keyword: Keyword) => {
    const filterWinnersUrl = `/reports/winners?range=90&query=${keyword.keyword}`;
    const summaryUrl = `/keywords?keyword=${keyword.keyword_id}`;
    return (
      <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href={summaryUrl}>
          Open in Summary
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href={filterWinnersUrl}>
          Filter Winners Report
        </a>
      </Menu.Item>
    </Menu>
    );
  }, []);

  const renderKeywords = useCallback((keywords: Keyword[]) => (
    <div
      style={{
        marginTop: 16,
      }}>
      {keywords.map((k, idx) => (
        <Dropdown
          overlay={getKeywordMenu(k)}
          trigger={['click']}
          key={`tag_${idx}`}
          placement="topCenter" >
          <Tag
            style={{
              cursor: 'pointer',
            }}
            title={k.is_tracked ? 'Tracked' : ''}
            color={k.is_tracked ? 'green' : 'volcano'}>
            {k.keyword}
          </Tag>
        </Dropdown>
      ))}
    </div>), [getKeywordMenu]);

  const loadKeywords = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => {
    const keywords = iArticle.keywords || [];
    const countt = keywords.length === 0 ? 'No' : keywords.length;
    const title = `${countt} keywords`;
    const content = renderKeywords(keywords);
    return {
      category: 'KEYWORDS',
      title,
      content,
    };
  }, [renderKeywords]);

  const renderWordFrequency = useCallback((wordFrequency: IWordFrequency[]) => {
    if (wordFrequency.length === 0) {
      return undefined;
    }
    return (
      <span>
          <Text>Word Frequency:</Text>
          <ul>
            {wordFrequency.map((ff) => (
              <li key={`freq_${ff.name}`}>
                <strong>{ff.name}: </strong>{ff.count}
              </li>
            ))}
          </ul>
        </span>
    );
  }, []);

  const wordFrequencyTitle = useCallback((wordFrequency: IWordFrequency[]) => {
    let count = 'No';
    if (wordFrequency.length > 0) {
      count = `${wordFrequency.length}`;
    }
    return `${count} Word Frequency`;
  }, []);

  const loadWords = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => {
    const allWordsObj = iArticle.content.map((c) => c.value);
    const allWords = allWordsObj.join(' ');
    const counted = countWords(allWords);
    const title = counted === 0 ? undefined : `${counted} words`;

    const frequency = countWordFrequency(iArticle, excludedWords);
    const subtitle = wordFrequencyTitle(frequency);
    const content = renderWordFrequency(frequency);
    return {
      category: 'WORDS',
      title,
      subtitle,
      content,
    };
  }, [excludedWords, renderWordFrequency, wordFrequencyTitle]);

  const filterTextContent = useCallback((iArticle: IAmazonArticleDTO, valids: string[], sufix: string) => {
    const filtered = iArticle.content.filter((c) => valids.includes(c.type));
    return `${filtered.length > 0 ? filtered.length : 'No'} ${sufix}`;
  }, []);

  const filterTextValueContent = useCallback((iArticle: IAmazonArticleDTO, valids: string[]) => {
    const filtered = iArticle.content.filter((c) => valids.includes(c.type));
    if (filtered.length > 0) {
      return (
          <ul>
            {filtered.map((f, idx) => (
              <li key={`fcv_${idx}`}>{f.value}</li>
            ))}
          </ul>
      );
    }
    return undefined;
  }, []);

  const loadParagraphs = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => {
    const title = filterTextContent(iArticle, ['p'], 'paragraphs');
    return {
      category: 'PARAGRAPHS',
      title,
    };
  }, [filterTextContent]);

  const renderFigures = useCallback((iFigures: IArticleContent[]) => (
      <Row>
        {iFigures.map((item, idx) => (
          <Col span={6} key={`fig_${idx}`} >
            <ComparisonImage width={100} height={60} src={item.value} />
          </Col>
        ))}
      </Row>
  ), []);

  const loadFigures = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => {
    const title = filterTextContent(iArticle, ['figure'], 'figures');
    const figures = iArticle.content.filter((c) => c.type === 'figure');
    const content = figures.length === 0 ? undefined : renderFigures(figures);
    return {
      category: 'FIGURES',
      title,
      content,
    };
  }, [filterTextContent, renderFigures]);

  const loadTitles = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => {
    const title = filterTextContent(iArticle, TITLE_TAGS, 'titles');
    const content = filterTextValueContent(iArticle, TITLE_TAGS);
    return {
      category: 'TITLES / SUBTITLE',
      title,
      content,
    };
  }, [filterTextContent, filterTextValueContent]);

  const loadBolds = useCallback((iArticle: IAmazonArticleDTO): IArticleMetaInfo => {
    const title = filterTextContent(iArticle, BOLD_TAGS, 'bolds');
    const content = filterTextValueContent(iArticle, BOLD_TAGS);
    return {
      category: 'BOLD CONTENT',
      title,
      content,
    };
  }, [filterTextContent, filterTextValueContent]);

  const loadArticleContent = useCallback((iArticle?: IAmazonArticleDTO) => {
    const sections: IArticleMetaInfo[] = [];
    if (!iArticle) {
      return undefined;
    }
    sections.push(loadHeroText(iArticle));
    sections.push(loadUpdated(iArticle));
    sections.push(loadProducts(iArticle));
    sections.push(loadKeywords(iArticle));
    sections.push(loadWords(iArticle));
    sections.push(loadParagraphs(iArticle));
    sections.push(loadFigures(iArticle));
    sections.push(loadTitles(iArticle));
    sections.push(loadBolds(iArticle));
    return sections;
  }, [loadHeroText, loadUpdated, loadProducts, loadKeywords, loadWords, loadParagraphs, loadFigures, loadTitles, loadBolds]);

  const metaArticle = useMemo(() => {
    if (!article) {
      return undefined;
    }
    return loadArticleContent(article);
  }, [article, loadArticleContent]);

  const myMetaArticle = useMemo(() => {
    if (!myArticle) {
      return undefined;
    }
    return loadArticleContent(myArticle);
  }, [myArticle, loadArticleContent]);

  const metaInfo = useMemo(() => {
    const typeValues = Object.values(ArticleMetaCategory).filter((cat) => typeof cat === 'string');
    const info = typeValues.map((meta) => ({
      name: meta as string,
      icon: getMetaIcon(meta as ArticleMetaCategoryType),
      article: metaArticle?.find((a) => a.category === meta),
      myArticle: myMetaArticle?.find((a) => a.category === meta),
      info: getMetaInfo(meta as ArticleMetaCategoryType),
    }));
    return info;
  }, [getMetaIcon, getMetaInfo, metaArticle, myMetaArticle]);

  const loadExcludedWords = useCallback(async () => {
    try {
      const ff = await api.get<string[]>('/api/comparison/excluded-words');
      setExcludedWords(ff);
    } catch (err) {}
  }, []);

  useEffect(() => {
    loadExcludedWords();
  }, [loadExcludedWords]);

  const loadStatArticle = useCallback(async () => {
    if (!stat) {
      return;
    }
    let url;
    try {
      setArticleError(undefined);
      setArticleLoading(true);
      url = fixArticleURL(stat.parsed_data.fullArticleUrl);
      const foundArticle = await api.patch<IAmazonArticleDTO>('/api/comparison/scan', {
        body: {
          url,
        },
      });
      if (!foundArticle.title) {
        throw Error('Article Unavailable');
      }
      if (foundArticle.title) {
        setArticle(foundArticle);
      }
    } catch (err) {
      setArticleError({
        message: err.message,
        url: url || stat.parsed_data.fullArticleUrl,
      });
    } finally {
      setArticleLoading(false);
    }
  }, [stat]);

  const loadMyStat = useCallback(async () => {
    if (!stat) {
      return;
    }
    let url;
    try {
      setMyArticleError(undefined);
      setMyArticleLoading(true);
      const foundStat = await api.get<IStatDTO>('/api/stats/find', {
        params: {
          keyword: stat.keyword_id,
        },
      });
      if (!foundStat.stat_id) {
        throw new Error('No articles from our sites');
      }
      url = foundStat.parsed_data.fullArticleUrl;
      url = fixArticleURL(url);
      const foundArticle = await api.patch<IAmazonArticleDTO>('/api/comparison/scan', {
        body: {
          url,
        },
      });
      setMyArticle(foundArticle);
    } catch (err) {
      setMyArticleError({
        message: err.message,
        url,
      });
    } finally {
      setMyArticleLoading(false);
    }
  }, [stat]);

  useEffect(() => {
    loadStatArticle();
    loadMyStat();
  }, [loadMyStat, loadStatArticle]);

  const handleClose = useCallback(() => {
    setArticle(undefined);
    setMyArticle(undefined);
    onClose();
  }, [onClose]);

  return (
    <>
      <Modal
        title="Article Comparison"
        width="84%"
        visible={!!stat}
        style={{ top: 20 }}
        destroyOnClose={true}
        okButtonProps={{
          hidden: true,
        }}
        cancelText="Close"
        bodyStyle={{
          overflowY: 'auto',
          height: '77vh',
        }}
        onCancel={handleClose}
      >
        <div style={MODAL_STYLES}>
          <ComparisonRow >
            <ComparisonHeader
              error={articleError}
              loading={articleLoading}
              article={article} />

            <ComparisonCenterBlock
              text="VS"
              styles={CENTER_BLOCK_HEADER_STYLES}
            />

            <ComparisonHeader
              error={myArticleError}
              loading={myArticleLoading}
              article={myArticle} />
          </ComparisonRow>

          <Collapse
            bordered={false}
            defaultActiveKey={undefined}
            ghost>
            {metaInfo.map((item, idx) => (
              <Panel
                key={`info_${idx}`}
                className="App-Thin-Collapse"
                style={{
                  margin: 0,
                }}
                showArrow={false}
                header={
                  <ComparisonRow
                    styles={{
                      cursor: item.article?.content || item.myArticle?.content ? 'pointer' : 'default',
                    }}
                    index={idx}>
                    <ComparisonCell title={item.article?.title || 'None'} subtitle={item.article?.subtitle} />
                    <ComparisonCenterBlock
                      index={idx}
                      text={item.name}
                      icon={item.icon}
                      info={item.info}
                    />
                    <ComparisonCell title={item.myArticle?.title || 'None'} subtitle={item.myArticle?.subtitle} />
                  </ComparisonRow>
              } >
                {(item.article?.content || item.myArticle?.content) && (
                  <ComparisonRowValue
                    contentA={item.article?.content || ''}
                    contentB={item.myArticle?.content || ''} />
                )}
              </Panel>
            ))}
          </Collapse>
        </div>
      </Modal>

      <WordsExcludeModal
        words={excludedWords}
        visible={showExcludedWordsModal}
        onClose={() => setShowExcludedWordsModal(false)}
        onUpdate={(newContent) => {
          setExcludedWords(newContent);
          setShowExcludedWordsModal(false);
        }} />
    </>
  );
};

export default ArticleComparisonModal;
