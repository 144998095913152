/* eslint-disable no-empty */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Pagination } from 'antd';

import Table, { ColumnProps } from 'antd/lib/table';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { IProductStockReportDTO } from '../../../../shared/dtos/ProductStockDTO';
import {
  IChangeTableConfig, IFilterReport,
} from '../../pages/reports';

interface ProductsOOSReportProps {
  filters: IFilterReport
  onChangeTable(tableConfig: IChangeTableConfig): void
}

const ProductsOOSReport: React.FC<ProductsOOSReportProps> = ({
  filters, onChangeTable,
}) => {
  const { query } = filters;

  const [searching, setSearching] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [productStocks, setProductStocks] = useState<IProductStockReportDTO[]>([]);

  const { handleError } = useAuth();

  const columns = useMemo(() => {
    const permittedColumns: ColumnProps<IProductStockReportDTO>[] = [
      {
        title: 'ASIN',
        dataIndex: 'ASIN',
        key: 'ASIN',
        sorter: true,
        width: 200,
        render: (ASIN) => (
          <a
            href={`https://www.amazon.com/dp/${ASIN as string}`}
            target={'_blank'} >
            {ASIN}
          </a>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'TITLE',
        key: 'TITLE',
        sorter: true,
      },
      {
        title: 'Article Title',
        key: 'POST_TITLE',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        render: (record: any) => {
          const url = new URL(record?.PERMALINK);
          return (
            <a
              href={`${url.origin}/wp-admin/post.php?post=${record?.POST_ID as string}&action=edit`}
              target={'_blank'} >
              {record?.POST_TITLE}
            </a>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'AVAILABILITY_TYPE',
        key: 'AVAILABILITY_TYPE',
        sorter: true,
      },
    ];
    return permittedColumns;
  }, []);

  const searchStocks = useCallback(async () => {
    setSearching(true);
    try {
      const { edges, pageInfo: total } = await api.get(
        '/api/stocks/oos-report',
        {
          params: {
            page: filters.page,
            perPage: filters.perPage,
            sort: filters.sort,
            sortOrder: filters.sortOrder,
            query,
          },
        },
      );
      setTotalCount(total);
      setProductStocks(edges);
    } catch (err) {
      handleError(err, 'Stocks could not be searched.');
    } finally {
      setSearching(false);
    }
  }, [filters.page, filters.perPage, filters.sort, filters.sortOrder, query, handleError]);

  useEffect(() => {
    searchStocks();
  }, [searchStocks]);

  const handleTableChange = useCallback((pagination, _filter, sorter) => {
    onChangeTable({
      sort: sorter.columnKey,
      sortOrder: sorter.order,
    });
  }, [onChangeTable]);

  const handlePaginationChange = useCallback((page, perPage) => {
    onChangeTable({
      page, perPage,
    });
  }, [onChangeTable]);

  return (
    <div>
      <Table
        loading={searching}
        columns={columns}
        dataSource={productStocks}
        className="App-Table"
        onChange={handleTableChange}
        pagination={false}
      />
      <div style={{ paddingTop: '20px' }}>
        <Pagination
          onChange={handlePaginationChange}
          showSizeChanger
          pageSize={filters.perPage || 20}
          current={filters.page || 1}
          total={totalCount}
        />
      </div>
    </div>
  );
};

export default ProductsOOSReport;
