import React, {
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import _ from 'lodash';

import {
  Form,
  Input,
  Button,
  Menu,
  Dropdown,
  Modal,
  Pagination,
  Typography,
} from 'antd';

import {
  PlusOutlined,
  FilterOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import Keyword, { Tag } from '../../../../shared/dtos/KeywordDTO';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import KeywordTable from '../../components/keyword_table';
import NewKeywordModal from '../../components/new_keyword_modal';
import TagManagementModal from '../../components/tag_management_modal';
import KeywordsFilters, {
  allFilters, IKeywordFilter,
} from '../../components/KeywordsFilters';
import api from '../../services/api';
import IPaginatedMetaQuery from '../../../../shared/dtos/IPaginatedQuery';
import { useAuth } from '../../hooks/auth';
import { saveData } from '../../utils';
import NewArticleModal from '../../components/Articles/new_article_modal';
import {
  ICreateAOPArticleDTO, ISaveArticleDTO, IArticle,
} from '../../../../shared/dtos/ArticleDTO';
import HeaderPanel, { IActionSubmenu } from '../../components/header_panel';

const { Text } = Typography;

const { Search } = Input;

export const keywordSubmenuActions = [
  {
    label: 'Reports',
    permit: 'KeywordsUpload',
    route: '/reports',
  },
  {
    label: 'Tools',
    permit: 'ViewTools',
    menu: [
      {
        label: 'Keyword Research',
        route: 'keyword-research',
        permite: 'ViewTools',
      },
      {
        label: 'Product Search',
        route: 'product-search',
        permite: 'ViewTools',
      },
    ],
  },
  {
    label: 'Keywords Summary',
    route: '/keywords',
    permit: 'ViewKeywords',
  },
  {
    label: 'Content Tracker',
    route: '/content-tracker',
    permit: 'ViewKeywords',
  },
  {
    label: 'CSV Upload',
    route: '/upload-keywords',
    permit: 'KeywordsUpload',
  },
  {
    label: 'Opportunities',
    route: '/keyword-opportunities',
    permit: 'KeywordsOpportunities',
  },
  {
    label: 'AOP Bot',
    route: '/bot',
    permit: 'Bot',
  },
  {
    label: 'Commission Categories',
    route: '/commission-categories',
    permit: 'CommissionCategories',
  },
] as IActionSubmenu[];

interface ISearchProps {
  limit: number;
  offset: number;
  currentPage: number;
  sort?: string;
  sortOrder?: string;
  keyword?: string;
  filters?: Record<string, unknown>;
}

const defaultSearchProps = {
  limit: 20,
  currentPage: 1,
  offset: 0,
} as ISearchProps;

const KeywordsSummary: React.FC = () => {
  let delaySearch: number | ReturnType<typeof setTimeout> = 0;
  const history = useHistory();

  const paramsQuery = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const commentsParam = useMemo(() => paramsQuery.get('comments'), [paramsQuery]);
  const keywordsParam = useMemo(() => paramsQuery.getAll('keyword'), [paramsQuery]);
  const [initialKeyword, initialRows] = useMemo(() => {
    if (keywordsParam && keywordsParam.length > 0) {
      return [keywordsParam[0], [Number(keywordsParam[0])]];
    }
    return [undefined, []];
  }, [keywordsParam]);

  const { handleError } = useAuth();
  const [showAddKeyword, setShowAddKeyword] = useState(false);
  const [showNewArticle, setShowNewArticle] = useState(false);
  const [keywordToAsanaArticle, setKeywordToAsanaArticle] = useState<Keyword>();
  const [selectedArticle, setSelectedArticle] = useState<IArticle>();
  const [newArticles, setNewArticles] = useState<IArticle[]>([]);
  const [showTagModal, setShowTagModal] = useState(false);
  const [filters, setFilters] = useState<IKeywordFilter[]>([]);
  const [searching, setSearching] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [search, setSearch] = useState({
    ...defaultSearchProps,
    keyword: initialKeyword,
  });

  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [count, setCount] = useState(0);

  const [availableSites, setAvailableSites] = useState<ISiteDTO[]>([]);
  const [editKeyword, setEditKeyword] = useState<Keyword>();

  const availableFilters = useMemo(() => {
    const available = allFilters.filter((filter) => {
      const index = filters.findIndex((usedFilter) => usedFilter.type === filter.type);
      return index === -1;
    });
    return available;
  }, [filters]);

  const usedFilters = useMemo(() => {
    const available = allFilters.filter((filter) => {
      const index = filters.findIndex((usedFilter) => usedFilter.type === filter.type);
      return index > -1;
    });
    return available;
  }, [filters]);

  const downloadModalContent = useMemo(() => {
    let text = <Text><Text strong>{count}</Text> found keywords.</Text>;
    if (count === 0) {
      text = <Text type="danger">No keywords found.</Text>;
    }
    return (
      <div>
        <Text>Confirm the download of the CSV file?</Text>
        <br />
        {text}
      </div>
    );
  }, [count]);

  const handleConfirmDownloadKeywords = useCallback(async () => {
    setDownloading(true);
    const params = {
      ...search,
      ...search.filters,
    };
    try {
      const blob = await api.get<Blob>('/api/keywords/keywords.csv', {
        params,
        blob: true,
      });
      saveData(blob, 'keywords_export.csv');
    } catch (err) {
      handleError(err, 'Keywords could not be downloaded.');
    } finally {
      setDownloading(false);
    }
  }, [handleError, search]);

  const handleDownloadKeywords = useCallback(() => {
    Modal.confirm({
      title: 'CSV Export',
      icon: <ExclamationCircleOutlined />,
      content: downloadModalContent,
      okText: 'Download file',
      onOk: () => {
        handleConfirmDownloadKeywords();
      },
    });
  }, [downloadModalContent, handleConfirmDownloadKeywords]);

  const handleNewKeyword = useCallback(() => {
    setEditKeyword(undefined);
    setShowAddKeyword(true);
  }, []);

  const handleRowSelection = useCallback((selected: ReactText[]) => {
    setSelectedRowKeys(selected);
  }, []);

  const handleFilterMenu = useCallback(({ key }) => {
    const filterToUse = allFilters.find((filter) => filter.type === key);
    if (filterToUse) {
      setFilters((previous) => [filterToUse, ...previous]);
    }
  }, []);

  const handleRemoveFilter = useCallback((filterKey, filterNames: string[]) => {
    const newFilters = filters.filter((fil) => fil.type !== filterKey);
    setFilters(newFilters);

    const newApply = { ...search.filters };
    filterNames.forEach((filterName) => {
      newApply[filterName] = undefined;
    });

    setSearch((previous) => ({
      ...previous,
      filters: newApply,
    }));
  }, [filters, search.filters]);

  const performSearchSites = useCallback(async () => {
    try {
      const siteData = await api.get<ISiteDTO[]>('/api/sites');
      setAvailableSites(siteData);
    // eslint-disable-next-line no-empty
    } catch (err) {}
  }, []);

  const performSearchKeywords = useCallback(async () => {
    setSearching(true);
    const params = {
      ...search,
      ...search.filters,
    };
    try {
      const { meta: { total }, data } = await api.get<IPaginatedMetaQuery<Keyword>>('/api/keywords', {
        params,
      });
      setKeywords(data);
      setCount(total);
    } catch (err) {
      handleError(err, 'Keywords could not be searched.');
    } finally {
      setSearching(false);
    }
  }, [handleError, search]);

  useEffect(() => {
    performSearchKeywords();
  }, [performSearchKeywords]);

  useEffect(() => {
    performSearchSites();
  }, [performSearchSites]);

  const handleChangeFilterSearch = useCallback((newFilters: Record<string, string | number | undefined>) => {
    setSearch((previous) => ({
      ...previous,
      filters: { ...previous?.filters, ...newFilters },
    }));
  }, []);

  const changeSearchingSort = useCallback((sort?: string, sortOrder?: string) => {
    setSearch((previous) => ({
      ...previous,
      sort,
      sortOrder,
    }));
  }, []);

  const onPaginationChange = (pageNumber: number, pageSize: number) => {
    const newOffset = (pageNumber - 1) * pageSize;
    setSearch((previous) => ({
      ...previous,
      limit: pageSize,
      offset: newOffset,
      currentPage: pageNumber,
    }));
  };

  const showMessageKeywordCreated = useCallback((newKeyword: Keyword) => {
    setShowAddKeyword(false);
    Modal.success({
      content: `Keyword '${newKeyword.keyword}' has been successfully saved`,
    });
    changeSearchingSort();
  }, [changeSearchingSort]);

  const showMessageTagsUpdated = useCallback(() => {
    setShowTagModal(false);
    Modal.success({
      content: 'Tags updated for selected Keywords',
    });
    changeSearchingSort();
  }, [changeSearchingSort]);

  const filterMenu = useMemo(() => (
      <Menu onClick={handleFilterMenu}>
        {availableFilters.map((filter) => (
          <Menu.Item key={filter.type} icon={<FilterOutlined />} >
            {filter.description}
          </Menu.Item>
        ))}
    </Menu>
  ), [availableFilters, handleFilterMenu]);

  const searchSuffix = useMemo(() => (
    <>
      {availableFilters.length === 0 ? (
        <FilterOutlined
          style={{
            color: '#b3b2b2',
          }}
        />
      ) : (
          <Dropdown
            overlay={filterMenu}
            placement="bottomCenter">
            <FilterOutlined
              style={{
                color: '#8c8c8c',
              }}
            />
          </Dropdown>
      )}
    </>
  ), [availableFilters.length, filterMenu]);

  // NOTE: Add any new action menu items here
  const actionMenuItems = useMemo(() => (
    <Menu onClick={
      (e) => {
        if (e?.key === 'tagManager') {
          const keywordTagArray: Tag[][] = [];

          keywords.forEach((keyword) => {
            if (_.includes(selectedRowKeys, keyword.keyword_id) && keyword?.tags) {
              keywordTagArray.push(keyword?.tags);
            }
          });

          // Bootstraping initial array
          let tagIntersection = (keywordTagArray.length ? keywordTagArray[0] : []) || [];
          keywordTagArray.forEach((tagArray) => {
            tagIntersection = _.intersectionBy(tagIntersection, tagArray, 'tag_id');
          });

          setTags(tagIntersection);
          setShowTagModal(true);
        }
      }}
    >
      <Menu.Item key="tagManager">Manage Tags</Menu.Item>
    </Menu>
  ), [keywords, selectedRowKeys]);

  const actionMenuButton = useMemo(() => {
    if (selectedRowKeys.length !== 0) {
      return (
        <Dropdown.Button
          type="primary"
          overlay={actionMenuItems}
        >
        Bulk Actions
      </Dropdown.Button>
      );
    }
    return null;
  }, [actionMenuItems, selectedRowKeys.length]);

  const handleSearch = useCallback((event) => {
    const value = event.target.value as string;
    if (delaySearch) {
      clearTimeout(delaySearch as ReturnType<typeof setTimeout>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    delaySearch = setTimeout(() => {
      setSearch((previous) => ({
        ...previous,
        keyword: value,
        offset: 0,
      }));
    }, 1500);
  }, []);

  const handleKeywordEdit = useCallback((keyword) => {
    setEditKeyword(keyword);
    setShowAddKeyword(true);
  }, []);

  const handleOpenNewArticle = useCallback((keyword: Keyword, article?: IArticle) => {
    setKeywordToAsanaArticle(keyword);
    setShowNewArticle(true);
    setSelectedArticle(article);
  }, []);

  const handleArticleSave = useCallback(async (article) => {
    try {
      const newArticle = await api.post<IArticle>('/api/articles', {
        body: article,
      });
      setNewArticles([...newArticles, newArticle]);
    } catch (err) {
      handleError(err, 'Article could not be saved.');
    }
  }, [handleError, newArticles]);

  const handleSaveArticleModal = useCallback(async (article: ISaveArticleDTO) => {
    try {
      const articleChanged = await api.patch<IArticle>(`/api/articles/${article.articleId}`, {
        body: {
          ...article,
        },
      });
      const updatedNewArticles = newArticles.filter((newArticle) => newArticle.article_id !== article.articleId);
      setNewArticles([...updatedNewArticles, articleChanged]);
    } catch (err) {
      handleError(err, 'Article could not be saved.');
    }
  }, [handleError, newArticles]);

  return (
    <>
      <HeaderPanel
        hideHome
        actions={keywordSubmenuActions}
        showKeywordSearch={false}
      />

      <div style={{
        margin: '10px 0 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Form
          name='keywordFormSearch'
        >
          <KeywordsFilters
            filters={usedFilters}
            onChange={handleChangeFilterSearch}
            onRemoveFilter={handleRemoveFilter}
            availableSites={availableSites}
          />
          <Search
            placeholder="Keywords finder"
            defaultValue={initialKeyword}
            allowClear
            style={{
              width: 464,
              marginRight: 0,
            }}
            onChange={handleSearch}
            loading={searching}
          />
          <Button
            type="default"
            style={{
              width: 37,
              left: -3,
            }}
            icon={searchSuffix}
          />
        </Form>

        { actionMenuButton }

        <div>

          <Button
            type="default"
            shape="round"
            loading={downloading}
            icon={<DownloadOutlined />}
            onClick={handleDownloadKeywords}>
            CSV Export
          </Button>

          <Button
            style={{
              marginLeft: 10,
            }}
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            onClick={handleNewKeyword}>
            New Keyword
          </Button>

        </div>

      </div>

      <KeywordTable
        keywords={keywords}
        articles={{
          newArticles,
          sort: search.sort,
          sortOrder: search.sortOrder,
          handleOpenNewArticleKeyword: (keyword, article) => handleOpenNewArticle(keyword, article),
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: handleRowSelection,
        }}
        rowExpandable={{
          initialExpandableKeys: initialRows,
          comments: commentsParam === '1',
        }}
        handleKeywordEdit={handleKeywordEdit}
        onChange={(iSort, iSortOrder) => changeSearchingSort(iSort, iSortOrder)}
        availableSites={availableSites}
      />

      <Pagination
        total={count}
        style={{ paddingTop: '12px' }}
        defaultPageSize={20}
        current={search.currentPage}
        onChange={(page, pageSize) => onPaginationChange(page, pageSize as number)}
      />

      <NewKeywordModal
        editKeyword={editKeyword}
        availableSites={availableSites}
        onClose={() => { setShowAddKeyword(false); setEditKeyword(undefined); }}
        onSuccess={(newKeyword) => showMessageKeywordCreated(newKeyword)}
        visible={showAddKeyword}
      />

      <NewArticleModal
        onSave={(article) => {
          handleSaveArticleModal(article);
        }}
        onClose={() => setShowNewArticle(false)}
        onSubmit={async (article: ICreateAOPArticleDTO) => {
          await handleArticleSave(article);
          setNewArticles((previous) => [...previous, article as unknown as IArticle]);
        }}
        visible={showNewArticle}
        keyword={keywordToAsanaArticle}
        selectedArticle={selectedArticle}
      />

      <TagManagementModal
        onClose={() => setShowTagModal(false)}
        visible={showTagModal}
        onSuccess={() => showMessageTagsUpdated()}
        tags={tags}
        keywordIds={selectedRowKeys}
      />
    </>
  );
};

export default KeywordsSummary;
