import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Typography,
  Button,
  Row,
  Col,
  Space,
  Image,
  Card,
  Comment,
  message,
  Table,
  Tooltip,
} from 'antd';

import {
  formatDistance,
  format,
  parseISO,
} from 'date-fns';

import {
  AmazonCircleFilled,
  CheckCircleOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';

import { ColumnsType } from 'antd/lib/table';
import api from '../../services/api';
import { IPaginatedQuery } from '../../../../shared/dtos/IPaginatedQuery';
import { useAuth } from '../../hooks/auth';

import {
  ISaveArticleDTO,
  IArticle,
} from '../../../../shared/dtos/ArticleDTO';

import { IMAGE_NOT_FOUND_AVG } from '../../utils';
import NewArticleModal from '../../components/Articles/new_article_modal';
import { IArticleReturn } from '../../../../shared/dtos/ArticleReturnDTO';
import ArticleReasonModal, { IArticleReasonCommand } from '../../components/Articles/article_reason_modal';
import User from '../../../../shared/dtos/UserDTO';
import HeaderPanel from '../../components/header_panel';
import UserLink from '../../components/Users/user_link';
import {
  handleCancelArticle,
  resubmitArticle,
  saveArticle,
} from '../../components/Articles/article_actions';
import { PERMISSIONS } from '../../services/auth';

const { Text, Link } = Typography;

export interface IArticleExtraField {
  title: string
  subtitle?: React.ReactElement
  placeholder: string
}

export interface IArticleToResubmit {
  article: IArticle
  command(article: ISaveArticleDTO, extraField: string): void
  extraField?: IArticleExtraField
}

interface IValidateArticle {
  article: IArticle
  command: 'approve' | 'reject' | 'return'
}

interface IResubmitArticle {
  article: IArticle
  command: 'cancel' | 'submit'
}

export const createExtraField = (lastReturn?: IArticleReturn): IArticleExtraField | undefined => {
  if (!lastReturn) {
    return undefined;
  }
  const title = lastReturn.user ? `${lastReturn.user.first_name} ${lastReturn.user?.last_name}` : '';
  return {
    title: 'QA Return reason',
    subtitle: (
        <>
          <Text strong type="secondary">{title}</Text>
          <Text type="secondary"> said:</Text><br />
          <Text type="secondary">{lastReturn.reason}</Text>
        </>
    ),
    placeholder: 'Type aditional informations here',
  };
};

const PendingArticlePage: React.FC = () => {
  const { user: loggedUser, handleError, isPermitted } = useAuth();
  const [totalCount, setTotalCount] = useState(0);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [reasonModal, setReasonModal] = useState<IArticleReasonCommand>();
  const [articleToEdit, setArticleToEdit] = useState<IArticleToResubmit>();

  const searchArticles = useCallback(async () => {
    try {
      const { nodes, totalCount: currentTotalCount } = await api.get<IPaginatedQuery<IArticle>>('/api/articles/pending', {
        params: {
          page: currentPage,
        },
      });
      setTotalCount(currentTotalCount);
      setArticles(nodes);
    } catch (err) {
      handleError(err, 'Articles could not be searched.');
    }
  }, [currentPage, handleError]);

  const handleApprove = useCallback(async (article: ISaveArticleDTO, reason: string) => {
    message.loading('Approving...', 0);
    try {
      await saveArticle(article);
      await api.patch(`/api/articles/validate/${article.articleId}`, {
        body: {
          command: 'approve',
          reason,
        },
      });
      searchArticles();
    } catch (err) {
      handleError(err);
    } finally {
      message.destroy();
    }
  }, [handleError, searchArticles]);

  const handleRequester = useCallback(({ article, command }: IResubmitArticle) => {
    const lastReturn = article.returns ? article.returns[0] : undefined;
    switch (command) {
      case 'cancel':
        handleCancelArticle({
          article,
          callback: () => {
            searchArticles();
          },
        });
        break;
      default:
        setArticleToEdit({
          article,
          command: async (savedArticle, comments) => {
            message.loading('Saving changes..');
            try {
              await saveArticle(savedArticle);
              if (lastReturn) {
                await resubmitArticle(savedArticle, lastReturn, comments);
              }
              await searchArticles();
            } catch (err) {
              handleError(err, 'Could not submit the article');
            } finally {
              message.destroy();
            }
          },
          extraField: createExtraField(lastReturn),
        });
        break;
    }
  }, [searchArticles, handleError]);

  const handleValidate = useCallback(({ article, command }: IValidateArticle) => {
    switch (command) {
      case 'approve':
        setArticleToEdit({
          article,
          command: (savedArticle: ISaveArticleDTO, extraField: string) => {
            handleApprove(savedArticle, extraField);
          },
          extraField: {
            title: 'QA Comments',
            placeholder: 'Type aditional comments here',
          },
        });
        break;
      case 'reject':
        setReasonModal({
          article,
          command: 'reject',
        });
        break;
      default:
        setReasonModal({
          article,
          command: 'return',
        });
        break;
    }
  }, [handleApprove]);

  const actions = useCallback((article: IArticle) => {
    if (isPermitted(PERMISSIONS.ValidateArticles) && article.status === 'requested') {
      return [
        <Button
          key="approve"
          type="primary"
          style={{
            width: 150,
          }}
          icon={<CheckCircleOutlined />}
          onClick={() => handleValidate({ article, command: 'approve' })} >
          Approve
        </Button>,
        <Button
          key="return"
          ghost
          style={{
            width: 150,
          }}
          type="primary"
          icon={<QuestionCircleOutlined />}
          onClick={() => handleValidate({ article, command: 'return' })} >
          Return
        </Button>,
        <Button
          key="reject"
          danger
          style={{
            width: 150,
          }}
          type="primary"
          icon={<StopOutlined />}
          onClick={() => handleValidate({ article, command: 'reject' })} >
          Reject
        </Button>,
      ];
    }
    return [
      <Button
        key="resubmit"
        type="primary"
        style={{
          width: 150,
        }}
        icon={<CheckCircleOutlined />}
        onClick={() => handleRequester({ article, command: 'submit' })} >
        Resubmit
      </Button>,
      <Button
        key="cancel"
        danger
        style={{
          width: 150,
        }}
        type="primary"
        icon={<StopOutlined />}
        onClick={() => handleRequester({ article, command: 'cancel' })} >
        Cancel
      </Button>,
    ];
  }, [handleRequester, handleValidate, isPermitted]);

  useEffect(() => {
    searchArticles();
  }, [searchArticles]);

  const formatDate = useCallback((date: Date) => {
    const dt = parseISO(date.toString());
    return format(dt, 'yyyy-MM-dd HH:mm');
  }, []);

  const formatDateDesc = useCallback((date: Date) => {
    const dt = parseISO(date.toString());
    return formatDistance(dt, new Date(), { addSuffix: true });
  }, []);

  const articleUsername = useCallback((user?: User) => {
    if (!user) {
      return '';
    }
    if (user.id === loggedUser.id) {
      return 'You';
    }
    return `${user.first_name} ${user.last_name}`;
  }, [loggedUser.id]);

  const articleReturnContent = useCallback((article: IArticle, articleReturn: IArticleReturn) => (
        <Comment
          key={articleReturn.article_return_id}
          author={articleUsername(articleReturn.user)}
          content={
            <p>
              {articleReturn.reason}
            </p>
          }
          datetime={
            <span>{formatDate(articleReturn.created_at)}</span>
          }
        >
        {articleReturn.answered_at && (
          <Comment
            style={{
              marginTop: -25,
            }}
            author={articleUsername(article.user)}
            content={
              <p style={
                articleReturn.complement ? {} : {
                  color: '#808080',
                  fontStyle: 'italic',
                }}>
                {articleReturn.complement || '[no comments]'}
              </p>
            }
            datetime={
              <span>{formatDate(articleReturn.answered_at)}</span>
            }
          />
        )}
      </Comment>
  ), [formatDate, articleUsername]);

  const returnsContent = useCallback((article: IArticle) => (
    <Row>
      <Col span={3} style={{
        textAlign: 'right',
        paddingRight: 10,
      }}>
        <Text strong>Rejection Notes:</Text>
      </Col>
      <Col span={21} >
      {article.returns && article.returns.length > 0 ? (
        <Card bodyStyle={{
          paddingTop: 0,
          paddingBottom: 0,
        }}>
          {article.returns.map((articleReturn) => (
            articleReturnContent(article, articleReturn)
          ))}
        </Card>
      ) : (
        <Text type="secondary">None</Text>
      )}
      </Col>
    </Row>
  ), [articleReturnContent]);

  const columns = useMemo(() => [
    {
      title: 'Article',
      dataIndex: 'keyword',
      key: 'keyword',
      width: '50%',
      render: (keyword: string, article: IArticle) => (
        <div style={{
          display: 'flex',
        }}>
          <Image
            preview={true}
            fallback={IMAGE_NOT_FOUND_AVG}
            style={{
              width: 'auto',
              height: 105,
            }}
            src={article.image_url} />
          <Space direction="vertical" style={{
            marginLeft: 10,
          }}>
            <div>
              <Text strong>{article.title}</Text>
              <div key={article.site?.site_id}>
                <img style={{ width: '18px' }}src={article.site?.logo_url} />
                {' '}{article.site?.display_name}
              </div>
            </div>
            <Text type="secondary">
              <ClockCircleOutlined style={{
                color: '#DCDCDC',
                marginRight: 4,
              }} />
              <Tooltip placement="top" title={formatDate(article.created_at)}>
                  {formatDateDesc(article.created_at)}
              </Tooltip>
            </Text>
            <div>
              {article.user && (
                <UserLink
                  icon
                  user={article.user} />
              )}
            </div>
          </Space>
        </div>
      ),
    },
    {
      title: 'Products',
      dataIndex: 'keyword_id',
      key: 'keyword_id',
      render: (keywordId: number, article: IArticle) => (
        <Space direction="vertical">
          {(!article.products || article.products?.length === 0) && (
            <div style={{
              alignSelf: 'center',
            }}>
              <Text type="secondary">None</Text>
            </div>
          )}
          {article.products?.map((product) => (
            <div key={`${keywordId}_${product.product_id}`}>
              <Text type="secondary" style={{ fontSize: 14, marginRight: 12 }}>{product.name}</Text>
              <Text type="secondary" style={{ marginRight: 12 }}>({product.asin})</Text>
              <Link
                href={`https://www.amazon.com/dp/${product.asin}`}
                target="_blank">
                <AmazonCircleFilled />
              </Link>
              <br />
              <Text type="secondary"><Text>Award: </Text>{product.award}</Text>
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'article_id',
      key: 'article_id',
      width: 50,
      render: (articleId: number, article: IArticle) => (
        <Space direction="vertical">
          {actions(article)}
        </Space>
      ),
    },

  ] as ColumnsType<IArticle>,
  [actions, formatDate, formatDateDesc]);

  return (
    <>
      <HeaderPanel
        actions={[
          {
            label: 'Articles',
            route: '/articles',
          },
          {
            label: 'Pending Articles',
            route: '/articles/pending',
          },
        ]}
        title="My Pending Articles" />

      <Table
        columns={columns}
        dataSource={articles}
        pagination={{
          total: totalCount,
          defaultPageSize: 20,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
          },
        }}
        rowKey={(item) => item.article_id}
        expandable={{
          expandedRowRender: (iArticle) => returnsContent(iArticle),
        }}
      />

      <ArticleReasonModal
        articleCommand={reasonModal}
        onFinish={() => {
          setReasonModal(undefined);
          searchArticles();
        }}
        onClose={() => setReasonModal(undefined)} />

      <NewArticleModal
        onSubmit={() => {}}
        onClose={() => setArticleToEdit(undefined)}
        onSave={(article: ISaveArticleDTO, extraField: string) => articleToEdit?.command(article, extraField)}
        selectedArticle={articleToEdit?.article}
        visible={!!articleToEdit?.article} />
    </>
  );
};

export default PendingArticlePage;
