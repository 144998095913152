import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Select,
  Spin,
  Tag as AntTag,
} from 'antd';

import { IChangeFilterProps } from '.';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface IArticleStatus {
  label: string
  value: string
}

const ArticleStatusFilter: React.FC<IChangeFilterProps> = ({ onChange }) => {
  const { handleError } = useAuth();
  const [allStatus, setAllStatus] = useState<IArticleStatus[]>([]);
  const [fetching, setFetching] = useState(false);

  const loadAllStatus = useCallback(async (): Promise<void> => {
    try {
      setFetching(true);
      const all = await api.get<IArticleStatus[]>('/api/articles/status');
      setAllStatus(all);
    } catch (err) {
      handleError(err, 'Article status could not be searched.', true);
    } finally {
      setFetching(false);
    }
  }, [handleError]);

  useEffect(() => {
    loadAllStatus();
  }, [loadAllStatus]);

  const articleStatusRender = useCallback(({
    label, value, closable, onClose,
  }) => (
      <AntTag
        key={value}
        color='cyan'
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}>
        {label}
      </AntTag>
  ), []);

  const handleChangeStatus = useCallback((values) => {
    const foundStatus = allStatus.filter((status) => values.includes(status.value.toLowerCase()));
    onChange({
      articleStatus: foundStatus.map((status) => status.value),
    });
  }, [allStatus, onChange]);

  return (
    <div>
      <Select
        mode="multiple"
        showArrow
        notFoundContent={fetching ? <Spin size="small" /> : null}
        tagRender={articleStatusRender}
        onChange={handleChangeStatus}
        style={{ marginRight: 15, minWidth: 260 }}
        options={allStatus}
      />
    </div>
  );
};

export default ArticleStatusFilter;
