import React, {
  useState, useCallback,
} from 'react';
import {
  Button,
  Card,
  Divider,
  Input,
  Table,
} from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import api from '../../services/api';
import HeaderPanel from '../../components/header_panel';

const { TextArea } = Input;

type Columns = {
  title: string
  dataIndex: string
  key: string
};

const DBQuery: React.FC = () => {
  const [exporting, setExporting] = useState(false);
  const [query, setQuery] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [results, setResults] = useState<any[]>();
  const [columns, setColumns] = useState<Columns[]>();
  const [error, setError] = useState();

  const handleQueryChange = useCallback((e) => {
    setQuery(e.target.value);
  }, []);

  const handleExportReport = useCallback(() => {
    setExporting(true);

    const toCsvString = (csvArray: string[][]): string => {
      let resultString = '';
      csvArray.forEach((row) => {
        resultString += row.join(',');
        resultString += '\n';
      });
      return resultString;
    };

    let csv: string[][] = [];
    const colHeaders: string[] = (columns && columns.map((column) => column.key)) || [];
    csv = colHeaders && [[...colHeaders]];
    if (results) {
      results.forEach((result) => {
        const row = colHeaders?.map((header) => result[header]);
        csv = [...csv, [...row]];
      });
    }
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(toCsvString(csv))}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${Date.now()}_aop_query.csv`;
    hiddenElement.click();

    setExporting(false);
  }, [columns, results]);

  const handleQuery = useCallback(async () => {
    setError(undefined);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryResults = await api.post<any>('/api/query', {
      body: {
        query,
      },
    });

    // eslint-disable-next-line no-prototype-builtins
    if (queryResults && queryResults.hasOwnProperty('length')) {
      const cols = Object.keys(queryResults[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key,
      }));
      setColumns(cols);
      setResults(queryResults);
    } else {
      setError(queryResults);
    }
  }, [query]);

  return (
    <>
      <HeaderPanel
        title="Database Interface"
      />
      <TextArea rows={10} onChange={handleQueryChange} />
      <Button
        onClick={handleQuery}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        Execute
      </Button>
      {
        error && (
          <>
            <Divider />
            <Card>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </Card>
            <Divider />
          </>
        )
      }
      <Button
          style={{
            marginTop: 20,
            marginBottom: 20,
            float: 'right',
          }}
          loading={exporting}
          onClick={handleExportReport}
          type="primary"
          icon={<ExportOutlined />}>
          Export Report
        </Button>
      <Table
        dataSource={results}
        columns={columns}
        scroll={{ x: '100%' }}
      />
    </>
  );
};

export default DBQuery;
