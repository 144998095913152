import { ExclamationCircleOutlined } from '@ant-design/icons/lib/icons';
import { notification } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import {
  ISaveArticleDTO, IArticle,
} from '../../../../shared/dtos/ArticleDTO';
import { IArticleReturn } from '../../../../shared/dtos/ArticleReturnDTO';
import api from '../../services/api';

interface IHandleCancelArticleProps {
  article: IArticle
  callback(article?: IArticle): void
}

export const handleCancelArticle = ({ article, callback }: IHandleCancelArticleProps): void => {
  confirm({
    title: 'Are you sure you want to cancel this article?',
    icon: <ExclamationCircleOutlined />,
    content: 'This operation cannot be undone',
    okText: 'Yes',
    zIndex: 9000,
    okType: 'danger',
    cancelText: 'No',
    async onOk() {
      try {
        if (article.status === 'draft') {
          await api.delete(`/api/articles/${article.article_id}`);
          callback();
        } else {
          const canceled = await api.patch<IArticle>(`/api/articles/cancel/${article.article_id}`, {});
          callback(canceled);
        }
      } catch (err) {
        notification.error({
          message: 'Could not cancel the article',
          description: err.message,
        });
      }
    },
  });
};

export const saveArticle = async (article: ISaveArticleDTO): Promise<IArticle> => {
  const changedArticle = await api.put<IArticle>(`/api/articles/${article.articleId}`, {
    body: {
      imageUrl: article.imageUrl || '',
      products: article.products,
      draft: article.draft,
      title: article.title,
    },
  });
  return changedArticle;
};

export const resubmitArticle = async (savedArticle: ISaveArticleDTO, lastReturn: IArticleReturn, comments: string): Promise<IArticle> => {
  await saveArticle(savedArticle);
  return api.patch(`/api/articles/resubmit/${savedArticle.articleId}`, {
    body: {
      articleReturnId: lastReturn.article_return_id,
      comments,
    },
  });
};
