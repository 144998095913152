import React from 'react';

import { Image } from 'antd';

import { IMAGE_NOT_FOUND_AVG } from '../../../utils';

interface ComparisonImageProps {
  size?: number
  width?: number
  height?: number
  src?: string
}

const ComparisonImage: React.FC<ComparisonImageProps> = ({
  size, height, width, src,
}) => (
    <Image
      style={{
        width: width || 'auto',
        maxWidth: size,
        height: height || 'auto',
        maxHeight: size,
        objectFit: height ? 'cover' : undefined,
      }}
      src={src || IMAGE_NOT_FOUND_AVG}
    />
);

export default ComparisonImage;
