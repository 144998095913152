import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Modal,
  Select,
} from 'antd';

import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { PERMISSIONS } from '../../../services/auth';

interface WordsExcludeModalProps {
  visible?: boolean
  words: string[]
  onClose(): void
  onUpdate(newContent: string[]): void
}

const WordsExcludeModal: React.FC<WordsExcludeModalProps> = ({
  visible, words, onClose, onUpdate,
}) => {
  const { handleError, isPermitted } = useAuth();
  const [saving, setSaving] = useState(false);
  const [newContent, setNewContent] = useState<string[]>();

  const saveWords = useCallback(async () => {
    try {
      setSaving(true);
      const ff = await api.put<string[]>('/api/comparison/excluded-words', {
        body: {
          content: newContent,
        },
      });
      onUpdate(ff);
    } catch (err) {
      handleError(err, 'Excluded Words could not be saved.');
    } finally {
      setSaving(false);
    }
  }, [handleError, newContent, onUpdate, setSaving]);

  const readOnly = useMemo(() => !isPermitted(PERMISSIONS.ManageExcludedWords), [isPermitted]);

  const handleChangeWords = useCallback((values) => {
    const arrayValue = values as string[];
    setNewContent(arrayValue);
  }, []);

  return (
      <Modal
        title='Exclusion List'
        visible={visible}
        onCancel={onClose}
        onOk={saveWords}
        destroyOnClose={true}
        okButtonProps={{
          hidden: readOnly,
          loading: saving,
        }}
        okText="Save"
        cancelText="Close"
        width={750}>

        <Select
          mode="tags"
          className={readOnly ? 'App-Select-Readonly' : undefined}
          tokenSeparators={[' ']}
          size="middle"
          defaultValue={words}
          onChange={handleChangeWords}
          dropdownStyle={{ display: 'none' }}
          style={{ width: '100%' }} >
        </Select>

      </Modal>
  );
};

export default WordsExcludeModal;
