/* eslint-disable no-console */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  parseISO,
  format,
} from 'date-fns';

import {
  Card,
  Image,
  List,
  Modal,
  Rate,
  Spin,
  Tabs,
} from 'antd';

import Meta from 'antd/lib/card/Meta';
import Text from 'antd/lib/typography/Text';
import {
  AlertOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

import Tag from 'antd/es/tag';

import api from '../../services/api';

import {
  IPAAPIProductProvider,
  IProduct,
} from '../../../../shared/dtos/ProductDTO';

import {
  getProductStockDescription,
  IMAGE_NOT_FOUND_AVG,
} from '../../utils';

import { IProductStock } from '../../../../shared/dtos/ProductStockDTO';
import { ItemsDTO } from '../../../../shared/dtos/StatDTO';

interface IProductDetailsModalProps {
  product?: IProduct
  onClose(): void
}

const ProductDetailsModal: React.FC<IProductDetailsModalProps> = ({
  product: preProduct, onClose,
}) => {
  const [product, setProduct] = useState<IProduct>();
  const [stocks, setStocks] = useState<IProductStock[]>([]);
  const [loadingPaapiProduct, setLoadingPaapiProduct] = useState(false);
  const [paapiProduct, setPaapiProduct] = useState<IPAAPIProductProvider>();
  const [invalidASIN, setInvalidASIN] = useState<string>();

  const [loadingStatsProduct, setLoadingStatsProduct] = useState(false);
  const [statsProduct, setStatsProduct] = useState<ItemsDTO>();
  const [invalidStatASIN, setInvalidStatASIN] = useState<string>();

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const getProductDetails = useCallback(async () => {
    if (!preProduct) {
      return;
    }
    try {
      const productDetails = await api.get<IProduct>(`/api/products/${preProduct.product_id}`);
      setProduct(productDetails);
    } catch (err) {
      console.error(err);
    }
  }, [preProduct]);

  const findStockHistory = useCallback(async () => {
    if (!preProduct) {
      return;
    }
    try {
      const myStocks = await api.get<IProductStock[]>(`/api/products/${preProduct.product_id}/stocks`);
      setStocks(myStocks);
    } catch (err) {
      console.error(err);
    }
  }, [preProduct]);

  const fetchPAAPI = useCallback(async () => {
    if (!preProduct) {
      return;
    }
    setLoadingPaapiProduct(true);
    try {
      const myPaapiProduct = await api.get<IPAAPIProductProvider>(`/api/products/${preProduct.product_id}/paapi`);
      setPaapiProduct(myPaapiProduct.asin ? myPaapiProduct : undefined);
      if (!myPaapiProduct.asin) {
        setInvalidASIN('ASIN not found');
      }
    } catch (err) {
      setInvalidASIN(err.message);
    } finally {
      setLoadingPaapiProduct(false);
    }
  }, [preProduct]);

  const fetchStats = useCallback(async () => {
    if (!preProduct) {
      return;
    }
    setLoadingStatsProduct(true);
    try {
      const statProduct = await api.get<ItemsDTO>(`/api/products/${preProduct.product_id}/stats`);
      setStatsProduct(statProduct.asin ? statProduct : undefined);
      if (!statProduct.asin) {
        setInvalidStatASIN('No Stats Found');
      }
    } catch (err) {
      setInvalidStatASIN(err.message);
    } finally {
      setLoadingStatsProduct(false);
    }
  }, [preProduct]);

  const formatDate = useCallback((date?: Date) => {
    if (!date) {
      return '-';
    }
    const dt = parseISO(date.toString());
    return format(dt, 'yyyy-MM-dd');
  }, []);

  const stockLineView = useMemo(() => {
    if (!product) {
      return '';
    }
    if (!product.lastStock) {
      return <strong>Never Scanned</strong>;
    }
    const lastScanPanel = (
      <span style={{
        fontStyle: 'italic',
        marginLeft: 6,
      }}>
        (Last Scanned: {formatDate(product.last_scanned)})
      </span>
    );
    if (product.lastStock.stock === 'INVALID_ASIN') {
      return (
        <span>
          <strong>Invalid ASIN</strong>
          {lastScanPanel}
        </span>
      );
    }
    return (
      <span>
        <strong>
          {getProductStockDescription(product.lastStock.stock)}
        </strong> since {formatDate(product.lastStock?.created_at)}
        {lastScanPanel}
      </span>
    );
  }, [formatDate, product]);

  useEffect(() => {
    getProductDetails();
  }, [getProductDetails, paapiProduct?.asin]);

  useEffect(() => {
    findStockHistory();
  }, [findStockHistory, paapiProduct?.asin]);

  useEffect(() => {
    setPaapiProduct(undefined);
    setInvalidASIN(undefined);
    fetchPAAPI();
  }, [fetchPAAPI]);

  useEffect(() => {
    setStatsProduct(undefined);
    setInvalidStatASIN(undefined);
    fetchStats();
  }, [fetchStats]);

  const stocksHistoryPanel = useMemo(() => {
    if (!product) {
      return undefined;
    }
    if (product.in_stock === 'NOT_SCANNED') {
      return (
        <span>Never Scanned</span>
      );
    }
    return (
      <div style={{
        display: 'grid',
      }}>
        {stocks.map((stock) => (
          <span key={`stock_${stock.product_stock_id}`}>
            <span
              style={{
                fontStyle: 'italic',
              }}>
              {formatDate(stock.created_at)}
            </span>{' - '}
            <Text type={stock.stock === 'IN_STOCK' ? 'success' : 'danger'}>{getProductStockDescription(stock.stock)}</Text>
          </span>
        ))}
      </div>
    );
  }, [formatDate, product, stocks]);

  const paapiCategoriesPanel = useMemo(() => {
    if (!paapiProduct) {
      return undefined;
    }
    return (
      <span style={{
        marginTop: 6,
      }}>
        {paapiProduct.categories.map((fm) => (
          <Tag
            key={fm}
            style={{
              marginBottom: 4,
            }}>
            {fm}
          </Tag>
        ))}
      </span>
    );
  }, [paapiProduct]);

  const paapiFeaturesPanel = useMemo(() => {
    if (!paapiProduct) {
      return undefined;
    }
    if (paapiProduct.features.length === 0) {
      return <Text type="danger">None</Text>;
    }
    return (
      <ul>
        {paapiProduct.features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    );
  }, [paapiProduct]);

  const paapiItemInfoPanel = useMemo(() => {
    if (!paapiProduct) {
      return undefined;
    }
    return (
      <div style={{
        marginLeft: 10,
      }}>
        {paapiProduct.info.map((item) => (
          <span
            key={`cat_${item.category}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Text>{item.category}</Text>
            <ul style={{
              columns: 2,
              listStyleType: 'none',
              overflow: 'hidden',
            }}>
            {item.values.map((value) => (
              <li key={`cat_value_${value.label}`}>
                <strong>{value.label}: </strong>{value.value}
              </li>
            ))}
            </ul>
          </span>
        ))}
      </div>
    );
  }, [paapiProduct]);

  const getOfferTag = useCallback((content: string) => (
      <li>
        <Tag
          style={{
            width: 160,
            marginTop: 4,
          }}
          color="blue">{content}</Tag>
      </li>
  ), []);

  const paapiOffersPanel = useMemo(() => {
    if (!paapiProduct) {
      return undefined;
    }
    if (paapiProduct.offers.length === 0) {
      return <Text type="danger">None</Text>;
    }
    return (
      <Tabs
        defaultActiveKey={paapiProduct.offers[0].merchantInfo.id}
        tabPosition="left"
        style={{ height: 170 }}>
        {paapiProduct.offers.map((offer) => (
          <Tabs.TabPane
            tab={offer.merchantInfo.name}
            key={offer.merchantInfo.id} >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
              <ul style={{
                listStyleType: 'none',
                overflow: 'hidden',
              }}>
                <li style={{
                  marginBottom: 10,
                }}>
                  {offer.typeAvailability === 'Now' ? (
                    <Tag icon={<ShoppingCartOutlined />} color="#3b5999">
                      {offer.availability} {offer.typeAvailability}
                    </Tag>
                  ) : (
                    <Tag icon={<AlertOutlined />} color="#cd201f">
                      {offer.availability} {offer.typeAvailability}
                    </Tag>
                  )}
                </li>
                {offer.deliveryInfo.amazonFulfilled && getOfferTag('Amazon Fulfilled')}
                {offer.deliveryInfo.freeShippingEligible && getOfferTag('Free Shipping Eligible')}
                {offer.deliveryInfo.primeEligible && getOfferTag('Prime Eligible')}
                {offer.programEligibility.primeExclusive && getOfferTag('Prime Exclusive')}
                {offer.programEligibility.primePantry && getOfferTag('Prime Pantry')}
              </ul>
              <span style={{
                marginLeft: 10,
                marginRight: 10,
              }}>
                <Rate
                  allowHalf
                  disabled
                  defaultValue={offer.merchantInfo.feedbackRating} /><br />
                  <Text>{offer.merchantInfo.feedbackRating.toFixed(1)} out of 5 </Text><br />
                <span>{offer.merchantInfo.feedbackCount} global ratings</span>
              </span>
              {offer.price ? (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}>
                  {offer.savingBasis && (
                    <span>List Price:	<Text delete>{offer.savingBasis}</Text></span>
                  )}
                  <span>Price: <Text type="success">{offer.price}</Text></span>
                  {offer.savings && (
                    <span>You Save: <Text type="danger">{offer.savings}</Text></span>
                  )}
                </div>
              ) : (
                <Text type="danger">No Price Information</Text>
              )}
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    );
  }, [getOfferTag, paapiProduct]);

  const paapiImage = useMemo(() => {
    if (!paapiProduct || !paapiProduct.image) {
      return IMAGE_NOT_FOUND_AVG;
    }
    return paapiProduct.image;
  }, [paapiProduct]);

  const statsPanel = useMemo(() => {
    if (loadingStatsProduct) {
      return (
        <Spin size='large'>
          <div style={{ height: '100px', width: '100%' }}></div>
        </Spin>
      );
    }
    if (invalidStatASIN) {
      return <Text type="danger">{invalidStatASIN}</Text>;
    }
    if (!statsProduct) {
      return <Text type="secondary">Not Scanned</Text>;
    }

    const data = [
      {
        title: 'Name',
        description: statsProduct.name,
      },
      {
        title: 'Subheader',
        description: statsProduct.subheader,
      },
      {
        title: 'Award',
        description: statsProduct.award,
      },
      {
        title: 'Blurb',
        description: statsProduct.blurb,
      },
    ];

    return (
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item style={{
            paddingTop: 0,
          }}>
            <List.Item.Meta
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
    );
  }, [invalidStatASIN, loadingStatsProduct, statsProduct]);

  const paapiPanel = useMemo(() => {
    if (loadingPaapiProduct) {
      return (
        <Spin size='large'>
          <div style={{ height: '100px', width: '100%' }}></div>
        </Spin>
      );
    }
    if (invalidASIN) {
      return <Text type="danger">{invalidASIN}</Text>;
    }
    if (!paapiProduct) {
      return <Text type="secondary">Not Scanned</Text>;
    }

    const data = [
      {
        description: (
          <div>
            <div style={{
              marginTop: 6,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}>
              <div>
                <Image src={paapiImage} style={{
                  height: 130,
                  width: 'auto',
                }} />
              </div>
              <span style={{
                marginLeft: 20,
                display: 'flex',
                flexDirection: 'column',
              }}>
                <span>{paapiProduct.title}</span>
                {paapiCategoriesPanel}
                <span style={{
                  marginTop: 6,
                }}>
                  <strong>URL Page: </strong><a href={paapiProduct.pageURL} target="_blank" title={paapiProduct.pageURL}>Open Details URL Page</a>
                </span>
              </span>
            </div>
          </div>
        ),
      },
      {
        title: 'Features',
        description: paapiFeaturesPanel,
      },
      {
        title: 'Item Info',
        description: paapiItemInfoPanel,
      },
      {
        title: 'Offers',
        description: paapiOffersPanel,
      },
    ];
    return (
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item style={{
            paddingTop: 0,
          }}>
            <List.Item.Meta
              title={item.title}
              description={item.description}
            />
          </List.Item>
        )}
      />
    );
  }, [invalidASIN, loadingPaapiProduct, paapiCategoriesPanel, paapiFeaturesPanel, paapiImage, paapiItemInfoPanel, paapiOffersPanel, paapiProduct]);

  return (
    <Modal
      title='Product Details'
      visible={!!preProduct}
      okButtonProps={{
        hidden: true,
      }}
      style={{
        top: 60,
      }}
      bodyStyle={{
        paddingBottom: 0,
        paddingTop: 0,
      }}
      cancelText="Close"
      onCancel={handleCloseModal}
      width={950}
    >
    {product ? (
      <Card
        bodyStyle={{
          padding: 0,
          overflowY: 'auto',
          height: '60vh',
        }}
        bordered={false}>
        <Meta
          style={{
            marginTop: 8,
          }}
          title={product.asin}
          description={
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <span>{product.name}</span>
              <span><strong>Award: </strong>{product.award || '-'}</span>
              <span>{stockLineView}</span>
              {stocksHistoryPanel && (
                <span style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 6,
                  marginBottom: 6,
                }}>
                  <strong style={{
                    marginRight: 4,
                  }}>OOS Scan History: </strong>
                  {stocksHistoryPanel}
                </span>
              )}
              <Tabs
                defaultActiveKey="paapi"
                type="card"
                tabBarStyle={{
                  marginBottom: 0,
                }}>
                <Tabs.TabPane
                  tab="PA API"
                  key="paapi">
                  <Card>{paapiPanel}</Card>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="STATS"key="stats">
                  <Card>{statsPanel}</Card>
                </Tabs.TabPane>
              </Tabs>
            </div>
          }
        />
      </Card>
    ) : (
        <Spin />
    )}
    </Modal>
  );
};

export default ProductDetailsModal;
