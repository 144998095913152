import React, {
  useCallback, useEffect,
} from 'react';
import { format } from 'date-fns';
import { IChangeFilterProps } from '.';

import DatePicker from '../DatePicker';

const { RangePicker } = DatePicker;

const RangeFilter: React.FC<IChangeFilterProps> = ({ onChange }) => {
  const handleCalendarChanged = useCallback((value) => {
    const dateFrom = format(value[0], 'yyyy-M-d');
    const dateTo = format(value[1], 'yyyy-M-d');
    onChange({
      createdFrom: dateFrom,
      createdTo: dateTo,
    });
  }, [onChange]);

  useEffect(() => {
    const currentDate = format(new Date(), 'yyyy-M-d');
    onChange({
      createdFrom: currentDate,
      createdTo: currentDate,
    });
  }, [onChange]);

  return (
  <RangePicker
    defaultValue={[new Date(), new Date()]}
    format={'yyyy-MM-dd'}
    onChange={handleCalendarChanged}
  />
  );
};

export default RangeFilter;
