import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Pagination,
  Table,
} from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

import {
  format,
  subDays,
} from 'date-fns';

import {
  IParsedKeywordStat,
  IStatDTO,
} from '../../../../shared/dtos/StatDTO';
import StatPopover from '../Stats/stat_popover';
import api from '../../services/api';
import {
  IChangeTableConfig,
  IFilterReport,
} from '../../pages/reports';
import { useAuth } from '../../hooks/auth';
import ArticleComparisonModal from '../Articles/ArticleComparison';

type ColumnObject = {
  title?: string | JSX.Element
  dataIndex?: string
  key?: string
  sorter?: boolean
};

type KeywordWinnersAPIResponse = {
  meta: {
    limit: number
    offset: number
    total: number
  },
  data: IParsedKeywordStat[]
};

type KeywordWinnersTableProps = {
  filters: IFilterReport
  onChangeTable(tableConfig: IChangeTableConfig): void
};

const KeywordWinnersTable: React.FC<KeywordWinnersTableProps> = ({
  filters, onChangeTable,
}) => {
  const {
    dateRange = 7, query, site, tags, page = 1, perPage = 10,
  } = filters;
  const { handleError } = useAuth();
  const [keywordStats, setKeywordStats] = useState<IParsedKeywordStat[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [keywordTotal, setKeywordTotal] = useState(0);
  const [selectedStat, setSelectedStat] = useState<IStatDTO>();
  const [comparisonStat, setComparisonStat] = useState<IStatDTO>();
  const [hasShareOfVoiceReport, setHasShareOfVoiceReport] = useState<{ [key: string]: boolean }>();

  const dateColumns = useMemo(() => {
    const result: string[] = [];
    for (let i = dateRange; i > 0; i--) {
      const tmpDate = subDays(new Date(), i);
      result.push(format(tmpDate, 'yyyy-MM-dd'));
    }
    return result;
  }, [dateRange]);

  const columns: ColumnObject[] = useMemo(() => {
    const keywordColumn = [{
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      fixed: 'left',
      width: 250,
      sorter: true,
    }];

    const winPercentageColumn = [{
      title: 'Win %',
      dataIndex: 'winning_percentage',
      key: 'winning_percentage',
      sorter: true,
      width: 100,
      fixed: 'right',
      render: (winPercentage: number) => `${(winPercentage * 100).toPrecision(3)}%`,
    }];

    const dateCells = dateColumns.map((dc) => ({
      title: hasShareOfVoiceReport && hasShareOfVoiceReport[dc]
        ? (
          <span>
            {dc}{' '}
            <a style={{ textDecoration: 'inherit', color: 'inherit' }} href={`/api/stats/share-of-voice-analysis.csv?date=${dc}`}>
              <DownloadOutlined />
            </a>
          </span>
        )
        : (
          <span>{dc}</span>
        ),
      key: dc,
      width: 150,
      render: (keywordStat: IParsedKeywordStat) => {
        const { stats } = keywordStat;
        const stat = stats.find(
          (statRecord) => format(new Date(statRecord.created_at), 'yyyy-MM-dd') === dc,
        );

        if (stat) {
          return {
            props: {
              className: stat.is_winning ? 'App-winner-cell' : 'App-loser-cell',
              onClick: () => {
                setSelectedStat(stat as unknown as IStatDTO);
              },
            },
            children: stat.stat_id === selectedStat?.stat_id
              ? <StatPopover
                  stat={stat as unknown as IStatDTO}
                  onCompare={() => {
                    setComparisonStat(selectedStat);
                    setSelectedStat(undefined);
                  }}
                  onClose={() => setSelectedStat(undefined)} />
              : stat.publisher,
          };
        }

        return (<div>N/A</div>);
      },
    }));

    return [
      ...keywordColumn,
      ...dateCells,
      ...winPercentageColumn,
    ];
  }, [dateColumns, hasShareOfVoiceReport, selectedStat]);

  const performQuery = useCallback(async () => {
    setIsLoading(true);
    try {
      const statData = await api.get<KeywordWinnersAPIResponse>(
        '/api/stats/keyword-winners',
        {
          params: {
            sort: filters.sort,
            dateRange,
            sortOrder: filters.sortOrder,
            query,
            siteFilter: site,
            limit: perPage,
            offset: (page - 1) * perPage,
            tags,
          },
        },
      );
      const hasShareOfVoiceReportData = await api.get<{ [key: string]: boolean }>(
        '/api/stats/has-share-of-voice-report',
        {
          params: {
            dateRange,
          },
        },
      );
      setKeywordStats(statData?.data);
      setKeywordTotal(statData?.meta?.total);
      setHasShareOfVoiceReport(hasShareOfVoiceReportData);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  }, [dateRange, filters.sort, filters.sortOrder, handleError, page, perPage, query, site, tags]);

  const handleTableOnChange = useCallback((pagination, _filters, sorter) => {
    onChangeTable({
      sort: sorter?.column?.key,
      sortOrder: sorter?.order,
    });
  }, [onChangeTable]);

  const handlePaginationChange = useCallback((iPage, pageSize) => {
    onChangeTable({
      perPage: pageSize,
      page: iPage,
    });
  }, [onChangeTable]);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  const comparisonModal = useMemo(() => (
      <ArticleComparisonModal
        stat={comparisonStat}
        onClose={() => setComparisonStat(undefined)} />
  ), [comparisonStat]);

  return (
    <div style={{
      minHeight: 390,
    }}>
      <Table
        columns={columns}
        scroll={{ x: 1500 }}
        dataSource={keywordStats}
        rowKey={(item) => item.keyword_id}
        loading={isLoading}
        onChange={handleTableOnChange}
        sticky
        pagination={false}
      />
      <div style={{ paddingTop: '20px' }}>
        <Pagination
          onChange={handlePaginationChange}
          current={page}
          showSizeChanger
          total={keywordTotal}
        />
      </div>
      {comparisonModal}
    </div>
  );
};

export default KeywordWinnersTable;
