import React from 'react';

import { STYLES } from './constants';

interface ComparisonRowValueProps {
  contentA: string | React.ReactNode
  contentB: string | React.ReactNode
}

const {
  ROW_CONTENT_STYLES,
  ROW_CONTENT_VALUES_STYLES,
} = STYLES;

const ComparisonRowValue: React.FC<ComparisonRowValueProps> = ({
  contentA, contentB,
}) => (
  <div style={ROW_CONTENT_STYLES}>
    <span style={{
      ...ROW_CONTENT_VALUES_STYLES,
      borderRightWidth: 1,
    }}>
      {contentA}
    </span>
    <span style={{
      ...ROW_CONTENT_VALUES_STYLES,
      borderLeftWidth: 1,
    }}>
      {contentB}
    </span>
  </div>
);

export default ComparisonRowValue;
