/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import {
  Button,
  Divider,
  Pagination,
  Table,
  Select,
  DatePicker,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Doughnut } from 'react-chartjs-2';
import api from '../../services/api';
import HeaderPanel from '../../components/header_panel';
import {
  UserStats,
  UserActivityResponse,
} from '../../../../shared/dtos/UserActivityDTO';
import User from '../../../../shared/dtos/UserDTO';

interface SiteStatsDataSet {
  label: string
  labels: string[]
  data: number[]
  backgroundColor: string[]
  borderWidth: number
}

interface SiteStatsDTO {
  labels: string[]
  datasets: SiteStatsDataSet[]
}

const { Option } = Select;
const { RangePicker } = DatePicker;

const UserActivity: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [results, setResults] = useState<UserStats[]>();
  const [chartData, setChartData] = useState<SiteStatsDTO>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>();
  const [userData, setUserData] = useState<User[]>();
  const [filterByUserId, setFilterByUserId] = useState<number>();
  const [roles, setRoles] = useState<string[]>();
  const [filterByRole, setFilterByRole] = useState<string>();
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const columns = [
    {
      title: 'User',
      key: 'user',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      render: (record: UserStats) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
    },
    {
      title: 'Recorded At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
  ];

  const fetchUserActivity = useCallback(async () => {
    const limit = 20;
    const offset = limit * (currentPage - 1);
    const userActivity = await api.get<UserActivityResponse>(
      '/api/user-activity',
      {
        params: {
          limit,
          offset,
          userId: filterByUserId,
          role: filterByRole,
          startDate: startDateFilter,
          endDate: endDateFilter,
        },
      },
    );
    setResults(userActivity.data);
    setTotal(userActivity.pageInfo.total);
  }, [currentPage, endDateFilter, filterByRole, filterByUserId, startDateFilter]);

  // eslint-disable-next-line no-bitwise
  const rand = (frm: number, to: number): number => ~~(Math.random() * (to - frm)) + frm;

  const handleChartClick = useCallback(async (_, legendItem) => {
    const tempChartData = chartData;
    const pathFilter = chartData && chartData.datasets[0].labels[legendItem[0]?.index];
    const pathStats = await api.get<{ username: string, last_name: string, first_name: string, count: number }[]>(
      '/api/user-activity/path/stats',
      {
        params: {
          path: pathFilter,
          userId: filterByUserId,
          role: filterByRole,
          startDate: startDateFilter,
          endDate: endDateFilter,
        },
      },
    );

    const pathDataSet: SiteStatsDataSet = {
      label: 'Path Usage',
      labels: pathStats.map((stat) => `${stat.first_name} ${stat.last_name}`),
      data: [
        ...tempChartData?.datasets[0].data.map(() => 0) as number[],
        ...pathStats.map((stat) => stat.count),
      ],
      backgroundColor: pathStats.map(() => `rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`),
      borderWidth: 1,
    };

    const updatedChartData: SiteStatsDTO = {
      labels: [
        ...tempChartData?.datasets[0].labels as string[],
        ...pathDataSet.labels,
      ],
      datasets: [
        tempChartData?.datasets[0] as SiteStatsDataSet,
        pathDataSet,
      ],
    };

    setChartData(updatedChartData);
  }, [chartData, endDateFilter, filterByRole, filterByUserId, startDateFilter]);

  const fetchStats = useCallback(async () => {
    const statResults = await api.get<{ path: string, count: number }[]>(
      '/api/user-activity/stats',
      {
        params: {
          userId: filterByUserId,
          role: filterByRole,
          startDate: startDateFilter,
          endDate: endDateFilter,
        },
      },
    );

    const data = {
      labels: statResults.map((stat) => stat.path),
      datasets: [
        {
          label: 'Site Usage',
          labels: statResults.map((stat) => stat.path),
          data: statResults.map((stat) => stat.count),
          backgroundColor: statResults.map(() => `rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`),
          borderWidth: 1,
        },
      ],
    };
    setChartData(data);
  }, [endDateFilter, filterByRole, filterByUserId, startDateFilter]);

  const fetchUsers = useCallback(async () => {
    const users = await api.get<User[]>('/api/users/all');
    const userRoles: { [key: string]: string } = {};
    users.forEach((user) => {
      userRoles[user.role_id] = user.role_id;
    });
    setRoles(Object.keys(userRoles));
    setUserData(users);
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleUserFilter = useCallback((userId) => {
    setFilterByUserId(userId);
  }, []);

  const handleRoleFilter = useCallback((role) => {
    setFilterByRole(role);
  }, []);

  const handleClearFiltersClick = useCallback(() => {
    setFilterByRole(undefined);
    setFilterByUserId(undefined);
    setStartDateFilter(null);
    setEndDateFilter(null);
  }, []);

  const handleDateRangeChange = useCallback((dates) => {
    const [startDate, endDate] = dates;
    if (startDate && endDate) {
      setStartDateFilter(startDate.format('YYYY-MM-DD'));
      setEndDateFilter(endDate.format('YYYY-MM-DD'));
    }
  }, []);

  useEffect(() => {
    fetchUserActivity();
    fetchStats();
    fetchUsers();
  }, [fetchStats, fetchUserActivity, fetchUsers]);

  const userMenu = useMemo(() => (
    <Select
      onChange={handleUserFilter}
      style={{ width: '100%' }}
      value={filterByUserId}
    >
      {
        userData && userData.map((user) => (
          <Option value={user.id} icon={<UserOutlined />}>
            {user.first_name}{' '}{user.last_name}
          </Option>
        ))
      }
    </Select>
  ), [filterByUserId, handleUserFilter, userData]);

  const roleMenu = useMemo(() => (
    <Select
      onChange={handleRoleFilter}
      style={{ width: '100%' }}
      value={filterByRole}
    >
      {
        roles && roles.map((role) => (
          <Option value={role}>
            {role}
          </Option>
        ))
      }
    </Select>
  ), [filterByRole, handleRoleFilter, roles]);

  const selectedUser: User | undefined = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    const userSelected = userData?.find((user) => user.id == filterByUserId);
    return userSelected;
  }, [filterByUserId, userData]);

  const dateRange = useMemo(() => (
    <RangePicker
      onCalendarChange={handleDateRangeChange}
    />
  ), [handleDateRangeChange]);

  return (
    <>
      <HeaderPanel
        title={
          `User Activity${selectedUser ? `: ${selectedUser.first_name} ${selectedUser.last_name}` : ''}`
        }
      />
      <div style={{ maxWidth: '480px', margin: '0 auto' }}>
        <Doughnut type={'doughnut'} data={chartData} options={{
          onClick: handleChartClick,
        }} />
      </div>
      <Divider />
      <div style={{
        paddingBottom: '15px',
        display: 'inline-block',
        width: '240px',
        paddingLeft: '10px',
      }}>
        {userMenu}
      </div>
      <div style={{
        paddingBottom: '15px',
        display: 'inline-block',
        width: '100px',
        paddingLeft: '10px',
      }}>
        {roleMenu}
      </div>
      <div style={{
        paddingBottom: '15px',
        display: 'inline-block',
        paddingLeft: '10px',
      }}>
        {dateRange}
      </div>
      <div style={{
        paddingBottom: '15px',
        display: 'inline-block',
        paddingLeft: '10px',
      }}>
        <Button type={'primary'} onClick={handleClearFiltersClick}>Clear Filters</Button>
      </div>
      <Table
        dataSource={results}
        columns={columns}
        pagination={false}
      />
      <Pagination
        total={total}
        current={currentPage}
        onChange={handlePageChange}
        showSizeChanger={false}
        pageSize={20}
      />
    </>
  );
};

export default UserActivity;
