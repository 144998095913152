import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Popconfirm,
  Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { IWebhookDTO } from '../../../../shared/dtos/ArticleDTO';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { IWebhookProps } from './webhook_button';

const { Text } = Typography;

interface WebhookDetailsModalProps {
  selected?: IWebhookProps
  onClose(): void
  onReset(): Promise<IWebhookDTO | undefined>
}

const WebhookDetailsModal: React.FC<WebhookDetailsModalProps> = ({
  selected, onClose, onReset,
}) => {
  const { handleError } = useAuth();
  const [saving, setSaving] = useState(false);
  const [webhook, setWebhook] = useState<IWebhookDTO>();

  const handleResetWebhook = useCallback(async () => {
    setSaving(true);
    try {
      const newWebhook = await onReset();
      if (newWebhook) {
        setWebhook(newWebhook);
      }
    } catch (err) {
      handleError(err, 'Could not reset the webhook');
    } finally {
      setSaving(false);
    }

    setSaving(false);
  }, [onReset, handleError]);

  const loadWebhook = useCallback(async (gid: string) => {
    try {
      const found = await api.get<IWebhookDTO>(`/api/webhooks/${gid}`);
      setWebhook(found);
    } catch (err) {
      handleError(err, 'Could not find webhook');
    }
  }, [handleError]);

  useEffect(() => {
    if (selected && selected.webhook) {
      loadWebhook(selected.webhook.gid);
    }
  }, [loadWebhook, selected]);

  const jsonContent = useMemo(() => {
    if (!webhook) {
      return '';
    }
    return JSON.stringify(webhook, undefined, 4);
  }, [webhook]);

  const isVisible = useMemo(() => !!selected && !!selected.webhook, [selected]);

  return (
    <Modal
      title="Webhook details"
      onCancel={onClose}
      visible={isVisible}
      footer={[
        <Popconfirm
          title={(
            <p>
              Do you want to reset this webhook?<br />
              This operation cannot be undone.
            </p>
          )}
          onConfirm={handleResetWebhook}
          okText="Yes"
          cancelText="No">
          <Button type="primary" loading={saving}>
            <SyncOutlined /> Reset webhook
          </Button>
        </Popconfirm>,
        <Button
          key="close"
          onClick={onClose}
          style={{ width: 110 }}>
          Close
        </Button>,
      ]} >
      <p>
        <Text><Text strong>Site: </Text>{selected?.site.display_name}</Text>
        <br />
        <Text><Text strong>Type: </Text>{selected?.type}</Text>
      </p>
      <TextArea
        readOnly
        value={jsonContent}
        style={{
          background: '#292929',
          color: '#eedf5b',
        }}
        autoSize={{ maxRows: 10 }} />
    </Modal>
  );
};

export default WebhookDetailsModal;
