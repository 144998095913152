/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';

import {
  Badge,
  Col,
  Divider,
  Rate,
  Row,
  Tooltip,
  Typography,
} from 'antd';

import { FlagOutlined } from '@ant-design/icons';

import api from '../../../services/api';

const { Link } = Typography;

interface IAmazonTile {
  asin: string
  award: string
  summary: string
  headline: string
}

const AmazonTile: React.FC<IAmazonTile> = ({
  asin,
  award,
  summary,
  headline,
}) => {
  const [productData, setProductData] = useState<any>();

  const getProductData = useCallback(async () => {
    const productResponse = await api.get<any>(`/api/preview/product/${asin}`);
    setProductData(productResponse.pop());
  }, [asin]);

  const renderPrice = useCallback(() => {
    const priceString: string = (
      productData && productData?.Offers?.Listings?.[0]?.Price?.DisplayAmount
    ) || '$0.00';
    const priceNumber: number = (
      productData && productData?.Offers?.Listings?.[0]?.Price?.Amount
    ) || 0;
    const savingsNumber: number = (
      productData && productData?.Offers?.Listings?.[0]?.Price?.Savings?.Amount
    ) || 0;
    const isFreeShippingEligible: boolean = (
      productData && productData?.Offers?.Listings?.[0]?.DeliveryInfo?.IsFreeShippingEligible
    ) || false;

    return (
      <div>
        <span className={'App-Price'}>{priceString}</span>
        {
          savingsNumber !== 0 && (
            <span
              style={{
                fontSize: '13px',
                color: '#565959',
                textDecoration: 'line-through',
                verticalAlign: 'text-bottom',
                paddingLeft: '4px',
              }}
            >
              ${(priceNumber + savingsNumber).toFixed(2)}
            </span>
          )
        }
        {
          isFreeShippingEligible && (
            <Link
              style={{
                paddingLeft: '12px',
                color: '#007185',
              }}
              href={'https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&pop-up=1&nodeId=527692'}
              target={'_blank'}
            >
              FREE Shipping
            </Link>
          )
        }
      </div>
    );
  }, [productData]);

  useEffect(() => {
    getProductData();
  }, [getProductData]);

  const itemOutOfStock = useMemo(() => (
    productData && productData?.inStock !== 'IN_STOCK'
  ), [productData]);

  return (
    <>
      <Badge
        count={
          itemOutOfStock ? (
            <Tooltip title={'Item out of stock'}>
              <FlagOutlined
                style={{
                  fontSize: '20px',
                  color: 'white',
                  padding: '8px',
                  backgroundColor: 'red',
                  borderRadius: '18px',
                }}
              />
            </Tooltip>
          ) : null}
      >
        <div
          style={{
            border: itemOutOfStock ? '3px red solid' : '2px solid #FFA724',
            margin: '16px 0',
          }}
        >
          <div
            style={{
              color: 'white',
              backgroundColor: '#E67A00',
              fontSize: '16px',
              fontWeight: 'bold',
              lineHeight: '26px',
              display: 'inline-block',
              marginTop: '1px',
              marginLeft: '1px',
              paddingLeft: '10px',
              position: 'relative',
              top: '-7px',
            }}
          >
            {award}
          </div>
          <div
            style={{
              borderTop: '26px solid #E67A00',
              borderRight: '14px solid white',
              display: 'inline-block',
              marginTop: '1px',
            }}
          ></div>
          <Row>
            <Col style={{ maxWidth: 250, padding: '5px' }}>
              <img src={productData && productData?.Images?.Primary?.Large?.URL} width={'100%'} />
            </Col>
            <Col style={{ maxWidth: 370, padding: '5px' }}>
              <Link
                style={{
                  color: '#007185',
                  fontSize: '18px',
                  lineHeight: '24px',
                }}
                href={`http://www.amazon.com/dp/${asin}`}
                target={'_blank'}
              >
                {productData && productData?.ItemInfo?.Title?.DisplayValue}
              </Link>
              <div
                style={{
                  color: '#565959',
                  fontSize: '15px',
                  lineHeight: '21px',
                }}
              >
                By{' '}{productData && productData?.ItemInfo?.ByLineInfo?.Brand?.DisplayValue}
              </div>
              {
                productData && (
                  <div style={{ paddingTop: '12px' }}>
                    <Rate
                      className={'App-Rate-Medium'}
                      style={{
                        color: '#f69b20',
                        textShadow: '-1px 0 #E67A00, 0 1px #E67A00, 1px 0 #E67A00, 0 -1px #E67A00',
                      }}
                      disabled
                      allowHalf
                      defaultValue={
                        productData && productData?.Offers?.Listings?.[0]?.MerchantInfo?.FeedbackRating
                      }
                    />
                    <Link style={{ color: '#007185', verticalAlign: 'text-top' }}>
                      {productData && productData?.Offers?.Listings?.[0]?.MerchantInfo?.FeedbackCount}
                    </Link>
                  </div>
                )
              }
              <div style={{ paddingTop: '12px' }}>
                {renderPrice()}
              </div>
              <Divider style={{ margin: '16px 0 16px 0' }} />
              <div
                style={{
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontWeight: 'bold',
                  color: '#555',
                }}
              >
                {headline}
              </div>
              <div
                style={{
                  color: '#555',
                  fontSize: '16px',
                  lineHeight: '24px',
                  paddingBottom: '12px',
                }}
              >
                {summary}
              </div>
            </Col>
          </Row>
        </div>
      </Badge>
    </>
  );
};

export default AmazonTile;
