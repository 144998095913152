import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Form,
  Table,
  Row,
  Col,
  Select,
  Typography,
  Button,
  Pagination,
} from 'antd';

import { ColumnProps } from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { PlusCircleOutlined } from '@ant-design/icons';
import { BaseType } from 'antd/lib/typography/Base';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import api from '../../services/api';
import { IPaginatedQuery } from '../../../../shared/dtos/IPaginatedQuery';
import { useAuth } from '../../hooks/auth';
import HeaderPanel from '../../components/header_panel';

import {
  IFindProductDTO,
  IProduct,
  IProductArticles,
  ProductInStockType,
} from '../../../../shared/dtos/ProductDTO';

import { getProductStockDescription } from '../../utils';
import ProductDetailsModal from '../../components/Products/product_details_modal';
import ProductArticlesModal from '../../components/Products/product_articles_modal';

const { Text } = Typography;

const OOS_OPTIONS = [
  'NOT_SCANNED',
  'INVALID_ASIN',
  'IN_STOCK',
  'OUT_OF_STOCK'] as ProductInStockType[];

const defaultFilters = {
  page: 1,
  sort: 'products.product_id',
  sortOrder: 'asc',
  perPage: 20,
} as IFindProductDTO;

interface IProductsFormFilter {
  query?: string
  stock?: ProductInStockType
}

const ProductsPage: React.FC = () => {
  const { handleError } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [totalCount, setTotalCount] = useState(0);
  const [products, setProducts] = useState<IProductArticles[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [selectedArticleProduct, setSelectedArticleProduct] = useState<IProduct>();

  const [filters, setFilters] = useState<IFindProductDTO>({ ...defaultFilters });

  const productStockTextType = useCallback((productStock: ProductInStockType): BaseType | undefined => {
    switch (productStock) {
      case 'IN_STOCK':
        return undefined;
      case 'OUT_OF_STOCK':
        return 'secondary';
      default:
        return 'danger';
    }
  }, []);

  const productStockPrefix = useCallback((productStock: ProductInStockType) => {
    switch (productStock) {
      case 'IN_STOCK':
        return '';
      case 'OUT_OF_STOCK':
        return '⚠️';
      default:
        return '✗';
    }
  }, []);

  const loadProductId = useCallback(async (productId: number) => {
    if (selectedProduct) {
      return;
    }
    try {
      const productFound = await api.get<IProduct>(`/api/products/${productId}`);
      if (productFound) {
        setSelectedProduct(productFound);
      }
    } catch (err) {
      history.push({});
      handleError(err, 'Product not found.');
    }
  }, [handleError, history, selectedProduct]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const productId = params.get('productId');
    if (productId) {
      loadProductId(Number(productId));
    }
  }, [loadProductId, location.search]);

  const handleCloseProduct = useCallback(() => {
    setSelectedProduct(undefined);
    history.push({});
  }, [history]);

  useEffect(() => {
    if (selectedProduct) {
      history.push({ search: `productId=${selectedProduct.product_id}` });
    }
  }, [history, selectedProduct]);

  const handleSelectArticle = useCallback((product: IProductArticles) => {
    setSelectedArticleProduct(product);
  }, []);

  const handleShowProductDetails = useCallback((product: IProductArticles) => {
    setSelectedProduct(product);
  }, []);

  const columns = useMemo(() => {
    const permittedColumns: ColumnProps<IProductArticles>[] = [
      {
        title: 'ASIN',
        dataIndex: 'asin',
        key: 'products.asin',
        width: 190,
        sorter: true,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'products.name',
        sorter: true,
      },
      {
        title: 'Stock Status',
        dataIndex: 'in_stock',
        key: 'products.in_stock',
        sorter: true,
        width: 170,
        render: (inStock: ProductInStockType) => (
          <Text
            type={productStockTextType(inStock)}>
            {productStockPrefix(inStock)}
            {' '}
            {getProductStockDescription(inStock)}
          </Text>
        ),
      },
      {
        title: 'Articles Count',
        dataIndex: 'countArticles',
        key: 'countArticles',
        sorter: true,
        width: 160,
        render: (countArticles: number, product: IProductArticles) => (
          <Button
            style={{
              width: 100,
            }}
            disabled={countArticles === 0}
            onClick={() => handleSelectArticle(product)}
            shape="round">
                {countArticles === 0 ? 'None' : (
                  <p>
                    <strong>{countArticles}</strong>
                    {` Article${countArticles > 1 ? 's' : ''}`}
                  </p>
                )}
            </Button>
        ),
      },
      {
        title: '',
        dataIndex: 'products.product_id',
        key: 'products.product_id',
        width: 120,
        render: (productId: number, product: IProductArticles) => (
          <Button
            onClick={() => handleShowProductDetails(product)}
            shape="round"
            type="primary"
            icon={<PlusCircleOutlined />}>
            Details
          </Button>
        ),
      },
    ];
    return permittedColumns;
  }, [handleSelectArticle, handleShowProductDetails, productStockPrefix, productStockTextType]);

  const searchProducts = useCallback(async () => {
    try {
      const { nodes, totalCount: currentTotalCount } = await api.get<IPaginatedQuery<IProductArticles>>('/api/products', {
        params: {
          ...filters,
        },
      });
      setTotalCount(currentTotalCount);
      setProducts(nodes);
    } catch (err) {
      handleError(err, 'Products could not be searched.');
    }
  }, [filters, handleError]);

  useEffect(() => {
    searchProducts();
  }, [searchProducts]);

  const handlePaginationChange = useCallback((page, perPage) => {
    setFilters((previous) => ({
      ...previous,
      page,
      perPage,
    }));
  }, []);

  const handleChangeQuery = useCallback((pagination, filter, sorter) => {
    const sortOrder = sorter.order ? sorter.order.slice(0, -3) : defaultFilters.sortOrder;
    const sort = sorter.columnKey;

    setFilters((previous) => ({
      ...previous,
      ...filter,
      sort,
      sortOrder,
    }));
  }, []);

  const handleChangeFormFilters = useCallback((changed: IProductsFormFilter) => {
    const iStock = changed.stock && [changed.stock];
    setFilters((previous) => ({
      ...previous,
      ...changed,
      stock: iStock,
    }));
  }, []);

  return (
    <>
      <HeaderPanel
        title="Products Library" />

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Form
          name='keywordFormSearch'
          layout="vertical"
          onValuesChange={handleChangeFormFilters}
        >
          <Row>
            <Col span={16}>
              <Form.Item
                label="Asin / Name"
                name='query'>
                <Search
                  style={{
                    width: 340,
                  }}
                  value={filters.query || ''}
                  placeholder="Type any part of name or ASIN"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Stock"
                name='stock'>
                <Select
                  defaultValue=''
                  style={{
                    width: 240,
                  }}>
                  <Select.Option value={''} >
                    All
                  </Select.Option>
                  {OOS_OPTIONS.map((stock) => (
                    <Select.Option key={`stock_${stock}`} value={stock}>
                      {getProductStockDescription(stock)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

      </div>

      <Table
        size="small"
        className="App-Table"
        columns={columns}
        dataSource={products}
        onChange={handleChangeQuery}
        pagination={false}
        sticky
        rowKey={(item) => item.product_id}
      />

      <div style={{ paddingTop: '20px' }}>
        <Pagination
          onChange={handlePaginationChange}
          showSizeChanger
          pageSize={filters.perPage}
          current={filters.page || 1}
          total={totalCount}
        />
      </div>

      <ProductDetailsModal
        onClose={handleCloseProduct}
        product={selectedProduct} />

      <ProductArticlesModal
        onClose={() => setSelectedArticleProduct(undefined)}
        product={selectedArticleProduct} />

    </>
  );
};

export default ProductsPage;
