import React from 'react';

import HeaderPanel from '../../components/header_panel';
import ChatBot from '../../components/ChatBot';
import { keywordSubmenuActions } from '../keywords/keywords_summary';

const BotPage: React.FC = () => (
    <>
    <HeaderPanel
        hideHome
        title="AOP Bot"
        actions={keywordSubmenuActions}
        showKeywordSearch={false}
      />
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <ChatBot />
      </div>
    </>
);

export default BotPage;
