/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, Modal, Pagination, Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ICommissionCategoryDTO } from '../../../../shared/dtos/CommissionCategoryDTO';
import NewCommissionCategory from '../../components/CommissionCategories/new_commission_category_modal';
import HeaderPanel from '../../components/header_panel';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { PERMISSIONS } from '../../services/auth';
import { keywordSubmenuActions } from '../keywords/keywords_summary';

interface ICommissionCategoryFilters {
  name?: string
  commission?: number
  page: number
  sort?: string
  sortOrder?: string
}

interface ICommissionCategoryResponse {
  totalCount: number
  page: number
  nodes: ICommissionCategoryDTO[];
}
export const commissionCategoriesBaseApiCall = '/api/commission-categories/';

const CommissionCategories: React.FC = () => {
  const { isPermitted, handleError } = useAuth();
  const [commissionCategoriesData, setCommissionCategoriesData] = useState<ICommissionCategoryDTO[]>();
  const canCreateCommissionCategory = useMemo(() => isPermitted(PERMISSIONS.ManageCommissionCategories), [isPermitted]);
  const [showAddNewCommissionCategory, setShowAddNewCommissionCategory] = useState(false);
  const [editCommissionCategory, setEditCommissionCategory] = useState<ICommissionCategoryDTO>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCommissionCategories, setTotalCommissionCategories] = useState<number>();
  const [sort, setSort] = useState('name');
  const [sortOrder, setSortOrder] = useState('desc');
  const [isLoading, setIsLoading] = useState(false);

  const showMessageCommissionCategoryAction = useCallback((commissionCategory: ICommissionCategoryDTO, action = 'saved') => {
    setShowAddNewCommissionCategory(false);
    Modal.success({
      content: `Commission Category has been successfully ${action}.`,
    });
  }, []);
  const loadCommissionCategories = useCallback(async () => {
    try {
      setIsLoading(true);
      const params: ICommissionCategoryFilters = { page: currentPage };
      if (sort) {
        params.sort = sort;
      }
      if (sortOrder) {
        params.sortOrder = sortOrder;
      }

      const results = await api.get<ICommissionCategoryResponse>(
        commissionCategoriesBaseApiCall, {
          params,
        },
      );
      setTotalCommissionCategories(results?.totalCount);
      setCommissionCategoriesData(results.nodes);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      handleError(e);
    }
  }, [currentPage, handleError, sort, sortOrder]);

  useEffect(() => {
    loadCommissionCategories();
  }, [loadCommissionCategories]);
  const handleNewCommissionCategory = useCallback(() => {
    setShowAddNewCommissionCategory(true);
    setEditCommissionCategory(undefined);
  }, []);

  const handleEditCommissionCategory = useCallback((commissionCategory: ICommissionCategoryDTO) => {
    setShowAddNewCommissionCategory(true);
    setEditCommissionCategory(commissionCategory);
  }, []);

  const handleDeleteCommissionCategory = useCallback((commissionCategory: ICommissionCategoryDTO) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ( ${commissionCategory.name} ) ?\nThis action can't be undone.`,
      async onOk() {
        await api.delete<ICommissionCategoryDTO>(
          `${commissionCategoriesBaseApiCall}/${commissionCategory.commission_category_id}`,
        );
        showMessageCommissionCategoryAction(commissionCategory, 'deleted');
        loadCommissionCategories();
      },
    });
  }, [loadCommissionCategories, showMessageCommissionCategoryAction]);
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleSuccess = useCallback((commissionCategory: ICommissionCategoryDTO) => {
    showMessageCommissionCategoryAction(commissionCategory, (editCommissionCategory ? 'updated' : undefined));
    loadCommissionCategories();
  }, [showMessageCommissionCategoryAction, editCommissionCategory, loadCommissionCategories]);

  const handleChangeQuery = useCallback((_pagination, _filter, sorter) => {
    const sortOrderValue = sorter.order ? sorter.order.slice(0, -3) : 'desc';
    const sortValue = sorter.columnKey;
    setSort(sortValue);
    setSortOrder(sortOrderValue);
  }, []);
  const showColumns = [
    {
      title: 'Amazon Commission Category',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Commission Rate',
      key: 'commission',
      sorter: true,
      render: (category: ICommissionCategoryDTO) => <div>{category.commission.toFixed(2)} %</div>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (value: string, commissionCategory: ICommissionCategoryDTO) => (
      <>
          <Button
          style={ { marginLeft: 10 } }
          onClick={() => handleEditCommissionCategory(commissionCategory)}
          type="primary"
          shape="circle"
          disabled={!canCreateCommissionCategory}
          icon={<EditOutlined />}
        ></Button>
        <Button
         style={ { marginLeft: 10 } }
          onClick={() => handleDeleteCommissionCategory(commissionCategory)}
          type="ghost"
          shape="circle"
          disabled={!canCreateCommissionCategory}
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        ></Button>
      </>
      ),
    },
  ];

  return (
    <>
      <HeaderPanel
        hideHome
        actions={keywordSubmenuActions}
        showKeywordSearch={false}
      />

      <div
        style={{
          margin: '10px 0 20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
        </div>
        <div>
          <Button
            style={{
              marginLeft: 10,
            }}
            type='primary'
            shape='round'
            disabled={!canCreateCommissionCategory}
            icon={<PlusOutlined />}
            onClick={handleNewCommissionCategory}
          >
            New Commission Category
          </Button>
        </div>
      </div>
      <div>
        <Table
          columns={showColumns as ColumnsType<ICommissionCategoryDTO>}
          dataSource={commissionCategoriesData}
          onChange={handleChangeQuery}
          pagination={false}
          expandIconColumnIndex={-1}
          loading={isLoading}
        />
        <div style={{ paddingTop: '12px' }}>
          <Pagination
            total={totalCommissionCategories}
            current={currentPage}
            onChange={handlePageChange}
            showSizeChanger={false}
            pageSize={20}
          />
        </div>
      </div>

      <NewCommissionCategory
        editCommissionCategory={editCommissionCategory}
        onClose={() => { setShowAddNewCommissionCategory(false); }}
        onSuccess={(newCommissionCategory) => handleSuccess(newCommissionCategory)}
        visible={showAddNewCommissionCategory}
      />
    </>
  );
};

export default CommissionCategories;
