import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { PlusOutlined } from '@ant-design/icons';

import {
  Typography,
  Button,
  Tooltip,
} from 'antd';

import ArticleTableCard, { ITempArticleCell } from './article_table_card';

import { IArticle } from '../../../../shared/dtos/ArticleDTO';

import { IPaginatedQuery } from '../../../../shared/dtos/IPaginatedQuery';
import api from '../../services/api';
import Keyword from '../../../../shared/dtos/KeywordDTO';
import { useAuth } from '../../hooks/auth';

const { Text } = Typography;

interface IArticleTableCellProps {
  keyword: Keyword
  sort?: string
  sortOrder?: string
  newArticles: IArticle[]
  handleOpenArticleModal(article?: IArticle, resubmit?: boolean): void;
}

interface IAddArticleButtonProps {
  addArticleFunction?(): void
}

const ArticleTableCell: React.FC<IArticleTableCellProps> = ({
  keyword, sort, sortOrder, newArticles, handleOpenArticleModal,
}) => {
  const { handleError } = useAuth();
  const [articles, setArticles] = useState<ITempArticleCell[]>([]);

  const [iSort, iSortOrder] = useMemo(() => {
    if (sort && sort.startsWith('articles.')) {
      return [sort, sortOrder || 'desc'];
    }
    return ['articles.article_id', 'desc'];
  }, [sort, sortOrder]);

  const keywordsArticles = useMemo(() => {
    const articleIds = articles.map((article) => article.article_id);
    const filteredArticles = newArticles.filter((article) => !articleIds.includes(article.article_id));
    return [...articles, ...filteredArticles];
  }, [articles, newArticles]);

  const loadArticles = useCallback(async () => {
    try {
      const foundArticles = await api.get<IPaginatedQuery<ITempArticleCell>>('/api/articles', {
        params: {
          keywordId: keyword.keyword_id,
          pending: true,
          sort: iSort,
          sortOrder: iSortOrder,
        },
      });
      const { nodes } = foundArticles;
      setArticles(nodes);
    } catch (err) {
      handleError(err, 'Could not get articles');
    }
  }, [keyword.keyword_id, iSort, iSortOrder, handleError]);

  useEffect(() => {
    loadArticles();
  }, [loadArticles]);

  const handleRemoveTmpArticle = useCallback((selectedArticle: IArticle) => {
    if (selectedArticle.article_id) {
      loadArticles();
    }
  }, [loadArticles]);

  const handleCreateArticle = useCallback((): void => {
    handleOpenArticleModal();
  }, [handleOpenArticleModal]);

  const newArticleButton = useCallback((buttonProps?: IAddArticleButtonProps) => {
    const { addArticleFunction } = buttonProps || { undefined };
    const onClick = addArticleFunction ? () => addArticleFunction() : undefined;
    return (
      <Tooltip title={'New Article'}>
        <Button
          type="dashed"
          onClick={onClick}
          shape="circle"
          size="small"
          icon={<PlusOutlined />}
        />
      </Tooltip>
    );
  }, []);

  const createAddArticleButton = useCallback(() => {
    const addArticleButton = newArticleButton({
      addArticleFunction: () => handleCreateArticle(),
    });

    return addArticleButton;
  }, [handleCreateArticle, newArticleButton]);

  const createArticlesPanel = useCallback(() => (
      <span style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {articles.length === 0 && newArticles.length === 0 ? (
          <Text
            type="secondary"
            style={{ width: 190, textAlign: 'center' }}>
              No pending
          </Text>
        ) : (
          <>
            {
              keywordsArticles.map((article) => {
                const count = articles.filter((iArticle) => {
                  const siteId1 = iArticle.site?.site_id;
                  const siteId2 = article.site?.site_id;
                  return siteId1 === siteId2;
                }).length;

                const repeated = !article.article_id && count > 0;
                return (
                  <ArticleTableCard
                    key={`${article.article_id}`}
                    article={article}
                    repeatedConfirmation={repeated}
                    onEdit={(iArticle, resubmit) => handleOpenArticleModal(iArticle, resubmit)}
                    onRemove={() => handleRemoveTmpArticle(article)}
                  />
                );
              })
            }
          </>
        )}
      </span>
  ), [articles, handleOpenArticleModal, handleRemoveTmpArticle, keywordsArticles, newArticles.length]);

  const addArticleButton = createAddArticleButton();
  const articlesCellContent = createArticlesPanel();
  return (
    <span style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
        {articlesCellContent}
        {addArticleButton}
    </span>
  );
};

export default ArticleTableCell;
