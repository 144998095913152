/* eslint-disable consistent-return */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Dropdown,
  Menu,
  Select,
  Spin,
  Tag as AntTag,
} from 'antd';

import {
  useHistory,
  useLocation,
} from 'react-router-dom';

import Search from 'antd/lib/input/Search';
import {
  CloseCircleOutlined,
  ExportOutlined,
  LoadingOutlined,
  DownOutlined,
} from '@ant-design/icons';

import HeaderPanel from '../../components/header_panel';
import { keywordSubmenuActions } from '../keywords/keywords_summary';
import ProductsOOSReport from '../../components/Reports/products_oos_report';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { Tag } from '../../../../shared/dtos/KeywordDTO';
import KeywordWinnersTable from '../../components/Reports/keyword_winners_table';
import ContentSurfacesChart from '../../components/Reports/content_surfaces_chart';
import { ProductStockType } from '../../../../shared/dtos/ProductStockDTO';
import { saveData } from '../../utils';
import ExportModal from '../../components/ExportModal';
import { IExportDTO } from '../../../../shared/dtos/ExportDTO';

const { Option } = Select;

type DropdownValues = 'winners' | 'surfaces' | 'oos';
const defaultStocks = ['INVALID_ASIN', 'OUT_OF_STOCK'] as ProductStockType[];

export interface IChangeTableConfig {
  page?: number
  perPage?: number
  sort?: string
  sortOrder?: string
}

export interface IFilterReport {
  dateRange?: number
  query?: string
  tags: number[]
  stock?: ProductStockType[]
  site?: number
  sortOrder?: string
  sort?: string
  page?: number
  perPage?: number
}

const OOS = 'oos';
const SURFACES = 'surfaces';
const WINNERS = 'winners';
const RAW = 'raw';

const Reports: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { handleError } = useAuth();

  const [showExport, setShowExport] = useState<string>();

  const pathName = location.pathname.split('/').reverse()[0];
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const initialReport: DropdownValues = pathName === OOS || pathName === SURFACES
    ? pathName
    : WINNERS;

  const [reportDropdownValue, setReportDropdownValue] = useState<DropdownValues>(initialReport);
  const [sites, setSites] = useState<ISiteDTO[]>([]);

  const [tagValues, setTagValues] = useState<string[]>([]);
  const [keywordSearch, setKeywordSearch] = useState(params.get('query') || '');
  const [filters, setFilters] = useState<IFilterReport>({
    dateRange: params.get('range') ? Number(params.get('range')) : 7,
    query: params.get('query') || undefined,
    site: params.get('site') ? Number(params.get('site')) : undefined,
    tags: params.getAll('tag[]').map((tag) => Number(tag)),
    stock: params.get('stock') ? [params.get('stock') as ProductStockType] : undefined,
    sort: params.get('sort') || undefined,
    page: params.get('page') ? Number(params.get('page')) : undefined,
    sortOrder: params.get('sortOrder') || undefined,
  });

  const [fetchingTags, setFetchingTags] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);

  const [exporting, setExporting] = useState(false);

  const iStock = useMemo(() => (filters.stock ? filters.stock : defaultStocks), [filters.stock]);

  const fetchTagsData = useCallback(async (tagIds) => {
    const tagsData = await api.get<Tag[]>('/api/tags', {
      params: {
        ids: tagIds,
      },
    });
    setTagValues(tagsData.map((td) => td.tag));
  }, []);

  const fetchSiteData = useCallback(async () => {
    const siteData = await api.get<ISiteDTO[]>('/api/sites');
    setSites(siteData);
  }, []);

  const handleChangeTableConfig = useCallback((newTableConfig: IChangeTableConfig) => {
    const sortOrder = newTableConfig.sortOrder && newTableConfig.sortOrder.slice(0, -3);
    setFilters((previous) => ({
      ...previous,
      page: newTableConfig.page || previous.page,
      perPage: newTableConfig.perPage || previous.perPage,
      sortOrder: sortOrder || previous.sortOrder,
      sort: newTableConfig.sort || previous.sort,
    }));
  }, []);

  useEffect(() => {
    fetchSiteData();
  }, [fetchSiteData]);

  const handleReportSelect = useCallback((value: string) => {
    setReportDropdownValue(value as DropdownValues);
    setFilters((previous) => ({
      ...previous,
      perPage: undefined,
      page: undefined,
      sort: undefined,
      sortOrder: undefined,
    }));
  }, []);

  const filterDateRange = useMemo(() => (
    <Select
      style={{ minWidth: 130 }}
      value={filters.dateRange}
      onSelect={(newRange) => setFilters((previous) => ({ ...previous, dateRange: newRange, page: undefined }))} >
        <Select.Option value={7}>7 Days</Select.Option>
        <Select.Option value={30}>30 Days</Select.Option>
        <Select.Option value={90}>90 Days</Select.Option>
    </Select>
  ), [filters.dateRange]);

  const handleQueryFilterChange = useCallback((event) => {
    const { value } = event.target;
    setKeywordSearch(value);
  }, []);

  const handleQueryFilterPressEnter = useCallback((event) => {
    const { value } = event.target;
    setFilters((previous) => ({ ...previous, query: value, page: undefined }));
  }, []);

  const filterQueryPlaceHolder = useMemo(() => {
    if (reportDropdownValue === OOS) {
      return 'Product Name / ASIN';
    }
    return 'Keyword';
  }, [reportDropdownValue]);

  const filterQuery = useMemo(() => (
    <Search
      style={{
        width: 280,
      }}
      value={keywordSearch}
      placeholder={filterQueryPlaceHolder}
      onChange={handleQueryFilterChange}
      onPressEnter={handleQueryFilterPressEnter}
    />
  ), [filterQueryPlaceHolder, handleQueryFilterChange, handleQueryFilterPressEnter, keywordSearch]);

  const tagRender = useCallback(({
    label, value, closable, onClose,
  }) => (
      <AntTag
        key={value}
        color='cyan'
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}>
        {label}
      </AntTag>
  ), []);

  const handleSearchTags = useCallback(async (values): Promise<void> => {
    try {
      setFetchingTags(true);
      const receveivedTags = await api.get<Tag[]>('/api/tags', {
        params: {
          query: values,
        },
      });
      setTags(receveivedTags);
    } catch (err) {
      handleError(err, 'Tags could not be searched.', true);
    } finally {
      setFetchingTags(false);
    }
  }, [handleError]);

  const handleChangeTags = useCallback((values) => {
    const foundTags = tags.filter((tag) => values.includes(tag.tag.toLowerCase()));
    setFilters((previous) => ({ ...previous, tags: foundTags.map((ft) => ft.tag_id), page: undefined }));
    setTagValues(values);
  }, [tags]);

  const tagOptions = useMemo(() => tags.map((tag) => ({
    label: tag.tag,
    value: tag.tag.toLowerCase(),
  })), [tags]);

  const filterTags = useMemo(() => (
    <Select
        mode="multiple"
        showArrow
        placeholder="Tags"
        notFoundContent={fetchingTags ? <Spin size="small" /> : null}
        tagRender={tagRender}
        onSearch={handleSearchTags}
        onChange={handleChangeTags}
        style={{ minWidth: 200 }}
        options={tagOptions}
        value={tagValues}
      />
  ), [fetchingTags, handleChangeTags, handleSearchTags, tagOptions, tagRender, tagValues]);

  const handleSiteSelect = useCallback((value) => {
    const site = value === '' ? undefined : value;
    setFilters((previous) => ({ ...previous, site, page: undefined }));
  }, []);

  const filterSites = useMemo(() => (
    <Select
        value={filters.site || ''}
        onSelect={handleSiteSelect}
        style={{ width: 200 }}
      >
        <Option value='' >
          All sites
        </Option>
        {
          sites.map((site) => (
            <Option key={`site_${site.site_id}`} value={site.site_id}>
              <img width={20} src={site.logo_url} style={{ paddingRight: '6px' }} />
              { site.display_name }
            </Option>
          ))
        }
      </Select>
  ), [filters.site, handleSiteSelect, sites]);

  const reportFilters = useMemo(() => {
    switch (reportDropdownValue) {
      case OOS:
        return [filterQuery];
      case SURFACES:
        return [filterTags, filterSites, filterDateRange];
      default: return [filterQuery, filterTags, filterSites, filterDateRange];
    }
  }, [filterDateRange, filterQuery, filterSites, filterTags, reportDropdownValue]);

  const performReportEndpoint = useCallback(async (reportType) => {
    let blob;
    if (reportType === SURFACES) {
      blob = await api.get<Blob>(
        '/api/stats/content-surfaces.xlsx',
        {
          params: {
            dateRange: filters.dateRange,
            site: filters.site,
            tags: filters.tags,
          },
          blob: true,
        },
      );
    }

    if (reportType === OOS) {
      blob = await api.get<Blob>(
        '/api/stocks/oos.xlsx',
        {
          params: {
            sort: filters.sort,
            sortOrder: filters.sortOrder,
            query: filters.query,
            dateRange: filters.dateRange,
            stock: iStock,
          },
          blob: true,
        },
      );
    }

    if (reportType === RAW) {
      setShowExport('/api/stats/keyword-winners-raw');
    }

    if (reportType === 'winners') {
      setShowExport('/api/stats/keyword-winners-report');
    }

    return blob;
  }, [filters, iStock]);

  useEffect(() => {
    const cleanedParams = JSON.parse(JSON.stringify({
      range: filters.dateRange,
      query: filters.query,
      site: filters.site,
      stock: filters.stock,
      sort: filters.sort,
      sortOrder: filters.sortOrder,
      page: filters.page,
    }));
    const queryString = new URLSearchParams(cleanedParams);
    if (filters.tags.length > 0) {
      filters.tags.forEach((iTag) => {
        queryString.append('tag[]', `${iTag}`);
      });
      void fetchTagsData(filters.tags);
    }
    history.push({ pathname: `/reports/${reportDropdownValue}`, search: queryString.toString() });
  }, [fetchTagsData, filters.page, filters.query, filters.dateRange, filters.site, filters.sort, filters.sortOrder, filters.stock, filters.tags, history, reportDropdownValue]);

  const handleCleanFields = useCallback(() => {
    setFilters({
      dateRange: 7,
      query: undefined,
      site: undefined,
      tags: [],
      stock: undefined,
      page: undefined,
    });
    setTagValues([]);
  }, []);

  const handleExportReport = useCallback(async ({ key = reportDropdownValue }) => {
    setExporting(true);
    try {
      const blob: Blob | undefined = await performReportEndpoint(key);
      if (blob) {
        saveData(blob, key, 'xlsx');
      }
    } catch (err) {
      handleError(err);
    } finally {
      setExporting(false);
    }
  }, [handleError, reportDropdownValue, performReportEndpoint]);

  const currentReport = useMemo(() => {
    switch (reportDropdownValue) {
      case OOS:
        return (
          <ProductsOOSReport
            filters={{
              ...filters,
              stock: iStock,
            }}
            onChangeTable={handleChangeTableConfig} />
        );
      case SURFACES:
        return (
          <ContentSurfacesChart sites={sites} filters={filters} />
        );
      default:
        return (
          <KeywordWinnersTable
            filters={filters}
            onChangeTable={handleChangeTableConfig} />
        );
    }
  }, [filters, handleChangeTableConfig, iStock, reportDropdownValue, sites]);

  const handlePrepareExport = useCallback(async () => {
    const result = await api.post<IExportDTO>(showExport || '', {
      body: {
        sort: filters.sort,
        sortOrder: filters.sortOrder,
        dateRange: filters.dateRange,
        query: filters.query,
        tags: filters.tags,
        siteFilter: filters.site,
      },
    });
    return result;
  }, [filters.dateRange, filters.query, filters.site, filters.sort, filters.sortOrder, filters.tags, showExport]);

  return (
    <>
      <ExportModal
          visible={!!showExport}
          fileName='export.xlsx'
          prepareCall={handlePrepareExport}
          title="Export Keyword Winners Content"
          onCancel={() => setShowExport(undefined)}
         />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Select
          value={reportDropdownValue}
          style={{ width: '200px' }}
          onSelect={handleReportSelect}
          >
          <Option value={WINNERS}>Keyword Winners</Option>
          <Option value={SURFACES}>Content Surfaces</Option>
          <Option value={OOS}>Products OOS</Option>
        </Select>
        <ul style={{
          listStyleType: 'none',
          overflow: 'hidden',
          display: 'inline-flex',
        }}>
          {reportFilters.map((reportFilter, idx) => (
            <li key={`report_filter_${idx}`} style={{ marginLeft: 10 }}>
              {reportFilter}
            </li>
          ))}
        </ul>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 14,
      }}>
        <Button
          onClick={handleCleanFields}
          type="default"
          icon={<CloseCircleOutlined />}>
          Clear Filters
        </Button>
        {
          reportDropdownValue === WINNERS
            ? (
              <Dropdown.Button
                style={{
                  marginLeft: 10,
                }}
                type="primary"
                icon={ <DownOutlined />}
                overlay={
                  <Menu onClick={handleExportReport}>
                    <Menu.Item key={RAW}>Raw Export</Menu.Item>
                    <Menu.Item key={WINNERS}>Keyword Winners Export</Menu.Item>
                  </Menu>
                }
              >
                { exporting ? <LoadingOutlined /> : <ExportOutlined /> }{' '}Export Report
              </Dropdown.Button>
            )
            : (
              <Button
                style={{
                  marginLeft: 10,
                }}
                loading={exporting}
                onClick={handleExportReport}
                type="primary"
                icon={<ExportOutlined />}
              >
                Export Report
              </Button>
            )
        }
      </div>
      <HeaderPanel
        hideHome
        actions={keywordSubmenuActions}
      />
      { currentReport }
    </>
  );
};

export default Reports;
