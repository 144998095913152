import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Divider,
  Checkbox,
  Form,
  Input,
  Modal,
  Spin,
  Tooltip,
  Typography,
  Select,
  Row,
  Col,
  Upload,
  Image,
  message,
} from 'antd';

import {
  CheckOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleFilled,
  UploadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  ICreateAOPArticleDTO, ISaveArticleDTO, IArticle,
} from '../../../../shared/dtos/ArticleDTO';
import { ICreateProductDTO } from '../../../../shared/dtos/ProductDTO';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import api from '../../services/api';
import Keyword from '../../../../shared/dtos/KeywordDTO';
import { IMAGE_NOT_FOUND_AVG } from '../../utils';
import { useAuth } from '../../hooks/auth';
import ProductForm from './product_form';
import FAQForm from './faq_form';
import ArticleKeywordForm from './keyword_form';

const { Text } = Typography;
const { Option } = Select;

interface INewArticleModalProps {
  keyword?: Keyword
  selectedArticle?: IArticle
  visible?: boolean
  onClose(): void
  onSubmit(newArticle: ICreateAOPArticleDTO): void
  onSave(article: ISaveArticleDTO, extraField?: string): void
}

interface IArticleFormData {
  products: ICreateProductDTO[];
  extraField?: string
  title: string
  faqs: string[]
  intro: string
  site_id: string
  draft: boolean
  notes: string
}

const defaultFormState = {
  products: [],
  sites: [],
  faqs: [],
  intro: '',
  imageUrl: '',
  title: '',
  draft: false,
  notes: '',
};

interface IFormSectionProps {
  title: string
  hint?: string
  required?: boolean
  children: React.ReactNode
  singleColumn?: boolean
}

const FormSection: React.FC<IFormSectionProps> = ({
  title, required, hint, singleColumn, children,
}) => {
  const marginLeft = singleColumn ? -20 : undefined;
  return (
      <>
        <Divider orientation="left" >
          <Text style={{
            fontSize: 14,
            marginLeft,
          }}>
            {required && (<Text type="danger">* </Text>)}{title}:
          </Text>
          {hint && (
            <Tooltip placement="top" title={hint}>
              <QuestionCircleFilled style={{
                marginLeft: 5,
              }} />
            </Tooltip>
          )}
        </Divider>

        <div style={{
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 29,
          marginRight: 29,
        }}>
          {children}
        </div>
      </>
  );
};

const NewArticleModal: React.FC<INewArticleModalProps> = ({
  keyword,
  selectedArticle,
  visible,
  onClose,
  onSubmit,
  onSave,
}) => {
  let delaySearch: number | ReturnType<typeof setTimeout> = 0;
  const initialNewKeywords = useMemo(() => {
    const keywords = selectedArticle?.keywords || [];
    const filteredKeywords = keywords.filter((keywordItem) => keywordItem.keyword !== keyword?.keyword);
    return filteredKeywords.map((keywordItem) => keywordItem.keyword);
  }, [keyword?.keyword, selectedArticle?.keywords]);
  const { handleError } = useAuth();
  const [saving, setSaving] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageFilePreview, setImageFilePreview] = useState<string>();
  const [imageFile, setImageFile] = useState<File | undefined>();
  const [imageFileList, setImageFileList] = useState([]);
  const [sites, setSites] = useState<ISiteDTO[]>([]);
  const [form] = Form.useForm();
  const [selectedSite, setSelectedSite] = useState<string>();
  const [newKeywords, setNewKeywords] = useState<string[]>([]);

  const clearUploadImage = () => {
    setImageFileList([]);
    setImageFilePreview('');
    setImageFile(undefined);
  };

  useEffect(() => {
    const keywordString = keyword ? keyword.keyword : '';
    defaultFormState.title = `The best ${keywordString}`;
    form.setFieldsValue(defaultFormState);
    setNewKeywords(initialNewKeywords);
  }, [form, initialNewKeywords, keyword]);

  const loadSites = useCallback(async () => {
    try {
      const nSites = await api.get<ISiteDTO[]>('/api/sites');
      setSites(nSites);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    loadSites();
  }, [loadSites]);

  useEffect(() => {
    setImageUrl(selectedArticle ? selectedArticle.image_url : undefined);
  }, [selectedArticle, visible]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (selectedArticle) {
        form.setFieldsValue({
          products: selectedArticle.products,
          faqs: selectedArticle.faqs,
          intro: selectedArticle.intro,
          imageUrl: selectedArticle.image_url,
          site_id: selectedArticle.site_id,
          title: selectedArticle.title,
          notes: selectedArticle.notes,
          draft: selectedArticle.status === 'draft',
        });
      } else {
        form.setFieldsValue(defaultFormState);
      }
    }
  }, [form, selectedArticle, visible]);

  const handleCloseModal = useCallback(() => {
    clearUploadImage();
    setNewKeywords([]);
    onClose();
  }, [onClose]);

  const handleSiteSelection = useCallback((value) => {
    setSelectedSite(value);
  }, []);

  const handleKeywordAdd = useCallback((newKeyword) => {
    if (newKeyword) {
      setNewKeywords([...newKeywords, newKeyword]);
    }
  }, [newKeywords]);

  const handleKeywordRemove = useCallback((keywordToRemove: string) => {
    const updatedNewKeywords = newKeywords.filter((keywordValue) => keywordValue !== keywordToRemove);
    setNewKeywords(updatedNewKeywords);
  }, [newKeywords]);

  const handleSaveImage = useCallback(async (): Promise<string | undefined> => {
    setSaving(true);
    if ((imageUrl || imageFile) && (imageUrl === '' || imageUrl !== selectedArticle?.image_url)) {
      const formData = new FormData();
      const siteId = selectedArticle?.site_id || Number(selectedSite);
      const siteName = sites.find((site) => site.site_id === siteId)?.normalized_name;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const imageKey = `${keyword?.keyword?.replace(/\s+/g, '+')}+${siteId}`;

      if (imageFile) {
        formData.append('file', imageFile);
        formData.append('site', siteName || '');
        formData.append('key', imageKey);
      }
      try {
        const imageServiceResponse = await api.post<{ image_url: string }>('/api/images', {
          body: imageFile ? formData : {
            key: imageKey,
            imageUrl,
            site: siteName,
          },
        });

        setImageFileList([]);
        setSaving(false);
        return imageServiceResponse.image_url;

      // eslint-disable-next-line no-empty
      } catch (err) {
        setSaving(false);
        return imageUrl;
      }
    }

    setSaving(false);
    return imageUrl;
  }, [imageFile, imageUrl, keyword?.keyword, selectedArticle?.image_url, selectedArticle?.site_id, selectedSite, sites]);

  const notifySaveSuccess = () => {
    message.success('Article Saved!');
  };

  const handleSubmit = useCallback(async (articleFormValues: IArticleFormData) => {
    const imageBaseUrl = await handleSaveImage();
    if (selectedArticle) {
      onSave({
        articleId: selectedArticle.article_id,
        products: articleFormValues.products,
        site_id: articleFormValues.site_id,
        faqs: articleFormValues.faqs,
        intro: articleFormValues.intro,
        imageUrl: imageBaseUrl,
        title: articleFormValues.title,
        draft: articleFormValues.draft,
        notes: articleFormValues.notes,
        keywords: keyword ? [...newKeywords, keyword.keyword] : newKeywords,
      }, articleFormValues.extraField);
      clearUploadImage();
      setNewKeywords([]);
      notifySaveSuccess();
      onClose();
      return;
    }

    onSubmit({
      ...form.getFieldsValue(),
      keywords: [keyword?.keyword, ...newKeywords],
    });
    clearUploadImage();
    setNewKeywords([]);
    notifySaveSuccess();
    onClose();
  }, [form, handleSaveImage, keyword, newKeywords, onClose, onSave, onSubmit, selectedArticle]);

  const handleConfirmSubmit = useCallback(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      draft: false,
    });
    form.validateFields().then(() => {
      const articleFormValues = form.getFieldsValue();
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        content: 'Are you sure you want to submit this article?',
        onOk() {
          if (selectedArticle) {
            onSave({
              articleId: selectedArticle.article_id,
              products: articleFormValues.products,
              site_id: articleFormValues.site_id,
              faqs: articleFormValues.faqs,
              intro: articleFormValues.intro,
              title: articleFormValues.title,
              draft: articleFormValues.draft,
              notes: articleFormValues.notes,
              keywords: keyword ? [...newKeywords, keyword.keyword] : newKeywords,
            }, articleFormValues.extraField);
          } else {
            form.submit();
          }
          clearUploadImage();
          setNewKeywords([]);
          notifySaveSuccess();
          onClose();
        },
      });
    });
  }, [form, keyword, newKeywords, onClose, onSave, selectedArticle]);

  const handleChangeImageUrl = useCallback((event) => {
    if (delaySearch) {
      clearTimeout(delaySearch as ReturnType<typeof setTimeout>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    delaySearch = setTimeout(() => {
      setImageUrl(event.target.value);
    }, 1000);
  }, []);

  const actions = useMemo(() => {
    const mActions = [
      <Button
        style={{
          width: 150,
        }}
        onClick={() => handleCloseModal()} >
        Cancel
      </Button>,
    ];
    if (!selectedArticle || selectedArticle.status === 'draft') {
      mActions.push(
        <Button
          type="dashed"
          icon={<EditOutlined />}
          style={{
            width: 150,
          }}
          loading={saving}
          onClick={() => {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              keywords: [keyword?.keyword, ...newKeywords],
              draft: true,
            });
            form.submit();
          }}
        >
          Save Draft
        </Button>,
      );
    }
    mActions.push(
      <Button
        type="primary"
        icon={<CheckOutlined />}
        style={{
          width: 150,
        }}
        loading={saving}
        // eslint-disable-next-line no-return-await
        onClick={() => handleConfirmSubmit()} >
        Submit
      </Button>,
    );
    return mActions;
  }, [form, handleCloseModal, handleConfirmSubmit, keyword?.keyword, newKeywords, saving, selectedArticle]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAttachedImage = useCallback(({ fileList }: { fileList: any }) => {
    const file = fileList[0];
    if (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
        return;
      }
    }

    setImageUrl('');
    setImageFileList(fileList);
    setImageFile(file?.originFileObj);
    form.setFieldsValue({ imageUrl: '' });
    setImageFilePreview(file ? URL.createObjectURL(file?.originFileObj) : '');
  }, [form]);

  return (
    <Modal
      title={selectedArticle ? 'Edit Article' : 'New Article'}
      visible={visible}
      footer={actions}
      bodyStyle={{
        padding: 0,
      }}
      onCancel={handleCloseModal}
      width={750}
    >
      <Spin size='large' tip='Saving...' spinning={saving}>
        <Form
          form={form}
          initialValues={defaultFormState}
          onFinish={handleSubmit}
          autoComplete="off" >
            <Form.Item name="draft" style={{
              display: 'none',
            }}>
              <Checkbox />
            </Form.Item>
          <Row>
            <Col xs={12}>
              <FormSection
                title="Keyword"
                required>
                  <ArticleKeywordForm
                    form={form}
                    keyword={keyword?.keyword}
                    newKeywords={newKeywords}
                    onAdd={handleKeywordAdd}
                    onRemove={handleKeywordRemove}
                  />
              </FormSection>
            </Col>
            <Col xs={12}>
              <FormSection
                title="Sites"
                required
              >
                <Form.Item
                  name={'site_id'}
                  rules={[{ required: true, message: 'Missing Site' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    onChange={handleSiteSelection}
                  >
                    {
                      sites && sites.map((site) => (
                        <Option key={site.site_id} value={site.site_id}>{site.display_name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </FormSection>
            </Col>
          </Row>
          <Row>
            <Col xs={24} >
              <FormSection title="Title" singleColumn>
                <Form.Item name="title">
                  <Input style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}/>
                </Form.Item>
              </FormSection>
            </Col>
          </Row>
          <Row>
            <Col xs={24} >
              <FormSection title="Image" singleColumn>
                <Row justify="space-between" >
                  <Col style={{
                    flex: 1,
                    marginRight: 10,
                  }}>
                    <Form.Item name="imageUrl">
                      <Input placeholder="Hero Image" onChange={handleChangeImageUrl} />
                    </Form.Item>
                    <Upload
                      name="heroImage"
                      listType="picture"
                      maxCount={1}
                      fileList={imageFileList}
                      beforeUpload={() => false}
                      onChange={handleAttachedImage}
                    >
                      <Button icon={<UploadOutlined />}>
                        {imageUrl || imageFilePreview ? 'Change' : 'Upload'}
                      </Button>
                    </Upload>
                  </Col>
                  <Col>
                    <Image
                      src={imageUrl || imageFilePreview || IMAGE_NOT_FOUND_AVG} width={90}
                    />
                  </Col>
                </Row>
              </FormSection>
            </Col>
          </Row>
          <Row>
            <Col xs={24} >
              <FormSection title="Introduction" singleColumn>
                <Form.Item name="intro">
                  <Input.TextArea />
                </Form.Item>
              </FormSection>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <FormSection title="Products" singleColumn>
                  <Form.List name="products">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((formListFieldData, index) => (
                          <ProductForm
                            key={formListFieldData.key}
                            form={form}
                            index={index}
                            onRemove={(name) => remove(name)}
                            formListFieldData={formListFieldData} />
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} >
                            Add Product
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
              </FormSection>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <FormSection title="Suggested FAQs" singleColumn>
                  <Form.List name="faqs">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((formListFieldData, index) => (
                          <FAQForm
                            key={formListFieldData.key}
                            form={form}
                            index={index}
                            onRemove={(name) => remove(name)}
                            formListFieldData={formListFieldData} />
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} >
                            Add FAQ
                          </Button>
                        </Form.Item>
                      </>
                    )
                  }
                  </Form.List>
              </FormSection>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <FormSection title="Notes" singleColumn>
                  <Form.Item name="notes">
                    <Input.TextArea />
                  </Form.Item>
              </FormSection>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default NewArticleModal;
