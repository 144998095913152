/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Avatar,
  Card,
  List,
} from 'antd';

import {
  IFeatureScan,
  IFunctionScan,
  IScan,
} from '../../../../shared/dtos/ScanDTO';
import ItemScan from './ItemScan';

interface IIconMap {
  [key: string]: string
}

const iconMap: IIconMap = {
  aop: '/dark_logo.png',
  new_relic: '/new_relic.png',
  junglescout: '/junglescout.png',
  asana: '/asana.png',
  wordpress: '/wordpress.png',
  imagebase: '/imagebase.png',
  amazon: '/amazon.png',
};

interface ListScanProps {
  currentCheckItem?: number
  scans: IScan[]
  autoScroll?: boolean
  runItem(functionId: number): Promise<void>
  autoFix(functionId: number, fix: string): Promise<void>
}

interface IFunctionRef {
  ref: RefObject<any>
  functionId: number
}

const ListScan: React.FC<ListScanProps> = ({
  scans, currentCheckItem, autoScroll, runItem, autoFix,
}) => {
  const functionRefs = useMemo(() => {
    const reducedFunctions = scans.reduce((scanFuctions: IFunctionRef[], scan) => {
      const deepFunctions = scan.features.reduce((featureFuctions: IFunctionRef[], feature) => {
        const ff = feature.functions.map((f) => ({
          ref: React.createRef<any>(),
          functionId: f.id,
        } as IFunctionRef));
        return [...featureFuctions, ...ff];
      }, scanFuctions);
      return deepFunctions;
    }, []);
    return reducedFunctions;
  }, [scans]);

  const findRefByFunctionId = useCallback((functionId: number) => {
    const functionRef = functionRefs.find((f) => f.functionId === functionId);
    return functionRef?.ref;
  }, [functionRefs]);

  useEffect(() => {
    if (currentCheckItem && autoScroll) {
      const currentFunctionRef = functionRefs.find((fr) => fr.functionId === currentCheckItem);
      currentFunctionRef?.ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [autoScroll, currentCheckItem, functionRefs]);

  const iconFeature = useCallback((myIcon: string) => {
    const iconImage = iconMap[myIcon] || myIcon;
    return iconImage;
  }, []);

  const createFunctionItem = useCallback((iFunction: IFunctionScan) => {
    const ref = findRefByFunctionId(iFunction.id);
    return (
      <ItemScan
        autoFix={autoFix}
        runItem={runItem}
        refefence={ref}
        iFunction={iFunction}
        checking={iFunction.id === currentCheckItem} />
    );
  }, [autoFix, currentCheckItem, findRefByFunctionId, runItem]);

  const createFeatureContent = useCallback((itemFeature: IFeatureScan) => {
    const myIcon = iconFeature(itemFeature.icon);
    return (
      <List.Item>
          <List.Item.Meta
            avatar={<Avatar size={20} src={myIcon} />}
            title={itemFeature.name}
            description={
              <List
                itemLayout="horizontal"
                dataSource={itemFeature.functions}
                renderItem={(iFunction) => (
                  createFunctionItem(iFunction)
                )}
              />
            }
            />
      </List.Item>
    );
  }, [createFunctionItem, iconFeature]);

  return (
    <List
        itemLayout="horizontal"
        dataSource={scans}
        renderItem={(scan) => (
          <Card>
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size={30} src={iconFeature(scan.icon)} />}
                title={scan.name}
                description={
                  <List
                    itemLayout="horizontal"
                    dataSource={scan.features}
                    renderItem={(feature) => (
                      createFeatureContent(feature)
                    )}
                  />
                }
              />
            </List.Item>
          </Card>
        )}
      />
  );
};

export default ListScan;
