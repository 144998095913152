import React, {
  useCallback,
  useState,
} from 'react';

import {
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import {
  AutoComplete,
  Button,
  Tooltip,
  Table,
  Modal,
} from 'antd';

import { IArticle } from '../../../../shared/dtos/ArticleDTO';

import api from '../../services/api';
import Keyword from '../../../../shared/dtos/KeywordDTO';
import { useAuth } from '../../hooks/auth';

const { confirm } = Modal;

interface KeywordArticlesTableCellProps {
  articles: IArticle[]
  keyword: Keyword
}

interface IAsanaResponse {
  gid: string
  name: string
  resource_type: string
}

const KeywordArticlesTableCell: React.FC<KeywordArticlesTableCellProps> = ({
  keyword, articles,
}) => {
  const { handleError } = useAuth();
  const [articleList, setArticleList] = useState(articles);
  const [showArticleDetailsModal, setShowArticleDetailsModal] = useState(false);
  const [asanaSearchResults, setAsanaSearchResults] = useState<IAsanaResponse[]>([]);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [selectedAsanaTask, setSelectedAsanaTask] = useState<IAsanaResponse>();

  const handleArticleDetailsClick = useCallback(() => {
    setShowArticleDetailsModal(true);
  }, []);

  const handleOk = useCallback(() => {
    setShowArticleDetailsModal(false);
    setSelectedAsanaTask(undefined);
    setSearchInProgress(false);
    setAsanaSearchResults([]);
  }, []);

  const handleCancel = useCallback(() => {
    setShowArticleDetailsModal(false);
    setSelectedAsanaTask(undefined);
    setSearchInProgress(false);
    setAsanaSearchResults([]);
  }, []);

  const handleSearch = useCallback(async (value: string) => {
    try {
      if (!searchInProgress) {
        setSearchInProgress(true);
        const searchResults = await api.get<IAsanaResponse[]>(`/api/asana/search?search=${encodeURIComponent(value)}`);
        setAsanaSearchResults(searchResults);
        setSearchInProgress(false);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, searchInProgress]);

  const handleOnSelect = useCallback((value: string) => {
    const task = asanaSearchResults.find((item) => item.name === value);
    setSelectedAsanaTask(task);
  }, [asanaSearchResults]);

  const handleArticleDissociation = useCallback((articleId: number) => {
    confirm({
      title: 'Are you sure you want to remove this article?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await api.delete(`/api/articles/${articleId}/keywords/${keyword.keyword_id}`);
          const updatedArticleList = articleList.filter((article) => article.article_id !== articleId);
          setArticleList(updatedArticleList);
        } catch (err) {
          handleError(err);
        }
      },
    });
  }, [articleList, handleError, keyword.keyword_id]);

  const handleAddArticleClickEvent = useCallback(async () => {
    try {
      setSearchInProgress(true);
      const newArticle = await api.post<IArticle>('/api/articles/asana_article', {
        body: {
          keyword_id: keyword.keyword_id,
          gid: selectedAsanaTask?.gid,
        },
      });
      if (newArticle.article_id) {
        setArticleList([...articleList, newArticle]);
      }
      setSearchInProgress(false);
    } catch (err) {
      handleError(err);
      setSearchInProgress(false);
    }
  }, [articleList, handleError, keyword.keyword_id, selectedAsanaTask?.gid]);

  return (
    <>
      <Modal
        visible={showArticleDetailsModal}
        onOk={handleOk}
        onCancel={handleCancel}
        title='Targeted Articles'
      >
        <div>
          <div style={{
            display: 'inline-block',
            backgroundColor: '#fff',
            padding: '0 0 16px 0',
          }}>
            <AutoComplete
              dropdownMatchSelectWidth={375}
              onSearch={handleSearch}
              onSelect={handleOnSelect}
              style={{ width: 352 }}
              options={asanaSearchResults.map((item) => ({ label: item.name, value: item.name }))}
              placeholder={'Search by article title'}
            />
            <Button
              type='primary'
              style={{ marginLeft: '14px', width: '106px' }}
              onClick={handleAddArticleClickEvent}
            >
              { searchInProgress ? <LoadingOutlined /> : 'Add Article' }
            </Button>
          </div>
          <Table
            size='small'
            columns={[
              {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
              },
              {
                title: 'Actions',
                key: 'actions',
                render: (article: IArticle) => (
                  <>
                    <Tooltip title='Remove Article From Keyword'>
                      <Button type='primary' danger>
                        <DeleteOutlined
                          onClick={() => handleArticleDissociation(article.article_id)}
                        />
                      </Button>
                    </Tooltip>
                  </>
                ),
              },
            ]}
            pagination={false}
            dataSource={articleList}
          />
        </div>
      </Modal>
      <Button
        shape='round'
        onClick={handleArticleDetailsClick}
      >
        {articleList.length}{' '}Articles
      </Button>
    </>
  );
};

export default KeywordArticlesTableCell;
