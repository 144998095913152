/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Form,
  Button,
  Image,
  Input,
  Modal,
  notification,
  Table,
  Tooltip,
  Typography,
} from 'antd';

import {
  InfoCircleOutlined,
  CloseCircleFilled,
  MinusCircleOutlined,
} from '@ant-design/icons';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import {
  ISiteDTO, ISiteWebhookDTO,
} from '../../../../../shared/dtos/SiteDTO';
import WebhookButton, { IWebhookProps } from '../../../components/Sites/webhook_button';
import WebhookDetailsModal from '../../../components/Sites/webhook_details_modal';
import { useHeader } from '../../../hooks/header';
import HeaderPanel from '../../../components/header_panel';
import { IWebhookDTO } from '../../../../../shared/dtos/ArticleDTO';

const { Text, Link } = Typography;

interface IWebhookHost {
  publicHost: string
}

const Sites: React.FC = () => {
  const { handleError } = useAuth();
  const [selectedWebhook, setSelectedWebhook] = useState<IWebhookProps>();
  const [sites, setSites] = useState<ISiteWebhookDTO[]>([]);
  const { envName, publicHost, updatePublicHost } = useHeader();
  const [form] = Form.useForm();
  const [showModalPort, setShowModalPort] = useState(false);
  const [starting, setStarting] = useState(false);

  const findSites = useCallback(async () => {
    try {
      const foundSites = await api.get<ISiteWebhookDTO[]>('/api/sites', {
        params: { webhooks: true },
      });
      setSites(foundSites);
    } catch (err) {
      handleError(err, 'Sites could not be searched.');
    }
  }, [handleError]);

  useEffect(() => {
    findSites();
  }, [findSites]);

  const handleShowWebhook = useCallback((selected: IWebhookProps) => {
    setSelectedWebhook(selected);
  }, []);

  const handleStartService = useCallback(async ({ url }) => {
    try {
      setStarting(true);
      const newHost = await api.patch<IWebhookHost>('/api/webhooks/tunnel', {
        body: { url },
      });
      notification.success({
        message: 'The Ngrok URL has been successfully configured',
        description: url,
      });
      updatePublicHost(newHost.publicHost);
      setShowModalPort(false);
      findSites();
    } catch (err) {
      handleError(err, 'Could not start the service');
    } finally {
      setStarting(false);
    }
  }, [findSites, updatePublicHost, handleError]);

  const handleResetWebhook = useCallback(async (site: ISiteWebhookDTO, siteGid: string, type: string): Promise<IWebhookDTO | undefined> => {
    if (!publicHost) {
      setShowModalPort(true);
      return undefined;
    }
    const reseted = await api.put<IWebhookDTO>(`/api/webhooks/${siteGid}`, {
      body: {
        type,
      },
    });

    const siteIndex = sites.findIndex((iSite) => iSite.site_id === site.site_id);
    if (type === 'copyboard') {
      site.copyboardWebhook = reseted;
    } else {
      site.contentWebhook = reseted;
    }
    const copySites = [...sites];
    copySites[siteIndex] = site;
    setSites(copySites);
    return reseted;
  }, [publicHost, sites]);

  const columns = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (name: string, site: ISiteDTO) => (
          <div>
            <Image
              width={35}
              preview={false}
              src={site.logo_url} />
            <Link
              href={site.url}
              target="_blank"
              style={{ marginLeft: 6 }}>
              {name}
            </Link>
            {!!site.sandbox && (
              <span style={{ marginLeft: 6 }}>
                <Tooltip title="Sandbox site" >
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            )}
          </div>
      ),
    },
    {
      title: 'Keywords',
      dataIndex: 'keywordsCount',
      key: 'keywordsCount',
      render: (keywordsCount: number) => {
        if (!keywordsCount || keywordsCount === 0) {
            <Text type="secondary">None</Text>;
        }
        return <Text>{keywordsCount}</Text>;
      },
    },
    {
      title: 'Articles',
      dataIndex: 'sitesCount',
      key: 'sitesCount',
      render: (sitesCount: number) => {
        if (!sitesCount || sitesCount === 0) {
            <Text type="secondary">None</Text>;
        }
        return <Text>{sitesCount}</Text>;
      },
    },
    {
      title: 'Asana Project',
      dataIndex: 'asana_gid',
      key: 'asana_gid',
      render: (asanaGid: string, site: ISiteWebhookDTO) => {
        if (asanaGid) {
          return (
              <WebhookButton
                site={site}
                type='content'
                webhook={site.contentWebhook}
                onShow={(selected) => handleShowWebhook(selected)}
                onReset={() => handleResetWebhook(site, asanaGid, 'content')} />
          );
        }
        return (
            <Tooltip
              title="There is no asana_gid set to this site">
              <Button
                disabled
                style={{
                  marginLeft: 32,
                }}
                size="small"
                type="dashed"
                icon={<MinusCircleOutlined />}>No Content Board
              </Button>
            </Tooltip>
        );
      },
    },
    {
      title: 'Asana CopyBoard',
      dataIndex: 'copyboard_gid',
      key: 'copyboard_gid',
      render: (copyboard: string, site: ISiteWebhookDTO) => {
        if (copyboard) {
          return (
              <WebhookButton
                site={site}
                type='copyboard'
                webhook={site.copyboardWebhook}
                onShow={(selected) => handleShowWebhook(selected)}
                onReset={() => handleResetWebhook(site, copyboard, 'copyboard')} />
          );
        }
        return (
            <Tooltip title="There is no copyboard_gid set to this site">
              <Button
                disabled
                size="small"
                type="dashed"
                style={{
                  marginLeft: 32,
                }}
                icon={<MinusCircleOutlined />}>No Copyboard
              </Button>
            </Tooltip>
        );
      },
    },
  ], [handleResetWebhook, handleShowWebhook]);

  const extraHeader = useMemo(() => (
      <>
        {envName === 'development' && !!publicHost && (
          <div style={{
            alignSelf: 'center',
          }}>
              <Text>Ngrok: <Text code>{publicHost}</Text></Text><br />
              <Button
                style={{
                  marginTop: 6,
                }}
                loading={starting}
                onClick={() => handleStartService('')}
                size="small"
                icon={<CloseCircleFilled />}>
                Reset
              </Button>
            </div>
        )}
      </>
  ), [envName, handleStartService, publicHost, starting]);

  return (
    <>
      <HeaderPanel
        title="Sites management"
        extra={extraHeader} />

      <Table
        columns={columns}
        dataSource={sites}
        pagination={false}
        rowKey={(item) => item.site_id}
      />

      <Modal
        okText="Submit"
        onOk={() => form.submit()}
        title="Ngrok URL"
        onCancel={() => setShowModalPort(false)}
        visible={showModalPort}>
          <Text strong type="danger">Development Environment</Text><br />
          <Text type="secondary">
            You need a public URL to test local webhooks.<br />
            Please start the <strong><a href='https://ngrok.com' target="_blank">ngrok cli</a></strong> and share it's https URL:<br />
          </Text>
          <Text code>
            ex.: ngrok http 50504
          </Text>
          <br /><br />
          <Form
            form={form}
            onFinish={handleStartService}
            initialValues={{ url: '' }}
            layout='vertical'
          >

            <Form.Item
              name='url'
              rules={[{ required: true }]}
            >
              <Input placeholder='https:// ... ngrok.io' />
            </Form.Item>
          </Form>
      </Modal>

      <WebhookDetailsModal
        selected={selectedWebhook}
        onReset={async (): Promise<IWebhookDTO | undefined> => {
          if (selectedWebhook) {
            const siteGid = selectedWebhook.type === 'copyboard' ? selectedWebhook.site.copyboard_gid : selectedWebhook.site.asana_gid;
            return handleResetWebhook(selectedWebhook.site, siteGid, selectedWebhook.type);
          }
          return undefined;
        }}
        onClose={() => setSelectedWebhook(undefined)} />
    </>
  );
};

export default Sites;
