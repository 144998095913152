/* eslint-disable */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Tag,
  Typography,
  Tooltip,
} from 'antd';
import { KEYWORDS_PER_PAGE } from '../pages/keywords/keyword_opportunities';
import {
  AmazonCircleFilled,
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditFilled,
  ImportOutlined,
} from '@ant-design/icons';
import api from '../services/api';
import Keyword from '../../../shared/dtos/KeywordDTO';
import { IOpportunityKeywordDTO } from '../../../shared/dtos/OpportunityDTO';
import KeywordOpportunitiesChart from './keyword_opportunity_chart';
import { ColumnType } from 'antd/lib/table';
import { formatCurrency } from '../utils';
import { useAuth } from '../hooks/auth';

const {Text, Link} = Typography;

interface IKeywordOpportunitiesTableProps {
  keywords: IOpportunityKeywordDTO[];
  page: number;
  totalCount: number;
  onChange(page: number, sort?: string, sortOrder?: string): void;
}

export const getScoreLabel = (score: number | undefined, total: number): string => {
  const scoreN = score || 0;
  const percent = (scoreN * 100) / total;
  if (percent < 25) {
    return 'Very Low';
  }
  if (percent < 45) {
    return 'Low';
  }
  if (percent < 60) {
    return 'Medium';
  }
  if (percent < 75) {
    return 'High';
  }
  return 'Very High';
};

const KeywordOpportunitiesTable: React.FC<IKeywordOpportunitiesTableProps> = ({keywords, page, totalCount, onChange}) => {
  const history = useHistory();
  const {handleError} = useAuth();

  const handleExportKeywordEvent = async (keywordRecord: IOpportunityKeywordDTO) => {
    const keyword = keywordRecord.name;
    try {
      const response = await api.post<Keyword>('/api/keywords', {
        body: { keyword },
      });

      if (response && response.keyword_id) {
        const win = window.open(`/keywords?keyword_id=${response.keyword_id}`, '_blank');
        win?.focus();
      }
    } catch (err) {
      handleError(err, 'Error exporting keyword.')
    }
  }

  const handleClickEvent = async (keywordRecord: IOpportunityKeywordDTO) => {
    if (keywordRecord.keyword_id) {
      const win = window.open(`/keywords?keyword_id=${keywordRecord.keyword_id}`, '_blank');
      win?.focus();
    } else {
      await handleExportKeywordEvent(keywordRecord);
    }
  }

  const columns: ColumnType<IOpportunityKeywordDTO>[] = [
    {
      title: 'Niche',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 200,
      fixed: 'left',
      render: (value: string, row: any) => {
        return (
          <>
            <Link
              href={row.keyword_url}
              target="_blank"
            >
              <Text strong underline>
                {value}
              </Text>
              <Tooltip
                title='Search on Amazon'
              >
                <AmazonCircleFilled style={{ marginLeft: 7 }} />
              </Tooltip>
            </Link>
            <div style={{ cursor: 'pointer'}} onClick={async () => await handleClickEvent(row)}>
              <Tooltip
                title={
                  row.keyword_id
                  ? 'This keyword is already in the system, clicking here will allow you to edit the keyword'
                  : 'Export this keyword to the Keyword Manager'
                }
              >
                <Tag
                  icon={row.keyword_id ? ( <EditFilled /> ) : ( <ImportOutlined /> )}
                  color={row.keyword_id ? '#f5222d' : '#40a9ff'}
                >
                  { row.keyword_id ? 'Edit' : 'Export'}
                </Tag>
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: true,
      width: 160,
      fixed: 'left',
    },
    {
      title: 'Avg Mthly Units Sold',
      dataIndex: 'avgUnitsSold',
      key: 'avgUnitsSold',
      sorter: true,
      width: 140,
    },
    {
      title: 'Avg Mthly Price',
      dataIndex: 'avgPrice',
      key: 'avgPrice',
      sorter: true,
      render: (value: number) => {
        return formatCurrency(value);
      },
    },
    {
      title: 'Mthly Search Volume',
      dataIndex: 'estimatedExactSearchVolume',
      key: 'estimatedExactSearchVolume',
      sorter: true,
    },
    {
      title: '30-day Trend',
      dataIndex: 'monthlyTrend',
      key: 'monthlyTrend',
      sorter: true,
      render: (value: number) => {
        return (
          <Text type={value < 0 ? 'danger' : 'success'}>
            {value > 0 ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            )}
            {value}%
          </Text>
        );
      },
    },
    {
      title: '90-day Trend',
      dataIndex: 'quarterlyTrend',
      key: 'quarterlyTrend',
      sorter: true,
      render: (value: number) => {
        return (
          <Text type={value < 0 ? 'danger' : 'success'}>
            {value > 0 ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            )}
            {value}%
          </Text>
        );
      },
    },
    {
      title: 'Competition',
      dataIndex: 'competition',
      key: 'competition',
      sorter: true,
      render: (competition: number) => {
        return getScoreLabel(competition, 100);
      },
    },
    {
      title: 'Score',
      dataIndex: 'opportunityScore',
      key: 'opportunityScore',
      sorter: true,
      width: 120,
      fixed: 'right',
    },
    {
      title: 'Seasonality',
      dataIndex: 'classification5',
      key: 'classification5',
      sorter: true,
      render: (seasonality: number, row: any) => {
        const label = row.isSeasonal && seasonality > 2 ? row.peakWeek.split(' ')[0] : 'N/A';
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}>
            <Text>{getScoreLabel(seasonality, 5)}</Text>
            <Text type="secondary" style={{
              fontSize: 10
            }}>{label}</Text>
          </div>
        )
      },
      width: 140,
      fixed: 'right',
    },
  ]

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    const toOrder = sorter?.order;
    onChange(pagination.current, sorter.field, toOrder);
  }, []);

  return (
    <Table
      dataSource={keywords}
      rowKey={(item) => item.name}
      onChange={handleTableChange}
      pagination={{
        total: totalCount,
        pageSize: KEYWORDS_PER_PAGE,
        current: page
      }}
      expandedRowRender={(record) => <KeywordOpportunitiesChart name={record.name} asins={record.combinedAsins.map(item => item.asin)}/> }
      columns={columns}
    />
  );
}

export default KeywordOpportunitiesTable;
