import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { HomeOutlined } from '@ant-design/icons';
import { PageHeader } from 'antd';
import { useHeader } from '../hooks/header';
import { PermissionType } from '../services/auth';

export interface IActionSubmenu {
  label: string
  route?: string
  menu?: IActionSubmenu[]
  icon?: React.ReactElement
  permit?: PermissionType
  onClick?(): void
}

interface IHeaderPanelProps {
  title?: string | React.ReactNode
  backIcon?: React.ReactNode
  hideHome?: boolean
  subTitle?: string
  extra?: React.ReactElement
  actions?: IActionSubmenu[]
  showKeywordSearch?: boolean
}

const HeaderPanel: React.FC<IHeaderPanelProps> = ({
  title,
  backIcon,
  subTitle,
  hideHome,
  extra,
  actions = [],
  showKeywordSearch: localShowKeyword = false,
}) => {
  const { initSubHeader, setKeywordSearch } = useHeader();
  const [loaded, setLoaded] = useState(false);

  const myActions = useMemo(() => {
    if (!hideHome) {
      return [
        {
          icon: <HomeOutlined />,
          label: 'Home',
          route: '/',
        },
        ...actions,
      ];
    }
    return actions;
  }, [actions, hideHome]);

  const loadHeader = useCallback(() => {
    initSubHeader(myActions);
    setKeywordSearch(localShowKeyword);
  }, [initSubHeader, localShowKeyword, myActions, setKeywordSearch]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      loadHeader();
    }
  }, [loadHeader, loaded]);

  const titleContent = useMemo(() => {
    if (!title) {
      return undefined;
    }
    if (typeof title === 'string') {
      return (
        <PageHeader
            className="site-page-header"
            title={title}
            backIcon={backIcon}
            subTitle={subTitle}
            style={{
              padding: 0,
            }}
          />
      );
    }
    return title;
  }, [backIcon, subTitle, title]);

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {titleContent}
        {extra}
      </div>
    </>
  );
};

export default HeaderPanel;
