/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  List, Typography,
} from 'antd';

import {
  CloseCircleTwoTone,
  LoadingOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  ClockCircleTwoTone,
  QuestionCircleTwoTone,
  ToolTwoTone,
} from '@ant-design/icons';
import { IFunctionScan } from '../../../../shared/dtos/ScanDTO';

interface ItemScanProps {
  checking?: boolean
  refefence?: RefObject<any>
  iFunction: IFunctionScan
  runItem(functionId: number): Promise<void>
  autoFix(functionId: number, fix: string): Promise<void>
}

const { Link } = Typography;

const selectedOutStyle = {
  boxShadow: 'rgb(178 212 255) 0px 0px 15px 10px',
  borderRadius: 6,
};

const selectedInStyle = {
  margin: 10,
  borderRadius: 6,
};

const statusStyle = {
  width: 100,
};

const ItemScan: React.FC<ItemScanProps> = ({
  refefence, checking, iFunction, runItem, autoFix,
}) => {
  const [processing, setProcessing] = useState(false);

  const style = useMemo(() => {
    const st = (checking || processing) ? selectedOutStyle : {};
    return st;
  }, [checking, processing]);

  const handleExecuteItem = useCallback(async (): Promise<void> => {
    setProcessing(true);
    await runItem(iFunction.id);
    setProcessing(false);
  }, [iFunction.id, runItem]);

  const handleAutoFixItem = useCallback(async (fix = ''): Promise<void> => {
    setProcessing(true);
    await autoFix(iFunction.id, fix);
    setProcessing(false);
  }, [autoFix, iFunction.id]);

  const iconStatus = useCallback((status = 'default') => {
    let iconObject:[React.ForwardRefExoticComponent<any>, string] = [ClockCircleTwoTone, '#708090'];
    switch (status) {
      case 'error':
        iconObject = [CloseCircleTwoTone, '#dc143c'];
        break;
      case 'processing':
        iconObject = [LoadingOutlined, '#0000FF'];
        break;
      case 'success':
        iconObject = [CheckCircleTwoTone, '#52c41a'];
        break;
      case 'warning':
        iconObject = [ExclamationCircleTwoTone, '#ffa500'];
        break;
      default:
        iconObject = [ClockCircleTwoTone, '#708090'];
    }
    const ObjImage = iconObject[0];
    if (checking || processing) {
      return <LoadingOutlined spin style={{
        ...statusStyle,
        color: iconObject[1],
      }} />;
    }
    return <ObjImage onClick={handleExecuteItem} style={statusStyle} twoToneColor={iconObject[1]} />;
  }, [checking, handleExecuteItem, processing]);

  return (
    <div style={style} >
      <div style={selectedInStyle} ref={refefence}>
        <List.Item>
          <List.Item.Meta
            description={(
              <div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  <Link
                    href={`/api/scan/${iFunction.id}`}
                    target="_blank"
                    style={{
                      color: 'gray',
                      textDecoration: 'none',
                    }}
                    >
                    {iFunction.name}
                  </Link>
                  {iconStatus(iFunction.status)}
                </div>
                {(!checking && !processing) && iFunction.items?.map((item) => (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 6,
                  }}>
                    <span></span>
                    <span style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      fontSize: 12,
                    }}>
                      <span style={{
                        textAlign: 'end',
                      }}>
                        {item.label}: <strong>{item.value}</strong>
                        {item.tip && (
                          <span style={{
                            fontStyle: 'italic',
                          }}>
                            <br />
                            <QuestionCircleTwoTone style={{
                              marginRight: 3,
                            }} />
                            {item.tip}
                          </span>
                        )}
                        {item.fix && (
                          <span>
                            <br />
                            <Button
                              style={{ float: 'right' }}
                              onClick={() => handleAutoFixItem(item.fix)}
                              shape="round"
                              size="small"
                              icon={<ToolTwoTone twoToneColor="#7393B3" />}>
                              Auto-Fix
                            </Button>
                          </span>
                        )}
                      </span>
                      <span style={{ paddingLeft: 20 }}>
                        {iconStatus(item.status)}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            )} />
        </List.Item>
      </div>
    </div>
  );
};

export default ItemScan;
