import {
  LoadingOutlined,
  WarningTwoTone,
} from '@ant-design/icons';

import Text from 'antd/lib/typography/Text';
import Link from 'antd/lib/typography/Link';
import { Tooltip } from 'antd';
import { IAmazonArticleDTO } from '../../../../../shared/dtos/ArticleDTO';
import ComparisonCell from './comparison_cell';
import ComparisonImage from './comparison_image';
import { IArticleError } from '.';
import { DIMENSIONS } from './constants';

const { HEADER_HEIGHT } = DIMENSIONS;

interface ComparisonHeaderProps {
  article?: IAmazonArticleDTO
  loading: boolean
  error?: IArticleError
}

const titleStyles = {
  width: '100%',
  paddingLeft: 22,
  paddingRight: 22,
  fontFamily: 'sans-serif',
  fontSize: 18,
};

const ComparisonHeader: React.FC<ComparisonHeaderProps> = ({
  article, loading, error,
}) => {
  if (loading) {
    return (
      <ComparisonCell styles={{
        alignItems: 'center',
        justifyContent: 'center',
        height: HEADER_HEIGHT,
      }}>
        <LoadingOutlined style={{
          fontSize: 43,
          alignSelf: 'center',
          justifySelf: 'center',
        }} />
      </ComparisonCell>
    );
  }
  return (
    <ComparisonCell styles={{
      height: HEADER_HEIGHT,
    }}>
      {article && (
        <>
          <ComparisonImage
            height={100}
            width={180}
            src={article?.image} />
          <Tooltip title={article.title}>
            <Link
              ellipsis={true}
              target="_blank"
              href={article.url}
              className="App-Link"
              style={titleStyles}>
              {article.title}
            </Link>
          </Tooltip>
          <div>
            <Text
              style={{
                fontSize: 12,
              }}
              type="secondary">
              <img
                style={{
                  maxHeight: 20,
                  marginRight: 3,
                }}
                src={article.writerIcon} />
              {article.writer}
            </Text>
          </div>
        </>
      )}
      {error && (
        <span style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <WarningTwoTone
            style={{
              fontSize: 45,
            }}
            twoToneColor="#e50000" />
          <Text
            type={error ? 'danger' : undefined}
            style={titleStyles}>
            {error.message}
          </Text>
          {error.url && (
            <Link
              target="_blank"
              className="App-Link"
              href={error.url} >
              Click here to open the Amazon URL
            </Link>
          )}
        </span>
      )}
    </ComparisonCell>
  );
};

export default ComparisonHeader;
