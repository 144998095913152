import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  AutoComplete,
  Button,
  Input,
  Menu,
} from 'antd';
import {
  DownOutlined,
  MenuOutlined,
} from '@ant-design/icons';

import { useAuth } from '../hooks/auth';
import api from '../services/api';
import IPaginatedMetaQuery from '../../../shared/dtos/IPaginatedQuery';
import { Keyword } from '../../../shared/dtos';
import { IActionSubmenu } from './header_panel';

const { SubMenu } = Menu;

interface ITopSubmenuProps {
  actions?: IActionSubmenu[]
  showKeywordSearch?: boolean
  style?: React.CSSProperties;
  onClickRightMenu(): void
}

type OptionGroup = {
  value: string
  label: JSX.Element
};

const { Search } = Input;

const TopSubmenu: React.FC<ITopSubmenuProps> = ({
  actions, showKeywordSearch, style, onClickRightMenu,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [searchResults, setSearchResults] = useState<OptionGroup[]>([]);
  const [showRightMenu, setShowRightMenu] = useState(false);
  const { isPermitted } = useAuth();

  const handleScroll = useCallback(() => {
    setShowRightMenu(window.pageYOffset > 60);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const allowedKeys = useMemo(() => {
    if (actions) {
      return actions.filter((action) => {
        const perm = action.permit ? [action.permit] : [];
        return isPermitted(...perm);
      });
    }
    return [];
  }, [actions, isPermitted]);

  const selectedSubmenuIndex = useMemo(() => {
    const { pathname } = location;
    const reversed = [...allowedKeys].reverse();
    const selectedPath = reversed.find((key) => (key.route && pathname.startsWith(key.route)));
    if (!selectedPath) {
      return 0;
    }
    const selectedIndex = allowedKeys.findIndex((key) => key.route === selectedPath.route);
    if (selectedIndex === -1) {
      return 0;
    }
    return selectedIndex;
  }, [allowedKeys, location]);

  const handleItemClick = useCallback((action: IActionSubmenu) => {
    if (action.onClick) {
      action.onClick();
    }
  }, []);

  const keywordOptionGroup = (keywords: Keyword[]): OptionGroup[] => {
    const keywordOptions = keywords.map((keyword) => ({
      value: keyword.keyword,
      label: (
        <a
          href={`/keywords?keyword=${encodeURIComponent(keyword.keyword)}`}
          target={'_blank'}
        >
          <span>{keyword.keyword}</span>
        </a>
      ),
    }));
    return keywordOptions;
  };

  const handleKeywordSearch = useCallback(async (searchValue: string) => {
    const { data } = await api.get<IPaginatedMetaQuery<Keyword>>('/api/keywords', {
      params: {
        keyword: searchValue,
      },
    });
    setSearchResults(keywordOptionGroup(data));
  }, []);

  const handleClickSubmenu = useCallback((action: IActionSubmenu) => {
    history.push(action.route || '');
    handleItemClick(action);
  }, [handleItemClick, history]);

  const getSubmenuColors = useCallback((idx: number) => {
    if (idx === selectedSubmenuIndex) {
      return ['white', '#1e2632'];
    }
    return ['#c3cccc', undefined];
  }, [selectedSubmenuIndex]);

  const createMenuLink = useCallback((action: IActionSubmenu, idx: number) => {
    const colors = getSubmenuColors(idx);
    return (
      <Link
        key={action.label}
        style={{
          color: colors[0],
          backgroundColor: colors[1],
        }}
        to={action.route || ''}
        onClick={() => handleItemClick(action)}
      >
        {action.icon && (
          <span style={{ marginRight: 5 }}>
            {action.icon}
          </span>
        )}
        {action.label}
      </Link>
    );
  }, [getSubmenuColors, handleItemClick]);

  const createMenuDropdown = useCallback(({ menu = [], ...action }: IActionSubmenu, idx: number) => {
    const colors = getSubmenuColors(idx);
    return (
      <SubMenu key={`sub_${action.label}`} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 41,
      }} title={
          <a style={{
            color: colors[0],
            backgroundColor: colors[1],
          }}>
          {action.icon && (
            <span style={{ marginRight: 5 }}>
              {action.icon}
            </span>
          )}
          {action.label}
          <DownOutlined style={{ marginLeft: 6, fontSize: 10 }} />
        </a>
      } >
      {menu.map((menuAction) => (
          <Menu.Item
            key={`subAction_${menuAction.label}`}
            style={{
              minWidth: 160,
            }}
            onClick={() => handleClickSubmenu(menuAction)}>
            {menuAction.icon && (
              <span style={{ marginRight: 5 }}>
                {menuAction.icon}
              </span>
            )}
            {menuAction.label}
          </Menu.Item>
      ))}
      </SubMenu>
    );
  }, [getSubmenuColors, handleClickSubmenu]);

  return (
    <div style={style}>
      {allowedKeys.length > 0 && (
        <div className="App-SubHeader">
          <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="horizontal"
          >
            {allowedKeys?.map((action, idx) => (
              action.menu ? createMenuDropdown(action, idx) : (
                  <Menu.Item key={`menu_${action.label}`} >{createMenuLink(action, idx)}</Menu.Item>
              )
            ))}
        </Menu>
          <span style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            {showKeywordSearch && (
              <AutoComplete
                dropdownMatchSelectWidth={252}
                style={{ width: 300 }}
                options={searchResults}
                onSearch={handleKeywordSearch}
              >
                <Search style={{
                  minHeight: 38,
                }} placeholder='Search Already Used Keywords' />
              </AutoComplete>
            )}
            {showRightMenu ? (
              <Button
                onClick={onClickRightMenu}
                style={{
                  width: 50,
                  borderWidth: 0,
                  color: 'white',
                  backgroundColor: 'transparent',
                  transition: 'all 0.5s ease-in-out',
                }}>
                <MenuOutlined style={{
                  fontSize: 16,
                  color: 'white',
                }} />
              </Button>
            ) : (
              <span style={{ marginRight: 50 }}></span>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default TopSubmenu;
