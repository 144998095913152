import React, {
  ReactText,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Table,
  Tag,
  Button,
} from 'antd';

import { EditOutlined } from '@ant-design/icons';

import { ColumnsType } from 'antd/lib/table';
import Keyword, { Tag as TagType } from '../../../shared/dtos/KeywordDTO';
import { ISiteDTO } from '../../../shared/dtos/SiteDTO';
import ArticleTableCell from './Articles/article_table_cell';
import KeywordArticlesTableCell from './Articles/keyword_articles_table_cell';
import KeywordStats from './Stats/keyword_stats';
import { IArticle } from '../../../shared/dtos/ArticleDTO';
import { useAuth } from '../hooks/auth';
import { PERMISSIONS } from '../services/auth';

interface IKeywordTableProps {
  availableSites: ISiteDTO[]
  articles: {
    newArticles: IArticle[]
    sort?: string
    sortOrder?: string
    handleOpenNewArticleKeyword(keyword: Keyword, article?: IArticle, resubmit?: boolean): void
  }
  keywords: Keyword[]
  rowSelection: {
    selectedRowKeys: ReactText[],
    comments?: boolean,
    onChange: (selectedRowKeys: ReactText[]) => void
  }
  rowExpandable:{
    initialExpandableKeys: number[]
    comments?: boolean
  }
  onChange(
    sort?: string,
    sortOrder?: string
  ): void;
  handleKeywordEdit(keyword: Keyword): void
}

const KeywordTable: React.FC<IKeywordTableProps> = ({
  availableSites,
  keywords,
  articles,
  rowSelection,
  rowExpandable = {
    initialExpandableKeys: [],
    comments: false,
  },
  onChange,
  handleKeywordEdit,
}) => {
  const {
    newArticles, handleOpenNewArticleKeyword,
  } = articles;
  const [fullScreen, setFullScreen] = useState<number>();
  const [expanded, setExpanded] = useState<number[]>(() => rowExpandable.initialExpandableKeys);
  const { isPermitted } = useAuth();
  const siteById: { [key: number]: ISiteDTO } = {};
  availableSites.forEach((site) => {
    siteById[site.site_id] = site;
  });

  const canCreateArticle = useMemo(() => isPermitted(PERMISSIONS.CreateArticle), [isPermitted]);

  const columns = useMemo(() => [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      sorter: true,
    },
    {
      title: 'Targeted Article',
      sorter: true,
      dataIndex: 'articles',
      render: (_, keyword: Keyword) => (
        <>
          <KeywordArticlesTableCell keyword={keyword} articles={keyword.articles} />
        </>
      ),
    },
    {
      title: 'Article Requests',
      dataIndex: 'articles.status',
      sorter: true,
      render: (keyword_id: number, keyword: Keyword) => (
        <ArticleTableCell
          keyword={keyword}
          sort={articles.sort}
          sortOrder={articles.sortOrder}
          newArticles={newArticles.filter(({ keywords: iKeywords = [] }) => iKeywords.map((ik) => ik.keyword_id).includes(keyword.keyword_id))}
          handleOpenArticleModal={(article, resubmit) => handleOpenNewArticleKeyword(keyword, article, resubmit)} />
      ),
    },
    {
      title: 'Tracked',
      dataIndex: 'is_tracked',
      render: (isTracked: boolean) => {
        if (isTracked) {
          return (<Tag color='green'>Tracked</Tag>);
        }
        return (<Tag color='volcano'>Not Tracked</Tag>);
      },
      sorter: true,
    },
    {
      title: 'Sites',
      dataIndex: 'sites',
      render: (sites?: ISiteDTO[]) => {
        const tagElementArray = sites?.map(
          (site) => (
              <div key={site.site_id}>
                <img style={{ width: '18px' }}src={site.logo_url} />
                {' '}{site.display_name}
              </div>
          ),
        );
        return tagElementArray;
      },
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (tags?: TagType[]) => {
        const tagElementArray = tags?.map(
          (tag) => (<Tag key={tag.tag_id.toString()} id={tag.tag_id.toString()} color='green'>{tag.tag}</Tag>),
        );
        return tagElementArray;
      },
    },
    {
      dataIndex: 'actions',
      render: (value: string, keyword: Keyword) => (
          <Button
            onClick={() => handleKeywordEdit(keyword)}
            type="primary"
            shape="circle"
            disabled={!canCreateArticle}
            icon={<EditOutlined />}
          ></Button>
      ),
    },
  ] as ColumnsType<Keyword>,
  [
    articles.sort,
    articles.sortOrder,
    newArticles,
    handleOpenNewArticleKeyword,
    canCreateArticle,
    handleKeywordEdit,
  ]);

  const handleTableChange = useCallback((_, filters, sorter) => {
    const toOrder = sorter?.order ? sorter?.order.slice(0, -3) : undefined;
    onChange(sorter.field, toOrder);
  }, [onChange]);

  const handleStatFullscreen = useCallback((keywordId: number) => {
    setFullScreen(keywordId);
  }, []);

  const onExpand = useCallback((iExpand: boolean, record: Keyword) => {
    if (iExpand) {
      setExpanded((previous) => [...previous, record.keyword_id]);
    } else {
      setExpanded((previous) => previous.filter((pv) => pv !== record.keyword_id));
    }
  }, []);

  return (
    <>
      <Table
        columns={columns}
        dataSource={keywords}
        onChange={handleTableChange}
        pagination={false}
        rowKey={(item) => item.keyword_id}
        rowSelection={canCreateArticle ? rowSelection : undefined}
        expandable={{
          expandedRowKeys: expanded,
          onExpand: (exp, rec) => onExpand(exp, rec),
          expandedRowRender: (record) => (
            <KeywordStats
              initialTab={rowExpandable.comments ? 'comments' : 'sov'}
              isFullscreen={fullScreen === record.keyword_id}
              handleFullscreen={handleStatFullscreen}
              keywordId={record.keyword_id} />
          ),
        }}
      />
    </>
  );
};

export default KeywordTable;
