import React, { useMemo } from 'react';

import {
} from 'antd';
import Icon from '@ant-design/icons';

import asanaLogoSvg from '../../assets/asana-logo.svg';

interface AsanaIconProps {
  size?: number
}

const AsanaIcon: React.FC<AsanaIconProps> = ({ size = 23 }: AsanaIconProps) => {
  const AsanaSvg = useMemo(() => (<img src={asanaLogoSvg} width={size} alt="" />), [size]);

  return (
    <Icon component={() => AsanaSvg} />
  );
};

export default AsanaIcon;
