import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Line } from 'react-chartjs-2';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface IArticleRevenueChartProps {
  articleTitle: string
}

interface IArticleData {
  EARNINGS: number
  WEEK_START_DATE: Date
}

const articleRevenueChartConfig = {
  label: 'Article Revenue History',
  yKey: 'avgPrice',
  xKey: 'date',
};

const KeywordOpportunitiesChart: React.FC<IArticleRevenueChartProps> = ({
  articleTitle,
}: IArticleRevenueChartProps) => {
  const { handleError } = useAuth();
  const [articleData, setArticleData] = useState<IArticleData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadArticleRevenue = useCallback(async (): Promise<void> => {
    try {
      const articleRevenueData = await api.get<IArticleData[]>(
        `/api/snowflake/article-revenue?articleTitle=${encodeURIComponent(articleTitle)}`,
      );
      setArticleData(articleRevenueData);
      setIsLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the graph data.', true);
    }
  }, [articleTitle, handleError]);

  useEffect(() => {
    loadArticleRevenue();
  }, [loadArticleRevenue]);

  return (
    <div style={{ boxShadow: 'rgb(178 212 255) 0px 0px 15px 10px' }}>
      {
        isLoading && (
          <div style={{
            fontSize: 54,
            color: 'rgba(65,145,247,1)',
            padding: 50,
            textAlign: 'center',
          }}>
            <LoadingOutlined />
          </div>
        )
      }
      {
        !isLoading && (
          <Line
            type={'vertical'}
            style={{ maxHeight: '500px' }}
            data={{
              labels: articleData && articleData.map((data) => data.WEEK_START_DATE),
              datasets: [
                {
                  label: articleRevenueChartConfig.label,
                  data: articleData && articleData.map((data) => data.EARNINGS),
                  backgroundColor: 'rgba(65,145,247,1)',
                  borderColor: 'rgba(65,145,247,1)',
                },
              ],
            }}
          />
        )
      }
    </div>
  );
};

export default KeywordOpportunitiesChart;
