import api from '../services/api';
import { IFeatureFlagDTO } from '../../../shared/dtos/FeatureFlagDTO';

interface FeatureFlag {
  [key: string]: string | boolean
}

const featureFlags: FeatureFlag = {};

(async () => {
  try {
    const featureFlagResponse = await api.get<IFeatureFlagDTO[]>('/api/feature-flags');

    featureFlagResponse.forEach((featureFlag: IFeatureFlagDTO) => {
      let value;

      if (featureFlag.type === 'bool') {
        value = featureFlag.value === '1' || featureFlag.value === 'true';
      } else {
        value = featureFlag.value;
      }

      featureFlags[featureFlag.feature_flag] = value;
    });
  // eslint-disable-next-line no-empty
  } catch (err) {}
})();

export default featureFlags;
