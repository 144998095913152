import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Select,
  Table,
  Tag,
  Typography,
  Pagination,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';

import Search from 'antd/lib/input/Search';
import { format } from 'date-fns';
import {
  DownloadOutlined,
  FlagOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import HeaderPanel from '../../components/header_panel';
import { keywordSubmenuActions } from '../keywords/keywords_summary';
import { ISiteDTO } from '../../../../shared/dtos/SiteDTO';
import ContentProductTable from '../../components/Content/product_table';
import ArticleRevenueChart from '../../components/Content/article_revenue_chart';
import ExportModal from '../../components/ExportModal';
import { IExportDTO } from '../../../../shared/dtos/ExportDTO';

interface IArticleFilters {
  title?: string
  site?: string
  page: number
  sort?: string
  sortOrder?: string
}

interface IContent {
  ARTICLE_URL: string
  EARNINGS: number
  FLAGS: string[]
  MODIFIED_DATE: string
  PERMALINK: string
  POST_DATE: string
  POST_ID: number
  POST_STATUS: string
  POST_TITLE: string
  PRODUCT_LIST: string[]
  SITE: string
  notes?: string
  asanaUrl?: string
  asanaStatus?: string
  asanaPriority?: string
}

interface IArticleResponse {
  edges: IContent[]
  pageInfo: {
    pageCount: number
    pageSize: number
    total: number
  }
}

interface CustomFields {
  name: string
  display_value: string
}

interface Memberships {
  section: {
    name: string
  }
}

interface TaskType {
  custom_fields: CustomFields[]
  memberships: Memberships[]
  permalink_url: string
  notes: string
}

const { Option } = Select;
const { Link } = Typography;

const EARNINGS = 'EARNINGS';
const PRODUCTS = 'PRODUCTS';
const NOTES = 'NOTES';

const PRIORITY_COLOR = {
  HIGH: '#5A9877',
  MEDIUM: '#E28267',
  LOW: '#E55F61',
};

const ContentTracker: React.FC = () => {
  const { handleError } = useAuth();
  const [articleData, setArticleData] = useState<IContent[]>();
  const [articleTotal, setArticleTotal] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [siteData, setSiteData] = useState<ISiteDTO[]>([]);
  const [siteFilter, setSiteFilter] = useState<string>();
  const [titleFilter, setTitleFilter] = useState<string>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [sort, setSort] = useState('EARNINGS');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showFlags, setShowFlags] = useState(false);
  const [showExpandedRow, setShowExpandedRow] = useState<{ [key: string]: 'EARNINGS' | 'PRODUCTS' | 'NOTES' }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAsana, setLoadingAsana] = useState(true);
  const [showExport, setShowExport] = useState<boolean>();

  const handlePrepareDowload = useCallback(async () => {
    const params: IArticleFilters = { page: currentPage };
    if (siteFilter) {
      params.site = siteFilter;
    }

    if (titleFilter) {
      params.title = titleFilter;
    }

    if (sort) {
      params.sort = sort;
    }

    if (sortOrder) {
      params.sortOrder = sortOrder;
    }

    const result = api.get<IExportDTO>('/api/articles/content-tracker.csv', {
      params,
    });

    return result;
  }, [currentPage, siteFilter, titleFilter, sort, sortOrder]);

  const handleExportReport = useCallback(() => {
    setShowExport(true);
  }, []);

  const fetchSiteData = useCallback(async () => {
    try {
      const results = await api.get<ISiteDTO[]>('/api/sites');
      setSiteData(results);
    } catch (e) {
      handleError(e);
    }
  }, [handleError]);

  const fetchArticleData = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoadingAsana(true);
      const params: IArticleFilters = { page: currentPage };
      if (siteFilter) {
        params.site = siteFilter;
      }

      if (titleFilter) {
        params.title = titleFilter;
      }

      if (sort) {
        params.sort = sort;
      }

      if (sortOrder) {
        params.sortOrder = sortOrder;
      }

      const results = await api.get<IArticleResponse>(
        '/api/snowflake/content', {
          params,
        },
      );
      setArticleTotal(results?.pageInfo?.total);
      setArticleData(results.edges);
      setIsLoading(false);

      const articleTitles = results.edges.map((articles) => encodeURIComponent(articles.POST_TITLE));
      const asanaData = await api.get<TaskType[]>(
        `/api/articles/asana-statuses?articleTitles[]=${articleTitles.join('&articleTitles[]=')}`,
      );

      asanaData.forEach((asanaTask, index: number) => {
        if (asanaTask) {
          const notes = asanaTask?.notes;
          const status = asanaTask && asanaTask?.memberships[0]?.section?.name;
          const asanaUrl = asanaTask?.permalink_url;
          const customFields = asanaTask?.custom_fields;
          const asanaPriority = customFields && customFields.find((field) => field.name === 'Update Level')?.display_value;
          results.edges[index].notes = notes;
          results.edges[index].asanaStatus = status;
          results.edges[index].asanaUrl = asanaUrl;
          results.edges[index].asanaPriority = asanaPriority;
        }
      });

      setArticleData(results.edges);
      setLoadingAsana(false);
    } catch (e) {
      setIsLoading(false);
      handleError(e);
    }
  }, [currentPage, handleError, siteFilter, sort, sortOrder, titleFilter]);

  useEffect(() => {
    fetchSiteData();
    fetchArticleData();
  }, [fetchArticleData, fetchSiteData]);

  const handleSearchEnter = useCallback((event) => {
    setTitleFilter(event.target.value);
  }, []);

  const handleSiteSelection = useCallback((site) => {
    setSiteFilter(site);
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleChangeQuery = useCallback((_pagination, _filter, sorter) => {
    const sortOrderValue = sorter.order ? sorter.order.slice(0, -3) : 'desc';
    const sortValue = sorter.columnKey;
    setSort(sortValue);
    setSortOrder(sortOrderValue);
  }, []);

  const handleProductButtonClick = useCallback((articleId) => {
    const tempShowExpandedRow = showExpandedRow;
    tempShowExpandedRow[articleId] = PRODUCTS;
    setShowExpandedRow(tempShowExpandedRow);

    if (expandedRowKeys.indexOf(articleId) === -1) {
      const updateExpandedRowKeys = [
        ...expandedRowKeys,
        articleId,
      ];
      setShowFlags(false);
      setExpandedRowKeys(updateExpandedRowKeys);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== articleId));
    }
  }, [expandedRowKeys, showExpandedRow]);

  const handleEarningsClick = useCallback((record: IContent) => {
    const articleKey = `${record.POST_ID}-${record.SITE}`;
    const tempShowEarnings = showExpandedRow;
    tempShowEarnings[articleKey] = EARNINGS;

    if (expandedRowKeys.indexOf(articleKey) === -1) {
      const updateExpandedRowKeys = [
        ...expandedRowKeys,
        articleKey,
      ];
      setShowFlags(false);
      setShowExpandedRow(tempShowEarnings);
      setExpandedRowKeys(updateExpandedRowKeys);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== articleKey));
    }
  }, [expandedRowKeys, showExpandedRow]);

  const handleFlagsButtonClick = useCallback((articleId) => {
    const tempShowEarnings = showExpandedRow;
    tempShowEarnings[articleId] = PRODUCTS;
    setShowExpandedRow(tempShowEarnings);

    if (expandedRowKeys.indexOf(articleId) === -1) {
      const updateExpandedRowKeys = [
        ...expandedRowKeys,
        articleId,
      ];
      setShowFlags(true);
      setExpandedRowKeys(updateExpandedRowKeys);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== articleId));
    }
  }, [expandedRowKeys, showExpandedRow]);

  const handleInfoIconClick = useCallback((articleId) => {
    const tempShowEarnings = showExpandedRow;
    tempShowEarnings[articleId] = NOTES;
    setShowExpandedRow(tempShowEarnings);

    if (expandedRowKeys.indexOf(articleId) === -1) {
      const updateExpandedRowKeys = [
        ...expandedRowKeys,
        articleId,
      ];
      setShowFlags(false);
      setExpandedRowKeys(updateExpandedRowKeys);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((item) => item !== articleId));
    }
  }, [expandedRowKeys, showExpandedRow]);

  const columns = [
    {
      title: 'Title',
      key: 'CONTENT.POST_TITLE',
      sorter: true,
      render: (article: IContent) => (
        <div>
          <Link
            style={{ color: 'black', fontWeight: 'bold' }}
            onClick={() => {
              if (article.notes) {
                handleInfoIconClick(`${article.POST_ID}-${article.SITE}`);
              }
            }}
            href={article.notes ? undefined : article.ARTICLE_URL}
            target={'_blank'}
          >
            {article.POST_TITLE}
          </Link>
          <div
            style={{
              float: 'right',
            }}
          >
            <span>
              <Link
                href={article.ARTICLE_URL}
                target={'_blank'}
              >
                <img src={'./amazon.png'} width={20} style={{ padding: '4px 4px 0 0' }}/>
              </Link>
            </span>
            <span>
              <Link
                href={`${new URL(article.PERMALINK).origin}/wp-admin/post.php?post=${article.POST_ID}&action=edit`}
                target={'_blank'}
              >
                <img src={'./wordpress.png'} width={20} />
              </Link>
            </span>
            {
              article.asanaUrl && (
                <>
                  <span>
                    <Link
                      href={article.asanaUrl}
                      target={'_blank'}
                    >
                      <img src={'./asana.png'} width={20} />
                    </Link>
                  </span>
                </>
              )
            }
          </div>
        </div>
      ),
    },
    {
      title: 'Site',
      key: 'SITE',
      dataIndex: 'SITE',
    },
    {
      title: 'Published Date',
      key: 'CONTENT.POST_DATE',
      sorter: true,
      render: (record: IContent) => (

        <span>{format(new Date(record.POST_DATE), 'yyyy-MM-dd')}</span>
      ),
    },
    {
      title: 'Priority',
      key: 'asanaPriority',
      render: (record: IContent) => {
        if (loadingAsana) {
          return (<LoadingOutlined />);
        }

        const priority = record.asanaPriority;

        if (priority) {
          // eslint-disable-next-line no-nested-ternary
          const color = priority === 'High'
            ? PRIORITY_COLOR.HIGH
            : (
              priority === 'Medium'
                ? PRIORITY_COLOR.MEDIUM
                : PRIORITY_COLOR.LOW
            );

          return (
            <Tag
              color={color}
            >
              {record.asanaPriority}
            </Tag>
          );
        }

        return null;
      },
    },
    {
      title: 'Status',
      key: 'asanaStatus',
      render: (record: IContent) => {
        if (loadingAsana) {
          return (<LoadingOutlined />);
        }

        const { asanaStatus } = record;
        if (asanaStatus) {
          return (
            <span>{asanaStatus}</span>
          );
        }

        return null;
      },
    },
    {
      title: 'Products',
      key: 'PRODUCT_LIST',
      sorter: true,
      render: (record: IContent) => {
        const products = record.PRODUCT_LIST;

        return (
          products?.length
            ? (
              <Button
                danger={!!record.FLAGS}
                shape={'round'}
                onClick={() => { handleProductButtonClick(`${record.POST_ID}-${record.SITE}`); }}
              >
                <b>{products.length}{' '}</b>Products
              </Button>
            )
            : (
              <Button shape={'round'}>No Products</Button>
            )
        );
      },
    },
    {
      title: 'Earnings',
      key: 'EARNINGS',
      dateIndex: 'EARNINGS',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (record: IContent) => (
        <Button
          type={'dashed'}
          shape={'round'}
          onClick={() => handleEarningsClick(record)}
        >
          {`$${record.EARNINGS || '0.00'}`}
        </Button>
      ),
    },
    {
      title: 'Flags',
      key: 'FLAGS',
      sorter: true,
      render: (article: IContent) => {
        const flags = article.FLAGS;
        return (
          flags
            ? (
              <div
                onClick={() => { handleFlagsButtonClick(`${article.POST_ID}-${article.SITE}`); }}
              >
                <Badge
                  count={flags}
                  size={'small'}
                >
                  <FlagOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                </Badge>
              </div>
            )
            : null
        );
      },
    },
  ];

  // eslint-disable-next-line consistent-return
  const expandedRowRender = useCallback((record: IContent) => {
    const articleKey = `${record.POST_ID}-${record.SITE}`;
    if (showExpandedRow[articleKey] === EARNINGS) {
      return (<ArticleRevenueChart articleTitle={record.POST_TITLE} />);
    }

    if (showExpandedRow[articleKey] === NOTES) {
      return (<pre>{record.notes}</pre>);
    }

    return (<ContentProductTable record={record} showFlags={showFlags} />);
  }, [showExpandedRow, showFlags]);

  return (
    <>
      <HeaderPanel
        hideHome={true}
        actions={keywordSubmenuActions}
      />
      <div>
        <div style={{ marginBottom: '16px' }}>
            <Search
              style={{
                width: '400px',
              }}
              onPressEnter={handleSearchEnter}
              placeholder={'Search By Article Title'}
            />
            <div style={{ float: 'right' }}>
            <Button
              type="default"
              shape="round"
              icon={<DownloadOutlined />}
              onClick={handleExportReport}>
              CSV Export
            </Button>
            <ExportModal
              visible={showExport}
              fileName='content_tracker.csv'
              prepareCall={handlePrepareDowload}
              title="Export Content Tracker"
              onCancel={() => setShowExport(false)}
            />
              <Select
                style={{ width: '160px', marginLeft: 10 }}
                onChange={handleSiteSelection}
                placeholder={'Site'}
              >
                {
                  siteData.map((site) => (
                    <Option value={site.display_name} key={site.site_id}>
                      <img src={site.logo_url} width={12} style={{ marginRight: '8px' }} />
                      {site.display_name}
                    </Option>
                  ))
                }
              </Select>
            </div>
        </div>
        <Table
          columns={columns as ColumnsType<IContent>}
          rowKey={(article) => `${article.POST_ID}-${article.SITE}`}
          dataSource={articleData}
          onChange={handleChangeQuery}
          pagination={false}
          expandedRowKeys={expandedRowKeys}
          expandedRowRender={expandedRowRender}
          expandIconColumnIndex={-1}
          loading={isLoading}
        />
        <div style={{ paddingTop: '12px' }}>
          <Pagination
            total={articleTotal}
            current={currentPage}
            onChange={handlePageChange}
            showSizeChanger={false}
            pageSize={20}
          />
        </div>
      </div>
    </>
  );
};

export default ContentTracker;
