/* eslint-disable consistent-return */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Card,
  Upload,
  Button,
  Alert,
  Typography,
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import KeywordDTO, { IKeywordsImportSummaryDTO } from '../../../../shared/dtos/KeywordDTO';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import HeaderPanel from '../../components/header_panel';
import { keywordSubmenuActions } from './keywords_summary';

const { Text } = Typography;

interface IUploadResult {
  summary: IKeywordsImportSummaryDTO,
  keywords: KeywordDTO[];
}

const KeywordsUpload: React.FC = () => {
  const { handleError } = useAuth();
  const [fileUpload, setFileUpload] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<IKeywordsImportSummaryDTO | undefined>();

  const beforeUpload = useCallback((file): boolean => {
    setFileUpload(file);
    return false;
  }, []);

  const submitFile = useCallback(async (): Promise<void> => {
    if (fileUpload) {
      setLoading(true);
      try {
        const form = new FormData();

        form.append('file', fileUpload);

        const result = await api.post<IUploadResult>('/api/keywords/upload', {
          body: form,
        });
        setSummary(result.summary);
      } catch (err) {
        handleError(err, 'Keywords could not be searched.');
      } finally {
        setLoading(false);
      }
    }
  }, [fileUpload, handleError]);

  useEffect(() => {
    submitFile();
  }, [fileUpload, submitFile]);

  const summaryQuotes = useMemo(() => {
    if (summary) {
      const { added } = summary;
      let addedQuote = 'No keywords imported at this time.';
      if (added > 0) {
        addedQuote = added === 1 ? 'One imported keyword' : `${added} imported keyword`;
      }

      const { repeated } = summary;
      let repeatedQuote = 'No repeated keywords.';
      if (repeated > 0) {
        repeatedQuote = repeated === 1 ? 'One repeated keyword received' : `${repeated} repeated keywords`;
      }
      return {
        added: addedQuote,
        repeated: repeatedQuote,
      };
    }
  }, [summary]);

  return (
    <>
      <HeaderPanel
        hideHome
        title="Keywords Upload"
        subTitle="Importing keywords from a CSV file"
        actions={keywordSubmenuActions} />

      <Card
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        className='App-form'
      >
        <Text style={{
          marginRight: 5,
        }}>Select the CSV file: </Text>
        <Upload
          name='file'
          accept='.csv'
          beforeUpload={(file) => beforeUpload(file)}
          showUploadList={false}
        >
          <Button
            loading={loading}
            disabled={loading}
            icon={<UploadOutlined />}>
            {loading ? 'Uploading' : 'Upload'}
          </Button>
        </Upload>
        {summary && (
          <div style={{
            width: 400,
          }}>
            <Alert
              message={summaryQuotes?.added}
              description={summaryQuotes?.repeated}
              type="info"
              style={{
                marginTop: 10,
              }}
              showIcon
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default KeywordsUpload;
