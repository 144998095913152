import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  Table,
  message,
} from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import api from '../../services/api';
import HeaderPanel from '../../components/header_panel';
import GoogleSheet from '../../../../shared/dtos/GoogleSheetDTO';
import googleSheetIdImage from '../../assets/google_sheet_id.png';
import asanaBoardIdImage from '../../assets/asana_board_id.png';

const { Link } = Typography;
const { Option } = Select;

const GoogeSheetManager: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAsanaHelp, setShowAsanaHelp] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [googleSheets, setGoogleSheets] = useState<GoogleSheet[]>([]);
  const [showGoogleSheetHelp, setShowGoogleSheetHelp] = useState(false);

  const loadGoogleSheets = useCallback(async () => {
    const googleSheetsData = await api.get<GoogleSheet[]>('/api/google-sheets');
    setGoogleSheets(googleSheetsData);
  }, []);

  const submitNewGoogleSheet = useCallback(async () => {
    try {
      const formData = form.getFieldsValue();
      await api.post('/api/google-sheets', { body: formData });
      await loadGoogleSheets();
      form.resetFields();
      setShowAsanaHelp(false);
      setShowGoogleSheetHelp(false);
      setShowFormModal(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  }, [form, loadGoogleSheets]);

  const removeGoogleSheet = useCallback(async (googleSheetTableId: number) => {
    await api.delete(`/api/google-sheets/${googleSheetTableId}`);
    loadGoogleSheets();
  }, [loadGoogleSheets]);

  const syncGoogleSheet = useCallback(async (googleSheetTableId: number) => {
    message.loading({ content: 'Syncing Google Sheet', key: 'updatable', duration: 10 });
    await api.post(`/api/google-sheets/sync/${googleSheetTableId}`, {});
    message.success({ content: 'Success', key: 'updatable', duration: 3 });
  }, []);

  const columns = [
    {
      title: 'Google Sheet ID',
      dataIndex: 'google_sheet_id',
      key: 'google_sheet_id',
      render: (sheetId: string) => (
        <Link href={`https://docs.google.com/spreadsheets/d/${sheetId}/edit#gid=0`}>
          {sheetId}
        </Link>
      ),
    },
    {
      title: 'Site ID',
      dataIndex: 'site_id',
      key: 'site_id',
    },
    {
      title: 'Asana Board ID',
      dataIndex: 'asana_board_id',
      key: 'asana_board_id',
      render: (boardId: string) => (
        <Link href={`https://app.asana.com/0/${boardId}/board`}>
          {boardId}
        </Link>
      ),
    },
    {
      title: 'Action',
      render: (record: GoogleSheet) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={{ width: '40px', marginRight: '20px' }}>
            <Button
              onClick={async () => {
                await syncGoogleSheet(record.google_sheet_table_id);
              }}
              type='primary'
            >
              <SyncOutlined />
            </Button>
          </div>
          <div style={{ width: '40px' }}>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Are you sure?',
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  onOk: async () => {
                    await removeGoogleSheet(record.google_sheet_table_id);
                  },
                });
              }}
              type='primary'
              danger
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const handleCancel = useCallback(() => {
    form.resetFields();
    setShowFormModal(false);
  }, [form]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await submitNewGoogleSheet();
    setLoading(false);
  }, [submitNewGoogleSheet]);

  useEffect(() => {
    loadGoogleSheets();
  }, [loadGoogleSheets]);

  return (
    <>
      <HeaderPanel
        title="Google Sheet Manager"
      />
      <Modal
        visible={showFormModal}
        title={'Create New Google Sheet Record'}
        width={'800px'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
        ]}
      >
        {
          errorMessage && (
            <div
              style={{
                textAlign: 'center',
                paddingBottom: '20px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div style={{ width: '40px' }}>
                <ExclamationCircleOutlined />
              </div>
              <div>
                {errorMessage}
              </div>
              <div style={{ width: '40px' }}>
                <ExclamationCircleOutlined />
              </div>
            </div>
          )
        }
        <Form form={form}>
          <Form.Item required={true} label='Site ID' name='siteId'>
            <Select placeholder='Please Select A Site'>
              <Option value='DT'>DigitalTrends</Option>
              <Option value='TM'>The Manual</Option>
              <Option value='TA'>The Angle</Option>
              <Option value='OK'>21Oak</Option>
              <Option value='TJ'>ToughJobs</Option>
              <Option value='NF'>NewFolks</Option>
              <Option value='HS'>HappySprout</Option>
              <Option value='BM'>BlissMark</Option>
              <Option value='PT'>PawTracks</Option>
            </Select>
          </Form.Item>
          <div style={{
            display: showGoogleSheetHelp ? 'block' : 'none',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}>
            The Google Sheet ID can be found in the URL.
            <img width={'100%'} src={googleSheetIdImage} />
            <br />
            <br />
            In order for the Sync Service to work, you must share the Google Sheet with our Service Account and grant it 'Editor' permissions.{' '}
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>Service Account Email:</span>
            <br />
            <code style={{ backgroundColor: '#eee' }}>asana-google-sheet-service-acc@asana-sync-350018.iam.gserviceaccount.com</code>
          </div>
          <a onClick={() => setShowGoogleSheetHelp(!showGoogleSheetHelp)} style={{ color: '#4091F7' }}><QuestionCircleOutlined />{' '}Authentication Steps</a>
          <Form.Item required={true} label={'Google Sheet ID'} name='googleSheetId'>
            <Input />
          </Form.Item>
          <div style={{
            display: showAsanaHelp ? 'block' : 'none',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}>
            Additionally, the Asana Board ID can be found in the URL.
            <img width={'100%'} src={asanaBoardIdImage} />
            <br />
            <br />
            Furthermore, you must add our Asana Bot to the board you wish to sync.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>Asana Bot Email:</span>
            <br/>
            <code style={{ backgroundColor: '#eee' }}>aop.digitaltrends@gmail.com</code>
          </div>
          <a onClick={() => setShowAsanaHelp(!showAsanaHelp)} style={{ color: '#4091F7' }}><QuestionCircleOutlined />{' '}Authentication Steps</a>
          <Form.Item required={true} label="Asana Board ID" name='asanaBoardId'>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type='primary'
        style={{ marginBottom: '20px', marginTop: '20px' }}
        onClick={() => setShowFormModal(true)}
      >
        Add New Google Sheet
      </Button>
      <Table
        columns={columns}
        dataSource={googleSheets}
        pagination={false}
      />
    </>
  );
};

export default GoogeSheetManager;
