import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  notification,
  Modal,
} from 'antd';

import { LockOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import { useAuth } from '../../hooks/auth';
import HeaderPanel from '../../components/header_panel';
import ChangePasswordModal from '../../components/change_password_modal';
import UserDetails from '../../components/Users/user_details';

const Profile: React.FC = () => {
  const { user } = useAuth();
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleEditProfile = useCallback(() => {
    Modal.warning({
      title: 'Edit Profile',
      content: 'Sorry, Under Construction.',
    });
  }, []);

  const handleChangePassword = useCallback(() => {
    setShowChangePassword(true);
  }, []);

  const handlePasswordChanged = useCallback(() => {
    setShowChangePassword(false);
    notification.success({
      message: 'Password Changed',
      description:
        'Your password has been successfully changed.',
    });
  }, []);

  const actions = useMemo(() => [
    {
      label: 'My Profile',
      route: '/profile',
    },
    {
      label: 'Edit Profile',
      route: '/profile/edit',
      onClick: () => handleEditProfile(),
    },
  ], [handleEditProfile]);

  return (
    <>
      <HeaderPanel
        title="My Profile"
        actions={actions}
        extra={(
          <Button
            shape="round"
            icon={<LockOutlined />}
            onClick={handleChangePassword}
            type="primary">
            Change Password
          </Button>
        )}
        />

      <UserDetails
        id={user.id} />

      <ChangePasswordModal
        visible={showChangePassword}
        onClose={() => setShowChangePassword(false)}
        onSuccess={handlePasswordChanged}
       />
    </>
  );
};

export default Profile;
