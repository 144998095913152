import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Modal,
  Form,
} from 'antd';

import TextArea from 'antd/lib/input/TextArea';
import { IArticle } from '../../../../shared/dtos/ArticleDTO';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

export interface IArticleReasonCommand {
  article: IArticle
  command: 'reject' | 'return'
}

interface ArticleDetailsModalProps {
  articleCommand?: IArticleReasonCommand
  onClose(): void
  onFinish(saved: IArticle): void
}

interface IFormReason {
  reason: string
}

const ArticleReasonModal: React.FC<ArticleDetailsModalProps> = ({
  articleCommand, onClose, onFinish,
}) => {
  const { handleError } = useAuth();
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async ({ reason }: IFormReason) => {
    if (!articleCommand) {
      return;
    }
    try {
      setSaving(true);
      const validatedArticle = await api.patch<IArticle>(`/api/articles/validate/${articleCommand.article.article_id}`, {
        body: {
          command: articleCommand.command,
          reason,
        },
      });
      onFinish(validatedArticle);
    } catch (err) {
      handleError(err, 'Error on saving the reason.');
    } finally {
      setSaving(false);
    }
  }, [handleError, onFinish, articleCommand]);

  const title = useMemo(() => {
    if (articleCommand) {
      if (articleCommand.command === 'reject') {
        return 'Rejecting article request';
      }
      if (articleCommand.command === 'return') {
        return 'Returning article request';
      }
    }
    return undefined;
  }, [articleCommand]);

  const description = useMemo(() => {
    if (articleCommand) {
      if (articleCommand.command === 'reject') {
        return 'Please type the reason you are rejecting this article:';
      }
      if (articleCommand.command === 'return') {
        return 'Please type the reason you are returning this article:';
      }
    }
    return undefined;
  }, [articleCommand]);

  const submitName = useMemo(() => {
    if (articleCommand) {
      if (articleCommand.command === 'reject') {
        return 'Confirm rejection';
      }
      if (articleCommand.command === 'return') {
        return 'Confirm returning';
      }
    }
    return undefined;
  }, [articleCommand]);

  useEffect(() => {
    if (articleCommand) {
      form.resetFields();
    }
  }, [form, articleCommand]);

  return (
    <Modal
        title={title}
        visible={!!articleCommand}
        onOk={() => form.submit()}
        okText={submitName}
        confirmLoading={saving}
        onCancel={() => onClose()}
      >
      <Form
        form={form}
        onFinish={handleSubmit}
        name='reasonForm'
        layout="vertical"
        className='App-form'
      >
        <Form.Item
          label={description}
          name="reason"
          rules={[{ required: true, message: 'Missing reason' }]}>
          <TextArea rows={2} />
        </Form.Item>
      </Form>

    </Modal>
  );
};

export default ArticleReasonModal;
