import {
  useCallback,
  useMemo,
  memo,
} from 'react';

import {
  Modal,
  Menu,
} from 'antd';

import {
  UsergroupAddOutlined,
  UserOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  BarsOutlined,
  ToolOutlined,
  GoldOutlined,
  FlagOutlined,
  ScanOutlined,
  ShoppingOutlined,
  StarOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  AuditOutlined,
  GoogleOutlined,
  SnippetsOutlined,
  SearchOutlined,
  ContainerOutlined,
  FileSearchOutlined,
  HddOutlined,
  RobotOutlined,
  BulbOutlined,
  UploadOutlined,
  SelectOutlined,
} from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useAuth } from '../../hooks/auth';
import {
  PERMISSIONS, PermissionType,
} from '../../services/auth';

interface ISideMenuProps {
  onCloseMenu(): void
}

interface IMenuItem {
  onClick(): void
  text: string
  icon: React.ReactElement
  permit?: PermissionType
}

const SideMenu: React.FC<ISideMenuProps> = ({ onCloseMenu }) => {
  const history = useHistory();

  const { signOut, isPermitted } = useAuth();

  const handleRoute = useCallback((route: string) => {
    history.push(route);
    onCloseMenu();
  }, [history, onCloseMenu]);

  const handleLogout = useCallback(() => {
    Modal.confirm({
      title: 'Signing Out?',
      content: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, I am!',
      onOk() {
        signOut();
      },
    });
  }, [signOut]);

  const getMenuItem = useCallback(({
    onClick, icon, text, permit,
  }: IMenuItem) => {
    const per = permit ? [permit] : [];
    if (!isPermitted(...per)) {
      return <></>;
    }
    return (
      <Menu.Item
        onClick={() => {
          onClick();
          onCloseMenu();
        }}
        icon={icon}>{text}</Menu.Item>
    );
  }, [isPermitted, onCloseMenu]);

  const canAdmin = useMemo(() => {
    const permitted = isPermitted(PERMISSIONS.ManageSites, PERMISSIONS.ManageUsers, PERMISSIONS.ManageFeatureFlags);
    return permitted;
  }, [isPermitted]);

  return (
    <Menu mode="inline" style={{ width: '100%' }}>
        {getMenuItem({
          onClick: () => handleRoute('/gsc-keywords'),
          icon: <ShoppingOutlined />,
          text: 'GSC Keywords Manager',
        })}
        {getMenuItem({
          onClick: () => handleRoute('/google-sheets'),
          icon: <GoogleOutlined />,
          text: 'Google Sheets Manager',
        })}
        <SubMenu key="sub3" icon={<SnippetsOutlined />} title="AOP Tools">
          {getMenuItem({
            onClick: () => handleRoute('/'),
            icon: <StarOutlined />,
            text: 'Keywords',
            permit: 'ViewKeywords',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/keyword-research'),
            icon: <SearchOutlined />,
            text: 'Keywords Research',
            permit: 'KeywordResearch',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/product-search'),
            icon: <FileSearchOutlined />,
            text: 'Product Search',
            permit: 'ViewTools',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/reports'),
            icon: <ContainerOutlined />,
            text: 'Reports',
            permit: 'KeywordsUpload',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/content-tracker'),
            icon: <SelectOutlined />,
            text: 'Content Tracker',
            permit: 'ViewKeywords',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/upload-keywords'),
            icon: <UploadOutlined />,
            text: 'CSV Upload',
            permit: 'KeywordsUpload',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/keyword-opportunities'),
            icon: <BulbOutlined />,
            text: 'Opportunities',
            permit: 'KeywordsOpportunities',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/bot'),
            icon: <RobotOutlined />,
            text: 'AOP Bot',
            permit: 'Bot',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/commission-categories'),
            icon: <HddOutlined />,
            text: 'Commission Categories',
            permit: 'CommissionCategories',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/articles'),
            icon: <BarsOutlined />,
            text: 'Articles',
            permit: 'SearchArticles',
          })}
          {getMenuItem({
            onClick: () => handleRoute('/products'),
            icon: <ShoppingOutlined />,
            text: 'Products',
            permit: 'ManageProducts',
          })}
        </SubMenu>
        {canAdmin && (
          <SubMenu key="sub4" icon={<ToolOutlined />} title="Admin">
            {getMenuItem({
              onClick: () => handleRoute('/users'),
              icon: <UsergroupAddOutlined />,
              text: 'Users',
              permit: 'ManageUsers',
            })}
            {getMenuItem({
              onClick: () => handleRoute('/sites'),
              icon: <GoldOutlined />,
              text: 'Sites',
              permit: 'ManageSites',
            })}
            {getMenuItem({
              onClick: () => handleRoute('/scan'),
              icon: <ScanOutlined />,
              text: 'App Scan',
              permit: 'ManageScan',
            })}
            {getMenuItem({
              onClick: () => handleRoute('/feature-flag'),
              icon: <FlagOutlined />,
              text: 'Feature Flags',
              permit: 'ManageFeatureFlags',
            })}
            {getMenuItem({
              onClick: () => handleRoute('/user-activity'),
              icon: <DatabaseOutlined />,
              text: 'User Activity',
              permit: 'ManageSites',
            })}
            {getMenuItem({
              onClick: () => handleRoute('/user-access'),
              icon: <AuditOutlined />,
              text: 'User Access',
              permit: 'UserAccess',
            })}
            {getMenuItem({
              onClick: () => handleRoute('/db-query'),
              icon: <GlobalOutlined />,
              text: 'DB Query',
              permit: 'ManageFeatureFlags',
            })}
          </SubMenu>
        )}
        <Menu.Divider />
        {getMenuItem({
          onClick: () => handleRoute('/profile'),
          icon: <UserOutlined />,
          text: 'My Profile',
        })}
        {getMenuItem({
          onClick: () => handleLogout(),
          icon: <LogoutOutlined />,
          text: 'Logout',
        })}
      </Menu>
  );
};

export default memo(SideMenu);
