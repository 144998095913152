/* eslint-disable */
import './App.scss';
import 'antd/dist/antd.css';

import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import AppProvider from './hooks';

function App() {

  return (
    <BrowserRouter>
      <AppProvider>
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
