import React from 'react';

import {
  Typography,
  Row,
  Col,
  Image,
} from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import { format } from 'date-fns';

import AmazonCard from '../components/amazon_card';
import { ISiteDTO } from '../../../../../shared/dtos/SiteDTO';
import { IAOPWordpressPost } from '../../../../../shared/dtos/WordpressDTO';

const { Link } = Typography;

interface IArticleCollapsedPreview {
  site: ISiteDTO
  article: IAOPWordpressPost
  handleViewFullArticleClick: () => void
}

const ArticleCollapsedPreview: React.FC<IArticleCollapsedPreview> = ({
  site,
  article,
  handleViewFullArticleClick,
}) => (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
  }}>
      <Image preview={false} src={'/amazon_side_nav.png'} style={{ width: 260, height: 'auto', marginLeft: 8 }}/>
      <div style={{
        borderTop: '2px solid #ddd',
        borderBottom: '2px solid #ddd',
        backgroundColor: 'white',
        fontFamily: "'Lato', sans-serif",
        marginBottom: 20,
        marginLeft: 8,
        fontWeight: 600,
      }}>
        <div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          <Row style={{ paddingBottom: '12px' }}>
            <Col style={{ paddingRight: '6px' }}>
              <img src={site?.logo_url} width={44}/>
            </Col>
            <Col>
              <div style={{ lineHeight: '1.3em', fontSize: '24px', fontWeight: 'bold' }}>
                Editorial recommendations
              </div>
              <Row style={{ paddingTop: '8px', fontSize: '14px' }}>
                <Col>
                  By
                  <span style={{ display: 'inline-block', width: '.385em' }} />
                  <Link style={{ color: '#007185' }}>{site?.display_name}</Link>
                </Col>
                <Col>
                  <span style={{ display: 'inline-block', width: '.385em' }} />
                  <span style={{ fontWeight: 'bolder' }}>|</span>
                  <span style={{ display: 'inline-block', width: '.385em' }} />
                </Col>
                <Col>
                  <Link
                    style={{ color: '#007185' }}
                    href={'https://www.amazon.com/ospublishing/onsite-associates/info'}
                    target={'_blank'}
                  >
                    Earns commissions
                  </Link>
                  <span style={{ display: 'inline-block', width: '.385em' }} />
                  <InfoCircleOutlined style={{ fontSize: '10px', color: '#aaa' }}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col style={{ maxWidth: 240, paddingRight: '12px' }}>
              <div style={{ lineHeight: '1.3em', fontSize: '24px', fontWeight: 'bold' }}>
                {article.title}
              </div>
              <div style={{ color: '#565959', fontSize: '14px', lineHeight: '20px', paddingTop: '12px' }}>
                {article.datePublished && format(new Date(article.datePublished), 'MMM dd, yyyy')}{' '}-{' '}
                {article.products?.length}
                {' '}Recommendations
              </div>
              <div style={{ paddingTop: '12px' }}>
                { article.introText }
              </div>
              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <Link style={{ color: '#007185' }} onClick={handleViewFullArticleClick}>Read full article</Link>
              </div>
            </Col>
              {
                article.products?.map((listItem, index) => (
                  <Col key={index} style={{ padding: 12 }}>
                    <AmazonCard
                      asin={listItem.asin}
                      award={listItem.award}
                      summary={listItem.summary}
                    />
                  </Col>
                ))
              }
          </Row>
        </div>
      </div>
  </div>
);

export default ArticleCollapsedPreview;
