import React from 'react';

export const DIMENSIONS = {
  HEADER_HEIGHT: 162,
};

export const WORD_FREQUENCY = {
  MIN: 6,
};

export const COLORS = {
  BORDER: '#dddddd',
  BORDER_DARK: '#333333',
  ICON_CENTER: '#efbb4f',
  FONT_CENTER: '#d7d7d7',
  ROW: '#f9f9f6',
  ROW_DARKER: '#f2f0ea',
  ROW_CONTENT: '#fdfdfd',
  CENTER: '#4b4b4b',
  CENTER_DARKER: '#333333',
};

export const STYLES = {
  MODAL_STYLES: {
    paddingTop: 16,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: COLORS.BORDER,
    borderBottomColor: COLORS.BORDER_DARK,
  } as React.CSSProperties,

  ICON_STYLES: {
    fontSize: 24,
    color: COLORS.ICON_CENTER,
  } as React.CSSProperties,

  ROW_STYLES: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderStyle: 'solid',
    borderColor: '#333332',
    borderWidth: 0,
  } as React.CSSProperties,

  ROW_CONTENT_STYLES: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    background: COLORS.ROW_CONTENT,
    flex: 2,
  } as React.CSSProperties,

  CELL_STYLES: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  } as React.CSSProperties,

  CENTER_BLOCK_STYLES: {
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: 6,
  } as React.CSSProperties,

  CENTER_BLOCK_CONTENT_STYLES: {
    marginTop: 4,
    fontFamily: 'sans-serif',
    color: COLORS.FONT_CENTER,
  } as React.CSSProperties,

  CENTER_BLOCK_HEADER_STYLES: {
    fontSize: 49,
    fontWeight: 'bold',
    color: '#343434',
  } as React.CSSProperties,

  ROW_CONTENT_VALUES_STYLES: {
    flex: 1,
    textAlign: 'justify',
    padding: '10px 22px',
    color: '#00000073',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: '#4b4b4b40',
  } as React.CSSProperties,
};
