import React, {
  useCallback,
  useState,
} from 'react';

import {
  Form,
  Input,
  Button,
  Divider,
  Typography,
  notification,
} from 'antd';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

const { Text, Link } = Typography;

interface IForgotPasswordParams {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { handleError } = useAuth();

  const handleBackLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleContinue = useCallback(async ({ email }: IForgotPasswordParams): Promise<void> => {
    try {
      setLoading(true);
      await api.post('/api/reset-password', {
        body: {
          username: email,
        },
      });
      notification.success({
        message: 'Resetting password',
        description: 'You will receive an email with a link to reset your password.',
      });
      history.push('/');
    } catch (err) {
      handleError(err, 'Resetting password error');
    } finally {
      setLoading(false);
    }
  }, [history, handleError]);

  return (
    <>
      <Text type="secondary">Reset your password</Text>
      <br />
      <Text type="secondary">Enter your email below to reset your password:</Text>
      <Form
        form={form}
        style={{ marginTop: 15 }}
        labelCol={{ span: 4 }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleContinue}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
        >
          <Input placeholder="name@domain.com" />
        </Form.Item>

        <Form.Item wrapperCol={{
          offset: 4,
        }}>
          <Button loading={loading} type="primary" htmlType="submit" style={{
            width: '100%',
          }}>
            Send email
          </Button>

        </Form.Item>

      </Form>
      <Divider />
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      }}>
        <Link style={{ marginLeft: 4 }} onClick={handleBackLogin}>Back to Login</Link>
      </div>
    </>
  );
};

export default ForgotPassword;
