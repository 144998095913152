import React, { useMemo } from 'react';

import { Typography } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../hooks/auth';
import User from '../../../../shared/dtos/UserDTO';
import { PERMISSIONS } from '../../services/auth';

const { Text, Link } = Typography;

interface LinkUserProps {
  icon?: boolean
  label?: string
  user: User
}

const UserLink: React.FC<LinkUserProps> = ({ user, icon, label }) => {
  const { isPermitted } = useAuth();
  const permitted = useMemo(() => {
    const canSeeit = isPermitted(PERMISSIONS.ManageUsers);
    return canSeeit;
  }, [isPermitted]);

  const username = useMemo(() => {
    const fullName = `${user.first_name} ${user.last_name}`;
    return label || fullName;
  }, [label, user.first_name, user.last_name]);

  const userIcon = useMemo(() => {
    if (icon) {
      return (
        <UserOutlined style={{
          color: '#DCDCDC',
          marginRight: 4,
        }} />
      );
    }
    return <></>;
  }, [icon]);

  return (
    <>
      {permitted ? (
        <Link type="secondary" href={`/users/${user.id}`}>
          {userIcon}
          {username}
        </Link>
      ) : (
        <Text>
          {userIcon}{username}
        </Text>
      )}
    </>
  );
};

export default UserLink;
