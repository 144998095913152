/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Calendar,
  Tabs,
  Tag,
} from 'antd';

import {
  ArrowsAltOutlined,
  LoadingOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';

import { format } from 'date-fns';
import api from '../../services/api';
import Comments from '../Comments';
import StatPopover from './stat_popover';
import { IStatDTO } from '../../../../shared/dtos/StatDTO';
import { useAuth } from '../../hooks/auth';
import ArticleComparisonModal from '../Articles/ArticleComparison';

interface IKeywordStatsProps {
  keywordId: number
  isFullscreen: boolean
  initialTab?: 'sov' | 'comments'
  handleFullscreen(keywordId?: number): void
}

type StatDateMapper = {
  [key: string]: IStatDTO[]
};

type StatMonthMapper = {
  [key: string]: {
    won: number
    lost: number
  }
};

const { TabPane } = Tabs;

const KeywordStats: React.FC<IKeywordStatsProps> = ({
  keywordId,
  initialTab,
  isFullscreen,
  handleFullscreen,
}: IKeywordStatsProps) => {
  const { handleError } = useAuth();
  const [statDataByDate, setStatDataByDate] = useState<StatDateMapper>({});
  const [statDataByMonth, setStatDataByMonth] = useState<StatMonthMapper>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedStat, setSelectedStat] = useState<IStatDTO>();
  const [compareStat, setCompareStat] = useState<IStatDTO>();

  const comparisonModal = useMemo(() => (
    <ArticleComparisonModal
      stat={compareStat}
      onClose={() => setCompareStat(undefined)} />
  ), [compareStat]);

  const fullscreenButton = useMemo(() => {
    if (isFullscreen) {
      return (
        <Button onClick={() => handleFullscreen()}>
          <ShrinkOutlined />
        </Button>
      );
    }
    return (
      <Button onClick={() => handleFullscreen(keywordId)}>
        <ArrowsAltOutlined />
      </Button>
    );
  }, [handleFullscreen, isFullscreen, keywordId]);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      handleFullscreen();
    }
  }, [handleFullscreen]);

  useEffect(() => {
    if (isFullscreen) {
      document.addEventListener('keydown', escFunction, false);
    }

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction, isFullscreen]);

  const loadShareOfVoiceData = useCallback(async (): Promise<void> => {
    try {
      const shareOfVoiceResponse = await api.get<IStatDTO[]>(
        '/api/stats',
        {
          params: {
            keyword_id: keywordId,
          },
        },
      );
      const dateStatMapper: StatDateMapper = {};
      const monthStatMapper: StatMonthMapper = {};
      shareOfVoiceResponse.forEach((stat) => {
        const createdAtDate = format(new Date(stat.created_at), 'yyyy-MM-dd');
        const createdAtMonth = format(new Date(stat.created_at), 'M');
        if (!dateStatMapper[createdAtDate]) {
          dateStatMapper[createdAtDate] = [stat];
        } else {
          dateStatMapper[createdAtDate].push(stat);
        }

        if (!monthStatMapper[createdAtMonth]) {
          monthStatMapper[createdAtMonth] = {
            won: 0,
            lost: 0,
          };
        }

        ++monthStatMapper[createdAtMonth][stat.is_winning ? 'won' : 'lost'];
      });
      setStatDataByDate(dateStatMapper);
      setStatDataByMonth(monthStatMapper);
      setIsLoading(false);
    } catch (err) {
      handleError(err, 'There was a problem loading the graph data.', true);
    }
  }, [keywordId, handleError]);

  useEffect(() => {
    loadShareOfVoiceData();
  }, [loadShareOfVoiceData]);

  return (
    <div className={isFullscreen ? 'App-fullscreen' : ''} style={{ boxShadow: 'rgb(178 212 255) 0px 0px 15px 10px' }}>
      <Tabs
        tabBarStyle={{
          margin: '0 20px',
        }}
        defaultActiveKey={initialTab || 'sov'}
        style={{
          height: isFullscreen ? undefined : 410,
        }}
        tabBarExtraContent={fullscreenButton}>
        <TabPane
          tab="Share of Voice"
          key="sov">
          <div style={{
            margin: '0 20px',
          }} >
            {
              isLoading && (
                <div style={{
                  fontSize: 54,
                  color: 'rgba(65,145,247,1)',
                  padding: 50,
                  textAlign: 'center',
                }}>
                  <LoadingOutlined />
                </div>
              )
            }
            {
              !isLoading && (
                <Calendar
                  fullscreen={false}
                  dateCellRender={(date) => {
                    const dateString = date.format('yyyy-MM-DD');
                    if (statDataByDate[dateString]) {
                      return statDataByDate[dateString].map((stat) => (
                        <Tag
                          onClick={() => setSelectedStat(stat)}
                          color={ stat.is_winning ? '#cbdfb8' : '#f25050' } >
                          {selectedStat?.stat_id === stat.stat_id
                            ? <StatPopover onCompare={() => setCompareStat(stat)} onClose={() => setSelectedStat(undefined)} stat={stat} />
                            : stat.publisher}
                        </Tag>
                      ));
                    }

                    return null;
                  }}
                  monthCellRender={(date) => {
                    const month = date.format('M');
                    if (statDataByMonth[month]) {
                      return (
                        <div style={{ minWidth: 100 }}>
                          <Badge status='success' text={`Won ${statDataByMonth[month].won}`} />
                          <Badge status='error' text={`Lost ${statDataByMonth[month].lost}`} />
                        </div>
                      );
                    }

                    return null;
                  }}
                />
              )
            }
          </div>
        </TabPane>
        <TabPane tab="Comments" key="comments">
          <Comments keywordId={keywordId} fullscreen={isFullscreen} />
        </TabPane>
      </Tabs>
      {comparisonModal}
    </div>
  );
};

export default KeywordStats;
