/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { WarningOutlined } from '@ant-design/icons';
import {
  Rate, Tooltip, Table, Pagination, Typography, Card, Checkbox, notification, Image,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { format } from 'date-fns';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  useHistory, useLocation,
} from 'react-router-dom';
import {
  IOpportunityProductData,
  IOpportunityProductDataResponse,
  IOpportunityProductsFilterDTO,
} from '../../../../shared/dtos/OpportunityDTO';

import HeaderPanel from '../../components/header_panel';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { IMAGE_NOT_FOUND_AVG } from '../../utils';
import { keywordSubmenuActions } from '../keywords/keywords_summary';

const { Text, Link } = Typography;

const formatter = new Intl.NumberFormat(
  'en-US', {
    style: 'currency',
    currency: 'USD',
  },
);

const ProductSearch: React.FC = () => {
  let delaySearch: number | ReturnType<typeof setTimeout> = 0;
  const { handleError } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [productData, setProductData] = useState<IOpportunityProductData[]>([]);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [query, setQuery] = useState<IOpportunityProductsFilterDTO>({});
  const [totalCount, setTotalCount] = useState(0);
  const [queryString, setQueryString] = useState<string>();

  const loadProducts = useCallback(async (): Promise<void> => {
    if (!preload) {
      return;
    }
    try {
      setLoading(true);
      const params = {
        ratingMin: 4.5,
        reviewsMin: 100,
        page: query.page,
        perPage: query.perPage,
        sortBy: query.sortBy || 'price',
        sortOrder: query.sortOrder || 'desc',
        includeText: query.includeText,
        showUnavailable: query.showUnavailable,
      };
      const productsResults = await api.get<IOpportunityProductDataResponse>(
        '/api/keyword-opportunities/products', {
          params,
        },
      );
      if (!productsResults || !productsResults.data || !productsResults.data.data) {
        const iPage = query && query.page ? query.page : 0;
        const description = iPage > 1 ? `Check the page ${iPage}` : undefined;
        notification.error({
          message: "Couldn't search products.",
          description,
        });
        return;
      }
      setTotalCount(productsResults.data.data.total_count);
      setProductData(productsResults.data.data.products);
      const cleanedParams = JSON.parse(JSON.stringify(query));
      const queryParams = new URLSearchParams(cleanedParams);
      history.push({ search: queryParams.toString() });
    } catch (err) {
      handleError(err);
    } finally {
      setSearching(false);
      setLoading(false);
    }
  }, [handleError, history, preload, query]);

  useEffect(() => {
    if (!preload) {
      const params = new URLSearchParams(location.search);
      const defaults = {} as IOpportunityProductsFilterDTO;
      if (params.get('page')) {
        defaults.page = Number(params.get('page'));
      }
      if (params.get('perPage')) {
        const pageNumber = Number(params.get('perPage'));
        if ([25, 50, 100].includes(pageNumber)) {
          Object.assign(defaults, {
            perPage: pageNumber,
          });
        }
      }
      if (params.get('showUnavailable') === 'true') {
        defaults.showUnavailable = true;
      }
      defaults.sortBy = params.get('sortBy') || undefined;
      Object.assign(defaults, {
        sortOrder: params.get('sortOrder') || undefined,
      });
      defaults.includeText = params.get('includeText') || undefined;
      setQueryString(defaults.includeText);
      setQuery(defaults);
      loadProducts();
      setPreload(true);
    }
  }, [loadProducts, location.search, preload]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const productColumns = [
    {
      title: 'Product Information',
      width: 300,
      key: 'name',
      sorter: true,
      render: (product: IOpportunityProductData) => (
        <>
          <div style={{ display: 'flex', width: 300 }}>
            <div>
              <Image
                src={product.imageUrl}
                preview={false}
                width={60}
                fallback={IMAGE_NOT_FOUND_AVG}
                style={{ padding: '10px' }} />
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Tooltip title={product.name}>
                <Text ellipsis strong style={{ width: 230 }}>
                  {product.name}
                </Text>
              </Tooltip>
              <Text ellipsis style={{ width: 230 }}>{product.brand}</Text>
              <Link target="_blank" href={product.product_url}>
                {product.asin}
              </Link>

            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'calculatedCategory',
      sorter: true,
    },
    {
      title: 'Mo. Sales',
      dataIndex: 'estimatedSales',
      key: 'estimatedSales',
      sorter: true,
    },
    {
      title: 'Revenue',
      dataIndex: 'estRevenue',
      key: 'estRevenue',
      sorter: true,
      render: (revenue: number) => formatter.format(revenue),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: true,
      render: (price: number) => formatter.format(price),
    },
    {
      title: 'Review Numbers',
      dataIndex: 'nReviews',
      key: 'nReviews',
      width: 100,
      sorter: true,
    },
    {
      title: 'Sellers',
      dataIndex: 'nSellers',
      key: 'nSellers',
      sorter: true,
    },
    {
      title: 'Date First Available',
      key: 'listedAt',
      sorter: true,
      render: (product: IOpportunityProductData) => {
        let productListedAt;
        if (product.listedAt) {
          productListedAt = product.listedAt;
          if (typeof product.listedAt === 'string') {
            productListedAt = parseInt(product.listedAt, 10);
          }
          return (<span>{format(new Date(productListedAt), 'yyyy-MM-dd')}</span>);
        }

        if (product.estimatedListedAt) {
          productListedAt = product.estimatedListedAt;
          if (typeof product.estimatedListedAt === 'string') {
            productListedAt = parseInt(product.estimatedListedAt, 10);
          }
          return (
            <span>
              {format(new Date(productListedAt), 'yyyy-MM-dd')}{' '}
              <Tooltip title={'Since Amazon does not provide this data, we will estimate by using the earliest review date or earliest Q&A date'}>
                <WarningOutlined />
              </Tooltip>
            </span>
          );
        }

        return null;
      },
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      sorter: true,
      width: 120,
      render: (rating: number) => (
        rating && <Rate className="App-Rate-Small" disabled allowHalf defaultValue={Number(rating)} />
      ),
    },
  ];

  const handlePaginationChange = useCallback((page, pageSize) => {
    setQuery((previous) => ({
      ...previous,
      page,
      perPage: pageSize,
    }));
  }, []);

  const handleTableChange = useCallback((pagination, _filter, sorter) => {
    const toOrder = sorter?.order ? sorter?.order.slice(0, -3) : undefined;
    setQuery((previous) => ({
      ...previous,
      sortBy: sorter.columnKey,
      sortOrder: toOrder,
    }));
  }, []);

  const handleProductName = useCallback((e) => {
    const { value } = e.target;
    setQueryString(value);
    if (delaySearch) {
      clearTimeout(delaySearch as ReturnType<typeof setTimeout>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    delaySearch = setTimeout(() => {
      setSearching(true);
      setQuery((previous) => ({
        ...previous,
        includeText: value,
      }));
    }, 2000);
  }, []);

  const handleUnavailable = useCallback((e) => {
    setQuery((previous) => ({
      ...previous,
      showUnavailable: !e.target.checked,
    }));
  }, []);

  return (
    <>
      <HeaderPanel
        title="Product Search"
        hideHome
        actions={keywordSubmenuActions}
      />
      <Card
        bodyStyle={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Search
          style={{
            width: 300,
          }}
          onChange={handleProductName}
          value={queryString}
          loading={searching}
          placeholder="Type the product name"
        />
        <Checkbox
          checked={!query.showUnavailable}
          style={{ marginLeft: 30 }}
          onChange={handleUnavailable} >Exclude Unavailable Products</Checkbox>
      </Card>

      <Table
        loading={loading}
        columns={productColumns}
        dataSource={productData}
        onChange={handleTableChange}
        pagination={false}
        rowKey={(product) => product.asin}
        style={{ marginTop: 12 }}
        />
      <div style={{ paddingTop: 20, float: 'right' }}>
        <Pagination
          onChange={(page, pageSize) => handlePaginationChange(page, pageSize as number)}
          showSizeChanger={true}
          pageSizeOptions={['25', '50', '100']}
          pageSize={query.perPage || 50}
          current={query.page || 1}
          total={totalCount}
        />
      </div>
    </>
  );
};

export default ProductSearch;
