import React from 'react';

import { Switch } from 'react-router-dom';

import Articles from '../pages/articles';
import ArticlePreview from '../pages/articles/preview';
import Confirmation from '../pages/auth/confirmation';
import ContentTracker from '../pages/content';
import DBQuery from '../pages/admin/db_query';
import FeatureFlag from '../pages/admin/feature_flag';
import ForgotPassword from '../pages/auth/forgot_password';
import KeywordOpportunities from '../pages/keywords/keyword_opportunities';
import KeywordResearch from '../pages/tools/keyword_research';
import ProductSearch from '../pages/tools/product_search';
import KeywordsSummary from '../pages/keywords/keywords_summary';
import GSCKeywordsSummary from '../pages/gsc_keywords/keywords_summary';
import KeywordsUpload from '../pages/keywords/keywords_upload';
import PendingArticles from '../pages/articles/pending_articles';
import ProductsPage from '../pages/products';
import Profile from '../pages/profile';
import Reports from '../pages/reports';
import ResetPassword from '../pages/auth/reset-password';
import Route from './Route';
import Scan from '../pages/admin/scan';
import ShowUser from '../pages/admin/users/show_user';
import UserAccess from '../pages/admin/users/access';
import SignIn from '../pages/auth/sign_in';
import Sites from '../pages/admin/sites';
import Users from '../pages/admin/users';
import Bot from '../pages/bot';
import UserActivity from '../pages/admin/user_activity';
import CommissionCategories from '../pages/commission_categories/commision_categories';
import GoogleSheetsManager from '../pages/google_sheets_manage';
import GameSolutions from '../pages/online_game_solutions/solutions';

const Routes: React.FC = () => (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/articles" exact component={Articles} permit={'ManageArticles'} />
      <Route path="/articles/pending" component={PendingArticles} permit={'PendingArticles'} />
      <Route path="/products" exact component={ProductsPage} permit={'ManageProducts'} />
      <Route path="/commission-categories" exact component={CommissionCategories} permit={'CommissionCategories'} />
      <Route path="/confirmation" component={Confirmation} />
      <Route path="/content-tracker" component={ContentTracker} permit={'ViewKeywords'} />
      <Route path="/db-query" component={DBQuery} permit={'ManageFeatureFlags'} />
      <Route path="/user-activity" component={UserActivity} permit={'ManageSites'} />
      <Route path="/feature-flag" component={FeatureFlag} permit={'ManageFeatureFlags'} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/keywords" exact component={KeywordsSummary} permit={'ViewKeywords'} />
      <Route path="/gsc-keywords" exact component={GSCKeywordsSummary} permit={'ViewKeywords'} />
      <Route path="/keyword-opportunities" component={KeywordOpportunities} permit={'KeywordsOpportunities'} />
      <Route path="/preview/:site/:id" component={ArticlePreview} permit={'ViewTools'} />
      <Route path="/profile" component={Profile} permit={'Profile'} />
      <Route path="/reports" component={Reports} permit={'ViewKeywords'} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/scan" component={Scan} permit={'ManageScan'} />
      <Route path="/sites" component={Sites} permit={'ManageSites'} />
      <Route path="/keyword-research" component={KeywordResearch} permit={'ViewTools'} />
      <Route path="/product-search" component={ProductSearch} permit={'ViewTools'} />
      <Route path="/upload-keywords" component={KeywordsUpload} permit={'KeywordsUpload'} />
      <Route path="/users/:id" component={ShowUser} permit={'ManageUsers'} />
      <Route path="/users" component={Users} permit={'ManageUsers'} />
      <Route path="/user-access" component={UserAccess} permit={'UserAccess'} />
      <Route path="/bot" component={Bot} permit={'Bot'} />
      <Route path="/google-sheets" component={GoogleSheetsManager} permit={'ViewTools'} />
      <Route path="/game-solutions" component={GameSolutions} />
    </Switch>
);

export default Routes;
