/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Form,
  Input,
  List,
  Modal,
} from 'antd';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import api from '../../../services/api';
import { IUserAccess } from '../../../../../shared/dtos/UserDTO';

import HeaderPanel from '../../../components/header_panel';
import { useAuth } from '../../../hooks/auth';

interface IUserAccessSearchType {
  email: string
  github?: string
  manager?: string
}

const UserAccess: React.FC = () => {
  const [form] = Form.useForm();
  const { handleError } = useAuth();
  const [revokingMap, setRevokingMap] = useState<string[]>([]);
  const [userFetchedAccess, setUserFetchedAccess] = useState<IUserAccess[]>([]);
  const [removedMap, setRemovedMap] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [github, setGithub] = useState<string>();
  const [manager, setManager] = useState<string>();
  const [email, setEmail] = useState<string>();

  const handleSearch = useCallback(async (
    {
      email: iEmail,
      github: iGithub,
      manager: iManager,
    }: IUserAccessSearchType,
  ) => {
    try {
      setUserFetchedAccess([]);
      setRemovedMap([]);
      setEmail(undefined);
      setGithub(undefined);
      setManager(undefined);
      setLoading(true);
      const accessList = await api.get<IUserAccess[]>('api/users/check-account', {
        params: {
          email: iEmail, github: iGithub, manager: iManager,
        },
      });
      setEmail(iEmail);
      setGithub(iGithub);
      setManager(iManager);
      setUserFetchedAccess(accessList);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  }, [handleError]);

  const revoke = useCallback(
    async (userAccess: IUserAccess) => {
      setRevokingMap((previous) => [...previous, userAccess.operation]);
      const isRemoved = await api.put<IUserAccess[]>('api/users/revoke-access', {
        body: {
          source: userAccess.operation,
          data: {
            id: `${userAccess.operationId}`, email, github, manager,
          },
        },
      });

      if (isRemoved) {
        setRemovedMap((previous) => [...previous, userAccess.operation]);
        setRevokingMap((previous) => previous.filter((p) => p !== userAccess.operation));
      }
    },
    [email, github, manager],
  );

  const numberActiveAccess = useMemo(() => {
    const cont = userFetchedAccess.filter((f) => f.active && !removedMap.includes(f.operation)).length;
    return cont;
  }, [removedMap, userFetchedAccess]);

  const handleRevokeAll = useCallback(() => {
    Modal.confirm({
      title: `Want to revoke ${numberActiveAccess} user access?`,
      onOk: () => {
        userFetchedAccess
          .filter((userAccess) => userAccess.active)
          .forEach((userAccess) => revoke(userAccess));
      },
    });
  }, [numberActiveAccess, revoke, userFetchedAccess]);

  const handleRevoke = (userAccess: IUserAccess) => {
    Modal.confirm({
      title: `Want to revoke ${userAccess.operationName}?`,
      onOk: () => {
        revoke(userAccess);
      },
    });
  };

  return (
    <>
      <HeaderPanel
        title="User Access Management"
      />
        <div style={{
          margin: '0 100px 20px 100px',
          padding: 23,
          border: '1px solid #d9d9d9',
          borderRadius: 2,
        }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-evenly',
        }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Form form={form} onFinish={handleSearch}>
              <Form.Item name="email" rules={[{ required: true }]}>
                <Input
                  style={{ width: 360 }}
                  placeholder="DT account. (example@digitaltrends.com)"
                  allowClear
                  size="middle"
                  type="email"
                  required
                />
              </Form.Item>
              <Form.Item name="github">
                <Input
                  style={{ width: 360 }}
                  placeholder="GitHub account"
                  allowClear
                  size="middle"
                  type="text"
                  required
                />
              </Form.Item>
              <Form.Item name="manager">
                <Input
                  style={{ width: 360 }}
                  placeholder="Asana Manager. (example@digitaltrends.com)"
                  allowClear
                  size="middle"
                  type="email"
                  required
                />
              </Form.Item>
              {numberActiveAccess > 0 && (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'end',
                  }}
                >
                  <Button loading={revokingMap.length > 0} danger onClick={handleRevokeAll}>
                    Revoke all access <ArrowRightOutlined />
                  </Button>
                </div>
              )}
            </Form>
            <Button
              style={{
                marginLeft: 12,
              }}
              loading={loading}
              onClick={() => form.submit()}
              icon={<SearchOutlined />}
            >
              Find
            </Button>
          </div>
          {userFetchedAccess && (
            <List
              style={{ width: 450, marginLeft: 80 }}
              dataSource={userFetchedAccess}
              renderItem={(item) => (
                <>
                  {removedMap.includes(item.operation) && (
                    <div style={{
                      position: 'absolute',
                      marginTop: 20,
                      marginRight: 20,
                      background: '#c9c9c990',
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingBottom: 6,
                      paddingTop: 6,
                      color: 'red',
                      borderStyle: 'solid',
                      borderRadius: 6,
                      borderWidth: 1,
                      zIndex: 999,
                      right: 10,
                    }}>
                      <CheckOutlined /> Revoked
                    </div>
                  )}

                  <div style={{
                    width: '100%',
                    borderRadius: 6,
                    paddingLeft: 8,
                    paddingRight: 8,
                    marginBottom: 4,
                    opacity: removedMap.includes(item.operation) ? 0.3 : 1,
                    background: removedMap.includes(item.operation) ? '#dfe0e0' : undefined,
                  }}>
                    <List.Item key={item.operation}>
                      <List.Item.Meta
                        avatar={<Avatar src={item.avatar} />}
                        title={
                          <a target="_blank" href={item.url} rel="noreferrer">
                            {item.operationName}
                          </a>
                        }
                        description={
                          <div>
                            {item.error ? (
                              <span style={{
                                color: 'red',
                              }}>
                                <CheckCircleOutlined /> {item.error}
                              </span>
                            ) : (
                              <>
                                Status:{' '}
                                {item.active ? (
                                  <span style={{
                                    color: 'green',
                                  }}>
                                    Active <CheckCircleOutlined />
                                  </span>
                                ) : (
                                  <span style={{
                                    color: 'red',
                                  }}>
                                    Inactive <CloseCircleOutlined />
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        }
                      />
                      <div>
                        <Button
                          loading={revokingMap.includes(item.operation)}
                          danger
                          disabled={!item.active || removedMap.includes(item.operation)}
                          onClick={() => handleRevoke(item)}
                        >
                          Revoke
                        </Button>
                      </div>
                    </List.Item>
                  </div>
                </>
              )}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UserAccess;
