import React,
{
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Typography,
  Collapse,
  Button,
} from 'antd';
import api from '../../services/api';

type FutureSolutions = {
  value: string,
  date: string,
};

type GameSolutionsResponse = {
  wordleSolution: string,
  heardleSolution: string,
  framedSolution: string,
  wordleFutureSolutions: FutureSolutions[],
  heardleFutureSolutions: FutureSolutions[],
  framedFutureSolutions: FutureSolutions[],
  expired: number,
  created: Date,
};

const { Title, Link } = Typography;
const { Panel } = Collapse;

const GameSolutions: React.FC = () => {
  const [gameSolutions, setGameSolutions] = useState<GameSolutionsResponse>();

  const getGameSolutions = useCallback(async (clearCache) => {
    const url = clearCache ? '/api/game-solutions' : '/api/game-solutions?clear_cache=true';
    const gameSolutionsResults = await api.get<GameSolutionsResponse>(url);
    setGameSolutions(gameSolutionsResults);
  }, []);

  useEffect(() => {
    getGameSolutions(false);
  }, [getGameSolutions]);

  return (
    <>
      <div>

        <Title level={3}>Game Solutions</Title>
        <Collapse>
          <Panel
              key={1}
              header={
                <Title level={5}>
                  <Link href={'https://www.nytimes.com/games/wordle/index.html'} target={'_blank'}>Wordle:{' '}</Link>
                  <span>{gameSolutions && gameSolutions.wordleSolution}</span>
                </Title>
              }
          >
            {
              gameSolutions && gameSolutions.wordleFutureSolutions.map((solution, index) => (
                <div key={`wordle${index}`} style={{ backgroundColor: (index % 2) === 0 ? 'white' : 'lightgray', width: '100%' }}>
                  <div style={{ textAlign: 'left', width: '50%', display: 'inline-block' }}>{solution.value}</div>
                  <div style={{ textAlign: 'right', width: '50%', display: 'inline-block' }}>{solution.date}</div>
                </div>
              ))
            }
          </Panel>
          <Panel
              key={2}
              header={
                <Title level={5}>
                  <Link href={'https://www.spotify.com/heardle/?type=auto&message=no-local-storage'} target={'_blank'}>Heardle:{' '}</Link>
                  <span>{gameSolutions && gameSolutions.heardleSolution}</span>
                </Title>
              }
          >
            {
              gameSolutions && gameSolutions.heardleFutureSolutions.map((solution, index) => (
                <div key={`heardle${index}`} style={{ backgroundColor: (index % 2) === 0 ? 'white' : 'lightgray', width: '100%' }}>
                  <div style={{ textAlign: 'left', width: '50%', display: 'inline-block' }}>{solution.value}</div>
                  <div style={{ textAlign: 'right', width: '50%', display: 'inline-block' }}>{solution.date}</div>
                </div>
              ))
            }
          </Panel>
        </Collapse>
        <div style={{ textAlign: 'center', paddingTop: '12px' }}>
          <Button onClick={() => getGameSolutions(true)} type='primary' size='large'>Run</Button>
          <div style={{ color: '#444', fontSize: '12px', paddingTop: '10px' }}>
            Last ran:{' '}{gameSolutions && gameSolutions.created}
          </div>
        </div>
      </div>
    </>
  );
};

export default GameSolutions;
