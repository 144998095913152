import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  format,
  parseISO,
} from 'date-fns';

import {
  Form,
  Input,
  Button,
  Typography,
  Modal,
  Table,
  Tag,
} from 'antd';

import {
  PlusOutlined,
  EditOutlined,
  UserOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import { ColumnProps } from 'antd/lib/table';
import api from '../../../services/api';
import { IPaginatedQuery } from '../../../../../shared/dtos/IPaginatedQuery';

import User, {
  Role,
  IFilterUsersDTO,
} from '../../../../../shared/dtos/UserDTO';

import { useAuth } from '../../../hooks/auth';
import HeaderPanel from '../../../components/header_panel';
import UserLink from '../../../components/Users/user_link';
import NewUserModal from '../../../components/Users/new_user_modal';
import { PERMISSIONS } from '../../../services/auth';

const { Search } = Input;
const { Text } = Typography;

const defaultFilters = {
  page: 1,
  query: '',
  sort: 'first_name',
  sortOrder: 'asc',
} as IFilterUsersDTO;

const Users: React.FC = () => {
  const { handleError, isPermitted } = useAuth();
  const [showAddUser, setShowAddUser] = useState(false);
  const [searching, setSearching] = useState(false);
  const [filters, setFilters] = useState<IFilterUsersDTO>(defaultFilters);
  const [totalCount, setTotalCount] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [editUser, setEditUser] = useState<User>();

  const handleNewUser = useCallback(() => {
    setEditUser(undefined);
    setShowAddUser(true);
  }, []);

  const handleEditUser = useCallback((user) => {
    setEditUser(user);
  }, []);

  useEffect(() => {
    if (editUser) {
      setShowAddUser(true);
    }
  }, [editUser]);

  const columns = useMemo(() => {
    const permittedColumns: ColumnProps<User>[] = [
      {
        title: 'Name',
        dataIndex: 'first_name',
        key: 'first_name',
        sorter: true,
        render: (firstName: string, user: User) => (
          <UserLink
            label={firstName}
            user={user} />
        ),
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
        sorter: true,
        render: (lastName: string, user: User) => (
          <UserLink
            label={lastName}
            user={user} />
        ),
      },
      {
        title: 'Email',
        dataIndex: 'username',
        key: 'username',
        sorter: true,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role_id',
        sorter: true,
        render: (value: Role) => (
          <Tag key={value.id} color={value.id === 'adm' ? 'blue' : 'default'}>
            {value.id === 'adm' && (
              <UserOutlined style={{
                marginRight: 3,
                fontSize: 12,
              }} />
            )}
            {value.description}
          </Tag>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'active',
        key: 'active',
        sorter: true,
        render: (value: number) => (value === 1 ? (
          <Tag color="blue">
            <CheckOutlined style={{
              fontSize: 10,
              marginRight: 3,
            }} />Active
          </Tag>
        ) : (
          <Tag color="error">
            <CloseOutlined style={{
              fontSize: 10,
              marginRight: 3,
            }} />Locked
          </Tag>
        )),
      },
      {
        title: 'Last Login',
        dataIndex: 'last_sign_in_at',
        key: 'last_sign_in_at',
        sorter: true,
        render: (value: string) => {
          if (value) {
            const dt = parseISO(value);
            const dtFormatted = format(dt, 'yyyy-MM-dd HH:mm');
            return (<Text>{dtFormatted}</Text>);
          }
          return (<Text type="danger">Never</Text>);
        },
      },
      {
        title: 'Login count',
        dataIndex: 'sign_in_count',
        key: 'sign_in_count',
        sorter: true,
        render: (value: number) => (
          <Text>{value}</Text>
        ),
      },
    ];
    if (isPermitted(PERMISSIONS.ManageFeatureFlags)) {
      permittedColumns.push({
        title: 'Email',
        key: 'email',
        render: (user: User) => (
          <Button
            type="primary"
            onClick={() => {
              api.post(`/api/users/${user.id}/email/resend`, {});
            }}
          >
            { 'Send Test Email' }
          </Button>
        ),
      });
    }

    if (isPermitted(PERMISSIONS.UpdateUser)) {
      permittedColumns.push({
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (value: string, row) => (
          <Button onClick={() => handleEditUser(row)} type="primary" shape="circle" icon={<EditOutlined />}></Button>
        ),
      });
    }
    return permittedColumns;
  }, [handleEditUser, isPermitted]);

  const searchUsers = useCallback(async () => {
    setSearching(true);

    try {
      const { nodes, totalCount: currentTotalCount } = await api.get<IPaginatedQuery<User>>('/api/users', {
        params: filters,
      });
      setTotalCount(currentTotalCount);
      setUsers(nodes);
    } catch (err) {
      handleError(err, 'Users could not be searched.');
    } finally {
      setSearching(false);
    }
  }, [filters, handleError]);

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    const sortOrder = sorter.order ? sorter.order.slice(0, -3) : defaultFilters.sortOrder;
    const sort = sorter.columnKey;
    const page = pagination.current;
    setFilters((previous) => ({
      ...previous, sort, sortOrder, page,
    }));
  }, []);

  const handleCloseModalNewUser = useCallback(() => {
    setShowAddUser(false);
    setEditUser(undefined);
  }, []);

  const handleChangeQuery = useCallback((event) => {
    const { value } = event.target;
    setFilters((previous) => ({ ...previous, query: value }));
  }, []);

  const showMessageUserCreated = useCallback((newUser: User) => {
    setShowAddUser(false);
    setEditUser(undefined);
    if (editUser) {
      Modal.success({
        title: `User '${newUser.first_name} ${newUser.last_name}' updated successfully.`,
      });
    } else {
      Modal.success({
        title: `User '${newUser.first_name} ${newUser.last_name}' has been successfully created`,
        content: 'An email will be sent with the access credentials',
      });
    }

    searchUsers();
  }, [editUser, searchUsers]);

  return (
    <>
      <HeaderPanel
        title="Users management" />

      <div style={{
        margin: '0 0 20px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Form
          name='keywordFormSearch'
          className='App-form'
        >
          <Search
            placeholder="Find users"
            onChange={handleChangeQuery}
            style={{
              width: 350,
            }}
            loading={searching} />
        </Form>

        {isPermitted(PERMISSIONS.CreateUser) && (
          <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          onClick={handleNewUser}>
          New User
        </Button>
        )}

      </div>

      <Table
        columns={columns}
        dataSource={users}
        onChange={handleTableChange}
        pagination={{
          total: totalCount,
          defaultPageSize: 20,
          current: filters.page,
        }}
        rowKey={(item) => item.id}
      />

      <NewUserModal
        editUser={editUser}
        onClose={handleCloseModalNewUser}
        onSuccess={(newUser) => showMessageUserCreated(newUser)}
        visible={showAddUser}
      />

    </>
  );
};

export default Users;
