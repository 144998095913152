/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Popconfirm,
  Typography,
} from 'antd';

import {
  CloseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { ISiteWebhookDTO } from '../../../../shared/dtos/SiteDTO';
import AsanaIcon from '../Icons/AsanaIcon';
import { IWebhookDTO } from '../../../../shared/dtos/ArticleDTO';
import { ASANA_URL } from '../../utils';
import { useAuth } from '../../hooks/auth';
import { PERMISSIONS } from '../../services/auth';

const { Link } = Typography;

export interface IWebhookProps {
  webhook?: IWebhookDTO
  site: ISiteWebhookDTO
  type: 'content' | 'copyboard'
}

interface WebhookButtonProps extends IWebhookProps{
  onShow(select: IWebhookProps): void
  onReset(): Promise<IWebhookDTO | undefined>
}
const WebhookButton: React.FC<WebhookButtonProps> = ({
  webhook, site, type, onShow, onReset,
}) => {
  const { isPermitted, handleError } = useAuth();
  const [saving, setSaving] = useState(false);

  const handleReset = useCallback(async () => {
    if (!isPermitted(PERMISSIONS.ManageWebhooks)) {
      return;
    }
    setSaving(true);
    try {
      await onReset();
    } catch (err) {
      handleError(err, 'Could not save the webhook');
    } finally {
      setSaving(false);
    }
  }, [isPermitted, onReset, handleError]);

  const handleShow = useCallback(() => {
    if (!isPermitted(PERMISSIONS.ManageWebhooks)) {
      return;
    }
    onShow({
      webhook, site, type,
    });
  }, [isPermitted, onShow, site, type, webhook]);

  const webhookGid = useMemo(() => {
    if (type === 'copyboard') {
      return site.copyboard_gid;
    }
    return site.asana_gid;
  }, [site.asana_gid, site.copyboard_gid, type]);

  const createNoWebhookRunningContent = useCallback(() => (
    <Popconfirm
      title={(
        <p>
          Do you want to start a webhook to <strong>{site.display_name}</strong>?<br />
          This operation cannot be undone.
        </p>
      )}
      disabled={!isPermitted(PERMISSIONS.ManageWebhooks)}
      onConfirm={handleReset}
      okText="Yes"
      cancelText="No">
      <Button
        type="dashed"
        loading={saving}
        size="small"
        style={{ width: 150 }} >
        <CloseCircleOutlined /> No Webhook
      </Button>
    </Popconfirm>
  ), [handleReset, isPermitted, saving, site.display_name]);

  const createWebhookRunningContent = useCallback(() => (
      <Button
        type="primary"
        onClick={handleShow}
        loading={saving}
        size="small"
        style={{ width: 150 }}>
        <PlayCircleOutlined /> Active Webhook
      </Button>
  ), [handleShow, saving]);

  const buttonContent = useMemo(() => {
    if (!webhook) {
      return createNoWebhookRunningContent();
    }
    return createWebhookRunningContent();
  }, [createNoWebhookRunningContent, createWebhookRunningContent, webhook]);

  return (
    <div style={{
      maxWidth: 170,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <Link
        target="_blank"
        style={{ marginRight: 5 }}
        href={`${ASANA_URL}${webhookGid}`}
        title={`${ASANA_URL}${webhookGid}`}>
        <AsanaIcon />
      </Link>
      {buttonContent}
    </div>
  );
};

export default WebhookButton;
