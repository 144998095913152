import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';

import {
  Table,
  Menu,
  Dropdown,
  Space,
  Tabs,
  Form,
  Input,
  Spin,
  Select,
  Alert,
  notification,
} from 'antd';

import HeaderPanel from '../../components/header_panel';

import api from '../../services/api';

import { IIgnoredPostDTO } from '../../../../shared/dtos/IgnoredPostDTO';

import {
  IApiResponse,
  IPageInfo,
  IPostKeywords,
  IPostKeywordsDTO,
  IUpdatePostKeywordDTO,
} from '../../../../shared/dtos/PostKeywordsDTO';

import { IActionLogDTO } from '../../../../shared/dtos/ActionLogDTO';

import { IKeywordsDTO } from '../../../../shared/dtos/KeywordsDTO';

import './styles.scss';

const { TabPane } = Tabs;
const { Option } = Select;

const WP_DOMAIN = 'https://www.digitaltrends.com';
const KEYWORDS_PAGE_SIZE = 25;

const GSCKeywordsSummary: React.FC = () => {
  const [keywords, setKeywords] = useState<IPostKeywords[]>([]);
  const [keywordsPageInfo, setKeywordsPageInfo] = useState<IPageInfo>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [ignoredPosts, setIgnoredPosts] = useState<IIgnoredPostDTO[]>([]);
  const [actionLog, setActionLog] = useState<IActionLogDTO[]>([]);
  const [editRowKey, setEditRowKey] = useState<string>();
  const [editedRowKeys, setEditedRowKeys] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<IPostKeywords[]>([]);
  const [gscQueryDateRange, setGscQueryDateRange] = useState('30');
  const [form] = Form.useForm();

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const triggerNotification = (message: string, type: NotificationType = 'warning') => {
    notification[type]({
      message: type === 'warning' ? 'Ops something went wrong' : 'Notification',
      description: message,
    });
  };

  const loadKeywords = async (page = 1) => {
    try {
      setDataLoading(true);
      const keywordsRes = await api.get<IPostKeywordsDTO>(`/api/gsc-keywords/?limit=${KEYWORDS_PAGE_SIZE}&page=${page}&daysRange=${gscQueryDateRange}`);
      keywordsRes.edges.forEach((item, index) => {
        item.key = `post_${index}_${item.POST_ID}`;
        item.keywords.forEach((keyword, keywordIndex) => {
          keyword.key = `keyword_${keywordIndex}_${item.POST_ID}`;
        });
      });
      setKeywords(keywordsRes.edges);
      setKeywordsPageInfo(keywordsRes.pageInfo);
      setDataLoading(false);
    } catch (error) {
      triggerNotification(error.message);
    }
  };

  useEffect(() => {
    loadKeywords();
    // eslint-disable-next-line
  }, [gscQueryDateRange]);

  const setOrLoadKeywords = async (newKeywords: IPostKeywords[]) => {
    if (newKeywords.length > 5) {
      setKeywords(newKeywords);
    } else {
      triggerNotification('Loading new data...', 'info');
      await loadKeywords();
    }
  };

  const rowSelection = {
    // eslint-disable-next-line
    onChange: (rowKeys: any[], rows: IPostKeywords[]) => {
      setSelectedRows(rows);
    },
  };

  const changeEditedRowsClasses = (record: IPostKeywords) => {
    if (editedRowKeys.includes(record.key || '')) {
      return 'highlighted';
    }

    return '';
  };

  const updateAction = async (record: IPostKeywords, previousKeyword: string) => {
    const updateKeywordData = {
      site_id: record.SITE_CODE,
      post_id: record.POST_ID,
      keyword_before: previousKeyword,
      keyword_after: record.POST_KEYWORD,
    } as IUpdatePostKeywordDTO;

    if (updateKeywordData.keyword_before === updateKeywordData.keyword_after) {
      triggerNotification('Previous and Current keyword are the same', 'info');
      return;
    }

    try {
      const newEditedRowKeys = editedRowKeys.filter((item) => item !== record.key);
      setEditedRowKeys(newEditedRowKeys);
      const newKeywords = keywords.filter((item) => item.key !== record.key);
      await setOrLoadKeywords(newKeywords);

      await api.post<IApiResponse>('/api/gsc-keywords/', {
        body: updateKeywordData,
      });

      triggerNotification('Keyword was updated', 'info');
    } catch (error) {
      triggerNotification(error.message);
    }
  };

  const postKeywordCellRenderer = (text: string, record: IPostKeywords) => {
    if (editRowKey === record.key) {
      return <Form.Item name='keywords'>
        <Input />
      </Form.Item>;
    }
    return record.POST_KEYWORD;
  };

  const postKeywordCellEvents = (record: IPostKeywords, rowIndex: number) => ({
    onDoubleClick: () => {
      setEditRowKey(record.key);
      form.setFieldsValue({
        keywords: record.POST_KEYWORD,
      });
    },
    onBlur: (ev: React.SyntheticEvent) => {
      const inputValue = ev.target as HTMLInputElement;
      if (inputValue.value !== keywords[rowIndex].POST_KEYWORD) {
        if (inputValue.value === '' && keywords[rowIndex].POST_KEYWORD == null) {
          setEditRowKey('');
          return;
        }
        const keywordsState = [...keywords];
        keywordsState[rowIndex].PREV_KEYWORD = keywords[rowIndex].POST_KEYWORD || '';
        keywordsState[rowIndex].POST_KEYWORD = inputValue.value;
        setKeywords(keywordsState);
        setEditedRowKeys([...editedRowKeys, record.key || '']);
        setEditRowKey('');
      } else {
        setEditRowKey('');
      }
    },
  });

  const addIgnoredPost = async (record: IPostKeywords) => {
    const ignoredPostData = {
      post_id: record.POST_ID,
      site_id: record.SITE_CODE,
      post_title: record.POST_TITLE,
    } as IIgnoredPostDTO;

    try {
      const newEditedRowKeys = editedRowKeys.filter((item) => item !== record.key);
      setEditedRowKeys(newEditedRowKeys);
      const newKeywords = keywords.filter((item) => item.key !== record.key);
      await setOrLoadKeywords(newKeywords);
      setIgnoredPosts([...ignoredPosts, {
        post_id: record.POST_ID,
        site_id: record.SITE_CODE,
        post_title: record.POST_TITLE,
        key: `ignoredPost_${record.POST_ID}`,
      }]);

      const post = await api.post<IApiResponse>('/api/gsc-keywords-ignored-posts/', {
        body: ignoredPostData,
      });

      if (post.status === 'success') {
        triggerNotification('Post was added to ignore list', 'info');
      }
    } catch (error) {
      triggerNotification(error.message);
    }
  };

  const loadIgnoredPosts = async () => {
    try {
      const posts = await api.get<IIgnoredPostDTO[]>('/api/gsc-keywords-ignored-posts/');
      posts.forEach((item, index) => {
        item.key = `ignoredPost_${index}_${item.post_id}`;
      });
      setIgnoredPosts(posts);
    } catch (error) {
      triggerNotification(error.message);
    }
  };

  useEffect(() => {
    loadIgnoredPosts();
    // eslint-disable-next-line
  }, []);

  const removeIgnoredPost = async (record: IIgnoredPostDTO) => {
    try {
      const newIgnoredPosts = ignoredPosts.filter((item) => item.post_id !== record.post_id);
      setIgnoredPosts(newIgnoredPosts);

      await api.delete<IApiResponse>(`/api/gsc-keywords-ignored-posts/${record.post_id}/`);

      triggerNotification('Post was removed to ignore list', 'info');
    } catch (error) {
      triggerNotification(error.message);
    }
  };

  const expandedActionCellRendered = (text: string, record: IKeywordsDTO, topRowData: IPostKeywords) => {
    const recordData = { ...topRowData };
    recordData.POST_KEYWORD = record.QUERY;

    return <Space size="middle">
      <a
        onClick={() => updateAction(recordData, topRowData.POST_KEYWORD) }
      >Apply Keywords</a>
    </Space>;
  };

  const postKeywordActionCellRenderer = (text: string, record: IPostKeywords) => {
    const previousKeyword = typeof record.PREV_KEYWORD === 'string' ? record.PREV_KEYWORD : record.POST_KEYWORD;

    return (<>
      <div>
        <a onClick={() => updateAction(record, previousKeyword)}>Update keywords</a>
      </div>
      <div>
        <a onClick={() => addIgnoredPost(record)}>Ignore post</a>
      </div>
    </>);
  };

  const expandedRowRender = (topRowData: IPostKeywords) => {
    const postKeywordsExpandedColumns = [
      { title: 'Query/Keywords', dataIndex: 'QUERY', key: 'keywords' },
      { title: 'Clicks', dataIndex: 'CLICKS', key: 'clicks' },
      { title: 'Impression', dataIndex: 'IMPRESSIONS', key: 'impression' },
      {
        title: 'Action',
        key: 'operation',
        render: (text: string, record: IKeywordsDTO) => expandedActionCellRendered(text, record, topRowData),
      },
    ];

    return <Table columns={postKeywordsExpandedColumns} dataSource={topRowData.keywords} pagination={false} />;
  };

  const postsKeywordsColumns = [
    { title: 'Site', dataIndex: 'SITE_CODE', key: 'site' },
    { title: 'Post Id',
      dataIndex: 'POST_ID',
      key: 'postId',
      render: (text: string) => (<a target={'_blank'} href={`${WP_DOMAIN}/wp-admin/post.php?post=${text}&action=edit`}>{text}</a>) },
    { title: 'Post Title', dataIndex: 'POST_TITLE', key: 'postTitle' },
    { title: 'Author', dataIndex: 'POST_AUTHOR', key: 'author' },
    { title: 'Post Keyword',
      dataIndex: 'POST_KEYWORD',
      key: 'postKeywords',
      // eslint-disable-next-line
      onCell: (record: IPostKeywords, rowIndex: any) => postKeywordCellEvents(record, rowIndex),
      render: (text: string, record: IPostKeywords) => postKeywordCellRenderer(text, record) },
    { title: 'Action', key: 'operation', render: (text: string, record: IPostKeywords) => postKeywordActionCellRenderer(text, record) },
  ];

  const ignoredPostsColumns = [
    { title: 'Site', dataIndex: 'site_id', key: 'site' },
    { title: 'Post Id', dataIndex: 'post_id', key: 'postId' },
    { title: 'Post Title', dataIndex: 'post_title', key: 'postTitle' },
    { title: 'Action',
      key: 'operation',
      render: (text: string, record: IIgnoredPostDTO) => <a onClick={() => removeIgnoredPost(record)}>Remove from Ignored</a> },
  ];

  const actionLogColumns = [
    { title: 'Site', dataIndex: 'site_id', key: 'site' },
    { title: 'Post Id', dataIndex: 'post_id', key: 'postId' },
    { title: 'Action', dataIndex: 'action', key: 'action' },
    { title: 'Keyword before', dataIndex: 'keyword_before', key: 'keyword_before' },
    { title: 'Keyword After', dataIndex: 'keyword_after', key: 'keyword_after' },
    { title: 'Timestamp', dataIndex: 'created_at', key: 'created_at' },
  ];

  const loadActionLog = async () => {
    try {
      const actions = await api.get<IActionLogDTO[]>('/api/gsc-keywords-actions/');
      actions.forEach((item) => {
        item.key = `action_${item.action_id}`;
      });
      setActionLog(actions);
    } catch (error) {
      triggerNotification(error.message);
    }
  };

  useEffect(() => {
    loadActionLog();
    // eslint-disable-next-line
  }, [keywords, ignoredPosts]);

  const bulkIgnorePosts = async () => {
    try {
      const data: IIgnoredPostDTO[] = [];
      selectedRows.forEach((item) => {
        data.push({
          post_id: item.POST_ID,
          site_id: item.SITE_CODE,
          post_title: item.POST_TITLE,
        });
      });

      const newKeywords = _.difference(keywords, selectedRows);
      setSelectedRows([]);
      await setOrLoadKeywords(newKeywords);
      setIgnoredPosts([...ignoredPosts, ...data]);

      await api.post<IApiResponse>('/api/gsc-keywords-ignored-posts/bulk/', {
        body: data,
      });

      triggerNotification('Posts was added to ignore list', 'info');
    } catch (error) {
      triggerNotification(error.message);
    }
  };

  const bulkChangeKeywords = async () => {
    try {
      const data: IUpdatePostKeywordDTO[] = [];
      selectedRows.forEach((item) => {
        const keywordRow = item.keywords.sort((a, b) => b.CLICKS - a.CLICKS).shift();

        data.push({
          post_id: item.POST_ID,
          site_id: item.SITE_CODE,
          keyword_after: keywordRow?.QUERY || '',
          keyword_before: item.POST_KEYWORD,
        });
      });

      const newKeywords = _.difference(keywords, selectedRows);
      await setOrLoadKeywords(newKeywords);
      setSelectedRows([]);

      await api.post<IApiResponse>('/api/gsc-keywords/bulk/', {
        body: data,
      });

      triggerNotification('Posts keywords were updated, reloading data...', 'info');
    } catch (error) {
      notification.open({
        message: 'Ops something went wrong',
        description: error.message,
      });
    }
  };

  const paginationChanged = async (page: number) => {
    await loadKeywords(page);
  };

  return (
    <>
      <HeaderPanel/>
      {editedRowKeys.length > 0 && <Alert
        message="Don't forget to save changed keywords"
        type="warning"
      />}
      <Tabs defaultActiveKey="1">
        <TabPane
          tab='Keywords mismatch posts list'
          key="1"
        >
          <Spin spinning={dataLoading}>
            <Dropdown.Button overlay={
              <Menu>
                <Menu.Item key='ignore_list' onClick={bulkIgnorePosts}>Add to ignored list</Menu.Item>
                <Menu.Item key='apply_best_keyword' onClick={bulkChangeKeywords}>Apply the best keywords</Menu.Item>
              </Menu>
            }>Actions</Dropdown.Button>
            <Select
              placeholder="Select a person"
              optionFilterProp="children"
              defaultValue={gscQueryDateRange}
              onChange={(value) => setGscQueryDateRange(value)}
            >
              <Option value={'30'}>Last 30 days</Option>
              <Option value={'90'}>Last 3 month</Option>
              <Option value={'180'}>Last 6 month</Option>
              <Option value={'360'}>Last Year</Option>
            </Select>
            <Form form={form}>
              <Table
                className="components-table-demo-nested"
                rowClassName={changeEditedRowsClasses}
                columns={ postsKeywordsColumns }
                expandable={{ expandedRowRender }}
                dataSource={keywords}
                rowSelection={{ ...rowSelection }}
                pagination={{ onChange: paginationChanged, pageSize: KEYWORDS_PAGE_SIZE, total: keywordsPageInfo?.total }}
              />
            </Form>
          </Spin>
        </TabPane>
        <TabPane
          tab='Ignore posts lists'
          key="2"
        >
          <Table
            className="components-table-demo-nested"
            columns={ignoredPostsColumns}
            dataSource={ignoredPosts}
          />
        </TabPane>
        <TabPane
          tab='Actions Log'
          key="3"
        >
          <Table
            className="components-table-demo-nested"
            columns={actionLogColumns}
            dataSource={actionLog}
            pagination={{ pageSizeOptions: ['25', '50', '100', '200'], pageSize: 25, hideOnSinglePage: true }}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default GSCKeywordsSummary;
