/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import {
  Select,
  Spin,
} from 'antd';

import { valueType } from 'antd/lib/statistic/utils';
import User from '../../../shared/dtos/UserDTO';
import api from '../services/api';
import { IPaginatedQuery } from '../../../shared/dtos/IPaginatedQuery';
import { useAuth } from '../hooks/auth';

const { Option } = Select;

export interface ISearchUsersProps {
  onChange?(usersIds: number[]): void
  style?: React.CSSProperties
}

interface IItemOption {
  value: number
  label: string
}

const SearchUsers: React.FC<ISearchUsersProps> = ({
  onChange, style,
}) => {
  let delaySearch: number | ReturnType<typeof setTimeout> = 0;
  const { handleError } = useAuth();
  const [fetching, setFetching] = React.useState(false);
  const [users, setUsers] = React.useState<User[]>([]);

  const fetchUsers = useCallback(async (username: string): Promise<void> => {
    try {
      setFetching(true);
      const foundUsers = await api.get<IPaginatedQuery<User>>('/api/users', {
        params: {
          query: username,
        },
      });
      setUsers(foundUsers.nodes);
    } catch (err) {
      handleError(err, 'Users could not be searched.', true);
    } finally {
      setFetching(false);
    }
  }, [handleError]);

  const handleSearchUsers = useCallback((iValue: string) => {
    if (delaySearch) {
      clearTimeout(delaySearch as ReturnType<typeof setTimeout>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    delaySearch = setTimeout(() => {
      fetchUsers(iValue);
    }, 1000);
  }, []);

  const handleChangeSelection = useCallback((selectedUsers: valueType) => {
    if (onChange) {
      const listOptions = selectedUsers as unknown as IItemOption[];
      onChange(listOptions.map((option) => option.value));
    }
  }, [onChange]);

  return (
    <Select<valueType>
      labelInValue
      style={style}
      filterOption={false}
      onSearch={handleSearchUsers}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      mode="multiple"
      placeholder="Select users"
      onChange={handleChangeSelection}
    >
      {
          users.map((iUser) => (
            <Option key={iUser.id} value={iUser.id}>
                {`${iUser.first_name} ${iUser.last_name}`}
            </Option>
          ))
        }
    </Select>
  );
};

export default SearchUsers;
