import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Select,
  Spin,
  Tag as AntTag,
} from 'antd';

import { Tag } from '../../../../shared/dtos/KeywordDTO';
import { IChangeFilterProps } from '.';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

const TagsFilter: React.FC<IChangeFilterProps> = ({ onChange }) => {
  const { handleError } = useAuth();
  const [tags, setTags] = useState<Tag[]>([]);
  const [fetching, setFetching] = useState(false);

  const loadTags = useCallback(async (): Promise<void> => {
    try {
      setFetching(true);
      const receveivedTags = await api.get<Tag[]>('/api/keywords/tags');
      setTags(receveivedTags);
    } catch (err) {
      handleError(err, 'Tags could not be searched.', true);
    } finally {
      setFetching(false);
    }
  }, [handleError]);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  const tagRender = useCallback(({
    label, value, closable, onClose,
  }) => (
      <AntTag
        key={value}
        color='cyan'
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}>
        {label}
      </AntTag>
  ), []);

  const tagOptions = useMemo(() => tags.map((tag) => ({
    label: tag.tag,
    value: tag.tag.toLowerCase(),
  })), [tags]);

  const handleChangeTags = useCallback((values) => {
    const foundTags = tags.filter((tag) => values.includes(tag.tag.toLowerCase()));
    const tagsIds = foundTags.map((fTag) => fTag.tag_id);
    onChange({
      tags: tagsIds,
    });
  }, [onChange, tags]);

  return (
    <div>
      <Select
        mode="multiple"
        showArrow
        notFoundContent={fetching ? <Spin size="small" /> : null}
        tagRender={tagRender}
        onChange={handleChangeTags}
        style={{ marginRight: 15, minWidth: 260 }}
        options={tagOptions}
      />
    </div>
  );
};

export default TagsFilter;
