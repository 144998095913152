import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  format, parseISO,
} from 'date-fns';
import {
  Card,
  Descriptions,
  Badge,
  Typography,
} from 'antd';

import {
  MinusCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Button from 'antd/es/button';
import { useAuth } from '../../hooks/auth';
import User from '../../../../shared/dtos/UserDTO';
import api from '../../services/api';

const { Text, Link } = Typography;

interface ShowUserParams {
  id: number
}

const UserDetails: React.FC<ShowUserParams> = ({ id }) => {
  const { handleError, user: loggedUser } = useAuth();
  const [user, setUser] = useState<User>();
  const [finding, setFinding] = useState(false);

  const loadUser = useCallback(async () => {
    const url = id === loggedUser.id ? '/api/profile' : `/api/users/${id}`;
    try {
      setFinding(true);
      const foundUser = await api.get<User>(url);
      setUser(foundUser);
    } catch (err) {
      handleError(err);
    } finally {
      setFinding(false);
    }
  }, [handleError, id, loggedUser.id]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const lastSigninFormatted = useMemo(() => {
    if (!user || !user.last_sign_in_at) {
      return 'unkown';
    }
    const dt = parseISO(user.last_sign_in_at.toString());
    const dtFormatted = format(dt, 'yyyy-MM-dd HH:mm');
    return dtFormatted;
  }, [user]);

  return (
    <>
      {!finding && user ? (
          <Card>
            <Descriptions
              title={(
                <span style={{
                  marginLeft: 10,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <span>{`${user.first_name} ${user.last_name}`}</span>
                  <Link
                    type="secondary"
                    href={`mailto: ${user.username}`}>
                      {user.username}
                  </Link>
                </span>
              )}
              extra={(
                <Button
                  shape="round"
                  style={{
                    cursor: 'auto',
                    marginRight: 10,
                  }}
                  danger={!user.active}
                  icon={<Badge status="processing" color={user.active ? 'green' : 'red'}/>}>
                    {user.active ? 'Active' : 'Locked'} User
                </Button>
              )}
              layout="horizontal"
              size="middle"
              bordered>
              <Descriptions.Item
                label="First Name / Last Name"
                labelStyle={{
                  width: 350,
                }}
                span={3}>
                {user.first_name} / {user.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
              <Link
                type="secondary"
                href={`mailto: ${user.username}`}>
                  {user.username}
              </Link>
              </Descriptions.Item>
              <Descriptions.Item label="Role" span={3}>{user.role?.description}</Descriptions.Item>
              <Descriptions.Item label="Sign In Count / Last Sign In At" span={3}>
                {user.sign_in_count}
                <Text type="secondary" style={{
                  marginLeft: 10,
                }}>
                  {lastSigninFormatted}
                </Text>
              </Descriptions.Item>
              <Descriptions.Item label="Articles Count" span={3}>
                <MinusCircleOutlined title="Not availabe" />
              </Descriptions.Item>
            </Descriptions>
          </Card>
      ) : (
          <LoadingOutlined spin />
      )}
    </>
  );
};

export default UserDetails;
