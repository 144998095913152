import React, { useMemo } from 'react';
import { Tag } from 'antd';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

interface IArticleStatusProps {
  status: string
  style?: React.CSSProperties
}

const ArticleStatusText: React.FC<IArticleStatusProps> = ({ status, style }) => {
  const humanized = status ? status.charAt(0).toUpperCase() + status.slice(1) : '';

  const articleStatusProps = useMemo(() => {
    switch (status) {
      case 'draft':
        return {
          icon: <EditOutlined />,
          color: 'default',
        };
      case 'approved':
        return {
          icon: <ClockCircleOutlined />,
          color: 'blue',
        };
      case 'rejected':
        return {
          icon: <CloseCircleOutlined />,
          color: 'red',
        };
      case 'returned':
        return {
          icon: <QuestionCircleOutlined />,
          color: 'gold',
        };
      case 'canceled':
        return {
          icon: <MinusCircleOutlined />,
          color: 'cyan',
        };
      case 'published':
        return {
          icon: <CheckCircleOutlined />,
          color: 'green',
        };
      default:
        return {
          icon: <PlusCircleOutlined />,
          color: 'volcano',
        };
    }
  }, [status]);

  return (
    <Tag
      style={{
        width: 120,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
      icon={articleStatusProps.icon}
      color={articleStatusProps.color}>
      {humanized}
    </Tag>
  );
};

export default ArticleStatusText;
