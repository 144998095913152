import React, {
  useState,
  useCallback,
} from 'react';
import {
  Input,
  Table,
} from 'antd';
import api from '../../services/api';
import HeaderPanel from '../../components/header_panel';
import { keywordSubmenuActions } from '../keywords/keywords_summary';

type GoogleSearchResults = string[];

const KeywordResearch: React.FC = () => {
  const [results, setResults] = useState<GoogleSearchResults[]>();
  const [questions, setQuestions] = useState<string[]>();

  const renderTableCell = (record: string) => (
    <>
      {record}
    </>
  );

  const resultsColumns = [
    {
      title: 'Results',
      render: renderTableCell,
    },
  ];

  const questionsColumns = [
    {
      title: 'People also ask',
      render: renderTableCell,
    },
  ];

  const handleQuery = useCallback(async (e) => {
    const keyword = e.target.value;
    const googleResults = await api.get<GoogleSearchResults[]>('/api/keyword-research', {
      params: {
        keyword,
      },
    });
    const googleQuestions = await api.get<string[]>('/api/keyword-research/people-also-ask', {
      params: {
        keyword,
      },
    });

    setResults(googleResults);
    setQuestions(googleQuestions);
  }, []);

  return (
    <>
      <HeaderPanel
        title="Keyword Research"
        hideHome
        actions={keywordSubmenuActions}
      />
      <Input style={{ marginBottom: 12 }} onPressEnter={handleQuery} />
      <Table
        dataSource={questions as unknown as Record<string, string>[]}
        columns={questionsColumns}
        pagination={false}
        style={{ marginBottom: 16 }}
      />
      <Table
        dataSource={results}
        columns={resultsColumns}
        pagination={false}
        style={{ marginBottom: 16 }}
      />
    </>
  );
};

export default KeywordResearch;
