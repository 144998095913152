/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  useCallback,
  useState,
} from 'react';

import { PlusOutlined } from '@ant-design/icons';

import {
  FormInstance,
  Input,
  Tag,
} from 'antd';

interface IKeywordFormProps {
  form: FormInstance
  keyword?: string
  newKeywords?: string[]
  onRemove(keyword: string): void
  onAdd(keyword: string): void
}

const ArticleKeywordForm: React.FC<IKeywordFormProps> = ({
  keyword, newKeywords, onAdd, onRemove,
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  const inputRef = React.createRef<Input>();

  const handleAddKeywordClick = useCallback(() => {
    setInputVisible(!inputVisible);
    inputRef.current?.focus();
  }, [inputRef, inputVisible]);

  const handleInputConfirm = useCallback((event) => {
    const { value } = event.target;
    onAdd(value);
    setInputValue('');
    setInputVisible(false);
  }, [onAdd]);

  const handleInputChange = useCallback((event) => {
    const { value } = event.target;
    setInputValue(value);
  }, []);

  const handleKeywordRemove = useCallback((keywordToRemove: string) => {
    onRemove(keywordToRemove);
  }, [onRemove]);

  return (
    <>
      <div>
        <Tag>
          {keyword}
        </Tag>
        {
          newKeywords && newKeywords.map((newKeyword) => (
            <Tag
              closable
              key={newKeyword}
              onClick={() => handleKeywordRemove(newKeyword)}
            >
              {newKeyword}
            </Tag>
          ))
        }
        {
          inputVisible && (
            <Input
              ref={inputRef}
              type="text"
              size="small"
              value={inputValue}
              className="tag-input"
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          )
        }
        {
          !inputVisible && (
          <Tag
            style={{
              background: '#fff',
              borderStyle: 'dashed',
            }}
            onClick={handleAddKeywordClick}
          >
            <PlusOutlined/> Add Keyword
          </Tag>
          )
        }
      </div>
    </>
  );
};

export default ArticleKeywordForm;
