import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Collapse,
  Image,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import Link from 'antd/lib/typography/Link';

import {
  formatDistance,
  parseISO,
  format,
} from 'date-fns';

import { IProduct } from '../../../../shared/dtos/ProductDTO';
import api from '../../services/api';
import { IArticle } from '../../../../shared/dtos/ArticleDTO';
import { IMAGE_NOT_FOUND_AVG } from '../../utils';
import UserLink from '../Users/user_link';
import ArticleStatusText from '../Articles/article_status_text';

const { Panel } = Collapse;

interface IProductArticlesModalProps {
  product?: IProduct
  onClose(): void
}

const ProductArticlesModal: React.FC<IProductArticlesModalProps> = ({
  product, onClose,
}) => {
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState<IArticle>();

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const loadArticles = useCallback(async () => {
    if (!product) {
      return;
    }
    try {
      setLoading(true);
      const myArticles = await api.get<IArticle[]>(`/api/products/${product.product_id}/articles`);
      setArticles(myArticles);
      if (myArticles.length > 0) {
        setCollapsed(myArticles[0]);
      }
    } catch (err) {
      onClose();
      notification.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [onClose, product]);

  useEffect(() => {
    loadArticles();
  }, [loadArticles]);

  const generateItemDetail = useCallback((title: string, content: React.ReactElement | string | undefined) => (
    <Row>
      <Col span={8}>
        <Text strong>{title}</Text>
      </Col>
      <Col span={16}>
        <Text>{content}</Text>
      </Col>
    </Row>
  ), []);

  const linkAsana = useCallback((article: IArticle) => {
    if (article && article.asana_url) {
      return <Link target="_blank" href={article.asana_url}>{article.gid}</Link>;
    }
    return <Text type="secondary">Pending</Text>;
  }, []);

  const formatdDate = useCallback((date: Date) => {
    const dt = parseISO(date.toString());
    const dtAgoFormatted = formatDistance(dt, new Date(), { addSuffix: true });
    const dtFormatted = format(dt, 'yyyy-MM-dd HH:mm');
    return (
      <Tooltip title={dtFormatted}>
        <Text type="secondary" style={{
          fontSize: 12,
        }}>{dtAgoFormatted}</Text>
      </Tooltip>
    );
  }, []);

  const handleCollapseArticle = useCallback((options: string | string[]) => {
    const articleId = typeof options === 'object' ? options[options.length - 1] : options;
    const selected = articles.find((article) => article.article_id === Number(articleId));
    setCollapsed(selected);
  }, [articles]);

  const generateArticleContent = useCallback((article: IArticle) => {
    let keywords = '';
    if (article.keywords) {
      keywords = article.keywords.map((k) => k.keyword).join(', ');
    }
    return (
      <Panel
        extra={formatdDate(article.created_at)}
        header={article.title || ''}
        key={article.article_id}>
        <Row>
          <Col xs={7}>
            {article.image_url ? (
              <Image
                fallback={IMAGE_NOT_FOUND_AVG}
                width={170}
                src={article.image_url}
              />
            ) : (
              <Image
                preview={false}
                width={170}
                src={IMAGE_NOT_FOUND_AVG}
              />
            )}
          </Col>
          <Col xs={17}>
            <Space direction="vertical" style={{
              width: '100%',
            }}>
              {generateItemDetail('Keywords:', keywords)}
              {generateItemDetail('Site:', (
                <>
                  <img style={{ width: '18px' }}src={article.site?.logo_url} />
                  {' '}
                  {article.site?.display_name}
                </>
              ))}
              {article.user && generateItemDetail('Requested By:', <UserLink user={article.user} />)}
              {generateItemDetail('Status:', (
                <ArticleStatusText status={article.status} />
              ))}
              {generateItemDetail('Validated by:', (
                <Text type="secondary">{article.qa_user ? <UserLink user={article.qa_user} /> : 'Pending'}</Text>
              ))}
              {generateItemDetail('Asana:', linkAsana(article))}
              {article.wordpress_id
                && generateItemDetail('Wordpress:', `${article.wordpress_id}`)
              }
            </Space>
          </Col>
        </Row>
      </Panel>
    );
  }, [formatdDate, generateItemDetail, linkAsana]);

  return (
    <Modal
      title='Product Articles'
      visible={!!product}
      okButtonProps={{
        hidden: true,
      }}
      cancelText="Close"
      onCancel={handleCloseModal}
      width={750}
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <Collapse
            activeKey={collapsed ? [collapsed.article_id] : []}
            onChange={handleCollapseArticle}
            >
            {articles.map((article) => generateArticleContent(article))}
          </Collapse>
        </>
      )}
    </Modal>
  );
};

export default ProductArticlesModal;
